import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { authCtx } from "src/api/AuthProvider";
import { StoreContext } from "src/database/store/StoreProvider";
import { upsertCustomer } from "src/models/Organization";

type AddCustomerProps = {
  closeModal: () => void;
  orgId?: string;
}

const AddCustomer: FC<AddCustomerProps> = ({ closeModal, orgId = '' }) => {
  const { store } = useContext(StoreContext);
  const { justSync } = useContext(authCtx);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [phone, setPhone] = useState('');
  const [website, setWebsite] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const org = store.organizations[orgId];

  const isSubmissionReady = name && address && city && state && zip && phone;

  useEffect(() => {
    if (!org) return;
    setName(org.name || '');
    setAddress(org.address || '');
    setCity(org.city || '');
    setState(org.state || '');
    setZip(org.zipcode || '');
    setPhone(org.phone || '');
    setWebsite(org.website || '');
  }, [org])

  const handleSubmit = useCallback(async () => {
    if (!isSubmissionReady) return;
    setIsProcessing(true);
    await upsertCustomer({ name, address, city, state, zip, phone, website, orgId });
    closeModal();
    justSync();
    setIsProcessing(false);
  }, [name, address, city, state, zip, phone, website, orgId, isSubmissionReady, closeModal]);

  return (
    <div className="modalContainer">
      <div className="modalHeader">
        <h2>{org ? org.name : 'New Customer'}</h2>
      </div>
      <div className="content">
        <label>Customer Name</label>
        <input
          onChange={({ target }) => setName(target.value)}
          value={name} />
        <label>Address</label>
        <input
          onChange={({ target }) => setAddress(target.value)}
          value={address} />
        <div className="multiCol" style={{ gridTemplateColumns: '2fr 1fr 1fr' }}>
          <div>
            <label>City</label>
            <input
              onChange={({ target }) => setCity(target.value)}
              value={city} />
          </div>
          <div>
            <label>State</label>
            <input
              onChange={({ target }) => setState(target.value)}
              value={state} />
          </div>
          <div>
            <label>Zip</label>
            <input
              onChange={({ target }) => setZip(target.value)}
              value={zip} />
          </div>
        </div>
        <label>Phone</label>
        <input
          onChange={({ target }) => setPhone(target.value)}
          value={phone} />
        <label>Website</label>
        <input
          onChange={({ target }) => setWebsite(target.value)}
          value={website} />
      </div>
      <div className="footer">
        <button disabled={isProcessing} className="cancel" onClick={closeModal}>Cancel</button>
        <button disabled={!isSubmissionReady || isProcessing} onClick={handleSubmit}>{org ? 'Update' : 'Add Customer'}</button>
      </div>
    </div>
  );
};

export default AddCustomer;