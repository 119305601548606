import create from "zustand";
interface navState {
  isCallibrationAutoSave: string;
  setIsCallibrationAutoSave: (isCallibrationAutoSave: string) => void;
  isToogle: string;
  setIsToogle: (isCallibrationAutoSave: string) => void;
  isSensorCalibrationPage: boolean;
  setIsSensorCalibrationPage: (setIsSensorCalibrationPage: boolean) => void;
}

const useNavStore = create<navState>((set) => ({
    isCallibrationAutoSave: "NO",
  setIsCallibrationAutoSave: (isCallibrationAutoSave) =>
    set((state) => ({
        ...state,
      isCallibrationAutoSave
    })),
    isToogle: "NO",
    setIsToogle: (isToogle) =>
    set((state) => ({
        ...state,
        isToogle
    })),
    isSensorCalibrationPage:false,
    setIsSensorCalibrationPage: (isSensorCalibrationPage) =>
    set((state) => ({
        ...state,
        isSensorCalibrationPage
    })),
    
}));

export default useNavStore;

