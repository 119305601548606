import React, { useCallback, useContext, useEffect, useState } from 'react';
import _, { compact, divide, filter, map, orderBy, reduce } from 'lodash';
import { authCtx, webAuth } from 'src/api/AuthProvider';
// import { authCtx, webAuth } from 'src/api/LoginAuthProvider';
import useCurrentUser from 'src/hooks/useCurrentUser';
import ResetPassword from './ResetPassword';
import styles from './styles.module.css';
import logo from '../../assets/img/fcs-logo.png';
import { database } from 'src/database';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { useHistory } from 'react-router';
import { hasUnsyncedChanges } from '@nozbe/watermelondb/sync';
import Bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { forceDbReset } from 'src/database/utils';
import { Auth } from 'aws-amplify';
import urls from 'src/api/urls';
import Loader from '../modals/Loaded/Loader';

export enum View {
	LOGIN = 0,
	FORGOT = 1,
}
/**
 * This is only used to check if the stored token was for a different environment and display helpful suggestions
 */
const authDomainToStringMap: Record<string, string> = {
	'https://appstem-fcs-test.us.auth0.com/': 'test [internal]',
	'https://dev-4ert80pv.auth0.com/': 'dev [internal]',
	'https://dev-gnlygm90.us.auth0.com/': 'staging [internal]',
	'https://dev-l64pyjuz.us.auth0.com/': 'staging [client]',
	'https://dev-n6zhy9pk.us.auth0.com/': 'production',
};
const Login = () => {
	const history = useHistory();

	const { setToken, isLoadingUser, user, tokenIsExpired, isSyncing } =
		useContext(authCtx);
	// console.log('setToken=',setToken);
	// console.log('user=',user);
	// console.log('tokenIsExpired=',tokenIsExpired);
	// console.log('isLoadingUser=',isLoadingUser);
	// console.log('isSyncing=',isSyncing);

	const dbUser = useCurrentUser();
	const [email, setEmail] = useState<string>('');
	const [curView, setCurView] = useState(View.LOGIN);
	const [password, setPassword] = useState<string>('');
	const [error, setError] = useState('');
	const [errorList, setErrorList] = useState<any>([]);
	const [isLoadingAuth, setIsLoadingAuth] = useState(false);
	const [requireNewPassword, setRequireNewPassword] = useState(false);
	const [newPassword, setNewPassword] = useState<string>('');
	const oldToken = localStorage.getItem('token');
	let isLoading;
	// const useFallback = true;

	// console.log('setToken useFallback==============================');
	// useEffect(() => {
	// 	if (useFallback) return;
	// 	if (!window.location.hash) return;
	// 	webAuth.parseHash({ hash: window.location.hash }, (err, authResult) => {
	// 		if (err) return console.warn(err);
	// 		if (!authResult?.accessToken) return;
	// 		setToken!(authResult.accessToken);
	// 	});
	// }, [useFallback, setToken]);

	const authenticateAsRegularUser = async (user: any) => {
		let token: string;

		try {
			token = user.getSignInUserSession().getAccessToken().getJwtToken();
			if (!token) {
				throw new Error('Token not found');
			}
			const newToken = token;
			let savedToken = oldToken;
			const savedUserJson = localStorage.getItem('user');
			const savedUser = savedUserJson
				? JSON.parse(savedUserJson)
				: savedUserJson;
			const noLocalChanges =
				database && (await hasUnsyncedChanges({ database })) === false;
			let doReset = false;
			if (noLocalChanges) {
				doReset = true;
			} else {
				if (oldToken) {
					const oldDecoded = jwtDecode<JwtPayload>(oldToken);
					const newDecoded = jwtDecode<JwtPayload>(newToken);
					const isSameUser = oldDecoded.sub === newDecoded.sub;
					const isSameEnv = oldDecoded.iss === newDecoded.iss;
					const isSameAsSavedUser =
						Boolean(oldDecoded.sub) &&
						Boolean(oldDecoded.iss) &&
						isSameUser &&
						isSameEnv;
					if (!isSameAsSavedUser) {
						let message =
							'Warning: there are unsaved local changes associated with a different ';
						if (!isSameUser) {
							message = message + `user: (${savedUser.email})`;
						}
						if (!isSameEnv) {
							const env = authDomainToStringMap[oldDecoded.iss!];
							if (!isSameUser) {
								message = message + ' and ';
							}
							message = message + `environment: (${env})`;
						}
						message += `.\n\nPlease press Cancel and login with the specified user if you'd like to attempt to preserve your data.\n\nOtherwise, press OK to discard local changes and continue login.`;
						doReset = window.confirm(message);
					} else {
						savedToken = newToken;
					}
				}
			}

			if (doReset) {
				savedToken = newToken;
				await forceDbReset({
					database,
					skipConfirmation: true,
				});
			}
			if (savedToken && savedToken !== oldToken) {
				setToken!(savedToken);
			}
			setIsLoadingAuth(false);
			return savedToken;
		} catch (error) {
			localStorage.removeItem('user');
			throw error;
		}
	};

	const authenticateAsGuestUser = (user: any) => {
		return authenticateAsRegularUser(user);
	};

	const handleGuestLogin = async (accessToken: string, props: any) => {
		console.log('props--------', props);

		const url = '/users';
		return fetch(`${urls.base}${url}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify({
				id: props.id,
				name: props.name,
				email: props.email,
				roleId: props.roleId,
				workgroupId: props.workgroupId || '',
				UserAttributes: props.attrs,
			}),
		});
	};

	const login = async () => {
		let user: any,
			isRegularUser = true;

		try {
			user = await Auth.signIn(email, password);
			if (requireNewPassword) {
				if (!newPassword.length) {
					return;
				}
				const { requiredAttributes } = user.challengeParam;
				const guestUser = await Auth.completeNewPassword(
					user,
					newPassword,
					requiredAttributes
				);

				user = await Auth.signIn(email, newPassword);

				console.log('REQUIRE PASSWORD USER, GUESTUSER', user, guestUser);
				const attrs = user.attributes;
				console.log('attrs----', attrs);

				let token = user
					.getSignInUserSession()
					.getAccessToken()
					.getJwtToken();

				console.log('ATTRS', attrs);
				console.log('ATTRS [sub]', attrs['sub']);
				console.log('TOKEN', token);

				const response = await handleGuestLogin(token, {
					id: attrs['sub'],
					name: attrs['custom:name'],
					email: attrs['email'],
					roleId: attrs['custom:roleid'],
					workgroupId: attrs['custom:workgroupId'] || undefined,
					UserAttributes: attrs,
				});
				console.log('RESPONSE', response);
				isRegularUser = false;
				user = guestUser;
			} else {
				if (
					'challengeName' in user &&
					user.challengeName === 'NEW_PASSWORD_REQUIRED'
				) {
					console.log('REQUIRE NEW PASSWORD');
					setRequireNewPassword(true);
					return;
				} else {
					console.log('EXISTING PASSWORD');
				}
			}

			console.log('USER', isRegularUser, user);

			if (isRegularUser) {
				await authenticateAsRegularUser(user);
			} else {
				await authenticateAsGuestUser(user);
			}
		} catch (error) {
			console.error('Something went wrong', error);
		}
	};

	const unsafeLogin = useCallback(async () => {
		setError('');
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		const raw = JSON.stringify({
			grant_type: 'password',
			client_id: process.env.REACT_APP_FALLBACK_CLIENT_ID,
			client_secret: process.env.REACT_APP_FALLBACK_SECRET,
			username: email,
			password: password,
			audience: process.env.REACT_APP_AUDIENCE,
			realm: 'Username-Password-Authentication',
		});
		const requestOptions = {
			method: 'POST',
			headers,
			body: raw,
			redirect: 'follow' as any,
		};
		let tokenResponse;
		let response;
		setIsLoadingAuth(true);
		try {
			tokenResponse = await fetch(
				`https://${process.env.REACT_APP_AUTH_DOMAIN}/oauth/token`,
				requestOptions
			);
			response = await tokenResponse.json();
		} catch (err) {
			Bugsnag.notify(err as any);
			setIsLoadingAuth(false);
			setError('Could not log in at this time. Please try again later.');
			return oldToken;
		}

		console.log('response--login--', response);

		const newToken = response.access_token;
		let savedToken = oldToken;
		const savedUserJson = localStorage.getItem('user');
		const savedUser = savedUserJson
			? JSON.parse(savedUserJson)
			: savedUserJson;
		const noLocalChanges =
			database && (await hasUnsyncedChanges({ database })) === false;
		let doReset = false;
		if (noLocalChanges) {
			doReset = true;
		} else {
			if (oldToken) {
				const oldDecoded = jwtDecode<JwtPayload>(oldToken);
				const newDecoded = jwtDecode<JwtPayload>(newToken);
				const isSameUser = oldDecoded.sub === newDecoded.sub;
				const isSameEnv = oldDecoded.iss === newDecoded.iss;
				const isSameAsSavedUser =
					Boolean(oldDecoded.sub) &&
					Boolean(oldDecoded.iss) &&
					isSameUser &&
					isSameEnv;
				if (!isSameAsSavedUser) {
					let message =
						'Warning: there are unsaved local changes associated with a different ';
					if (!isSameUser) {
						message = message + `user: (${savedUser.email})`;
					}
					if (!isSameEnv) {
						const env = authDomainToStringMap[oldDecoded.iss!];
						if (!isSameUser) {
							message = message + ' and ';
						}
						message = message + `environment: (${env})`;
					}
					message += `.\n\nPlease press Cancel and login with the specified user if you'd like to attempt to preserve your data.\n\nOtherwise, press OK to discard local changes and continue login.`;
					doReset = window.confirm(message);
				} else {
					savedToken = newToken;
				}
			}
		}
		if (doReset) {
			savedToken = newToken;
			await forceDbReset({
				database,
				skipConfirmation: true,
			});
		}
		if (savedToken && savedToken !== oldToken) {
			setToken!(savedToken);
		}
		setIsLoadingAuth(false);
		return savedToken;
	}, [email, password, setToken, oldToken, history, database]);

	const isLoadingProfile = user && !dbUser && !tokenIsExpired; // TODO: fix this hacky assumption;
	isLoading = isLoadingAuth || isLoadingUser || isLoadingProfile || isSyncing;

	function validatePassword() {		
		setErrorList('');
		// let value = newPassword;
		let err: any = [];
		if (newPassword) {
			const isWhitespace = /^(?=.*\s)/;
			if (isWhitespace.test(newPassword)) {
				console.log('Password must not contain Whitespace.');
				err.push('Password must not contain Whitespace.');
			}

			const isContainsUppercase = /^(?=.*[A-Z])/;
			if (!isContainsUppercase.test(newPassword)) {
				err.push('Password must have at least one Uppercase Character.');
			}
			const isContainsLowercase = /^(?=.*[a-z])/;
			if (!isContainsLowercase.test(newPassword)) {
				err.push('Password must have at least one Lowercase Character.');
			}

			const isContainsNumber = /^(?=.*[0-9])/;
			if (!isContainsNumber.test(newPassword)) {
				err.push('Password must contain at least one Digit.');
			}

			const isContainsSymbol =
				/^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/;
			if (!isContainsSymbol.test(newPassword)) {
				err.push('Password must contain at least one Special Symbol.');
			}
			const isValidLength = /^.{8,16}$/;
			if (!isValidLength.test(newPassword)) {
				err.push('Password must be 8-16 Characters Long.');
			}
		}
		if (err.length > 0) {
			setErrorList((errs: any) => [...errs, ...err]);
			setNewPassword('');
			return;
		} else {
			setErrorList('');
			// setNewPassword(newPassword);
			login();
		}
	}
	return (
		<div className={styles.container}>
			{curView === View.LOGIN && (
				<div className={styles.leftSide}>
					<div className={styles.loging}>
						<div className={styles.company}>
							<img
								src={logo}
								className={styles.companyLogo}
								alt="logo"
							/>
							<p className={styles.companyName}>OpCen</p>
						</div>
						<p className={styles.pageTitle}>Login</p>
						<label className={styles.emailHint}>Email Address</label>
						<div className={styles.emailInput}>
							<input
								className={styles.emailField}
								value={email}
								type="text"
								name="email"
								placeholder="email"
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						{requireNewPassword ? (
							<div className={styles.passwordInput}>
								<label className={styles.passwordHint}>
									Create new password
								</label>
								<input
									className={styles.passwordField}
									value={newPassword}
									type="password"
									name="create-new-password"
									onChange={(e) => setNewPassword(e.target.value)}
								/>
							</div>
						) : (
							<div className={styles.passwordInput}>
								<label className={styles.passwordHint}>Password</label>
								<input
									className={styles.passwordField}
									value={password}
									type="password"
									name="password"
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
						)}
						<div className={styles.checkfoo}>
							<div className={styles.check}>
								<input
									className={styles.stayLoggedInCheckbox}
									type="checkbox"
								/>
								<p className={styles.stayLoggedInCheckboxDescription}>
									Keep me logged in
								</p>
							</div>

							<button
								className={styles.forgotPasswordButton}
								onClick={() => setCurView(View.FORGOT)}
							>
								Forgot your password?
							</button>
						</div>

						<button
							disabled={(!password && !newPassword) || !email}
							className={styles.loginButton}
							onClick={validatePassword}
							// onClick={login}
						>
							Login
						</button>
						{error && <p className={styles.errorDescription}>{error}</p>}
					<ul className={styles.errListDiv}>
					{errorList.length > 0 &&
								_.map(errorList, (err) => {
									return (
										<li key={err} className={styles.ValidationErrorList}>
											{err}
										</li>
									);
								})}
					</ul>
							
					
					</div>
				</div>
			)}
			{curView === View.FORGOT && (
				<ResetPassword goBack={() => setCurView(View.LOGIN)} />
			)}
			{isLoading && <Loader closeModal={() => false} />}
		</div>
	);
};

export default Login;
