import _ from 'lodash';
import React, { FC, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StoreContext } from 'src/database/store/StoreProvider';
import { JobsDBEntry, PopColl } from 'src/database/store/StoreTypes';
import { Table, useTable } from 'src/hooks/useTable';
import { unixToStr } from 'src/utils/general';
import TableHeader, { SortingPackage } from '../TableHeader/TableHeader';
import styles from './jobs.module.css';
import { filterJobRecords } from './JobFilter.util';
import { JobFilterField } from './JobFilter';

type ColumnKey =
  | 'jobName'
  | 'customer'
  | 'sensorCount'
  | 'created';

const pendingJobTable: Table<JobsDBEntry, ColumnKey> = {
  columns: [
    {
      key: 'jobName',
      title: 'Job Name',
      extractFunction: (job) => job.name
    },
    {
      key: 'customer',
      title: 'Customer',
      extractFunction: (job) => {
        const workgroup = _.chain(job.job_workgroups)
          .find((jwg) => jwg.type === 'customer')
          .value()?.workgroup_id_ref;
        if (!workgroup) return '';
        return `${workgroup.organization_id_ref?.name}, ${workgroup.name}`
      }
    },
    {
      key: 'sensorCount',
      title: 'Num. Sensors',
      extractFunction: (job) => _.size(job.job_sensors),
      headerAlign: 'right',
    },
    {
      key: 'created',
      title: 'Created',
      extractFunction: (job) => job.created_at,
      headerAlign: 'right'
    },
  ],
};

type PendingJobsProps = {
  filteredJobs?: PopColl<JobsDBEntry>,
  filterField?: JobFilterField,
  filterValue?: string
};

const PendingJobs: FC<PendingJobsProps> = ({ filteredJobs, filterField, filterValue }) => {
  const history = useHistory();
  const { store } = useContext(StoreContext);
  const { jobs } = store;
  const [currentSort, setCurrentSort] = useState<ColumnKey>('created');
  const [isAscending, setIsAscending] = useState(false);

  const pendingJobs = useMemo(() => {
    const pendingJobs: Record<string, JobsDBEntry> = {};
    _.forEach(filteredJobs || jobs, (job, id) => { if (!job.status || job.status === 'pending') pendingJobs[id] = job });
    return pendingJobs;
  }, [filteredJobs, jobs]);

  const tableData = filterJobRecords(useTable(
    pendingJobTable,
    pendingJobs,
    currentSort,
    isAscending,
  ), filterField || '*', filterValue || '');

  const sortingPackage: SortingPackage<ColumnKey> = {
    setCurrentSort,
    currentSort,
    setIsAscending,
    isAscending,
  };

  return (
    <div>
      <TableHeader columns={pendingJobTable.columns} rowClassName={`${styles.pendingRow} ${styles.pendingRow2}`} sortingPackage={sortingPackage} />
      {tableData.map(
        ({ customer, jobName, sensorCount, created, id }) => (
          <div key={id} className={`${styles.pendingRow} hvrPtr row`} onClick={() => history.push(`/jobs/${id}`)}>
            <p>{jobName}</p>
            <p>{customer}</p>
            <p style={{ textAlign: 'end' }} >{sensorCount}</p>
            <p style={{ textAlign: 'end' }} >{unixToStr(created)}</p>
          </div>
        )
      )}
    </div>
  );
};

export default PendingJobs;
