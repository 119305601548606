import _ from "lodash";
import { useContext } from "react";
import { StoreContext } from "src/database/store/StoreProvider";
import { JobWorkgroupType } from "src/models/JobWorkgroup";

/**
 * Filters through organizations based on type (Customer or Manufacturer). This method
 * uses JobWorkgroup type attribute to filter and assumes that organizations with no 
 * workgroups are manufacturers.
 * @param targetType the type of organizations to filter by
 * @returns list of organizations
 */
export default function useOrganizationTypes(targetType?: JobWorkgroupType) {
  const { store } = useContext(StoreContext);

  // Note:
  // This is hardcoded because it is too complex to properly filter through the Organizations for manufacturers. It might
  // not even be possible with the current DB design (to make it work for all scenarios). If we can't filter properly in all scenarios 
  // then we are going to run into problems, so we need some sort of temporary fix to get this working. This same filtering was also used 
  // in src/components/modals/AddJob.tsx by a previous dev. 
  if (targetType === 'manufacturer')
    return _.filter(store.organizations, ({ name }) => ["Cooper-Atkins/Emerson"].includes(name));

  return _.filter(store.organizations, ({ name }) => !["Function Compliance Services", "Cooper-Atkins/Emerson"].includes(name));
  // return _.filter(store.organizations, ({ workgroups, name }) => {
  //   if (!_.size(workgroups) && targetType === 'customer') return true;
  //   return _.some(workgroups, ({ job_workgroups, name }) => {
  //     return _.some(job_workgroups, ({ type }) => type === targetType);
  //   });
  // });

  // To-Do: Need to revisit. This code doesn't cover all scenarios.
  // return useMemo(() => {
  //   return _.filter(store.organizations, ({ workgroups, name }) => {
  //     if (!_.size(workgroups) && targetType === 'manufacturer') return true;
  //     return _.some(workgroups, ({ job_workgroups, name }) => {
  //       if (!_.size(job_workgroups) && targetType === 'manufacturer') return true;
  //       return _.some(job_workgroups, ({ type }) => type === targetType);
  //     });
  //   });
  // }, [store, targetType]);
}