import React, { useEffect, FC, useContext, useState, useCallback } from 'react';
import _, { map } from 'lodash';
import { useHistory } from 'react-router-dom';
import { StoreContext } from 'src/database/store/StoreProvider';
import { ToolsDBEntry } from 'src/database/store/StoreTypes';
import { Table, useTable } from 'src/hooks/useTable';
import urls from 'src/api/urls';
import { fahrenheitToCelsius, getToleranceFScore } from 'src/utils/general';
import styles from './tools.module.css';
import { authCtx } from 'src/api/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import TableHeader, {
	SortingPackage,
} from 'src/components/TableHeader/TableHeader';
import useNavStore from 'src/components/RootNavBarIcon/NavStore';
import AddEditTools from '../AddEditTools/AddEditTools';
import { toast } from 'react-toastify';

type ColumnKey =
	| 'name'
	| 'description'
	| 'toleranceRange'
	| 'action'
	| 'uncertainty'
	| 'toolType';
const toolsTable: Table<ToolsDBEntry, ColumnKey> = {
	columns: [
		{
			key: 'name',
			title: 'Name',
			extractFunction: (tools) => tools.name,
		},
		{
			key: 'toolType',
			title: 'Tool Type',
			extractFunction: (tools) => tools.tool_type,
		},
		{
			key: 'description',
			title: 'Description',
			extractFunction: (tools) => tools?.description ?? null,
		},

		{
			key: 'toleranceRange',
			title: 'Tolerance range',
			extractFunction: (tools) => tools.uncertainty_range_list,
		},
		{
			key: 'uncertainty',
			title: 'Uncertainty',
			extractFunction: (tools) => tools.uncertainty_range_list,
		},
		{
			key: 'action',
			title: 'Action',
			headerAlign: 'center',
		},
	],
};

type ToolsProps = {};

const Tools: FC<ToolsProps> = () => {
	const { store } = useContext(StoreContext);
	const history = useHistory();
	const { tools } = store;
	const [currentSort, setCurrentSort] = useState<ColumnKey>('name');
	const [isAscending, setIsAscending] = useState(true);
	const [addToolssModalIsOpen, setaddToolssModalIsOpen] = useState(false);
	const [editToolsId, setEditToolsId] = useState('');
	const { accessToken, justSync } = useContext(authCtx);
	const tableData = useTable(toolsTable, tools, currentSort, isAscending);

	const sortingPackage: SortingPackage<ColumnKey> = {
		setCurrentSort,
		currentSort,
		setIsAscending,
		isAscending,
	};
	const { isToogle } = useNavStore();

	const handleAddEdit = (id?: string) => {
		setEditToolsId(id ?? '');
		setaddToolssModalIsOpen(true);
	};
	const deleteOneTool = async (id: string, name: string) => {
		if (id && window.confirm(`Are You Sure To Delete ${name}`)) {
			const { url, method } = urls.deleteOneTool;
			try {
				await fetch(`${urls.base}${url}${id}`, {
					method,
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`,
					},
					body: JSON.stringify({ toolId: id }),
				}).then((res) => {
					if (res?.status == 204) {
						justSync();
						toast.error(`Tool:${name} Deleted Successfull`, {
							autoClose: 5000,
						});
					}
				});
			} catch (error) {
				console.log('Error while deteting Tool', error);

				toast.error(`Error while deteting Tool:${name}`, {
					autoClose: 5000,
				});
			}
		}
	};
	// sorting
	const handleSort = useCallback(
		(colKey) => {
			if (currentSort === colKey) {
				setIsAscending(!isAscending);
			} else {
				setIsAscending(false);
				setCurrentSort(colKey);
			}
		},
		[currentSort, isAscending, setCurrentSort, setIsAscending]
	);

	return (
		<div className={styles.mainContentContainer}>
			<div className="header">
				<h1>Tools</h1>
				<button onClick={() => handleAddEdit()}>Add Tool</button>
			</div>
			<div
				className="sensor-datatable"
				style={{ marginLeft: isToogle === 'YES' ? '90px' : '' }}
			>
				<table className={styles.table}>
					<thead>
						<tr className={styles.toolsRow}>
							{toolsTable.columns.map(
								({
									title,
									key,
									doNotSort,
									headerComponent,
									headerAlign = 'left',
								}) =>
									headerComponent ? (
										headerComponent({})
									) : (
										<th key={key} data-record-field={key} id={key}>
											<span
												className={
													styles.tableHeaderTitleSortAndIcon
												}
											>
												{title}
												{!doNotSort && (
													<FontAwesomeIcon
														onClick={() =>
															!doNotSort && handleSort(key)
														}
														style={{
															marginLeft: '5px',
														}}
														icon={
															currentSort !== key
																? 'sort'
																: isAscending
																? 'sort-up'
																: 'sort-down'
														}
													/>
												)}
											</span>
										</th>
									)
							)}
						</tr>
					</thead>
					<tbody>
						{tableData.map((tData) => {
							return (
								<tr key={tData.id} className={styles.toolsRow}>
									<td
										onClick={() =>
											history.push(
												`/calibrationToolDetails/${tData.id}`
											)
										}
									>
										{tData.name}
									</td>
									<td
									onClick={() =>
										history.push(
											`/calibrationToolDetails/${tData.id}`
										)
									}
									>{tData.toolType}</td>
									<td
									onClick={() =>
										history.push(
											`/calibrationToolDetails/${tData.id}`
										)
									}>{tData.description}</td>

									<td>
										{tData.toleranceRange &&
											_.map(
												JSON.parse(`${tData.toleranceRange}`),
												(data, i) => {
													return (
														<>
															<span key={i}>
																{`(${data['lowerRange']} - ${data['upperRange']}) `}
																{data['uom'] === 'F' &&
																	`${'\u00b0'}F`}
																{data['uom'] === 'C' &&
																	`${'\u00b0'}C`}
																{data['uom'] === '%' && `%`}
															</span>
															<br />
														</>
													);
												}
											)}
									</td>
									<td>
										{tData.toleranceRange &&
											_.map(
												JSON.parse(`${tData.toleranceRange}`),
												(data, i) => {
													return (
														<>
															<span key={100000 + i}>
																{`${data['uncertainty']} `}
																{data['uom'] === 'F' &&
																	`${'\u00b0'}F`}
																{data['uom'] === 'C' &&
																	`${'\u00b0'}C`}
																{data['uom'] === '%' && `%`}
															</span>
															<br />
														</>
													);
												}
											)}
									</td>
									<td>
										{' '}
										<FontAwesomeIcon
											className={styles.actionEdit}
											onClick={() => handleAddEdit(tData.id)}
											icon="pen-square"
											size="1x"
											cursor="pointer"
										/>
										<FontAwesomeIcon
											className={styles.actionDel}
											onClick={() =>
												deleteOneTool(tData.id, tData.name)
											}
											icon="trash"
											size="1x"
											cursor="pointer"
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			<Modal
				isOpen={addToolssModalIsOpen}
				onRequestClose={() => setaddToolssModalIsOpen(false)}
				className="modal"
				overlayClassName="modalOverlay"
			>
				<AddEditTools
					toolId={editToolsId}
					closeModal={() => setaddToolssModalIsOpen(false)}
				/>
			</Modal>
		</div>
	);
};

export default Tools;
