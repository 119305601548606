import Bugsnag from "@bugsnag/js";

export default async function retry(promise: Promise<any>, retries: number = 1): Promise<any> {
  try {
    return await promise;
  } catch (err) {
    Bugsnag.notify(err as any);
    if (retries > 0) {
      return await retry(promise, retries - 1);
    } else {
      throw err;
    }
  }
}