// import { outlookCalendarSync, Eventcalendar, setOptions, Page, Button, Switch, CalendarNav, CalendarPrev, CalendarNext, CalendarToday, toast, confirm, MbscCalendarEvent, MbscEventcalendarView } from '@mobiscroll/react';
import React from 'react';


export function Calender() {

    return (
        <>
            <h1>Calender App</h1>

        </>
    );
}


