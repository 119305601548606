import { setDefaultResultOrder } from 'dns';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { StoreContext } from 'src/database/store/StoreProvider';
import { JobsDBEntry } from 'src/database/store/StoreTypes';
import { getJobStat, JobStat } from './index';
import { JobProgressBar } from './JobProgressBar';
// import { HighchartComp } from 'src/components/Highcharts/HighchartComp';

export function RecentJobDataBlock(props: { job: JobsDBEntry, onClick?: Function }) {
	const [jobStat] = React.useState<JobStat>(getJobStat(props.job));
	const history = useHistory();

	
	return (
		<div className="data-block-card recent-job-card" onClick={e => props.onClick && props.onClick.call(e) }>
			<div className="data-block-card-detail">
				<h5 className="data-block-job-name">{jobStat.name}</h5>
				<p className="data-block-job-role">{jobStat.role}</p>
				<div className='router-link' onClick={() => history.push(`/jobs/${props.job.id}/certificates`)}>See Certificates</div>
				<div className='router-link' onClick={() => history.push(`/jobs/${props.job.id}/documents`)}>See Documents</div>
			</div>
			<div className="data-block-card-gview">
				<JobProgressBar stat={jobStat} />
				{/* <HighchartComp options={options}/> */}
			</div>
		
			
		</div>
	);
}

export function RecentJobOverview(props: { onSelect: Function }) {
	const { store } = React.useContext(StoreContext);
	const [recentJobs, setRecentJobs] = React.useState<JobsDBEntry[]>([]);
	const [showAll, setLimitedJobs] = React.useState<boolean>(false);
	const [qRoleId, setQRoleId] = React.useState<string>('*');
	const jobs = _.values(store.jobs);
	let limit = showAll ? jobs.length : 2;

	React.useEffect(() => {

		setRecentJobs(
			_.slice(
				_.orderBy(
					_.filter(jobs, (job) => job.status === 'closed'),
					['updated_at'],
					['desc']
				),
				0,
				limit
			)
		);
	}, [showAll]);

	React.useEffect(() => {
		setRecentJobs(
			_.slice(
				_.orderBy(
					_.filter(jobs, (job) => {
						if (job.status === 'closed') {
							if (qRoleId === '*') {
								return true;
							}

							if (job.job_technicians) {
								return _.values(job.job_technicians).every((tech) => {
									if (tech) {
										if (tech.technician_id_ref && tech.technician_id_ref.role_id === qRoleId) {
											return true;
										}
									}
								})
							}
						}
						return false;
					}),
					['updated_at'],
					['desc']
				),
				0,
				limit
			)
		);
	}, [qRoleId])

	return (
		<div className="data-block-row">
			<div className="data-block-header">
				<div className='data-block-label'>Recent Jobs</div>
				<div className="role-based-job-filter">
					<select value={qRoleId} onChange={(e) => setQRoleId(e.target.value)}>
						<option value="*">All</option>
						<option value="311b83d9-2cc5-4c17-bc01-e2b9d006f1e2">Admin</option>
						<option value="e1c59d16-5bc0-4794-92e7-6bab999c7c63">Customer</option>
						<option value="d63cbb89-28ac-4742-bf95-54c7735ed798">Technician</option>
						<option value="manufacturer">Manufacturer</option>
					</select>
				</div>
			</div>
			{recentJobs.map((recentJob) => (
				<RecentJobDataBlock 
					key={recentJob.name} 
					job={recentJob}
					onClick={() => props.onSelect(recentJob)} />
			))}

			{recentJobs.length > 0 ? (
				<button onClick={() => setLimitedJobs(!showAll)}>
					{showAll ? 'Show less' : 'Show more'}
				</button>
			) : (
				<p className="text-center">No jobs</p>
			)}
		</div>
	);
}
