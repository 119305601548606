import { Relation } from '@nozbe/watermelondb';
import { BelongsToAssociation } from '@nozbe/watermelondb/Model';
import { field, date, relation, writer } from '@nozbe/watermelondb/decorators';
import { Association } from 'src/types/watermelon';
import BaseModel from './BaseModel';
import ProbeType from './ProbeType';
import { database } from 'src/database';
import Organization from './Organization';
import { write } from 'fs';
import Tool from './Tool';

export default class CalibrationProcedure extends BaseModel {
	static table = 'calibration_procedures';
	static associations = {
		probe_types: {
			type: Association.BELONGS_TO,
			key: 'probe_type_id',
		} as BelongsToAssociation,
		organizations: {
			type: Association.BELONGS_TO,
			key: 'customer_organization_id',
		} as BelongsToAssociation,
	};
	getTableName() {
		return CalibrationProcedure.table;
	}
	@field('revision_name') revisionName!: string;
	@field('description') description!: string;
	@field('level') level!: string;
	@date('revision_date') revisionDate!: number;
	@field('calibration_standard_text') calibrationStandardText!: string;
	@field('tool_id') toolId!: string;
	@field('calibration_procedure_text') calibrationProcedureText!: string;
	@field('number_of_tests') numberOfTests!: number;
	@relation('probe_types', 'probe_type_id') probeType!: Relation<ProbeType>;
	@relation('organizations', 'customer_organization_id')
	customerOrganization!: Relation<Organization>;
	// @relation("workgroups", "customer_workgroup_id") customerWorkgroup!: Relation<Workgroup>;
	@writer async deleteRecord() {
		await this.markAsDeleted();
	}
}

export async function upsertCalProc({
	id,
	name,
	level,
	// probeTypeId,
	revDate,
	description,
	toolId
}: CalProcParams) {
	return database.write(async () => {

		// const probeType = await database.collections
		// 	.get<ProbeType>('probe_types')
		// 	.find(probeTypeId);
		const coll = database.collections.get<CalibrationProcedure>(
			'calibration_procedures'
		);
		const upsertFunction = (calProc: CalibrationProcedure) => {
			calProc.revisionName = name;
			calProc.level = level;
			calProc.revisionDate = revDate.getTime();
			calProc.description = description;
			// calProc.probeType.set(probeType);
			calProc.toolId=toolId;
		};
		const prev = await (id && coll.find(id));
		return prev ? prev.update(upsertFunction) : coll.create(upsertFunction);
	});
}

type CalProcParams = {
	id?: string;
	orgId?: string;
	name: string;
	probeTypeId?: string;
	level: string;
	toolId: string;
	description: string;
	revDate: Date;
};

export type CalibrationProcedureUpdateBody = Pick<
	CalibrationProcedure,
	| 'revisionName'
	| 'description'
	| 'level'
	| 'revisionDate'
	| 'calibrationProcedureText'
	| 'numberOfTests'
>;
