import { BelongsToAssociation } from "@nozbe/watermelondb/Model";
import { field, relation } from "@nozbe/watermelondb/decorators";
import { Relation } from "@nozbe/watermelondb";
import { Association } from "src/types/watermelon";
import BaseModel from "./BaseModel";
import Job from "./Job";
import Workgroup from "./Workgroup";

/**
 * Should only allow one customer workgroup per job, but unlimited manufacturer
 * workgroups per job. If a customer user needs to see jobs across numerous
 * workgroups, they either need to be a part of a workgroup that has the elevated
 * setting, or create new user accounts (workgroup:users is a 1:m relationship)
 */
export type JobWorkgroupType = 'manufacturer' | 'customer';

export default class JobWorkgroup extends BaseModel {
  static table = "job_workgroups";
  static associations = {
    jobs: {
      type: Association.BELONGS_TO,
      key: "job_id",
    } as BelongsToAssociation,
    workgroups: {
      type: Association.BELONGS_TO,
      key: "workgroup_id",
    } as BelongsToAssociation,
  };
  getTableName() {
    return JobWorkgroup.table;
  }
  @field("type") type!: JobWorkgroupType;
  @relation("jobs", "job_id") job!: Relation<Job>;
  @relation("workgroups", "workgroup_id") workgroup!: Relation<Workgroup>;
}

export type JobWorkgroupUpdateBody = Pick<
  JobWorkgroup,
  "type"
>;
