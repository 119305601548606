import { BelongsToAssociation, HasManyAssociation } from "@nozbe/watermelondb/Model";
import { writer, children, field, relation } from "@nozbe/watermelondb/decorators";
import { Query, Relation } from "@nozbe/watermelondb";
import { Association } from "src/types/watermelon";
import Tool from "./Tool";
import User from "./User";
import BaseModel from "./BaseModel";
import Calibration from "./Calibration";

export default class TechnicianTool extends BaseModel {
  static table = "technician_tools";
  static associations = {
    tools: {
      type: Association.BELONGS_TO,
      key: "tool_id",
    } as BelongsToAssociation,
    users: {
      type: Association.BELONGS_TO,
      key: "technician_user_id",
    } as BelongsToAssociation,
    calibrations: {
      type: Association.HAS_MANY,
      foreignKey: "technician_tool_id",
    } as HasManyAssociation,
  };
  getTableName() {
    return TechnicianTool.table;
  }
  @field("serial_number") serialNumber!: string;
  @field("acknowledgement") acknowledgement?: string;
  @field("deactivated_date") deactivatedDate?: number;
  @field("notes") notes?: string;
  @field("calibration_date") calibrationDate!: number;
  @field("calibration_due_date") calibrationDueDate!: number;
  @relation("tools", "tool_id") tool!: Relation<Tool>;
  @relation("users", "technician_user_id") technician!: Relation<User>;
  @children("calibrations") calibrations!: Query<Calibration>;
  @writer async updateRecord(updateBody: TechnicianToolUpdateBody) {
    await this.update(record => {
      Object.assign(record, updateBody);
    });
  };
  @writer async disassociateFromTech() {
    await this.update(techTool => {
      techTool.deactivatedDate = Date.now();
    });
  };
  @writer async updateacknowledgementText(text: string) {
    if (!text)return alert('Acknowledgement Text Cant be Blank!')
    await this.update(techTool => {
      techTool.acknowledgement = text
    });
  }
}
export type TechnicianToolUpdateBody = Pick<
  TechnicianTool,
  "serialNumber" |
  "deactivatedDate" |
  "notes" |
  "calibrationDate" |
  "acknowledgement" |
  "calibrationDueDate"
>;




