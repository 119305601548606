import React, { FC, useContext, useState } from 'react';
import Modal from 'react-modal';
import { StoreContext } from 'src/database/store/StoreProvider';
import { CalibrationProceduresDBEntry } from 'src/database/store/StoreTypes';
import { Table, useTable } from 'src/hooks/useTable';
import AddCalProc from '../modals/AddCalProc/AddCalProc';
import { database } from 'src/database';
import TableHeader, { SortingPackage } from '../TableHeader/TableHeader';
import styles from './customerDetail.module.css';
import { unixToStr } from 'src/utils/general';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CalibrationProcedure from 'src/models/CalibrationProcedure';

type ColumnKey =
	| 'name'
	| 'manufacturer'
	| 'sequences'
	| 'tolerance'
	| 'procedure'
	| 'revisionDate'
	| 'action';

const procTable: Table<CalibrationProceduresDBEntry, ColumnKey> = {
	columns: [
		{
			key: 'name',
			title: 'Name',
			extractFunction: (calProc) => calProc?.probe_type_id_ref?.name,
		},
		{
			key: 'manufacturer',
			title: 'Manufacturer',
			extractFunction: (calProc) =>
				calProc?.probe_type_id_ref?.manufacturer_workgroup_id_ref
					?.organization_id_ref.name,
		},
		{
			key: 'sequences',
			title: 'Sequences',
			extractFunction: (calProc) => calProc?.number_of_tests,
		},
		{
			key: 'tolerance',
			title: 'Tolerance',
			extractFunction: ({
				probe_type_id_ref: { tolerance_unit, tolerance },
			}) => `${tolerance}${tolerance_unit}`,
		},
		{
			key: 'procedure',
			title: 'Procedure',
			extractFunction: (calProc) => calProc?.revision_name,
		},
		{
			key: 'revisionDate',
			title: 'Revision Date',
			// extractFunction: (calProc) => calProc?.created_at,
			extractFunction: (calProc) => calProc?.revision_date,
		},
		{
			key: 'action',
			title: 'Action',
		},
	],
};

type OrgProceduresProps = { orgId: string };

const OrgProcedures: FC<OrgProceduresProps> = ({ orgId }) => {
	const { store } = useContext(StoreContext);
	const [AddCalProcModalIsOpen, setAddCalProcModalIsOpen] = useState(false);
	const [selectedCalProcId, setSelectedCalProcId] = useState('');
	const [currentSort, setCurrentSort] = useState<ColumnKey>('revisionDate');
	const [isAscending, setIsAscending] = useState(false);

	const org = store.organizations[orgId];
	const calProc = store.calibration_procedures[selectedCalProcId];


	const calProcs = org.calibration_procedures;
	const tableData = useTable(procTable, calProcs, currentSort, isAscending);

	if (!org) return null;

	const sortingPackage: SortingPackage<ColumnKey> = {
		setCurrentSort,
		currentSort,
		setIsAscending,
		isAscending,
	};
	const deleteOneProcedures = async (proceduresId: string, name: string) => {
		if (proceduresId && window.confirm(`Are You Sure To Delete: ${name}`)) {
			const selectedCalProcforDelete = await database.collections
				.get<CalibrationProcedure>('calibration_procedures')
				.find(proceduresId);
			await selectedCalProcforDelete.deleteRecord();
		}
	};
	return (
		<div>
			<TableHeader
				columns={procTable.columns}
				rowClassName={styles.procedureRow}
				sortingPackage={sortingPackage}
			/>
			{tableData.map(
				({
					name,
					manufacturer,
					sequences,
					tolerance,
					procedure,
					revisionDate,
					id,
				}) => (
					<div key={id} className={`${styles.procedureRow} hvrPtr row`}>
						<p>{name}</p>
						<p>{manufacturer}</p>
						<p>{sequences}</p>
						<p>{tolerance}</p>
						<p>{procedure}</p>
						<p>{unixToStr(revisionDate)}</p>
						<p>
							<FontAwesomeIcon
								className={styles.actionEdit}
								onClick={() => {
									setSelectedCalProcId(id);
									setAddCalProcModalIsOpen(true);
								}}
								icon="pen-square"
								size="1x"
								cursor="pointer"
							/>

							<FontAwesomeIcon
								className={styles.actionDel}
								onClick={() => deleteOneProcedures(id, name)}
								icon="trash"
								size="1x"
								cursor="pointer"
							/>
						</p>
					</div>
				)
			)}
			<Modal
				isOpen={AddCalProcModalIsOpen}
				onRequestClose={() => setAddCalProcModalIsOpen(false)}
				className="modal"
				overlayClassName="modalOverlay"
			>
				<AddCalProc
					orgId={orgId}
					seed={{
						id: calProc?.id,
						probeTypeId: calProc?.probe_type_id,
						name: calProc?.revision_name,
						level: calProc?.level,
						seqCount: calProc?.number_of_tests.toString(10),
						calStd: calProc?.calibration_standard_text,
						procDesc: calProc?.calibration_procedure_text,
						description: calProc?.description,
						revDate:new Date(calProc?.revision_date),
					}}
					closeModal={() => setAddCalProcModalIsOpen(false)}
				/>
			</Modal>
		</div>
	);
};

export default OrgProcedures;
