import Bugsnag from "@bugsnag/js";
import { Database, Q } from "@nozbe/watermelondb";
import { hasUnsyncedChanges } from '@nozbe/watermelondb/sync';
import CalibrationProcedure from "src/models/CalibrationProcedure";
import Job from "src/models/Job";
import Organization from "src/models/Organization";
import ProbeType from "src/models/ProbeType";
import TechnicianTool from "src/models/TechnicianTool";
import Tool from "src/models/Tool";
import User from "src/models/User";
import Workgroup from "src/models/Workgroup";
import { database } from ".";

export async function forceDbReset(params: {
  database: Database,
  skipConfirmation?: boolean,
  onSuccess?: () => void
}) {
  const { database, skipConfirmation = false, onSuccess } = params;
  let doReset = true;
  if (!skipConfirmation) {
    const hasLocalChanges = database && await hasUnsyncedChanges({ database }) === true;
    if (hasLocalChanges) {
      doReset = window.confirm(`Unsaved local changes are detected. Are you sure you'd like to proceed? Press OK to permanently delete your changes, or OK to cancel.`)
    }
  }
  if (doReset) {
    Bugsnag.notify(new Error('forceDbReset'), (e) => e.addMetadata('database', database))
    console.info('Forcing unsafe reset database');
    await database.write(async () =>
      await database.unsafeResetDatabase()
    )
    onSuccess?.();
    window.location.reload();
  }
}

export async function createTestWorkgroup(organizationId?: string) {
  return database.write<Workgroup>(async () => {
    let organization: Organization | null = null;
    if (organizationId) {
      organization = await database.collections.get<Organization>('organizations').find(organizationId);
    }
    if (!organization) {
      organization = await database.collections.get<Organization>('organizations').create((organization) => {
        organization.city = 'San Francisco';
        organization.name = `Test Org - ${Date.now()}`;
        organization.nickname = 'Test Org Nickname';
        organization.phone = '+1(123)456-0000';
        organization.zipcode = '12345';
        organization.state = 'CA';
        organization.website = 'https://testorgsite.com';
      })
    }

    return database.collections.get<Workgroup>('workgroups').create((workgroup) => {
      workgroup.city = 'Neo San Francisco';
      workgroup.isElevatedWorkgroup = false;
      workgroup.name = `Test Workgroup - ${Date.now()}`
      workgroup.phone = '+1(123)456-1111';
      workgroup.address = '69 Cool Street';
      workgroup.zipcode = '12345';
      workgroup.state = 'CA';
      workgroup.website = 'https://testworkgroupsite.com';
      workgroup.organization.set(organization)
    });
  })
}

export async function createTestJob(auth0Id: string, workgroupId?: string) {

  let user: User | null = null;
  try {
    [user] = await database.collections.get<User>('users').query(Q.where('auth_0_id', auth0Id)).fetch();
    if (!user) throw new Error(`Failed to find user with auth0 id: ${auth0Id}`)
  } catch (err) {
    Bugsnag.notify(err as any);
    console.error(err);
    return;
  }

  let workgroup: Workgroup | null = null;
  if (workgroupId) {
    workgroup = await database.collections.get<Workgroup>('workgroups').find(workgroupId);
  }
  if (!workgroup) {
    workgroup = await createTestWorkgroup();
  }

  const job = await database.write<Job>(async () => {
    return await database.collections.get<Job>('jobs').create((job) => {
      job.name = `Test Job - ${Date.now()}`
      job.submittedDate = new Date();
      job.status = 'pending';
      job.notes = 'Test Job';
    });
  });
  await job.addTechnician(user.id);
  const jobWorkgroup = await job.addWorkgroup('customer', workgroup!);
  if (!jobWorkgroup) return job;
  database.write(async () => {
    const probes = await database.collections.get<ProbeType>('probe_types').query().fetch();
    for (let i = 0; i < probes.length; i++) {
      const probe = probes[i];
      await database.collections.get<CalibrationProcedure>('calibration_procedures').create((calibrationProcedure) => {
        calibrationProcedure.probeType.set(probe);
        calibrationProcedure.numberOfTests = Math.ceil(Math.random() * 5);
      })
    }
  })
  return job;
}

export async function testCheckoutAllTools(auth0Id: string) {
  let user: User | null = null;
  console.log({ auth0Id })
  try {
    [user] = await database.collections.get<User>('users').query(Q.where('auth_0_id', auth0Id)).fetch();
    if (!user) throw new Error(`Failed to find user with auth0 id: ${auth0Id}`)
  } catch (err) {
    Bugsnag.notify(err as any);
    console.error(err);
    return;
  }

  const tools = await database.collections.get<Tool>('tools').query().fetch();
  for (let i = 0; i < tools.length; i++) {
    const tool = tools[i];
    const hasTool = await database.collections.get<TechnicianTool>('tools')
      .query(Q.where('technician_user_id', user.id), Q.where('tool_id', tool.id)).fetchCount();
    if (hasTool) continue;
    await tool.checkoutTool({
      serialNumber: Date.now().toString(10), calibrationDate: new Date().getTime(), calibrationDueDate: new Date().getTime(),
      acknowledgement: ""
    }, user);
    console.log(`Added ${tool.name} to ${user.email}`)
  }
}
