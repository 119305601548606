import { BelongsToAssociation, HasManyAssociation } from "@nozbe/watermelondb/Model";
import { writer, children, date, field, relation } from "@nozbe/watermelondb/decorators";
import { Query, Relation, Q } from "@nozbe/watermelondb";
import { Association } from "src/types/watermelon";
import BaseModel from "./BaseModel";
import Job from "./Job";
import Calibration from "./Calibration";
import ProbeType from "./ProbeType";
import TechnicianTool from "./TechnicianTool";
import { subMinutes } from 'date-fns';
import CalibrationProcedure from "./CalibrationProcedure";
import { database } from "src/database";
import Workgroup from "./Workgroup";
import _ from "lodash";
import CalibrationReading from "./CalibrationReading";
import moment from "moment";
import useNavStore from "src/components/RootNavBarIcon/NavStore";

export type ProbeNumber = 1 | 2;

// export type SensorType = 'TEMP' | 'HUMIDITY' | // Keeping these just in case
//   '10080-DT (900-T)' |
//   '10071 (900-TH)' |
//   '10072 (900-TH)' |
//   '11078 (WIFI-T)' |
//   '11079 (WIFI-TH)' |
//   'FX100-DT (900-T)' |
//   'FX200 (900-TH)' |
//   '10086 (900-A)' |
//   '11089 (WIFI-A)' |
//   '10100-DT (900-C)' |
//   '11109 (900-C)' |
//   '10098-DT (900-S)';
export default class JobSensor extends BaseModel {
  static table = "job_sensors";
  static associations = {
    calibrations: {
      type: Association.HAS_MANY,
      foreignKey: "job_sensor_id",
    } as HasManyAssociation,
    jobs: {
      type: Association.BELONGS_TO,
      key: "job_id",
    } as BelongsToAssociation,
    workgroups: {
      type: Association.BELONGS_TO,
      key: "workgroup_id",
    } as BelongsToAssociation,
  };
  getTableName() {
    return JobSensor.table;
  }
  @field("sensor_id") sensorId!: string;
  @field("sensorProbeNumber") sensorProbeNumber!: number;
  @field("name") name!: string;
  @field("description") description!: string;
  @field("sensor_group") sensorGroup!: string;
  // Note: field database is protected by watermelonDB
  @field("sensor_database") sensor_database!: string;
  @field("probe_number") probeNumber!: number;
  @field("building") building!: string;
  @field("floor") floor!: string;
  @field("room") room!: string;
  @field("chamber") chamber!: string;
  @field("sensor_type") sensorType!: string;
  @field("sensor_modal") sensorModal!: string;
  @field("notes") notes!: string;
  @field("is_disabled") isDisabled!: 0 | 1;

  @date("added_to_scope_at") addedToScopeDate!: Date;
  @field("is_lumity") is_lumity!: number;
  @field("ispassed") ispassed!:string|null;
  @relation("jobs", "job_id") job!: Relation<Job>;
  @relation("workgroups", "manufacturer_workgroup_id") manufacturerWorkgroup!: Relation<Workgroup>;
  @children("calibrations") calibrations!: Query<Calibration>;
  /**
   * Creates a new calibration and updates the location details for the JobSensor.
   * 
   * NOTE: This method is no longer used since the calibration modal now uses progressive saving.
   * A calibration is initially created with partial data. The empty fields on the existing calibration
   * get updated as the user continues to fill out the calibration form data. However, this method might be
   * useful in the future for new requirements or even just for reference. 
   * @param param0 JobSensor and Calibration attributes
   * @returns a new calibration
   */
  // @writer async calibrateSensor({ readings, ...params }: SensorParams) {
  //   await this.update((jobSensor) => {
  //     // jobSensor.sensorType = params.sensorType as SensorType;
  //     jobSensor.sensorType = params.sensorType;
  //     jobSensor.name = params.name;
  //     jobSensor.sensorId = params.sensorId;
  //     jobSensor.building = params.building;
  //     jobSensor.floor = params.floor;
  //     jobSensor.room = params.room;
  //     jobSensor.chamber = params.chamber;
  //     jobSensor.sensorGroup = params.sensorGroup;
  //     jobSensor.description = params.description;
  //     // jobSensor.database = params.sensorDatabase;
  //     jobSensor.is_lumity = jobSensor.is_lumity;
      
  //   })
  //   const calibrationProcedure = await database.collections.get<CalibrationProcedure>('calibration_procedures').find(params.calibrationProcedureId);
  //   const technicianTool = await database.collections.get<TechnicianTool>('technician_tools').find(params.technicianToolId);
  //   const probeType = await database.collections.get<ProbeType>('probe_types').find(params.probeTypeId);

  //   if (calibrationProcedure.numberOfTests !== readings.length) return;

  //   const calibration = await this.collections.get<Calibration>('calibrations').create(calibration => {
  //     calibration.ambientHumidity = params.ambientHumidity;
  //     calibration.ambientTemperature = params.ambientTemperature;
  //     calibration.ambientReadingDate = subMinutes(new Date(), 3);
  //     calibration.probeSerialNumber =params.probeSerialNumber;
  //     calibration.jobSensor.set(this);
  //     calibration.calibrationProcedure.set(calibrationProcedure)
  //     calibration.probeType.set(probeType);
  //     calibration.technicianTool.set(technicianTool);
  //     calibration.calibratedDate = new Date();
  //     calibration.status = 'failed'; // Should check for validity first
  //     calibration.calibratedDate = new Date();
  //   });

  //   let isCalibrationValid = true;
  //   const { sensorType } = this;
  //   let remoteReadingKey = sensorType === 'TEMP' ? 'remoteTemperature' : 'remoteHumidity';
  //   let deviceReadingKey = sensorType === 'TEMP' ? 'deviceTemperature' : 'deviceHumidity';
  //   for (let i = 0; i < readings.length; i++) {
  //     const reading = readings[i];
  //     await this.callWriter(async () => {
  //       const readingThing = await calibration.addReading({
  //         SequenceNumber: i,
  //         [deviceReadingKey]: reading.sensor,
  //         [remoteReadingKey]: reading.calibration,
  //       });
  //       const isReadingValid = Math.abs(reading.sensor - reading.calibration) <= parseFloat(probeType.tolerance);
  //       isCalibrationValid = isReadingValid && isCalibrationValid;
  //     })
  //   }

  //   await calibration.update((cal) => {
  //     cal.status = isCalibrationValid ? 'valid' : 'failed';
  //   })

  //   return calibration;
  // }

  /**
   * DB update method for progressive saving. Updates attributes based on available params.
   * 
   * NOTE:
   *  Is there a cleaner (and performant) way to check params instead of a bunch of if statements? Using
   * the `params.name || ''` notation is insufficient because some attributes may need to be null (instead
   * if '').
   * @param param0 JobSensor and Calibration attributes
   * @returns a new calibration
   */
  // @writer async updateCalibration({ readings, ...params }: Partial<SensorParams>) {
  //   await this.update((sensor) => {
  //     // sensor.sensorType = params.sensorType as SensorType;
  //     if (params.sensorType) sensor.sensorType = params.sensorType;
  //     if (params.name) sensor.name = params.name;
  //     if (params.sensorId) sensor.sensorId = params.sensorId;
  //     if (params.building) sensor.building = params.building;
  //     if (params.floor) sensor.floor = params.floor;
  //     if (params.room) sensor.room = params.room;
  //     if (params.chamber) sensor.chamber = params.chamber;
  //     if (params.sensorGroup) sensor.sensorGroup = params.sensorGroup;
  //     if (params.description) sensor.description = params.description;
  //     if (params.sensorDatabase) sensor.sensorDatabase = params.sensorDatabase;
  //     if (params.is_lumity) sensor.is_lumity = params.is_lumity;
  //     sensor.probeNumber = params.sensorProbeNumber || -1;
  //     sensor.isDisabled = params.isDisabled || 0;
  //   });

  //   // If these haven't been filled out, skip calibration creation
  //   if (!params.calibrationProcedureId || !params.technicianToolId || !params.probeTypeId) return;

  //   const calibrationProcedure = await database.collections.get<CalibrationProcedure>('calibration_procedures').find(params.calibrationProcedureId);
  //   const technicianTool = await database.collections.get<TechnicianTool>('technician_tools').find(params.technicianToolId);
  //   const probeType = await database.collections.get<ProbeType>('probe_types').find(params.probeTypeId);

  //   // The number of readings should be the same as the number of tests outlined in the calibration procedure
  //   // if (readings?.length && calibrationProcedure)
  //   if (readings?.length)
  //     if (calibrationProcedure.numberOfTests !== readings.length) return;

  //   // Retrieve the latest calibration
  //   const calibrationsCollection = await this.collections.get<Calibration>('calibrations');


  //   const calibrations = await calibrationsCollection.query(Q.where('job_sensor_id', this.id)).fetch()
  //   const lastCalibration = _.reduce(calibrations,
  //     (prevCal, curCal) => curCal.createdAt > prevCal.createdAt ? curCal : prevCal,
  //     { createdAt: 0 } as Calibration);

  //   const calibrationsCallback = (calibration: Calibration) => {
  //     if (params.ispassed) calibration.ispassed = Number(params.ispassed==="PASS"?'1':params.ispassed==="FAIL"?'0':null);
  //     if (calibrationProcedure) calibration.calibrationProcedure.set(calibrationProcedure)
  //     if (probeType) calibration.probeType.set(probeType);
  //     if (technicianTool) calibration.technicianTool.set(technicianTool);
  //     if (params.probeSerialNumber) calibration.probeSerialNumber =params.probeSerialNumber ;
  //     if (params.ambientTemperature && params.ambientHumidity) {
  //       calibration.ambientHumidity = params.ambientHumidity;
  //       calibration.ambientTemperature = params.ambientTemperature;
  //       calibration.ambientReadingDate = subMinutes(new Date(), 3);
  //       if (readings?.length && probeType) {//&& readings[readings.length - 1].calibration && probeType) {
  //         let isCalibrationValid = true;
  //         const { sensorType } = this;
  //         let remoteReadingKey = sensorType === 'HUMIDITY' ? 'remoteHumidity' : 'remoteTemperature';
  //         let deviceReadingKey = sensorType === 'HUMIDITY' ? 'deviceHumidity' : 'deviceTemperature';
  //         for (let i = 0; i < readings.length; i++) {
  //           const reading = readings[i];
  //           calibration.callWriter(async () => {
  //             const readingThing = await calibration.addReading({
  //               SequenceNumber: i,
  //               [deviceReadingKey]: reading.sensor,
  //               [remoteReadingKey]: reading.calibration,
  //             });


  //           });
  //           const isReadingValid = Math.abs(reading.sensor - reading.calibration) <= parseFloat(probeType.tolerance);
  //           isCalibrationValid = isReadingValid && isCalibrationValid;
  //         }

  //         calibration.status = isCalibrationValid ? 'valid' : 'failed';
  //       }


  //     }
  //     if (params.notes) calibration.notes = params.notes;
  //     calibration.jobSensor.set(this);
  //     calibration.calibratedDate = new Date();
  //   }

  //   if (!lastCalibration.id) {
  //     console.log('creating new calibration')

  //     const calibration = await calibrationsCollection.create(calibrationsCallback);
  //     return calibration;
  //   } else {
  //     console.log('updating existing calibration')
  //     await lastCalibration.update(calibrationsCallback);

  //     return lastCalibration;
  //   }
  // }

  // inline editing--------------------------------------------------------------------------------------------------

  @writer async inlineUpdateJob({ readings, ...params }: Partial<SensorParams>) {
    console.log('inline Calibration Start here----------------params',params);

    await this.update((sensor) => {
      if (params.sensor_database) sensor.sensor_database = params.sensor_database;
      if (params.name) sensor.name = params.name;
      if (params.sensorId_SequenceNumber && (params.sensorId_SequenceNumber).includes('/')) {
        const [sensorRealId, RealSequenceNumber] = (params.sensorId_SequenceNumber).split('/');
        if (sensorRealId) sensor.sensorId = sensorRealId;
}
      if (params.sensorType) sensor.sensorType = params.sensorType;
      if (params.sensor_modal) sensor.sensorModal = params.sensor_modal;
      if (params.sensorProbeNumber) sensor.probeNumber = Number(params.sensorProbeNumber) || -1;
      if (params.building) sensor.building = params.building;
      if (params.floor) sensor.floor = params.floor;
      if (params.room) sensor.room = params.room;
      if (params.chamber) sensor.chamber = params.chamber;
      if (params.sensorGroup) sensor.sensorGroup = params.sensorGroup;
      if (params.description) sensor.description = params.description;
      if (params.timestamp) sensor.updatedAt = params.timestamp;
      if (params.is_lumity) sensor.is_lumity = Number(params.is_lumity);
      if (params.isDisabled) sensor.isDisabled = params.isDisabled;
    });

    if (params.probeTypeId && params.technicianToolId) {
      // const calibrationProcedure = await database.collections.get<CalibrationProcedure>('calibration_procedures').find(params.calibrationProcedureId);
      let calibrationProcedure:any =null
      const probeType = await database.collections.get<ProbeType>('probe_types').find(params.probeTypeId);
      const technicianTool = await database.collections.get<TechnicianTool>('technician_tools').find(params.technicianToolId);
      if (technicianTool.tool.id) {
        calibrationProcedure= await await database.collections.get<CalibrationProcedure>('calibration_procedures')
							.query(Q.where('tool_id', technicianTool.tool.id))
      }else{
        alert('Calibration Procedures not found for selected technician')
        		if (params.isCallibrationAutoSave=='NO' && !calibrationProcedure) {
							let text = `No Calibration Procedures found for selected technician in '${params.name}'. Press Cancel to change Probe Type or OK to save calibration details for other sensors.`;
							if (window.confirm(text) == false) {
								return;
							}
						}
      }
      if (params.id && params.calibrationId) {
        console.log('inline Calibration Start here');
                const calibrations = await database.collections
          .get<Calibration>('calibrations')
          .find(params.calibrationId);
        calibrations.update((calibrations) => {
          if (params.notes) calibrations.notes = params.notes;
          if (params.status) calibrations.status = params.status;
          if (params.ambientHumidity) calibrations.ambientHumidity = Number(params.ambientHumidity);
          if (params.ambientTemperature) calibrations.ambientTemperature = Number(params.ambientTemperature);
          if (params.probeSerialNumber) calibrations.probeSerialNumber =params.probeSerialNumber;
          // if (technicianTool.serialNumber ||params.probeSerialNumber) calibrations.probeSerialNumber = technicianTool.serialNumber??params.probeSerialNumber;
          if (params.probe_replaced) calibrations.probeReplacementStatus = params.probe_replaced;
          if (params.ispassed) calibrations.ispassed = params.ispassed==="PASS"?'1':params.ispassed==="FAIL"?'0':null;
          if (params.timestamp) calibrations.ambientReadingDate = params.timestamp ? new Date(params.timestamp) : new Date();
           calibrations.jobSensor.set(this);
           if (calibrationProcedure) {
            calibrations.calibrationProcedure.set(calibrationProcedure[0])
           }
         
          calibrations.probeType.set(probeType);
          calibrations.technicianTool.set(technicianTool);
          calibrations.calibratedDate = new Date();

        })
        if (params.calReadingId) {
          console.log('inline CalibrationReading Start her');
          const calReading = await database.collections.get<CalibrationReading>('calibration_readings').find(params.calReadingId);
          calReading.update((calReading) => {
            if (params.sensorId_SequenceNumber && (params.sensorId_SequenceNumber).includes('/')) {
              const [sensorRealId, RealSequenceNumber] = (params.sensorId_SequenceNumber).split('/');
              if (RealSequenceNumber) calReading.SequenceNumber = Number(RealSequenceNumber)
      }
              if (params.device_reading) calReading.remoteTemperature = Number(params.device_reading);
              if (params.tt_reading) calReading.deviceTemperature = Number(params.tt_reading);
              if (params.timestamp_2) calReading.reading2Date = moment( moment(new Date()).format('L') +' '+ params.timestamp_2).format("YYYY-MM-DD HH:mm:ss")
              if (params.timestamp_1) calReading.reading1Date = moment( moment(new Date()).format('L') +' '+ params.timestamp_1).format("YYYY-MM-DD HH:mm:ss")
              if (params.tt_reading_2) calReading.deviceTemperature2 = Number(params.tt_reading_2);
              if (params.device_reading_2) calReading.remoteTemperature2 = Number(params.device_reading_2);

              if (params.timestamp_3) calReading.reading3Date =moment( moment(new Date()).format('L') +' '+ params.timestamp_3).format("YYYY-MM-DD HH:mm:ss")
              if (params.tt_reading_3) calReading.deviceTemperature3 = Number(params.tt_reading_3);
              if (params.device_reading_3) calReading.remoteTemperature3 = Number(params.device_reading_3);

              if (params.timestamp_4) calReading.reading4Date = moment( moment(new Date()).format('L') +' '+ params.timestamp_4).format("YYYY-MM-DD HH:mm:ss")
              if (params.tt_reading_4) calReading.deviceTemperature4 = Number(params.tt_reading_4)
              if (params.device_reading_4) calReading.remoteTemperature4 = Number(params.device_reading_4?params.device_reading_4:null)
              
          })
        }
                return calibrations;
      }
    }
  }
  // inline editing------------------------------------------------------------------------------------------
  /**
   * Enables the sensor.
   * @returns 
   */
  @writer async enableSensor() {
    return await this.update((jobSensor) => jobSensor.isDisabled = 0);
  }

  /**
   * Disables the sensor.
   * @returns 
   */
  @writer async disableSensor() {
    return await this.update((jobSensor) => jobSensor.isDisabled = 1);
  }

  /**
   * Updates the JobSensor's notes.
   * @param notes the new notes
   * @returns response from update
   */
  @writer async updateSensorNotes(notes: string) {
    return await this.update((sensor) => sensor.notes = notes);
  }

  /**
   * Updates the last calibration notes.
   * @param notes 
   */
  // @writer async updateCalibrationNotes(notes: string) {
  //   const calibrations = await this.collections.get<Calibration>('calibrations').query(Q.where('job_sensor_id', this.id)).fetch();

  //   let lastCalibration = _.reduce(calibrations,
  //     (prevCal, curCal) => curCal.createdAt > prevCal.createdAt ? curCal : prevCal,
  //     { createdAt: 0 } as Calibration);

  //   if (lastCalibration.createdAt > 0) lastCalibration.update((calibration) => calibration.notes = notes)
  //   else {
    
  //     const calProcs = await database.collections.get<CalibrationProcedure>('calibration_procedures');
  //     const calProc = await (await calProcs.query().fetch()).pop() || null;

  //     const probeTypes = await database.collections.get<ProbeType>('probe_types');
  //     const probeType = await (await probeTypes.query().fetch()).pop() || null;

  //     const technicianTools = await database.collections.get<TechnicianTool>('technician_tools');
  //     const technicianTool = await (await technicianTools.query().fetch()).pop() || null;
  //     lastCalibration = await this.collections.get<Calibration>('calibrations').create(calibration => {
  //       calibration.calibrationProcedure.set(calProc)
  //       calibration.probeSerialNumber = '0';
  //       calibration.probeType.set(probeType);
  //       calibration.technicianTool.set(technicianTool);

  //       calibration.notes = notes;
  //       calibration.jobSensor.set(this);
  //     });
  //   }
  //   return lastCalibration;
  // }
}

export type ReadingKey = 'sensor' | 'calibration';

type Reading = { [k in ReadingKey]: number };

export type SensorParams = {
  isCallibrationAutoSave:string
  SequenceNumber: string;
  calibrationsId: string;
  calReadingId: string;
  timestamp: any;
  timestamp_2: any;
  timestamp_1: any;
  timestamp_3: any;
  timestamp_4: any;
  tt_reading: number
  description: string;
  calibrationId: string;
  device_reading: number;
  tt_reading_2: number;
  device_reading_2: number
  tt_reading_3: number;
  device_reading_3: number
  tt_reading_4: number;
  device_reading_4: number
  status: string;
  id: string;
  ambientHumidity: number;
  ambientTemperature: number;
  calibrationProcedureId: string;
  probeTypeId: string;
  probe_replaced: string;
  probeSerialNumber: string;
  notes: string;
  technicianToolId: string;
  readings: Reading[];
  sensorType: string;
  sensor_modal: string;
  name: string;
  sensorGroup: string;
  sensorId: string;
  sensorId_SequenceNumber: string;
  sensorProbeNumber: number;
  building: string;
  floor: string;
  room: string;
  chamber: string;
  sensor_database: string;
  isDisabled: 0 | 1;
  is_lumity: number;
  ispassed:string|null;
}
export type JobSensorUpdateBody = Pick<
  JobSensor,
  "sensorId"
  | "name"
  | "description"
  | "sensorGroup"
  | "sensor_database"
  | "probeNumber"
  | "building"
  | "floor"
  | "room"
  | "addedToScopeDate"
  | "isDisabled"
  | "sensorType"
  | "sensorModal"
  | "is_lumity"
  | "ispassed"
>;