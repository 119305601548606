import { HasManyAssociation } from "@nozbe/watermelondb/Model";
import { writer, children, field } from "@nozbe/watermelondb/decorators";
import { Query } from "@nozbe/watermelondb";
import { Association } from "src/types/watermelon";
import BaseModel from "./BaseModel";
import User from "./User";

export type RoleName = 'administrator' | 'customer' | 'technician' | 'manufacturer';
export default class Role extends BaseModel {
  static table = "roles";
  static associations = {
    users: {
      type: Association.HAS_MANY,
      foreignKey: "role_id",
    } as HasManyAssociation,
  };
  getTableName() {


    return Role.table;
  }
  @field("name") name!: RoleName;
  @field("description") description!: string;
  @children("users") users!: Query<User>;
  @writer async createUser(name: string) {
    await this.collections.get<User>("users").create(user => {
      user.name = name;
      user.role.set(this);
    })
  }
}
