import React, { useContext, useEffect, useState, useMemo } from 'react';
import useCurrentUser from 'src/hooks/useCurrentUser';
import _ from 'lodash';
import { AppTabs, AppTabItem } from '../AppTabs';
import { RoleBasedJobs } from './RoleBasedJobs';
import { JobSummary } from './JobSummary/JobSummary';
import { Headline } from './Headline';
import { UsersDBEntry, JobsDBEntry } from 'src/database/store/StoreTypes';
import { JobType } from './types';
import { StoreContext } from 'src/database/store/StoreProvider';
import useNavStore from '../RootNavBarIcon/NavStore';
import { toast } from 'react-toastify';
import styles from './Dashboard.module.css';
export function Dashboard1() {
	const { isToogle } = useNavStore();
	const [selectedJobType, setSelectedJobType] = useState<JobType | null>(null);
	const [selectedJob, setSelectedJob] = useState<JobsDBEntry | null>(null);
	let [user, setUser] = useState<UsersDBEntry>();
	const { store } = useContext(StoreContext);
	const userId = localStorage.getItem('user-id');
	const [isjobs, setIsjobs] = useState(false);
	const [is_survey_tool, setIsSurveyTool] = useState(false);
	const [is_employeees, setisEmployeees] = useState(false);
	const [is_company_assets, setIsCompanyAssets] = useState(false);
	const [is_alerts, setIsAlerts] = useState(false);
	const [isCalendar, setIsCalendar] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [isTechnician, setIsTechnician] = useState(false);
	const [isManufacturer, setIsManufacturer] = useState(false);
	const [isCustomer, setIsCustomer] = useState(false);
	const [alertCount, setAlertCount] = useState(0);
	const userRoleId = localStorage.getItem('user-role-id') || '';
	const { technician_tools } = store;
	const dbUser = useCurrentUser();
	const userIsTech = useMemo(
		() => dbUser?.role_id_ref.name === 'technician',
		[dbUser]
	);
	useEffect(() => {
		if (store && userId) {
			setUser(store?.users[userId]);
		}
	}, [store, userId]);
	useEffect(() => {}, [isToogle]);
	useEffect(() => {
		if (user) {
			setIsjobs(user.isjobs);
			setIsSurveyTool(user.is_survey_tool);
			setisEmployeees(user.is_employeees);
			setIsCompanyAssets(user.is_company_assets);
			setIsAlerts(user.is_alerts);
			setIsCalendar(user.is_calendar);
		}

		switch (userRoleId) {
			case '311b83d9-2cc5-4c17-bc01-e2b9d006f1e2':
				setIsAdmin(true);
				break;
			case 'e1c59d16-5bc0-4794-92e7-6bab999c7c63':
				setIsTechnician(true);
				break;
			case 'd63cbb89-28ac-4742-bf95-54c7735ed798':
				setIsCustomer(true);
				break;
			case 'cd6d582a-fd03-4484-b3fc-5aa89cc8b358':
				setIsManufacturer(true);
				break;
			default:
		}
	}, [user, store]);
	const justUserLogin = localStorage.getItem('just-user-login');
	useEffect(() => {
		let newData = _.filter(
			technician_tools,
			(data) =>
				data.calibration_due_date <
					+new Date() + 1000 * 60 * 60 * 24 * 30 && !data.acknowledgement
		);
		setAlertCount(newData.length);
		if (technician_tools && userIsTech && justUserLogin === 'true') {
			setTimeout(() => {
				let remaingingDays = _.map(newData, (data) => {
					let days = ~~(
						(data.calibration_due_date - +new Date()) /
						(1000 * 60 * 60 * 24)
					);
					if (days <= 30) {
						if (0 > days) {
							toast.error(
								`${data.tool_id_ref?.name}-${
									data.serial_number
								} is  expired  ${Math.abs(days)} days ago.`,
								{ autoClose: 10000 }
							);
						}
						if (days >= 0 && days < 16) {
							toast.error(
								`${data.tool_id_ref?.name}-${
									data.serial_number
								} is due expiry in ${Math.abs(days)} days.`,
								{ autoClose: 10000 }
							);
						}
						if (days >= 16 && days < 31) {
							toast.warning(
								`${data.tool_id_ref?.name}-${
									data.serial_number
								} is due expiry in ${Math.abs(days)} days.`,
								{ autoClose: 10000 }
							);
						}
					}
				});
				localStorage.removeItem('just-user-login');
			}, 1000);
			localStorage.removeItem('just-user-login');
		}
	}, []);

	const handleJobSelection = (type: JobType, job: JobsDBEntry) => {
		setSelectedJobType(type);
		setSelectedJob(job);
	};

	return (
		<div className="app-dashboard">
			<Headline />
			<AppTabs>
				{isAdmin || (isTechnician && isCalendar) ? (
					<AppTabItem label="Calendar" />
				) : (
					<></>
				)}
				{isAdmin || (isTechnician && isjobs) ? (
					<AppTabItem label="Jobs" />
				) : (
					<></>
				)}

				{/* {isAdmin || (isTechnician && is_survey_tool) ? (
					<AppTabItem label="Survey Tool" />
				) : isTechnician ? (
					<AppTabItem disabled={true} label="Survey Tool" />
				) : (
					<></>
				)} */}

				{/* {isAdmin || (isTechnician && is_employeees) ? (
					<AppTabItem label="Employees" />
				) : isTechnician ? (
					<AppTabItem disabled={true} label="Employees" />
				) : (
					<></>
				)}
				{isAdmin || (isTechnician && is_employeees) ? (
					<AppTabItem label="Customer List" />
				) : isTechnician ? (
					<AppTabItem disabled={true} label="Customer List" />
				) : (
					<></>
				)} */}

				{isAdmin || (isTechnician && is_company_assets) ? (
					<AppTabItem label="Company Assets" />
				) : isTechnician ? (
					<AppTabItem disabled={true} label="Company Assets" />
				) : (
					<></>
				)}

				<div>
					{isAdmin || (isTechnician && is_alerts) ? (
						<>
							<AppTabItem label="Alerts" />
							{alertCount > 0 && (
								<span className={styles.alert_count}>{alertCount}</span>
							)}
						</>
					) : isTechnician ? (
						<>
							<AppTabItem disabled={true} label="Alerts" />
							{alertCount > 0 && (
								<span className={styles.alert_count}>{alertCount}</span>
							)}
						</>
					) : (
						<></>
					)}
				</div>
			</AppTabs>
			<div></div>

			<RoleBasedJobs
				onSelect={(type: JobType, job: JobsDBEntry) =>
					handleJobSelection(type, job)
				}
			/>

			{selectedJobType && selectedJob && (
				<JobSummary type={selectedJobType} job={selectedJob} />
			)}
		</div>
	);
}
