import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './backout.module.css';

type BackoutProps = {
  backoutTitle: string,
  specificAddress?: string
}

const Backout: FC<BackoutProps> = ({ backoutTitle, specificAddress }) => {
  const history = useHistory();
  const backout = useCallback(() => {
    let address = specificAddress;
    if (!address) {
      const paths = history.location.pathname.split('/');
      address = paths.slice(0, paths.length - 1).join('/');
      if (address=='/calibrationToolDetails') {
        address='/calibrationTool'
      }
    }
    history.push(address);
  }, [history, specificAddress])

  return (
    <div className={`${styles.backout} hvrPtr`} onClick={backout}>
      <FontAwesomeIcon icon={'angle-left'} />
      <p>{backoutTitle}</p>
    </div>
  );
}

export default Backout;