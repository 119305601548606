import { SyncPullArgs } from '@nozbe/watermelondb/sync';
import urls from 'src/api/urls';
import { Changes } from './index';
import { ConvertTo, formatChanges } from './maps';
const { sync, base } = urls;


type MigrationColumn = {
  table: string;
  columns: string[]
}

type Migration = {
  from: number,
  tables: string[],
  columns: MigrationColumn[]
}

type PullChangesArgs = {
  lastPulledAt: number,
  // TODO
  // hmm ts throws an error with these although the docs suggest adding them.
  // works fine now but I assume will have to revisit this when we look at migrations
  // schemaVersion: number,
  // migration: Migration
}

type SyncPullResult = {
  changes: Changes,
  timestamp: number,
}

export default async function pullChanges({
  lastPulledAt,
  // schemaVersion,
  // migration
}: SyncPullArgs,
  bearer: string
): Promise<SyncPullResult> {
  const { pull } = sync;
  const { url, method } = pull;
  if (lastPulledAt === null) {
    lastPulledAt = 0;
  }
  const body = JSON.stringify({
    lastPulledAt,
    // schemaVersion,
    // migration
  });
  const headers = {
    'Content-Type': 'application/json'
  }
  if (bearer) {
    Object.assign(headers, { Authorization: `Bearer ${bearer}` });
  }
  const response = await fetch(`${base}${url}`, {
    method,
    body,
    headers
  });
  const { timestamp = 1, changes } = await response.json() as SyncPullResult;
  return {
    changes: formatChanges(changes, ConvertTo.INTERNAL),
    timestamp,
  }
}
