import _, { orderBy } from 'lodash';
import React, {
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { StoreContext } from 'src/database/store/StoreProvider';
import styles from '../jobCertificate.module.css';
import { Table, useTable } from 'src/hooks/useTable';
import {
	CalibrationsDBEntry,
	JobSensorsDBEntry,
} from 'src/database/store/StoreTypes';
import TableHeader, { SortingPackage } from '../../TableHeader/TableHeader';
import { database } from 'src/database';
import urls from 'src/api/urls';
import { authCtx } from 'src/api/AuthProvider';
import { SearchBar, useSearch } from '../../SearchBar/SearchBar';
import { getMostRecentCal, getMostRecentCert } from 'src/utils/general';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { CertificateStatus } from 'src/models/Certificate';
import CertificateBatch, {
	CertificateBatchStatus,
} from 'src/models/CertificateBatch';
import { Q } from '@nozbe/watermelondb';
import Bugsnag from '@bugsnag/js';
import useNavStore from 'src/components/RootNavBarIcon/NavStore';

type ColumnKey = 'sensorId' | 'name' | 'cal' | 'cert' | 'result';

// const statusOrder = {
//    'pending': 0,
//    'failed': 1,
//    'valid': 2,
// }
const POLLING_INTERVAL = parseInt(process.env.POLLING_INTERVAL || '5000');
const sensorCertificateTable: Table<JobSensorsDBEntry, ColumnKey> = {
	columns: [
		{
			key: 'sensorId',
			title: 'Sensor ID',
			extractFunction: (sensor) =>
				`${sensor.sensor_id}${
					sensor.probe_number > 0 ? '/' + sensor.probe_number : ''
				}`,
		},
		{
			key: 'name',
			title: 'Name',
			extractFunction: (sensor) => sensor.name,
		},
		{
			key: 'cert',
			title: 'Action',
			extractFunction: (sensor) => {
				let cal = getMostRecentCal(sensor);
				let cert = getMostRecentCert(cal);
				if (cert) {
					return cert;
				} else {
					return null;
				}
			},
		},
		{
			key: 'cert',
			title: 'Action',
			extractFunction: (sensor) => {
				let cal = getMostRecentCal(sensor);
				let cert = getMostRecentCert(cal);
				if (cert) {
					return cert;
				} else {
					return null;
				}
				//  extractFunction: (sensor) => getMostRecentCert(getMostRecentCal(sensor)),
				//  customSortFunction: (a, b) => {
				//    let aCal = getMostRecentCal(a)
				//    let aCert = getMostRecentCert(aCal)
				//    let aVal = aCal?.status !== 'valid' ? 0 : !aCert ? 1 : 2;
				//    let bCal = getMostRecentCal(b)
				//    let bCert = getMostRecentCert(bCal)
				//    let bVal = bCal?.status !== 'valid' ? 0 : !bCert ? 1 : 2;
				//    return aVal - bVal;
			},
			//  headerAlign: 'center'
		},

		{
			key: 'result',
			title: 'Result',
			extractFunction: (sensor) => {
				let is_passed = getMostRecentCal(sensor)?.ispassed;
				let r= is_passed == '1' ? 'PASS' : is_passed == '0' ? 'FAIL' : null;
				return r
			},

		},
	],
};

export function JobCertificateDetail() {
	const { isToogle } = useNavStore();
	const { accessToken, justSync, isSyncing } = useContext(authCtx);
	const { jobId } = useParams<{ jobId: string }>();
	const { store } = useContext(StoreContext);
	const [searchQuery, setSearchQuery] = useState('');
	const dbUser = useCurrentUser();
	const [currentSort, setCurrentSort] = useState<ColumnKey>('name');
	const [isAscending, setIsAscending] = useState(false);
	const [certificateBatch, setCertificateBatch] = useState<CertificateBatch>();
	const [savedBatchForLoading] = useState<CertificateBatch>();
	const [manualIsLoading, setManualIsLoading] = useState(false);
	const job = store.jobs[jobId];
	const sensors = job?.job_sensors;
	const [certificateFilterQuery, setCertificateFilterQuery] =
		useState<string>('');
	const queryAndSetCertificateBatch = useCallback(async () => {
		const jobUserBatches = await database.collections
			.get<CertificateBatch>('certificate_batches')
			.query(
				Q.where('job_id', jobId),
				Q.where('user_id', dbUser?.id || ''),
				Q.where('status', Q.notEq(CertificateBatchStatus.Unknown))
			)
			.fetch();
		const sortedBatches = orderBy(jobUserBatches, ['updatedAt'], ['desc']);
		const currentBatch = sortedBatches[0];
		const isSameState =
			savedBatchForLoading?.status === currentBatch?.status &&
			savedBatchForLoading?.id === currentBatch?.id;
		setCertificateBatch(currentBatch);
		if (manualIsLoading && !isSameState) {
			setManualIsLoading(false);
		}
	}, [jobId, dbUser?.id, isSyncing, manualIsLoading, savedBatchForLoading]);
	useEffect(() => {
		if (isSyncing === false) {
			queryAndSetCertificateBatch();
		}
	}, [isSyncing]);

	// job_lead_technician_id gets lost on sync pull???
	// const leadTechnician = job?.job_lead_technician_id_ref;
	const isCertifyAll = useMemo(() => {
		let Sensors_to_be_Calibrated: number = 0;
		let SensorsIsPass: number = 0;

		_.forEach(job.job_sensors, (sensor) => {
			const ispassed = getMostRecentCal(sensor)?.ispassed;
						if (ispassed) {
				
				SensorsIsPass++
								let cal = getMostRecentCal(sensor);
				let cert = getMostRecentCert(cal);
				cert?.file_url && Sensors_to_be_Calibrated++;
			}
		});
		
		return {"SensorsCalibrated":Sensors_to_be_Calibrated === Object.keys(job.job_sensors).length,"SensorsIsPass":SensorsIsPass === Object.keys(job.job_sensors).length} 
	}, [job,sensors]);
	const leadTechnician = useMemo(
		() =>
			_.find(
				store.job_lead_technicians,
				(leadTech) => leadTech.job_id === jobId
			),
		[jobId, store.job_lead_technicians]
	);
	const isCurrentTechLead =
		leadTechnician?.job_technician_id_ref.technician_id === dbUser?.id;
	// @ts-ignore
	const filteredSensors = useSearch(
		sensors,
		searchQuery,
		({ sensor_id, name }, test) => test(sensor_id) || test(name)
	);

	const tableData = useTable(
		sensorCertificateTable,
		filteredSensors,
		currentSort,
		isAscending
	);

	const certHasProcessing = Boolean(
		tableData.find((row) => row.cert?.status === CertificateStatus.Processing)
	);
	const batchHasProcessing =
		Boolean(certificateBatch?.status === CertificateBatchStatus.Processing) ||
		manualIsLoading;
	const hasProcessing = certHasProcessing || batchHasProcessing;
	useEffect(() => {
		justSync();
	}, []);

	useEffect(() => {
		const interval = setInterval(async () => {
			if (hasProcessing) {
				console.log(`Polling sync started on job: ${job.name}`);
				if (!isSyncing) {
					try {
						justSync();
						if (batchHasProcessing) {
							await queryAndSetCertificateBatch();
						}
					} catch (err) {
						Bugsnag.notify(err as any);
						console.error('JobDetail useEffect', err);
					}
				} else {
					console.log('Ongoing sync detected. Skipping sync...');
				}
			}
		}, POLLING_INTERVAL);
		return () => clearInterval(interval);
	}, [certHasProcessing, batchHasProcessing, hasProcessing, isSyncing]);

	const sortingPackage: SortingPackage<ColumnKey> = {
		setCurrentSort,
		currentSort,
		setIsAscending,
		isAscending,
	};

	/**
	 * Certifies a sensor and creates the PDF certificate.
	 */
	const downloadOne = useCallback(
    async (url: string) => {
      console.log('Downloading.................',url)
      window.open(url, '_blank');
		},
		[accessToken]
	);

	if (!job || !sensors) return null;
	// Download All Cert
	const downloadAllCert = async () => {
		console.log('fetching zip...');
		let ids: any[] = [];
		let calibrationsList = _.map(sensors, (data) => data.calibrations);
		_.values(calibrationsList).forEach((el) => {
			_.values(el).forEach((cal) => {
				if (cal.ispassed) {
					ids.push(cal.id);
				}
			});
		});

		const { base, batchCertificates } = urls;
		await fetch(`${base}${batchCertificates.url}`, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
			method: batchCertificates.method,
			body: JSON.stringify({ calibrationIds: ids, jobId }),
		}).then((res) => {
			justSync();
			const responsePromise = res.json();
			responsePromise.then(async (response) => {
				const { id } = response;
				if (id) {
					var refreshIntervalId = setInterval(async () => {
						justSync();
						const batchCertData = await database.collections
							.get<CertificateBatch>('certificate_batches')
							.query(Q.where('id', id))
							.fetch()
							.then((data) => {
								if (data[0]?.zipUrl) {
									window.open(data[0]?.zipUrl, '_blank');
									clearInterval(refreshIntervalId);
								}
							});
					}, 10000);
				}
			});
		});
	};
	return (
		<div style={{ marginLeft: isToogle === 'YES' ? '90px' : '' }}>
			<div className={styles.sensorsHeader}>
				<h3 className="listTitle">Certificates</h3>
				<div style={{ display: 'flex' }}>
					<SearchBar
						searchQuery={certificateFilterQuery}
						setSearchQuery={setCertificateFilterQuery}
						placeholder="Search sensor Name/ID"
					/>
					<button
						disabled={
							!isCertifyAll.SensorsIsPass || !isCertifyAll.SensorsCalibrated
						}
						onClick={() => downloadAllCert()}
						className={styles.headerButton}
					>
						Download All
					</button>
				</div>
			</div>
			<div style={{ flexGrow: 1 }}>
				<TableHeader
					// columns={sensorCertificateTable.columns}
					columns={sensorCertificateTable.columns.slice(
						0,
						sensorCertificateTable.columns.length - 2
					)}
					rowClassName={styles.row}
					sortingPackage={sortingPackage}
				/>
				{tableData
					.filter((sensor) =>
						(sensor.name as string)
							.toLowerCase()
							.includes(certificateFilterQuery.toLowerCase())
					)
					.map(({ name, cal, cert, id, result }) => {
									return (
							<div key={id} className={`${styles.row} row`}>
								<p>{id}</p>
								<p>{name}</p>
								<div className={styles.statusButtonContainer}>
									<button
										disabled={!cert?.file_url || !result}
										className={`${styles.row_action}`}
										onClick={() => downloadOne(cert?.file_url)}
									>
										Download
									</button>
								</div>
							</div>
						);
					})}
			</div>
		</div>
	);
}
