import { BelongsToAssociation, HasManyAssociation } from "@nozbe/watermelondb/Model";
import { children, field, relation } from "@nozbe/watermelondb/decorators";
import { Query, Relation } from "@nozbe/watermelondb";
import { Association } from "src/types/watermelon";
import BaseModel from "./BaseModel";
import CalibrationProcedure from "./CalibrationProcedure";
import Tool from "./Tool";
import Workgroup from "./Workgroup";

type ToleranceUnit = 'C' | 'F' | '%';
export default class ProbeType extends BaseModel {
  static table = "probe_types";
  static associations = {
    calibration_procedures: {
      type: Association.HAS_MANY,
      foreignKey: "probe_type_id",
    } as HasManyAssociation,
    tools: {
      type: Association.BELONGS_TO,
      key: "tool_id",
    } as BelongsToAssociation,
    workgroups: {
      type: Association.BELONGS_TO,
      key: "manufacturer_workgroup_id",
    } as BelongsToAssociation,
    // calibrations: {
    //   type: Association.HAS_MANY,
    //   foreignKey: "probe_type_id",
    // } as HasManyAssociation,
  };
  getTableName() {
    return ProbeType.table;
  }
  @field("name") name!: string;
  @field("model") model!: string;
  @field("tolerance") tolerance!: string;
  @field("tolerance_unit") toleranceUnit!: ToleranceUnit;
  @field("phone") phone!: string;
  @field("website") website!: string;
  @children("calibration_procedures") calibrationProcedures!: Query<CalibrationProcedure>;
  @relation("tool", "tool_id") tool!: Relation<Tool>;
  @relation("workgroup", "manufacturer_workgroup_id") manufacturerWorkgroup!: Relation<Workgroup>;
  // @children("calibrations") calibrations!: Query<Calibration>;
}
