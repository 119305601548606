import { Relation } from "@nozbe/watermelondb";
import { BelongsToAssociation } from "@nozbe/watermelondb/Model";
import { action, field, relation } from "@nozbe/watermelondb/decorators";
import { Association, FieldData } from "src/types/watermelon";
import BaseModel from "./BaseModel";
import User from "./User";
import Job from "./Job";

export default class CertificateBatch extends BaseModel {
  static table = "certificate_batches";
  static associations = {
    users: {
      type: Association.BELONGS_TO,
      key: "user_id",
    } as BelongsToAssociation,
    jobs: {
      type: Association.BELONGS_TO,
      key: "job_id",
    } as BelongsToAssociation,
  };
  getTableName() {
    return CertificateBatch.table;
  }
  @field("calibration_ids") calibrationIds!: string;
  @field("zip_url") zipUrl!: string;
  @field("status") status!: CertificateBatchStatus;
  @relation("users", "user_id") userId!: Relation<User>;
  @relation("jobs", "job_id") jobId!: Relation<Job>;
}

export enum CertificateBatchStatus {
  Unknown = 0,
  Processing = 1,
  Completed = 2,
  Failed = 3,
}

export type CertificateUpdateBody = Pick<
  CertificateBatch,
    | "zipUrl"
    | "status"
>;
