import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faAngleLeft,
	faHospitalUser,
	faSearch,
	faThermometerHalf,
	faToolbox,
	faUsers,
	faSort,
	faSortUp,
	faSortDown,
	faFileImport,
	faEye,
	faFolderOpen,
	faTrash,
	faCheck,
	faTimesCircle,
	faArrowRight,
	faArrowLeft,
	faTimes,
  	faCircle,
	faHome,
	faFileAlt,
  	faCheckCircle,
	faCloudDownloadAlt,
	faSortNumericUp,
	faSortNumericDown,
	faPenSquare,
	faMagnet,
	faWrench,
	faDownload,
} from '@fortawesome/free-solid-svg-icons';

export function initializeIcons() {
	library.add(
		faToolbox,
		faHospitalUser,
		faUsers,
		faHome,
    	faCircle,
		faThermometerHalf,
		faSearch,
		faAngleLeft,
		faSort,
		faSortUp,
		faSortDown,
		faFileImport,
		faEye,
		faFolderOpen,
		faTrash,
		faCheck,
    	faCheckCircle,
		faTimes,
		faTimesCircle,
		faFileAlt,
		faCloudDownloadAlt,
		faSortNumericUp,
		faSortNumericDown,
		faArrowRight,
		faArrowLeft,
		faPenSquare,
		faMagnet,
		faWrench,
		faDownload,
	);
}
