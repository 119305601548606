import React, { useEffect, useState } from 'react';
import { JobSensorDetail } from './JobSensorDetail';
import { JobDocumentDetail } from './JobDocumentDetail';
import { JobCertificateDetail } from './JobCertificateDetail';
import { JobStatusDetail } from './JobStatusDetails';

export enum JobDetailTabs {
	SENSORS = 'sensors',
	DOCUMENTS = 'documents',
	CERTIFICATES = 'certificates',
	STATUS = 'STATUS',
}

export function JobDetailTabView(props: { name: JobDetailTabs }) {

	switch (props.name) {
		default:
		case JobDetailTabs.SENSORS:
			return <JobSensorDetail />;

		case JobDetailTabs.DOCUMENTS:
			return <JobDocumentDetail />;

		case JobDetailTabs.CERTIFICATES:
			return <JobCertificateDetail />;
		case JobDetailTabs.STATUS:
			return <JobStatusDetail />;
	}
}
