import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { database } from 'src/database';
import { StoreContext } from 'src/database/store/StoreProvider';
import {
  JobsDBEntry,
  WorkgroupsDBEntry,
} from 'src/database/store/StoreTypes';
import { Table, useTable } from 'src/hooks/useTable';
import Workgroup from 'src/models/Workgroup';

import { unixToStr } from 'src/utils/general';
import useNavStore from '../RootNavBarIcon/NavStore';
import TableHeader, { SortingPackage } from '../TableHeader/TableHeader';
import styles from './customerDetail.module.css';


function getAllJobs(workgroup: WorkgroupsDBEntry) {
  const allJobs: JobsDBEntry[] = [];
  _.forEach(workgroup.job_workgroups, (jwg) => {
    if (!jwg.job_id_ref) return console.log(`jwg ${jwg.id} has broken ref to job ${jwg.job_id}`)
    allJobs.push(jwg.job_id_ref);
  });
  return allJobs;
}

type ColumnKey = 'name' | 'pending' | 'open' | 'closed' | 'users' | 'created'|'action';
const workgroupsTable: Table<WorkgroupsDBEntry, ColumnKey> = {
  columns: [
    {
      key: 'name',
      title: 'Name',
      extractFunction: (wg) => wg.name,
    },
    {
      key: 'pending',
      title: 'Pending',
      extractFunction: (wg) => 
        getAllJobs(wg).filter(({ status }) => status === 'pending').length,
      headerAlign: 'right',
    },
    {
      key: 'open',
      title: 'Open',
      extractFunction: (wg) =>
        getAllJobs(wg).filter(({ status }) => status === 'open').length,
      headerAlign: 'right',
    },
    {
      key: 'closed',
      title: 'Closed',
      extractFunction: (wg) =>
        getAllJobs(wg).filter(({ status }) => status === 'closed').length,
      headerAlign: 'right',
    },
    {
      key: 'users',
      title: 'Users',
      extractFunction: (wg) => _.size(wg.users),
      headerAlign: 'right',
    },
    {
      key: 'created',
      title: 'Created',
      extractFunction: (wg) => wg.created_at,
      headerAlign: 'right',
    },
    {
			key: 'action',
			title: 'Action',
      headerAlign: 'center',
		},
  ],
};


type UsersProps = { orgId: string };

const OrgWorkgroups: FC<UsersProps> = ({ orgId }) => {
  const history = useHistory();
  const { store } = useContext(StoreContext);
  const org = store.organizations[orgId];
  const [currentSort, setCurrentSort] = useState<ColumnKey>('created');
  const [isAscending, setIsAscending] = useState(false);
  const { isToogle } = useNavStore();
	useEffect(() => {}, [isToogle]);
  const tableData = useTable(
    workgroupsTable,
    org.workgroups,
    currentSort,
    isAscending
  );

  if (!org) return null;

  const sortingPackage: SortingPackage<ColumnKey> = {
    setCurrentSort,
    currentSort,
    setIsAscending,
    isAscending,
  };
  const deleteOneGroup = async (id: string, name: string) => {
		if (id && window.confirm(`Are You Sure To Delete: ${name}`)) {
      const selectedOrgWorkGroupDelete = await database.collections.get<Workgroup>('workgroups').find(id);
      await selectedOrgWorkGroupDelete.deleteRecord();
		}
	};
  return (
    <div>
      <TableHeader columns={workgroupsTable.columns} rowClassName={styles.workgroupRow} sortingPackage={sortingPackage}/>
      {/* <div style={{ marginLeft: isToogle === 'YES' ? '90px' : '' }}> */}
      {tableData.map(({ name, pending, open, closed, users, created, id }) => (
        
        <div
          key={id}
          className={`${styles.workgroupRow} hvrPtr row`}
        
        >
          <p>{name}</p>
          <p style={{ textAlign: 'end' }} >{pending}</p>
          <p style={{ textAlign: 'end' }} >{open}</p>
          <p style={{ textAlign: 'end' }} >{closed}</p>
          <p style={{ textAlign: 'end' }} >{users}</p>
          <p style={{ textAlign: 'end' }} >{unixToStr(created)}</p>
          <p style={{ textAlign: 'center' }}>
								<FontAwesomeIcon
								className={styles.actionEdit}
                onClick={() => history.push(`/customers/${orgId}/workgroup/${id}`)}
									icon="pen-square"
									size="1x"
									cursor="pointer"
								/>
								
								<FontAwesomeIcon
											className={styles.actionDel}
											onClick={() => deleteOneGroup(id,name)}
											icon="trash"
											size="1x"
											cursor="pointer"
										/>
							</p>
        </div>
      ))}
      </div>
    // </div>
  );
};

export default OrgWorkgroups;
