import _ from 'lodash';
import React from 'react';
import { StoreContext } from 'src/database/store/StoreProvider';
import { JobsDBEntry } from 'src/database/store/StoreTypes';
import { getJobStat, JobStat } from './index';
import urls from '../../../api/urls';
import { authCtx } from 'src/api/AuthProvider';

export function UpcomingJobDataBlock(props: { job: JobsDBEntry, onClick?: Function }) {
	const { accessToken } = React.useContext(authCtx);
	const [jobStat] = React.useState<JobStat>(getJobStat(props.job));
	const [customerIdentified, setCustomerIdentified] = React.useState<boolean>(Boolean(props.job.isCustomerIdentified));
	const [assetReconciliation, setAssetReconciliation] = React.useState(Boolean(props.job.isAssetReconciled));
	const [sow, setSow] = React.useState(Boolean(props.job.isSOW));
	const [scheduling, setScheduling] = React.useState(Boolean(props.job.isScheduled));
	const [assignTech, setAssignTech] = React.useState(Boolean(props.job.isAssignTech));

	const updateJobWorlflow = async (property: string, value: boolean) => {
		const formdata = new FormData();
		formdata.append(property, value? '1': '0');
		await fetch(`${urls.jobUpdate.url(props.job.id)}`, {
			method: urls.jobUpdate.method,
			headers: {
				'Authorization': `Bearer ${accessToken}`,
			},
			body: formdata,
		});
	}

	return (
		<div className="data-block-card" onClick={e => props.onClick && props.onClick.call(e)}>
			<div className="data-block-card-detail">
				<h5 className="data-block-job-name">{jobStat.name}</h5>
				<p className="data-block-cust-type">
					Customer Type: <strong>New</strong>
				</p>
				<br />
				<br />
				<div className="data-workflow-stage">
					<div className="data-workflow-stage-check">
						<input 
							type='checkbox' 
							id={"cust-ident-" + props.job.id}
							checked={customerIdentified}
							onChange={e => {
								setCustomerIdentified(e.target.checked);
								updateJobWorlflow('isCustomerIdentified', e.target.checked);
							}} />
						<label 
							className="label workflow-label"
							htmlFor={"cust-ident-" + props.job.id}>Customer Identified</label>
					</div>

					<div className="data-workflow-stage-check">
						<input 
							type='checkbox' 
							id={"asset-recon-" + props.job.id}
							checked={assetReconciliation}
							onChange={e => {
								setAssetReconciliation(e.target.checked);
								updateJobWorlflow('isAssetReconciled', e.target.checked);
							}} />
						<label 
							className="label workflow-label" 
							htmlFor={"asset-recon-" + props.job.id}>Asset Reconciliation</label>
					</div>

					<div className="data-workflow-stage-check">
						<input 
							type='checkbox' 
							id={"sow-" + props.job.id}
							checked={sow}
							onChange={e => {
								setSow(e.target.checked);
								updateJobWorlflow('isSOW', e.target.checked);
							}} />
						<label 
							className="label workflow-label" 
							htmlFor={"sow-" + props.job.id}>SOW</label>
					</div>

					<div className="data-workflow-stage-check">
						<input 
							type='checkbox' 
							id={"scheduling-" + props.job.id}
							checked={scheduling}
							onChange={e => {
								setScheduling(e.target.checked);
								updateJobWorlflow('isScheduled', e.target.checked);
							}} />
						<label 
							className="label workflow-label" 
							htmlFor={"scheduling-" + props.job.id}>Scheduling</label>
					</div>
				
					<div className="data-workflow-stage-check">
						<input 
							type='checkbox' 
							id={"assign-tech-" + props.job.id}
							checked={assignTech}
							onChange={e => {
								setAssignTech(e.target.checked);
								updateJobWorlflow('isAssignTech', e.target.checked);
							}} />
						<label 
							className="label workflow-label" 
							htmlFor={"assign-tech-" + props.job.id}>Assign Tech</label>
					</div>
				</div>
			</div>
		</div>
	);
}

export function UpcomingJobOverview(props: { onSelect: Function }) {
	const { store } = React.useContext(StoreContext);
	const [upcomingJobs, setUpcomingJobs] = React.useState<JobsDBEntry[]>([]);
	const [showAll, setLimitedJobs] = React.useState<boolean>(false);
	const [qRoleId, setQRoleId] = React.useState<string>('*');
	const jobs = _.values(store.jobs);
	let limit = showAll ? jobs.length : 2;

	React.useEffect(() => {

		setUpcomingJobs(
			_.slice(
				_.orderBy(
					_.filter(jobs, (job) => !job.status || job.status === 'pending'),
					['updated_at'],
					['desc']
				),
				0,
				limit
			)
		);
	}, [showAll]);

	React.useEffect(() => {
		setUpcomingJobs(
			_.slice(
				_.orderBy(
					_.filter(jobs, (job) => {
						if (!job.status || job.status === 'pending') {
							if (qRoleId === '*') {
								return true;
							}

							if (job.job_technicians && _.keys(job.job_technicians).length > 0) {
								return _.values(job.job_technicians).every((tech) => {
									if (tech) {
										if (tech.technician_id_ref && tech.technician_id_ref.role_id === qRoleId) {
											return true;
										}
									}
								})
							}
						}
						return false;
					}),
					['updated_at'],
					['desc']
				),
				0,
				limit
			)
		);
	}, [qRoleId])

	return (
		<div className="data-block-row">
			<div className="data-block-header">
				<div className='data-block-label'>Upcoming Jobs</div>
				<div className="role-based-job-filter">
					<select value={qRoleId} onChange={(e) => setQRoleId(e.target.value)}>
						<option value="*">All</option>
						<option value="311b83d9-2cc5-4c17-bc01-e2b9d006f1e2">Admin</option>
						<option value="e1c59d16-5bc0-4794-92e7-6bab999c7c63">Customer</option>
						<option value="d63cbb89-28ac-4742-bf95-54c7735ed798">Technician</option>
						<option value="manufacturer">Manufacturer</option>
					</select>
				</div>
			</div>

			{upcomingJobs.map((upcomingJob) => (
				<UpcomingJobDataBlock 
					key={upcomingJob.name} 
					job={upcomingJob}
					// onClick={() => {}}
					onClick={() => props.onSelect(upcomingJob)}
					/>
			))}

			{upcomingJobs.length > 0 ? (
				<button onClick={() => setLimitedJobs(!showAll)}>
					{showAll ? 'Show less' : 'Show more'}
				</button>
			) : (
				<p className="text-center">No jobs</p>
			)}
		</div>
	);
}
