import React ,{FC} from 'react';
import styles from './SurveyTool.module.css';
type CompanyAssetsProps = {};

const SurveyTool: FC<CompanyAssetsProps> = () => {
return(
    <>
    <div className='header'>
        Customer Information
    </div>
    <div>

    </div>
    </>
    
)
}
export default SurveyTool;