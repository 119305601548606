export default {
  base: process.env.REACT_APP_BASE_URL,
  documentUploadbase: process.env.REACT_APP_FILE_UPLOAD_BASE_URL,
  // uploadFileAwsApi: process.env.REACT_APP_AWS_FILE_UPLOAD,
  // listFileAwsApi: process.env.REACT_APP_AWS_FILE_LIST,
  // deleteFileAwsApi: process.env.REACT_APP_AWS_FILE_DELETE,
  sync: {
    pull: {
      url: '/sync/pull',
      method: 'POST',
    },
    push: {
      url: '/sync/push',
      method: 'POST',
    }
  },
  jobImport: {
    url: '/jobs/import',
    method: 'POST',
  },
  createJobWithoutCSV: {
    url: '/jobs',
    method: 'POST',
  },
  jobExport: {
    url: (jobId: string) => `/jobs/${jobId}/export`,
    method: 'GET'
  },
  jobUpdate: {
    url: (jobId: string) => `/jobs/${jobId}`,
    method: 'PUT'
  },
  jobDelete: {
    url: '/jobs',
    method: 'DELETE'
  },
  batchCertify: {
    url: '/calibrations/certificates/generate',
    method: 'POST'
  },
  batchCertificates: {
    url: '/certificates',
    method: 'POST'
  },
  userInvite: {
    url: '/users/invite',
    method: 'POST',
  },
  usersMe: {
    url: '/users/me',
    method: 'GET',
  },
  updateProfile: {
    url: '/users/me',
    method: 'PUT',
  },
  updateUserProfile: {
    url: '/users',
    method: 'PUT',
  },
  deleteUser: {
    url: '/users',
    method: 'DELETE'
  },

  addAsset: {
    url: '/toolbox',
    method: 'POST',
  },
  listAsset: {
    url: '/toolbox',
    method: 'GET',
  },
  updateAsset: {
    url: '/toolbox/toolId/',
    method: 'PUT',
  },
  deteteAsset: {
    url: '/toolbox/toolId',
    method: 'DELETE',
  },
  addProbeType: {
    url: '/probeTypes',
    method: 'POST',
  },
  updateProbeType: {
    url: '/probeTypes/',
    method: 'PUT',
  },
  deleteOneProbeType: {
    url: '/probeTypes/',
    method: 'DELETE',
  },
  addTools: {
    url: '/tools',
    method: 'POST',
  },
  updateTool: {
    url: '/tools/',
    method: 'PUT',
  },
  deleteOneTool: {
    url: '/tools/',
    method: 'DELETE',
  },
  documents: {
    upload: {
      url: '/uploadFiles',
      method: 'POST'
    },
    list: {
      url: '/listFiles',
      method: 'GET'
    },
    delete: {
      url: '/deleteFile',
      method: 'DELETE'
    },
  },
  calProc: {
    delete: {
      url: '/calProcedures/procedureId/',
      method: 'DELETE'
    },
  }
}