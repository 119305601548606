import React, { useEffect, useState } from 'react'
import "./JobFilter.css";

export type JobFilterField = '*' | 'job_name' | 'cust_name'

export interface JobFilterResult {
   type: JobFilterField,
   value: string
}

interface JobFilterProps {
   onChanges?: Function
}

export function JobFilter(props: JobFilterProps) {
   const [type, setType] = useState<JobFilterField>('*')
   const [value, setValue] = useState<string>('')

   useEffect(() => {
      if ('onChanges' in props && typeof props.onChanges === 'function') {
         props.onChanges({ type, value } as JobFilterResult)
      }
   }, [type, value])

   return (
      <div className='app-job-filter'>
         <div className='job-filter-field selectContainer'>
            <select value={type} onChange={e => setType(e.target.value as JobFilterField)}>
               <option value='*'>All</option>
               <option value='job_name'>Job Name</option>
               <option value='cust_name'>Customer Name</option>
            </select>
         </div>
         <div className='job-filter-text-input-wrapper'>
            <input
               className='job-filter-text-input'
               placeholder='Search here'
               onChange={e => setValue(e.target.value)} />
         </div>
      </div>
   )
} 
