import { camelCase, find, lowerCase, snakeCase, map, reduce } from "lodash";
import { RawRecord, TableChanges, Changes } from './index';

type FieldConversion = {
  camelKey: string,
  internalKey: string,
  transform?: {
    transformToInternal: (value: string) => any,
    transformToCamel: (value: any) => any,
  }
}

type FieldMap = {
  [fieldKey: string]: FieldConversion
}

type TableMap = {
  lowercaseKey?: string,
  internalKey?: string,
  camelKey?: string,
  fields?: FieldMap
}

type DatabaseMap = {
  [tableKey: string]: TableMap
}

/**
 * Custom database mappings to convert between backend/watermelon data.
 * Only add mappings here if the default transformations can't handle it.
 * See below for extended explanation:
 *
 * Watermelon's recommended naming conventions were used (snake_case for schema, otherwise
 * camelCase internally: https://nozbe.github.io/WatermelonDB/Schema.html#defining-a-schema.)
 * The backend was already made with default Sequelize settings and camelCased tableNames and fields.
 * Finally, heroku's MySQL provider transforms camelCased tablenames to lowercased tablenames...
 * not lower_cased_otherwise_id_have_said_snake_cased, but lowercased. This also applies to association
 * fields (jobLeadTechnicians -> jobleadtechnicians).
 */
export const databaseMap: DatabaseMap = {
  common: {
    fields: {
      // TODO: see if updatedAt/deletedAt can be deleted here, expected to have been blacklisted on
      // the backend
      // Note: reinvestigate above. Blacklisting should only happen on push, frontend should always
      // accept backend dates
      createdAt: {
        camelKey: 'createdAt',
        internalKey: 'created_at',
        transform: {
          transformToInternal: (v: string) => Number(new Date(v)),
          transformToCamel: (v: number) => new Date(v),
        }
      },
      updatedAt: {
        camelKey: 'updatedAt',
        internalKey: 'updated_at',
        transform: {
          transformToInternal: (v: string) => Number(new Date(v)),
          transformToCamel: (v: number) => new Date(v),
        }
      },
      deletedAt: {
        camelKey: 'deletedAt',
        internalKey: 'deleted_at',
      },
      // We don't want _changed and _status to go through default transformations.
      // This effectively blacklists them, otherwise they'd become changed/status after
      // applying _.camelCase or _.snakeCase
      _changed: {
        camelKey: '_changed',
        internalKey: '_changed',
      },
      _status: {
        camelKey: '_status',
        internalKey: '_status',
      },
    }
  },
  jobs:{
    lowercaseKey: 'jobs',
    internalKey: 'jobs',
    camelKey: 'jobs',
    fields: {
      toleranceLimitRule: {
        camelKey: 'toleranceLimitRule',
        internalKey: 'tolerance_limit_rule',
      },
    }
  },
  jobResources: {
    lowercaseKey: 'jobresources',
    internalKey: 'job_resources',
    camelKey: 'jobResources',
    fields: {
      mediaType: {
        camelKey: 'mediaType',
        internalKey: 'media_type',
      },
    }
  },
  calibrationProcedures: {
    lowercaseKey: 'calibrationprocedures',
    internalKey: 'calibration_procedures',
    camelKey: 'calibrationProcedures',
    fields: {
      revisionDate: {
        camelKey: 'revisionDate',
        internalKey: 'revision_date',
        transform: {
          transformToInternal: (v: string) => Number(new Date(v)),
          transformToCamel: (v: number) => new Date(v),
        }
      },
      calibrationProcedureText: {
        camelKey: 'calibrationProcedureText',
        internalKey: 'calibration_procedure_text',
      },
      toolId: {
        camelKey: 'toolId',
        internalKey: 'tool_id',
      },
      calibrationStandardText: {
        camelKey: 'calibrationStandardText',
        internalKey: 'calibration_standard_text',
      }
    }
  },
  certificateBatches: {
    lowercaseKey: 'certificatebatches',
    internalKey: 'certificate_batches',
    camelKey: 'certificateBatches',
  },
  calibrationReadings: {
    lowercaseKey: 'calibrationreadings',
    internalKey: 'calibration_readings',
    camelKey: 'calibrationReadings',
    fields: {
      remoteTemperature2: {
        camelKey: 'remoteTemperature2',
        internalKey: 'remote_temperature2',
      },
      remoteTemperature: {
        camelKey: 'remoteTemperature',
        internalKey: 'remote_temperature',
      },
      deviceTemperature: {
        camelKey: 'deviceTemperature',
        internalKey: 'device_temperature',
      },
      deviceTemperature2: {
        camelKey: 'deviceTemperature2',
        internalKey: 'device_temperature2',
      },
      reading2Date: {
        camelKey: 'reading2Date',
        internalKey: 'reading2_date',
      },
      reading1Date: {
        camelKey: 'reading1Date',
        internalKey: 'reading1_date',
      },
      remoteTemperature3: {
        camelKey: 'remoteTemperature3',
        internalKey: 'remote_temperature3',
      },
      deviceTemperature3: {
        camelKey: 'deviceTemperature3',
        internalKey: 'device_temperature3',
      },
      reading3Date: {
        camelKey: 'reading3Date',
        internalKey: 'reading3_date',
      },
      remoteTemperature4: {
        camelKey: 'remoteTemperature4',
        internalKey: 'remote_temperature4',
      },
      deviceTemperature4: {
        camelKey: 'deviceTemperature4',
        internalKey: 'device_temperature4',
      },
      reading4Date: {
        camelKey: 'reading4Date',
        internalKey: 'reading4_date',
      },
    }
  },
  jobLeadTechnicians: {
    lowercaseKey: 'jobleadtechnicians',
    internalKey: 'job_lead_technicians',
    camelKey: 'jobLeadTechnicians',
  },
  jobSensors: {
    lowercaseKey: 'jobsensors',
    internalKey: 'job_sensors',
    camelKey: 'jobSensors',
    fields: {
      database: {
        camelKey: 'sensorDatabase',
        // database was unavailable as a key in watermelonDB, renamed to sensor_database
        internalKey: 'sensor_database',
      },
      probeNumber: {
        camelKey: 'probeNumber',
        internalKey: 'probe_number',
      },
      isDisabled: {
        camelKey: 'isDisabled',
        internalKey: 'is_disabled'
      },
      isLumity: {
        camelKey: 'isLumity',
        internalKey: 'is_lumity',
      },
      sensorModal: {
        camelKey: 'sensorModal',
        internalKey: 'sensor_modal',
      },
      
    }
  },
  jobTechnicians: {
    lowercaseKey: 'jobtechnicians',
    internalKey: 'job_technicians',
    camelKey: 'jobTechnicians',
    fields: {
      deactivatedDate: {
        camelKey: 'deactivatedDate',
        internalKey: 'deactivated_date',
        transform: {
          transformToInternal: (v: string) => Number(new Date(v)),
          transformToCamel: (v: number) => new Date(v),
        }
      },
    }
  },
  jobWorkgroups: {
    lowercaseKey: 'jobworkgroups',
    internalKey: 'job_workgroups',
    camelKey: 'jobWorkgroups',
  },
  probeTypes: {
    lowercaseKey: 'probetypes',
    internalKey: 'probe_types',
    camelKey: 'probeTypes'
  },
  technicianTools: {
    lowercaseKey: 'techniciantools',
    internalKey: 'technician_tools',
    camelKey: 'technicianTools',
    fields: {
      deactivatedDate: {
        camelKey: 'deactivatedDate',
        internalKey: 'deactivated_date',
        transform: {
          transformToInternal: (v: string) => Number(new Date(v)),
          transformToCamel: (v: number) => new Date(v),
        }
      },
      calibrationDate: {
        camelKey: 'calibrationDate',
        internalKey: 'calibration_date',
        transform: {
          transformToInternal: (v: string) => Number(new Date(v)),
          transformToCamel: (v: number) => new Date(v),
        }
      },
      calibrationDueDate: {
        camelKey: 'calibrationDueDate',
        internalKey: 'calibration_due_date',
        transform: {
          transformToInternal: (v: string) => Number(new Date(v)),
          transformToCamel: (v: number) => new Date(v),
        }
      },
      closeDate: {
        camelKey: 'closeDate',
        internalKey: 'closed_at',
        transform: {
          transformToInternal: (v: string) => Number(new Date(v)),
          transformToCamel: (v: number) => new Date(v),
        }
      },
    


    }
  },
  users:{
    lowercaseKey: 'users',
    internalKey: 'users',
    camelKey: 'users',
    fields:{
      isCompanyAssets: {
        internalKey: 'is_company_assets',
        camelKey: 'isCompanyAssets'
      },
    }
    
  }
}

/**
 * INTERNAL: table_foo, table_foo.field_bar
 * STANDARD: tableFoo, tableFoo.fieldBar
 * HEROKU: tablefoo, tablefoo.fieldBar
 * Example: the table will get pulled in from heroku as tablefoo, there's no way
 * to turn the table name into table_foo. This mapping must be manually added to the
 * database map
 */
export enum ConvertTo {
  INTERNAL = "internal",
  EXTERNAL_STANDARD = "standard",
  EXTERNAL_HEROKU = "heroku"
}

function findTableMap(tableName: string) {
  return find(databaseMap, (tableMap, tableKey) => [tableMap.internalKey, tableMap.lowercaseKey, tableKey].includes(tableName));
}

function snakeToStrippedLowerCase(string: string) {
  return string.replace('_', '').toLowerCase();
}

function getCustomTableName(tableName: string, tableMap: TableMap, convertTo: ConvertTo) {
  const {
    lowercaseKey,
    camelKey,
    internalKey
  } = tableMap;
  let result = tableName;
  switch (convertTo) {
    case ConvertTo.INTERNAL:
      result = internalKey || result;
      break;
    case ConvertTo.EXTERNAL_STANDARD:
      result = camelKey || result;
      break;
    case ConvertTo.EXTERNAL_HEROKU:
      result = lowercaseKey || result;
      break;
  }
  return result;
}

function getDefaultTableName(tableName: string, convertTo: ConvertTo) {
  let result;
  switch (convertTo) {
    case ConvertTo.INTERNAL:
      result = lowerCase(tableName);
      break;
    case ConvertTo.EXTERNAL_STANDARD:
      result = camelCase(tableName);
      break;
    case ConvertTo.EXTERNAL_HEROKU:
      result = snakeToStrippedLowerCase(tableName);
      break;
  }
  return result;
}

export function convertTableName(tableName: string, convertTo: ConvertTo) {
  let customTableMap = findTableMap(tableName);
  if (!customTableMap) {
    return getDefaultTableName(tableName, convertTo)
  }
  return getCustomTableName(tableName, customTableMap, convertTo);
}

type FieldData = {
  k: string,
  v: any
}

/**
 * Most of the time the field values won't need to be transformed-- only the keys will change. Initially
 * expected to have to do Date -> Number(Date) transformations, not sure if still necessary
 */
function getCustomFieldConversion(fields: FieldConversion, value: any, convertTo: ConvertTo): FieldData {
  const { camelKey, internalKey, transform } = fields;
  const transformedKey = convertTo === ConvertTo.INTERNAL ? internalKey : camelKey;
  let transformedValue = value;
  if (transform) {
    const { transformToCamel, transformToInternal } = transform;
    const transformFn = convertTo === ConvertTo.INTERNAL ? transformToInternal : transformToCamel;
    transformedValue = transformFn(transformedValue);
  }
  return {
    k: transformedKey,
    v: transformedValue
  }
}

/**
 * Applies default field conversion, probably only key case transformation
 */
function defaultFieldConversion(fieldData: FieldData, convertTo: ConvertTo) {
  const { k, v } = fieldData;
  let result: FieldData = { ...fieldData };
  if (convertTo === ConvertTo.INTERNAL) {
    result = {
      k: snakeCase(k),
      v
    }
  } else if ([ConvertTo.EXTERNAL_STANDARD, ConvertTo.EXTERNAL_HEROKU].includes(convertTo)) {
    result = {
      k: camelCase(k),
      v
    }
  }
  return result;
}

export function convertField(tableName: string, fieldName: string, value: string, convertTo: ConvertTo): FieldData {
  let customTableTransformation = findTableMap(tableName);
  const fieldData = {
    k: fieldName,
    v: value
  };
  const commonFields = databaseMap.common.fields!;
  const commonFieldConversion = commonFields[fieldName];
  if (!!commonFieldConversion) {
    return getCustomFieldConversion(commonFieldConversion, fieldData.v, convertTo);
  }
  if (customTableTransformation) {
    const { fields } = customTableTransformation;
    const customFieldConversion = fields && fields[fieldName];
    if (!!customFieldConversion) {
      return getCustomFieldConversion(customFieldConversion, fieldData.v, convertTo);
    }
  }
  return defaultFieldConversion(fieldData, convertTo)
}

function formatRecord(record: RawRecord, tableName: string, convertTo: ConvertTo) {
  return reduce(record, (acc, value, key) => {
    const { k, v } = convertField(tableName, key, value, convertTo);
    acc[k] = v;
    return acc;
  }, {} as RawRecord)
}

function formatRecords(records: RawRecord[], tableName: string, convertTo: ConvertTo) {
  return map(records, (record => formatRecord(record, tableName, convertTo)))
}

function formatTableChanges(tableChanges: TableChanges, tableName: string, convertTo: ConvertTo) {
  const formattedTableChanges: TableChanges = {
    created: [...formatRecords(tableChanges.created, tableName, convertTo)],
    updated: [...formatRecords(tableChanges.updated, tableName, convertTo)],
    deleted: [...tableChanges.deleted]
  };
  return formattedTableChanges;
}

/**
 * Format and transform the Changes object, autogenerated by Watermelon's
 * built-in sync engine. Pulled changes will have to be transformed to ConvertTo.INTERNAL,
 * while pushed changes should be transformed to whatever format required by the destination backend.
 */
export function formatChanges(changes: Changes, convertTo: ConvertTo) {
  const result = reduce(changes, (acc, tableChanges, tableName) => {
    const convertedTableName = convertTableName(tableName, convertTo);
    const formattedTableChanges = formatTableChanges(tableChanges, tableName, convertTo);
    acc[convertedTableName] = formattedTableChanges;
    return acc;
  }, {} as Changes);
  return result;
}
