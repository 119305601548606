/**
 * Determines if a string of a number is a float
 * @param value the number as a string
 * @returns whether it is a float or not
 */
export function isFloat(value: string): boolean {
  return /^-?\d*\.?\d*$/.test(value);
}

/**
 * Determines if an email (or any kind of string) is an FCS email
 * @param value the email
 * @returns whether it is an FCS email or not
 */
export function isFcsEmail(value: string): boolean {
  return /^[A-Za-z0-9._%+-]+@((fcs|fcs-team)\.com)$/.test(value);
}