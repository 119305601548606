import React, { createContext, Dispatch, FC, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import useNavigatorOnLine from "./useNavigatorOnLine";

type OfflineQueueValues = {
  doSyncOnceOnline: boolean;
  setDoSyncOnceOnline: Dispatch<SetStateAction<boolean>>;
  isOnline: boolean;
}

export const offlineQueueCtx = createContext<OfflineQueueValues>({ doSyncOnceOnline: false, setDoSyncOnceOnline: () => { return }, isOnline: false });

export const OfflineQueueProvider: FC = ({ children }) => {
  const isOnline = useNavigatorOnLine();
  const [doSyncOnceOnline, setDoSyncOnceOnline] = useState(false);
  return <offlineQueueCtx.Provider value={{ doSyncOnceOnline, setDoSyncOnceOnline, isOnline }}>{children}</offlineQueueCtx.Provider>
}

export default function useOfflineQueue() {
  return useContext(offlineQueueCtx);
}
