import React, { useEffect, FC, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { authCtx } from 'src/api/AuthProvider';
import { PopColl, ProbeTypesDBEntry } from 'src/database/store/StoreTypes';
import { Table, useTable } from 'src/hooks/useTable';
import TableHeader, { SortingPackage } from '../TableHeader/TableHeader';
import {
	latestDefaultCalProc,
	unixToStr,
	celsiusToFahrenheit,
	fahrenheitToCelsius,
} from 'src/utils/general';
import styles from './probes.module.css';
import useNavStore from '../RootNavBarIcon/NavStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import AddEditProbes from '../modals/AddProbes/AddProbes';
import { StoreContext } from 'src/database/store/StoreProvider';
import urls from 'src/api/urls';
import { toast } from 'react-toastify';

type ColumnKey =
	| 'toolName'
	| 'name'
	| 'manufacturer'
	| 'series'
	| 'tolerance'
	| 'procedure'
	| 'revisionDate'
	| 'action';
const probeTable: Table<ProbeTypesDBEntry, ColumnKey> = {
	columns: [

		{
			key: 'name',
			title: 'Probe Type',
			extractFunction: (probe) => probe.name,
			},
		{
			key: 'toolName',
			title: 'Tool Name',
			extractFunction: (probe) => probe.tool_id_ref.name,
			},
		{
			key: 'manufacturer',
			title: 'Manufacturer',
			extractFunction: (probe) => probe.manufacturer_workgroup_id_ref?.organization_id_ref?.name,
		},
		{
			key: 'series',
			title: 'Series',
			extractFunction: (probe) =>
				latestDefaultCalProc(probe)?.number_of_tests,
		},
		{
			key: 'tolerance',
			title: 'Tolerance',
			extractFunction: ({ tolerance_unit, tolerance }) => {
				switch (tolerance_unit) {
					case '%':
						return `${tolerance}${tolerance_unit}`;
					case 'C':
						return `${tolerance}${'\u00b0'}${tolerance_unit} (${celsiusToFahrenheit(
							tolerance
						)}${'\u00b0'}F)`;
					case 'F':
						return `${tolerance}${'\u00b0'}${tolerance_unit} (${fahrenheitToCelsius(
							tolerance
						)}${'\u00b0'}C)`;
					default:
						return;
				}
			},
		},
		{
			key: 'procedure',
			title: 'Procedure',
			extractFunction: (probe) => latestDefaultCalProc(probe)?.revision_name,
		},
		{
			key: 'revisionDate',
			title: 'Revision Date',
			extractFunction: (probe) => latestDefaultCalProc(probe)?.revision_date,
			// <--- header alignment <-- thx!
		},
		{
			key: 'action',
			title: 'Action',
			headerAlign: 'center',
		},
	],
};

type ProbesProps = {};

interface ProbesTableViewProps {
	table: Table<ProbeTypesDBEntry, ColumnKey>;
	sort: SortingPackage<ColumnKey>;
	currentSort: ColumnKey;
	probes: PopColl<ProbeTypesDBEntry>;
	isAscending: boolean;
	onEdit: (id: any) => any;
	onDelete: (id: any, name: any, toolName: any) => any;
}

function ProbesTableView(props: ProbesTableViewProps) {
	const history = useHistory();
	const tableData = useTable(
		props.table,
		props.probes,
		props.currentSort,
		props.isAscending,
	);

	return (
	<React.Fragment>
		<TableHeader
			columns={probeTable.columns}
			rowClassName={styles.row}
			sortingPackage={props.sort}/>
			{tableData.map(({
				toolName,
				name,
				manufacturer,
				series,
				tolerance,
				procedure,
				revisionDate,
				id,
			}) => (
			<div key={id} className={`${styles.row} hvrPtr row`}>
				<p >{name}</p>
				<p >{toolName}</p>
				<p >{manufacturer}</p>
				<p>{series}</p>
				<p>+/- {tolerance}</p>
				<p>{procedure}</p>
				<p>{unixToStr(revisionDate)}</p>
				<p className={styles.action}>
					<FontAwesomeIcon 
						className={styles.actionEdit}
						onClick={() => props.onEdit(id)}
						icon="pen-square"
						size="1x"
						cursor="pointer" />

					<FontAwesomeIcon
						className={styles.actionDel}
						onClick={() => props.onDelete(id, name, toolName)}
						icon="trash"
						size="1x"
						cursor="pointer" />
					</p>
				</div>
			)
		)}
	</React.Fragment>)
}

const Probes: FC<ProbesProps> = () => {
	const { accessToken, isSyncing, justSync } = useContext(authCtx);
	const { store } = useContext(StoreContext);
	const [probeTypes, setProbeTypes]  = useState<PopColl<ProbeTypesDBEntry>>({});
	const [currentSort, setCurrentSort] = useState<ColumnKey>('name');
	const [isAscending, setIsAscending] = useState(true);
	const [addProbesModalIsOpen, setaddProbesModalIsOpen] = useState(false);
	const [editProbeId, setEditProbeId] = useState('');
	const { isToogle } = useNavStore();
	const sortingPackage: SortingPackage<ColumnKey> = {
		setCurrentSort,
		currentSort,
		setIsAscending,
		isAscending,
	};

	const handleAddEdit=(id?: string)=>{
			setEditProbeId(id??'')
		setaddProbesModalIsOpen(true)
	}
	const deleteOneProbe = async (probeTypeId: string, name: string,toolName:string) => {
		if (probeTypeId && window.confirm(`Are You Sure To Delete: ${name}-${toolName}`)) {
			const { url, method } = urls.deleteOneProbeType;
			try {
				await fetch(`${urls.base}${url}${probeTypeId}`, {
					method,
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`,
					},
					body: JSON.stringify({ probeTypeId: probeTypeId }),
				}).then((res) => {
					if (res?.status == 204) {
						justSync();
						toast.error(`Probe Type:${name}-${toolName} Deleted Successfull`, {
							autoClose: 5000,
						});
					}
				});
			} catch (error) {
				console.log('Error while deteting Probe Type', error);

				toast.error(`Error while deteting Probe Type:${name}-${toolName}`, {
					autoClose: 5000,
				});
			}
		}
	};

	useEffect(() => {
		justSync();
	}, [accessToken]);

	useEffect(() => {
		if (!isSyncing) {
			let probes = store.probe_types;

			_.forEach(probes, (probe) => {
				// extract manufacturer_workgroup_id_ref
				if (!probe.manufacturer_workgroup_id_ref && probe.manufacturer_workgroup_id) {
					const workgroup = store.workgroups[probe.manufacturer_workgroup_id]
					probe.manufacturer_workgroup_id_ref = workgroup
				}

				// extract calibration_procedures
				if (_.size(probe.calibration_procedures) === 0) {
					_.forEach(store.calibration_procedures, (procedure) => {
						if (probe.id === procedure.probe_type_id) {
							probe.calibration_procedures[procedure.id] = procedure 
						}
					})
				}
			})

			setProbeTypes(probes)
		}
	}, [isSyncing])

	return (
		<div className={'mainContentContainer'}>
			<div className="header">
				<h1>Probes</h1>
			
				<button onClick={()=>handleAddEdit()}>Add Probe</button>
			</div>
			<div style={{ marginLeft: isToogle === 'YES' ? '90px' : '' }}>
				<ProbesTableView 
					table={probeTable}
					sort={sortingPackage}
					currentSort={currentSort}
					probes={probeTypes}
					isAscending={isAscending}
					onEdit={handleAddEdit}
					onDelete={deleteOneProbe}
				/>
			</div>
			<Modal
				isOpen={addProbesModalIsOpen}
				onRequestClose={()=>setaddProbesModalIsOpen(false)}
				className="modal"
				overlayClassName="modalOverlay">
				<AddEditProbes probeId={editProbeId} closeModal={() => setaddProbesModalIsOpen(false)} />
			</Modal>
		</div>
	);
};

export default Probes;
