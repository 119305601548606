import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { JobsDBEntry } from 'src/database/store/StoreTypes';
import { JobType } from '../types';
// import { ActiveJobDataBlock } from '../JobOverview/ActiveJobOverview';
// import { RecentJobDataBlock } from '../JobOverview/RecentJobOverview';
// import { UpcomingJobDataBlock } from '../JobOverview/UpcomingJobOverview';
import './index.css';
import useNavStore from 'src/components/RootNavBarIcon/NavStore';
import { JobStat, getJobStat } from '../JobOverview/index';
import { JobProgressBar } from '../JobOverview/JobProgressBar';

export function JobSummary(props: { type: JobType; job: JobsDBEntry }) {
	const [jobStat, setJobStat] = React.useState<JobStat>(getJobStat(props.job));
	// console.log('JobSummary called---------------',props);

	React.useEffect(() => {
		setJobStat(getJobStat(props.job));
	}, [props.job]);

	const org = props.job.technician_organization_id_ref;
	const address = `${org.address}, ${org.city}, ${org.state} ${org.zipcode}`;
	const gotoGoogleMap = () => {
		const addrQuery = encodeURIComponent(address);
		window.open(
			`https://www.google.com/maps/search/?api=1&query=${addrQuery}`,
			'_blank'
		);
	};
	const { isToogle } = useNavStore();
	const [redirect, setRedirect] = React.useState<boolean>(false);
	const history = useHistory();
	// const JobSummaryBlock = () => {
	// 	setTimeout(() => {
	// 		window.scrollTo(0, 1000);
	// 	}, 300);

	// 	switch (props.type) {
	// 		default:
	// 		case 'active': {
	// 			return <ActiveJobDataBlock job={props.job} />;
	// 		}
	// 		case 'recent': {
	// 			return <RecentJobDataBlock job={props.job} />;
	// 		}
	// 		case 'upcoming': {
	// 			return <UpcomingJobDataBlock job={props.job} />;
	// 		}
	// 	}
	// };

	return (
		<div
			className="job-summary"
			style={{ marginLeft: isToogle === 'YES' ? '90px' : '' }}
		>
			<div className="job-summary-container">
				<div>
					<h5 className="job-name">{props.job.name}</h5>
					<p className="job-role">{jobStat.role}</p>
					{props.type === 'active' && (
						<p className="job-role">
							Total sensors: {props.job.total_sensors}
						</p>
					)}
					<br />
					<div className="job-address-bar">
						<h5>ADDRESS</h5>
						<br />
						<p>{address}</p>
					</div>
					<br />

					{props.type === 'recent' && (
						<>
							<div
								className="router-link"
								onClick={() =>
									history.push(`/jobs/${props.job.id}/certificates`)
								}
							>
								See Certificates
							</div>
							<div
								className="router-link"
								onClick={() =>
									history.push(`/jobs/${props.job.id}/documents`)
								}
							>
								See Documents
							</div>
						</>
					)}
				</div>
				<div>
					<JobProgressBar stat={jobStat} />

					{redirect && <Redirect to={`/jobs/${props.job.id}`} />}
				</div>
			</div>
			<div>
				<button className="mapBtn" onClick={gotoGoogleMap}>
					View map
				</button>
				<button onClick={() => setRedirect(true)}>See more detail</button>
			</div>
		</div>
	);
}
