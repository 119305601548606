import { Collection, Database } from "@nozbe/watermelondb";

export enum ColumnType {
  STRING = "string",
  NUMBER = "number",
  BOOLEAN = "boolean",
}

export enum Association {
  BELONGS_TO = "belongs_to",
  HAS_MANY = "has_many",
}

export enum TableName {
  POSTS = "posts",
  COMMENTS = "comments",
}

export type WatermelonServiceOptions = {
  database?: Database;
  collection?: Collection<any>;
  tableName?: TableName;
};

export type FieldDataKeys<T, OmittedKeys = {}> = keyof (T & OmittedKeys)

export type FieldData<T, OmittedKeys = {}> = {[key in FieldDataKeys<T, OmittedKeys>]: string};