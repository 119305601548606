import { JobFilterField } from './JobFilter'

export function filterJobRecords(data: any[], type: JobFilterField, value: string): any[] {
   const checkJobName = (row: any): boolean => row.jobName.toLowerCase().includes(value.toLowerCase())
   const checkCustName = (row: any): boolean => row.customer.toLowerCase().includes(value.toLowerCase())
   const checkAny = (row: any): boolean => checkJobName(row) || checkCustName(row);

   return data.filter(row => {
      if (type === 'job_name' && checkJobName(row)) {
         return row;
      } else if (type === 'cust_name' && checkCustName(row)) {
         return row;
      } else if (type === '*' && checkAny(row)) {
         return row;
      }
   })
}
