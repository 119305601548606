import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { database } from 'src/database';
import DatabaseProvider from '@nozbe/watermelondb/DatabaseProvider';
import { StoreProvider } from './database/store/StoreProvider';
import ReactModal from 'react-modal';
import { AuthProvider } from './api/AuthProvider';
import { OfflineQueueProvider } from './hooks/useOfflineQueue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, {
	BugsnagErrorBoundary,
} from '@bugsnag/plugin-react';
import { configureAmplify } from './providers/amplify';

const bugsnagKey = process.env.REACT_APP_BUGSNAG_KEY;
let ErrorBoundary: BugsnagErrorBoundary;

ReactModal.setAppElement('#root');
configureAmplify();

bugsnagKey &&
	Bugsnag.start({
		apiKey: bugsnagKey,
		plugins: [new BugsnagPluginReact()],
	});

ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary>
			<OfflineQueueProvider>
				<AuthProvider>
					<DatabaseProvider database={database}>
						<StoreProvider>
							<App />
						</StoreProvider>
					</DatabaseProvider>
				</AuthProvider>
			</OfflineQueueProvider>
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
