import { User } from '@auth0/auth0-react';
import _ from 'lodash';
import React, { FC, useCallback, useContext, useState } from 'react';
import { useEffect } from 'react';
import { authCtx } from 'src/api/AuthProvider';
import urls from 'src/api/urls';
import { StoreContext } from 'src/database/store/StoreProvider';
import useOrganizationTypes from 'src/hooks/useOrganizationTypes';
import { JobWorkgroupType } from 'src/models/JobWorkgroup';

type AddUserProps = {
	closeModal: () => void;
};

const AddUser: FC<AddUserProps> = ({ closeModal }) => {
	const { store } = useContext(StoreContext);
	const { accessToken, justSync } = useContext(authCtx);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [roleId, setRoleId] = useState('');
	// need to update once get data from db createEditUserLable
	const [createEditUserLable, setCreateEditUserLable] = useState(false);
	const [orgId, setOrgId] = useState('');
	const [groupId, setGroupId] = useState('');
	const [isProcessing, setIsProcessing] = useState(false);

	const { roles } = store;
	const role = roles[roleId];
	const logged_user_role_id = localStorage.getItem('user-role-id') || '';
	var isAdmin: boolean = false;
	if (logged_user_role_id == '311b83d9-2cc5-4c17-bc01-e2b9d006f1e2') {
		isAdmin = true;
	}
	const updateTabShow = (assignToRoleId: string) => {
		setRoleId(assignToRoleId);
		assignToRoleId == 'e1c59d16-5bc0-4794-92e7-6bab999c7c63'
			? setCreateEditUserLable(true)
			: setCreateEditUserLable(false);
	};
	const [isCalendar, setisCalendar] = React.useState(true);
	const [isjobs, setjobs] = React.useState(true);
	const [is_survey_tool, setSurveyTool] = React.useState(false);
	const [is_employeees, setEmployeees] = React.useState(false);
	const [is_company_assets, setCompanyAssets] = React.useState(false);
	const [is_alerts, setAlerts] = React.useState(false);

	const handleTabChange = (tabName: any) => {
		switch (tabName) {
			case 'calender':
				setisCalendar(!isCalendar);
				break;
			case 'jobs':
				setjobs(!isjobs);
				break;
			case 'employeees':
				setEmployeees(!is_employeees);
				break;
			case 'companyAssets':
				setCompanyAssets(!is_company_assets);
				break;
			case 'surveyTool':
				setSurveyTool(!is_survey_tool);
				break;
			case 'alerts':
				setAlerts(!is_alerts);
				break;

			default:
				break;
		}
	};

	// unavoidable dynamic to fixed value hackiness - bug prone
	const orgType =
		role?.name === 'customer' || role?.name === 'manufacturer'
			? (role?.name as JobWorkgroupType)
			: undefined;
	const orgsOfType = useOrganizationTypes(orgType);

	const org = store.organizations[orgId];

	const hasValidPermissions =
		!orgType ||
		(orgType === 'customer' && orgId && groupId) ||
		(orgType === 'manufacturer' && orgId);
	const isSubmissionReady =
		firstName &&
		lastName &&
		email &&
		roleId &&
		hasValidPermissions &&
		groupId;

	// var createEditUserLable: Boolean = false

	// if (role?.name === "technician") {
	//   createEditUserLable = true

	// }

const saveInvitedUserData=async (bodyData:User) => {
	bodyData.isverified=false
	const url = '/users';
		return fetch(`${urls.base}${url}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(bodyData),
		});
}

	const handleSubmit = useCallback(async () => {
		let bodyData = {
			name: `${firstName} ${lastName}`,
			email,
			roleId,
			workgroupId: groupId ,
			isCalendar,
			isjobs,
			is_survey_tool,
			is_employeees,
			is_company_assets,
			is_alerts,
		};
		
		if (!isSubmissionReady) return;
		saveInvitedUserData(bodyData)
		setIsProcessing(true);
		const { url, method } = urls.userInvite;
		await fetch(`${urls.base}${url}`, {
			method,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(bodyData),
		});
		justSync();
		closeModal();
		setIsProcessing(false);
	}, [
		isSubmissionReady,
		firstName,
		lastName,
		email,
		roleId,
		groupId,
		accessToken,
		isCalendar,
		isjobs,
		is_survey_tool,
		is_employeees,
		is_company_assets,
		is_alerts,
		closeModal,
		setIsProcessing,
	]);

	/**
	 * Manufacturers dont deal with workgroups but they share the same model (Organization) as Customers so they
	 * have a default workgroup. The backend API requires a workgroup when creating either of these roles so the
	 * simplest way to handle Manufacturer users is to automatically associate with the default workgroup.
	 */

	useEffect(() => {
		if (orgType === 'manufacturer') {
			setGroupId(
				_.find(
					store.workgroups,
					(workgroup) => workgroup.organization_id === orgId
				)?.id || ''
			);
		}
	}, [orgType, store.workgroups, orgId]);
	useEffect(() => {
		if (roleId === 'e1c59d16-5bc0-4794-92e7-6bab999c7c63' || roleId === '311b83d9-2cc5-4c17-bc01-e2b9d006f1e2') {
			setGroupId(	'022abfa7-ff39-48a5-b815-2e7af3b3118f');
		}
	}, [orgType, store.workgroups, orgId,roleId]);
console.log('store',store);
console.log('store',store);

	return (
		<div className="modalContainer">
			<div className="modalHeader">
				<h2>Add User</h2>
			</div>
			<div className="content">
				<h3>Contact Information</h3>
				<div
					className="multiCol"
					style={{ gridTemplateColumns: '1fr 1fr' }}
				>
					<div>
						<label>First Name</label>
						<input
							onChange={({ target }) => setFirstName(target.value)}
							value={firstName}
						/>
					</div>
					<div>
						<label>Last Name</label>
						<input
							onChange={({ target }) => setLastName(target.value)}
							value={lastName}
						/>
					</div>
				</div>

				<label>Email</label>
				<input
					onChange={({ target }) => setEmail(target.value)}
					value={email}
				/>

				<h3>Permissions</h3>
				<label>Role</label>
				<div className="selectContainer">
					<select
						value={roleId}
						// onChange={({ target }) => setRoleId(target.value)}
						onChange={({ target }) => updateTabShow(target.value)}
					>
						<option value="">Select Role...</option>
						{_.map(roles, ({ name, id }) => (
							<option key={id} value={id}>
								{_.capitalize(name)}
							</option>
						))}
					</select>
				</div>
				<div
					className="multiCol"
					style={{ gridTemplateColumns: '1fr 1fr' }}
				>
					{orgType && (
						<>
							<div>
								<label>Organization</label>
								<div className="selectContainer">
									<select
										value={orgId}
										onChange={({ target }) => setOrgId(target.value)}
									>
										<option value="">Select Organization...</option>
										{_.map(orgsOfType, ({ name, id }) => (
											<option key={id} value={id}>
												{name}
											</option>
										))}
									</select>
								</div>
							</div>
							<div>
								<label>Group</label>
								<div className="selectContainer">
									<select
										disabled={!org}
										value={groupId}
										onChange={({ target }) =>
											setGroupId(target.value)
										}
									>
										<option value="">Select Group...</option>
										{_.map(org?.workgroups, ({ name, id }) => (
											<option key={id} value={id}>
												{name}
											</option>
										))}
									</select>
								</div>
							</div>
						</>
					)}
				</div>
				<div
					className="multiCol"
					style={{ gridTemplateColumns: '1fr 1fr' }}
				>
					{isAdmin && createEditUserLable && (
						<div>
							<h3>Select Tab List To Show</h3>
							<div className="checkListTab"></div>
							<label>
								<input
									name="calender"
									type="checkbox"
									checked={isCalendar}
									onChange={({ target }) =>
										handleTabChange(target.name)
									}
								/>
								Calendar
							</label>
							<label>
								<input
									name="jobs"
									type="checkbox"
									checked={isjobs}
									onChange={({ target }) =>
										handleTabChange(target.name)
									}
								/>
								Jobs
							</label>
							{/* <label>
								<input
									name="surveyTool"
									type="checkbox"
									checked={is_survey_tool}
									onChange={({ target }) =>
										handleTabChange(target.name)
									}
								/>
								Survey Tool
							</label> */}
							<label>
								<input
									name="employeees"
									type="checkbox"
									checked={is_employeees}
									onChange={({ target }) =>
										handleTabChange(target.name)
									}
								/>
								Employeees
							</label>
							<label>
								<input
									name="companyAssets"
									type="checkbox"
									checked={is_company_assets}
									onChange={({ target }) =>
										handleTabChange(target.name)
									}
								/>
								Company Assets
							</label>
							<label>
								<input
									name="alerts"
									type="checkbox"
									checked={is_alerts}
									onChange={({ target }) =>
										handleTabChange(target.name)
									}
								/>
								Alerts
							</label>
						</div>
					)}
				</div>
			</div>
			<div className="footer">
				<button
					disabled={isProcessing}
					className="cancel"
					onClick={closeModal}
				>
					Cancel
				</button>
				<button
					disabled={!isSubmissionReady || isProcessing}
					onClick={handleSubmit}
				>
					Submit
				</button>
			</div>
		</div>
	);
};

export default AddUser;
