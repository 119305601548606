import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import { JobsDBEntry, OrganizationsDBEntry } from 'src/database/store/StoreTypes';
import useOrganizationTypes from 'src/hooks/useOrganizationTypes';
import { Table, useTable } from 'src/hooks/useTable';
import useCurrentUser from 'src/hooks/useCurrentUser';
import AddCustomer from '../modals/AddCustomer/AddCustomer';
import TableHeader, { SortingPackage } from '../TableHeader/TableHeader';
import styles from './customers.module.css';
import { unixToStr } from 'src/utils/general';
import useNavStore from '../RootNavBarIcon/NavStore';

function getAllJobs(org: OrganizationsDBEntry) {
  const allJobs: JobsDBEntry[] = [];
  _.forEach(org.workgroups, (wg) => {
    _.forEach(wg.job_workgroups, (jwg) => {
      if (jwg.job_id_ref) allJobs.push(jwg.job_id_ref);
    });
  })
  return allJobs;
}

type ColumnKey = 'name' | 'pending' | 'open' | 'closed' | 'created';
const customerTable: Table<OrganizationsDBEntry, ColumnKey> = {
  columns: [
    {
      key: 'name',
      title: 'Name',
      extractFunction: (org) => org.name,
    },
    {
      key: 'pending',
      title: 'Pending',
      extractFunction: (org) => getAllJobs(org).filter(({ status }) => status === 'pending').length,
      headerAlign: 'right',
    },
    {
      key: 'open',
      title: 'Open',
      extractFunction: (org) => getAllJobs(org).filter(({ status }) => status === 'open').length,
      headerAlign: 'right',
    },
    {
      key: 'closed',
      title: 'Closed',
      extractFunction: (org) => getAllJobs(org).filter(({ status }) => status === 'closed').length,
      headerAlign: 'right',
    },
    {
      key: 'created',
      title: 'Created',
      extractFunction: (org) => org.created_at,
      headerAlign: 'right',
    },
  ],
};

type CustomersProps = {};

const Customers: FC<CustomersProps> = () => {
  const history = useHistory();
  const [currentSort, setCurrentSort] = useState<ColumnKey>('name');
  const [isAscending, setIsAscending] = useState(true);
  const [addCustomerModalIsOpen, setAddCustomerModalIsOpen] = useState(false);
  const customers = useOrganizationTypes('customer');
  const dbUser = useCurrentUser();

  const tableData = useTable(
    customerTable,
    customers,
    currentSort,
    isAscending
  );

  const sortingPackage: SortingPackage<ColumnKey> = {
    setCurrentSort,
    currentSort,
    setIsAscending,
    isAscending,
  };

  /**
   * Customer accounts should automatically route to their workgroup page when on the customers tab.
   */
  useEffect(() => {
    if (dbUser?.role_id_ref.name === 'customer') {
      history.push(`/customers/${dbUser.workgroup_id_ref?.organization_id}/workgroup/${dbUser.workgroup_id_ref?.id}`);
    }
  })
  const { isToogle } = useNavStore();
  useEffect(() => {},[isToogle])
  return (
    <div className={'mainContentContainer'}>
      <div className="header">
        <h1>Accounts</h1>
        <button onClick={() => setAddCustomerModalIsOpen(true)}>New Customer</button>
        <Modal isOpen={addCustomerModalIsOpen} onRequestClose={() => setAddCustomerModalIsOpen(false)} className="modal" overlayClassName="modalOverlay">
          <AddCustomer closeModal={() => setAddCustomerModalIsOpen(false)} />
        </Modal>
      </div>
      <div style={{marginLeft:isToogle==='YES'?'90px':''}}>
        <TableHeader
          columns={customerTable.columns}
          rowClassName={styles.row}
          sortingPackage={sortingPackage}
        />
        {tableData.map(({ name, pending, open, closed, created, id }) => (
          <div
            key={id}
            className={`${styles.row} hvrPtr row`}
            onClick={() => history.push(`/customers/${id}`)}
          >
            <p>{name}</p>
            <p style={{ textAlign: 'end' }} >{pending}</p>
            <p style={{ textAlign: 'end' }} >{open}</p>
            <p style={{ textAlign: 'end' }} >{closed}</p>
            <p style={{ textAlign: 'end' }} >{unixToStr(created)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Customers;
