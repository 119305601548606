import React from 'react';
import './index.css';

// type MultiProgressBarStatType = 'empty' | 'success' | 'warn' | 'error';

interface MultiProgressBarProps {
	children?: JSX.Element[] | JSX.Element;
}

interface MultiProgressBarStatProps {
	type?: string;
	// type?: MultiProgressBarStatType;
	value?: number;
	tooltip?: string |number;
}

export function MultiProgressBarStat(props: MultiProgressBarStatProps) {
	const classNames = `multi-progress-stat progress-${props.type || 'empty'}`;

	return (
		<div
		className={classNames}
			style={{ width: (props.value || 0) + '%'}}
			title={String(props.tooltip)}>
		</div>
	);
}

export function MultiProgressBar(props: MultiProgressBarProps) {
	return <div className="multi-progress-bar">{props.children}</div>;
}
