import { Database } from '@nozbe/watermelondb';
import { synchronize } from '@nozbe/watermelondb/sync';
import retry from 'src/utils/retry';
import pullChanges from './pull';
import pushChanges from './push';

export type RawRecord = {
  [key: string]: any
}

export type TableChanges = {
  created: RawRecord[],
  updated: RawRecord[],
  deleted: string[]
}

export type Changes = {
  [table_name: string]: TableChanges
}

export async function syncDatabase(database: Database, bearer: string) {
  const log = {};
  const sync = synchronize({
    database,
    log,
    pullChanges: (args) => pullChanges(args, bearer),
    pushChanges: (args) => pushChanges(args, bearer)
  });
  // https://nozbe.github.io/WatermelonDB/Advanced/Sync.html#general-information-and-tips retry synchronize once
  return await retry(sync);
}