import React, { FC } from "react";
import styles from "./styles.module.css";
import paperPlane from "../../../../assets/img/paper-plane.png"

type PasswordResetSuccessModalProps = {
    goBack: () => void;
}

const PasswordResetSuccessModal: FC<PasswordResetSuccessModalProps> = ({ goBack }) => {
    return (
        <div className={styles.container}>
            <img src={paperPlane} alt="sent icon" className={styles.paperPlane}/>
            <h1 className={styles.modalTitle}>Check your email</h1>
            <p className={styles.modalDescription}>If your address is registered with an account an email will be sent with a link to reset your password. If you don’t receive an email, be sure to check your spam folder.</p>
            <button className={styles.loginButton} onClick={goBack} >Login</button>
        </div>
    );
};

export default PasswordResetSuccessModal;
