import { Model } from "@nozbe/watermelondb";
import { writer, date, readonly } from "@nozbe/watermelondb/decorators";

export default class BaseModel extends Model {
  // @readonly @date('created_at') createdAt!: number
  // @readonly @date('updated_at') updatedAt!: number
  @date('created_at') createdAt!: number
  @date('updated_at') updatedAt!: number
  @writer async deleteRecord() {
    await this.markAsDeleted();
  };
  @writer async deletePermanentlyRecord() {
    await this.destroyPermanently();
  };
}
