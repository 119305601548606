import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { FC, useCallback, useContext, useState, useMemo } from "react";
import Select from 'react-select';
import { authCtx } from "src/api/AuthProvider";
import { database } from "src/database";
import { StoreContext } from "src/database/store/StoreProvider";
import { PopColl, UsersDBEntry } from "src/database/store/StoreTypes";
import useCurrentUser from "src/hooks/useCurrentUser";
import Job from "src/models/Job";
import JobTechnician from "src/models/JobTechnician";
import { getActiveTechs, getInitials } from "src/utils/general";
import { isFcsEmail } from "src/utils/validation";
import styles from "./addTech.module.css";

type AddTechProps = {
  jobId: string;
  closeModal: () => void;
}

const AddTech: FC<AddTechProps> = ({ jobId, closeModal }) => {
  const { store } = useContext(StoreContext);
  const { justSync } = useContext(authCtx);
  const dbUser = useCurrentUser();
  const [isProcessing, setIsProcessing] = useState(false);
  const job = store.jobs[jobId];

  const members = useMemo(() => {
    return getActiveTechs(job)
  }, [job]);

  const memberOptions = useMemo(() => {
    const possibleUsers: PopColl<UsersDBEntry> = {};
    _.forEach(store.users, (user) => {
      const role = user.role_id_ref?.name;
      const email = user.email;
      // skip already included techs
      if (_.some(job.job_technicians, ({ technician_id, deactivated_date }) => technician_id === user.id && !deactivated_date)) return;
      if (role === 'technician' || role === 'administrator' || isFcsEmail(email)) possibleUsers[user?.id] = user;
    })

    let options: { label: string, value: string }[] = [];
    _.forEach(possibleUsers, (user) =>
      options = options.concat([{ value: user?.id, label: user?.name }]))

    return options;
  }, [store, job.job_technicians])

  const handleSelectTech = useCallback(async (userId: string) => {
    setIsProcessing(true);
    const jobModel = await database.collections.get<Job>('jobs').find(jobId);
    await jobModel.addTechnician(userId);
    justSync();
    setIsProcessing(false);
  }, [jobId]);

  const removeTech = useCallback(async (jobTechId: string) => {
    setIsProcessing(true);
    if (jobTechId === job.job_lead_technician_id_ref?.job_technician_id) return;
    const jobTechModel = await database.collections.get<JobTechnician>('job_technicians').find(jobTechId)
    if (!jobTechModel) {
      setIsProcessing(false);
      return;
    }
    await jobTechModel.disassociate();
    justSync();
    setIsProcessing(false);
  }, [job.job_lead_technician_id_ref]);

  const setLead = useCallback(async (userId: string) => {
    setIsProcessing(true);
    const jobModel = await database.collections.get<Job>('jobs').find(jobId);
    if (!jobModel) return;
    await jobModel.setLeadTechnician(userId);
    justSync();
    setIsProcessing(false);
    // window.location.reload(); // Lead Technician label in list doesn't automatically update
  }, [jobId])

  const jobLead = useMemo(() => _.find(store.job_lead_technicians, (leadTech) => leadTech.job_id === jobId), [job, jobId, store.job_lead_technicians])
  const userIsAdmin = useMemo(() => dbUser?.role_id_ref.name === 'administrator', [dbUser]);

  return (
    <div className="modalContainer">
      <div className="modalHeader">
        <h2>Technicians</h2>
      </div>
      <div className="content">
        <Select isDisabled={!userIsAdmin} options={memberOptions} placeholder="Add Technician..." maxMenuHeight={100} onChange={(opt) => opt && handleSelectTech(opt!.value)} />
        <h3>Members</h3>
        {_.map(members, ({ technician_id_ref, id }) => {
          // TOFIX: This illustrates a failure to propagate changes to nested references.
          // job_lead_technician_id gets lost on sync pull???
          // const jobLead = store.job_lead_technicians[job?.job_lead_technician_id];
          const currentRole = jobLead?.job_technician_id === id ? 'leadTechnician' : 'technician';
          return (
            <div className={styles.techRow} key={id}>
              <div className={styles.nameDot}>{getInitials(technician_id_ref?.name)}</div>
              <p>{technician_id_ref?.name}</p>
              {
                currentRole === 'leadTechnician' ?
                  <p>Lead Technician</p>
                  :
                  <div className={styles.statusContainer}>
                    <select disabled={!userIsAdmin} value={currentRole} onChange={({ target }) => { if (target.value === 'leadTechnician') setLead(id) }}>
                      <option value="leadTechnician">Lead Technician</option>
                      <option value="technician">Technician</option>
                    </select>
                  </div>
              }
              {
                id !== jobLead?.job_technician_id &&
                <FontAwesomeIcon className={userIsAdmin ? "hvrPtr" : ''} icon="times" color="#8C9299" onClick={() => userIsAdmin ? removeTech(id) : undefined} />
              }
            </div>)
        })}
      </div>
      <div className="footer">
        <button disabled={isProcessing} className="cancel" onClick={closeModal}>Cancel</button>
        <button disabled={isProcessing} onClick={closeModal}>Done</button>
      </div>
    </div>
  );
};

export default AddTech;