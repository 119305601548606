import { HasManyAssociation } from "@nozbe/watermelondb/Model";
import { writer, children, field } from "@nozbe/watermelondb/decorators";
import { Association } from "src/types/watermelon";
import BaseModel from "./BaseModel";
import { Query } from "@nozbe/watermelondb";
import Workgroup from "./Workgroup";
import Job from "./Job";
import { database } from "src/database";
import CalibrationProcedure from "./CalibrationProcedure";

export default class Organization extends BaseModel {
  static table = "organizations";
  static associations = {
    workgroups: {
      type: Association.HAS_MANY,
      foreignKey: "organization_id",
    } as HasManyAssociation,
    jobs: {
      type: Association.HAS_MANY,
      foreignKey: "technician_organization_id",
    } as HasManyAssociation,
    calibration_procedures: {
      type: Association.HAS_MANY,
      foreignKey: "customer_organization_id",
    } as HasManyAssociation,
  };
  getTableName() {
    return Organization.table;
  }
  @field("name") name!: string;
  @field("nickname") nickname!: string;
  @field("state") state!: string;
  @field("city") city!: string;
  @field("phone") phone!: string;
  @field("website") website!: string;
  @field("address") address!: string;
  @field("zipcode") zipcode!: string;
  @children("workgroups") workgroups!: Query<Workgroup>;
  @children("calibration_procedures") calibrationProcedures!: Query<CalibrationProcedure>;
  @children("jobs") jobs!: Query<Job>;
  @writer async createWorkgroup(name: string) {
    await this.collections.get<Workgroup>("workgroups").create(workgroup => {
      workgroup.name = name;
      workgroup.organization.set(this);
    })
  }
}

type NewCustomerParams = {
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  website: string;
  orgId?: string;
}

export async function upsertCustomer({ name, address, city, state, zip, phone, website, orgId}: NewCustomerParams) {
  return database.write(async () => {
    const upsertFun = (org: Organization) => {
      org.name = name;
      org.address = address;
      org.city = city;
      org.state = state;
      org.zipcode = zip;
      org.phone = phone;
      org.website = website;
    }
    const orgColl = database.collections.get<Organization>('organizations');
    if (!orgId) {
      return orgColl.create(upsertFun);
    } else {
      const org = await orgColl.find(orgId);
      return org.update(upsertFun);
    }
  })
}

