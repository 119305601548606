import _, { orderBy } from 'lodash';
import React, {
	useContext,
	useMemo,
} from 'react';
import styles from '../JobStatusDetails.module.css';
import { HighchartComp } from 'src/components/Highcharts/HighchartComp';
import { StoreContext } from 'src/database/store/StoreProvider';
import { useParams } from 'react-router-dom';
import { getMostRecentCal } from 'src/utils/general';
import StatHeader from '../StatHeader';
import useNavStore from 'src/components/RootNavBarIcon/NavStore';

export function JobStatusDetail() {
	const { jobId } = useParams<{ jobId: string }>();
	const { store } = useContext(StoreContext);
	const { isToogle } = useNavStore();
const job = store.jobs[jobId];
	const cal = useMemo(() => {
        let CalibratedCalCount = 0;
		let CertifiedCalCount = 0;
		let InProgressCalCount = 0;
		let IssueCalCount = 0;
		let NoCalCount = 0;
		let Sensors_to_be_Calibrated = 0;

		_.forEach(job.job_sensors, (sensor) => {
			const status = getMostRecentCal(sensor)?.status;
			if (status == 'Certified') CertifiedCalCount++;
			if (status == 'Calibrated') CalibratedCalCount++;
			if (status == 'InProgress') InProgressCalCount++;
			if (status == 'Issue') IssueCalCount++;
			if (status == 'No') NoCalCount++;
			if (!status) Sensors_to_be_Calibrated++;
            });
        let calStatusCount={'CertifiedCalCount':CertifiedCalCount,'CalibratedCalCount':CalibratedCalCount,'InProgressCalCount':InProgressCalCount,'IssueCalCount':IssueCalCount,'NoCalCount':NoCalCount,'Sensors_to_be_Calibrated':Sensors_to_be_Calibrated}
        
		return calStatusCount;
	}, [job]);

	// HighChart Data Set    //
	const options = {
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			type: 'pie',
            height:'30%'
		},
		title: {
			text: `${job.name} `,
		},

		credits: {
			enabled: false,
		},
		tooltip: {
			pointFormat: '{series.name}: <b>Count:{point.y}</b>',
		},
		accessibility: {
			point: {
				valueSuffix: '%',
			},
		},
		plotOptions: {
			pie: {
				colors: ['#487EC1','#8DB367',  '#CCC0DA', 'yellow','red','#c4bd97'],
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels: {
                    size:'40%',
                    height: '40%',
                    format: '<b>{point.name}:{point.y}</b>',
                    enabled: true,
                    connectorColor: 'silver'
				},
              
			},
		},
		series: [
			{
				name: 'Job',
				colorByPoint: true,
				data: [
					{
						y: cal.CalibratedCalCount,
						name: `Calibrated`,
					},
					{
						y: cal.CertifiedCalCount,
						name: `Certified`,
					},
					{
						y: cal.InProgressCalCount,
						name: `In progress`,
					},
					{
						y: cal.IssueCalCount,
						name: `Issue`,
					},
					{
						y: cal.NoCalCount,
						name: `No`,
					},
					{
						y: cal.Sensors_to_be_Calibrated,
						name: `Sensors to be Calibrated`,
					},
				],
				
				innerSize: '15%,',
			},
		],
	};
	return (

		<div style={{ marginLeft: isToogle === 'YES' ? '90px' : '' }} className={styles.highChart}>
			<hr />
			<StatHeader job={job} />
			<hr />
			<HighchartComp options={options} />
		</div>
	);
}
