import { Relation } from "@nozbe/watermelondb";
import { BelongsToAssociation } from "@nozbe/watermelondb/Model";
import { field, date, relation } from "@nozbe/watermelondb/decorators";
import { Association } from "src/types/watermelon";
import BaseModel from "./BaseModel";
import User from "./User";

type MetadataIdentity = {
  user_id: string;
  provider: string;
  connection: string;
  isSocial: boolean;
};

type MetadataUserMetadata = Partial<{
  institution: string;
  prefix: string;
}>;

type MetadataAppMetadata = Partial<{}>;

export default class Metadata extends BaseModel {
  static table = "metadata";
  static associations = {
    users: {
      type: Association.BELONGS_TO,
      key: "user_id",
    } as BelongsToAssociation,
  };
  getTableName() {
    return Metadata.table;
  }
  @date("_created_at") created_at!: Date;
  @field("email") email!: string;
  @field("email_verified") email_verified!: boolean;
  @field("identities") identities!: MetadataIdentity[];
  @field("name") name!: string;
  @field("nickname") nickname!: string;
  @field("picture") picture!: string;
  @date("_updated_at") updated_at!: Date;
  @field("user_id") user_id!: string;
  @field("user_metadata") user_metadata!: MetadataUserMetadata;
  @date("last_login_at") last_login!: Date;
  @field("last_ip") last_ip!: string;
  @field("logins_count") logins_count!: number;
  @field("app_metadata") app_metadata!: MetadataAppMetadata;
  @relation("users", "user_id") user!: Relation<User>;
}
