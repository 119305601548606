import _ from 'lodash';
import React, { FC, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StoreContext } from 'src/database/store/StoreProvider';
import { JobsDBEntry, PopColl } from 'src/database/store/StoreTypes';
import { Table, useTable } from 'src/hooks/useTable';
import { getMostRecentCal, unixToStr } from 'src/utils/general';
import TableHeader, { SortingPackage } from '../TableHeader/TableHeader';
import styles from './jobs.module.css';
import { filterJobRecords } from './JobFilter.util';
import { JobFilterField } from './JobFilter';

type ColumnKey =
  | 'jobName'
  | 'customer'
  | 'passed'
  | 'failed'
  | 'closed'
  | 'created';

const closedJobTable: Table<JobsDBEntry, ColumnKey> = {
  columns: [
    {
      key: 'jobName',
      title: 'Job Name',
      extractFunction: (job) => job.name
    },
    {
      key: 'customer',
      title: 'Customer',
      extractFunction: (job) => {
        const workgroup = _.chain(job.job_workgroups)
          .find((jwg) => jwg.type === 'customer')
          .value()?.workgroup_id_ref;
        if (!workgroup) return '';
        return `${workgroup.organization_id_ref?.name}, ${workgroup.name}`
      }
    },
    {
      key: 'passed',
      title: 'Passed',
      extractFunction: (job) => _.reduce(job.job_sensors, (validSensorCount, sensor) => {
        let mostRecentCalibration = getMostRecentCal(sensor)
        return mostRecentCalibration?.ispassed=='1' ? validSensorCount + 1 : validSensorCount;
      }, 0),
      headerAlign: 'right',
    },
    {
      key: 'failed',
      title: 'Failed',
      extractFunction: (job) => _.reduce(job.job_sensors, (failedSensorCount, sensor) => {
        let mostRecentCalibration = getMostRecentCal(sensor)
        return mostRecentCalibration?.ispassed=='0'? failedSensorCount + 1 : failedSensorCount;
      }, 0),
      headerAlign: 'right',
    },
    {
      key: 'closed',
      title: 'Closed',
      extractFunction: (job) => {
        // console.log('job=',job);
        // console.log('job.closed_at=',job.closed_at);
        // console.log('job.submitted_at=',job.submitted_at);
        // if(job.name==='Geisinger_XYZ_2022-10-13_6:30')console.log('job=',job);
        
      return job.closed_at
      },
      headerAlign: 'right',
    },
    {
      key: 'created',
      title: 'Created',
      extractFunction: (job) => job.created_at,
      headerAlign: 'right',
    },
  ],
};

type ClosedJobsProps = {
  filteredJobs?: PopColl<JobsDBEntry>,
  filterField?: JobFilterField,
  filterValue?: string
};

const ClosedJobs: FC<ClosedJobsProps> = ({ filteredJobs, filterField, filterValue }) => {
  const history = useHistory();
  const { store } = useContext(StoreContext);
  const { jobs } = store;
  const [currentSort, setCurrentSort] = useState<ColumnKey>('created');
  const [isAscending, setIsAscending] = useState(false);

  const closedJobs = useMemo(() => {
    const pendingJobs: Record<string, JobsDBEntry> = {};
    _.forEach(filteredJobs || jobs, (job, id) => {
      if (job.status === 'closed') pendingJobs[id] = job;
    });
    return pendingJobs;
  }, [filteredJobs, jobs]);

  const tableData = filterJobRecords(useTable(
    closedJobTable,
    closedJobs,
    currentSort,
    isAscending,
  ), filterField || '*', filterValue || '');

  const sortingPackage: SortingPackage<ColumnKey> = {
    setCurrentSort,
    currentSort,
    setIsAscending,
    isAscending,
  };

  return (
    <div>
      <TableHeader
        columns={closedJobTable.columns}
        rowClassName={styles.closedRow}
        sortingPackage={sortingPackage}
      />
      {tableData.map(
        ({ customer, jobName, passed, failed, closed, created, id }) => (
          <div key={id} className={`${styles.closedRow} hvrPtr row`} onClick={() => history.push(`/jobs/${id}`)}>
            <p>{jobName}</p>
            <p>{customer}</p>
            <p style={{ textAlign: 'end' }} >{passed}</p>
            <p style={{ textAlign: 'end' }} >{failed}</p>
            <p style={{ textAlign: 'end' }} >{unixToStr(closed)}</p>
            {/* <p style={{ textAlign: 'end' }} >{closed}</p> */}
            <p style={{ textAlign: 'end' }} >{unixToStr(created)}</p>
          </div>
        )
      )}
    </div>
  );
};

export default ClosedJobs;
