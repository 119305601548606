import React, { FC, useMemo } from 'react';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { JobsDBEntry } from 'src/database/store/StoreTypes';
import _ from 'lodash';
import { getJobProgress, getMostRecentCal } from 'src/utils/general';
import styles from './jobDetail.module.css';
import useCurrentUser from 'src/hooks/useCurrentUser';

const StatHeader: FC<{ job: JobsDBEntry }> = ({ job }) => {
	const dbUser = useCurrentUser();
	const userId = useMemo(() => dbUser?.id, [dbUser]);
	const toolId = useMemo(
		() =>
			_.filter(
				dbUser?.technician_tools,
				(data) => data.technician_user_id == userId
			)[0]?.id,
		[dbUser]
	);
	const techToolId = useMemo(
		() =>
			_.filter(
				dbUser?.technician_tools,
				(data) => data.technician_user_id == userId
			)[0]?.tool_id,
		[dbUser]
	);
	const toolType = useMemo(
		() =>
			_.filter(
				dbUser?.technician_tools,
				(data) => data.technician_user_id == userId
			)[0]?.tool_id_ref.name,
		[dbUser]
	);
	const jobProgress = useMemo(() => getJobProgress(job), [job]);
	const CertifiedCalibrated900mhz = useMemo(() => {
		let cal900mhzCount = 0;

		_.forEach(job.job_sensors, (sensor) => {
			const status = getMostRecentCal(sensor)?.status;
			const sensor_id = sensor.sensor_id
			const technician_tool_id = getMostRecentCal(sensor)?.technician_tool_id;
      if ((status == 'Calibrated' || status == 'Certified')&& !(/[a-zA-Z]/.test(sensor_id))) cal900mhzCount++;
			
		});
		return cal900mhzCount;
	}, [job]);
	const CertifiedCalibratedWifi = useMemo(() => {
		let calWifiCount = 0;

		_.forEach(job.job_sensors, (sensor) => {
			const status = getMostRecentCal(sensor)?.status;
			const sensor_id = sensor.sensor_id
			if (status == 'Calibrated' || status == 'Certified' &&(/[a-zA-Z]/.test(sensor_id))) calWifiCount++;
		});
		return calWifiCount;
	}, [job]);
	const CertifiedCalibratedHygro = useMemo(() => {
		let calHygroCount = 0;

		_.forEach(job.job_sensors, (sensor) => {
			const status = getMostRecentCal(sensor)?.status;
			const  hygropalm_tool_id = getMostRecentCal(sensor)?.probe_type_id_ref?.tool_id
			const  probe_type_id = getMostRecentCal(sensor)?.probe_type_id
			if ((status == 'Calibrated' || status == 'Certified') && (hygropalm_tool_id=='830a1846-b8e9-4828-9058-832d1a46b8fa' ||probe_type_id=='21a68593-599f-4446-991d-7cbf37248eff')) calHygroCount++;
		});
    return calHygroCount;
	}, [job]);

	const probeTypeCount = useMemo(() => {
		const uniqueProbes: { [k: string]: boolean } = {};
		_.forEach(job.job_sensors, (sensor) => {
			const probeType = getMostRecentCal(sensor)?.probe_type_id;
			if (probeType) uniqueProbes[probeType] = true;
		});

		return _.size(uniqueProbes);
	}, [job]);

	return (
		<div className={styles.statHeader}>
			{/* <div>
				<ProgressBar prog={jobProgress} height={8} />
			</div> */}
			<div>
				Sensors:{_.size(job.job_sensors)}
			</div>
			<div>
				Types:{probeTypeCount}
			</div>
			<div>
				Total Calibrated 900mhz:{CertifiedCalibrated900mhz}
			</div>
			<div>
				Total Calibrated WIFI:{CertifiedCalibratedWifi}
			</div>
			<div>
			Total Calibrated Probes:{CertifiedCalibratedHygro}
			</div>
		</div>
	);
};

export default StatHeader;
