import { appSchema, ColumnSchema, tableSchema } from "@nozbe/watermelondb";
import { ColumnType } from "src/types/watermelon";

const defaultColumns = [
  { name: 'created_at', type: ColumnType.NUMBER },
  { name: 'updated_at', type: ColumnType.NUMBER },
]

function generateTableSchema(name: string, columns: ColumnSchema[]) {
  return tableSchema({
    name,
    columns: [
      ...defaultColumns,
      ...columns
    ]
  })
}

export default appSchema({
  version: 2,
  tables: [
    generateTableSchema('roles', [
      { name: "name", type: ColumnType.STRING },
      { name: "description", type: ColumnType.STRING },
    ]),
    generateTableSchema('users', [
      { name: "auth_0_id", type: ColumnType.STRING },
      { name: "name", type: ColumnType.STRING },
      { name: "email", type: ColumnType.STRING },
      { name: "workgroup_id", type: ColumnType.STRING, isOptional: true, isIndexed: true },
      { name: "phone", type: ColumnType.STRING, isOptional: true },
      { name: "role_id", type: ColumnType.STRING, isIndexed: true },
      { name: "isjobs", type: ColumnType.BOOLEAN, isIndexed: true },
      { name: "is_survey_tool", type: ColumnType.BOOLEAN, isIndexed: true },
      { name: "is_employeees", type: ColumnType.BOOLEAN, isIndexed: true },
      { name: "is_company_assets", type: ColumnType.BOOLEAN, isIndexed: true },
      { name: "is_alerts", type: ColumnType.BOOLEAN, isIndexed: true },
      { name: "is_calendar", type: ColumnType.BOOLEAN, isIndexed: true },
      { name: "isverified", type: ColumnType.BOOLEAN, isIndexed: true },
    ]),
    generateTableSchema('organizations', [
      { name: "name", type: ColumnType.STRING },
      { name: "nickname", type: ColumnType.STRING },
      { name: "state", type: ColumnType.STRING, isOptional: true },
      { name: "address", type: ColumnType.STRING, isOptional: true },
      { name: "zipcode", type: ColumnType.STRING, isOptional: true },
      { name: "city", type: ColumnType.STRING, isOptional: true },
      { name: "phone", type: ColumnType.STRING, isOptional: true },
      { name: "website", type: ColumnType.STRING, isOptional: true },
    ]),
    generateTableSchema('workgroups', [
      { name: "name", type: ColumnType.STRING },
      { name: "is_elevated_workgroup", type: ColumnType.BOOLEAN },
      { name: "city", type: ColumnType.STRING, isOptional: true },
      { name: "phone", type: ColumnType.STRING, isOptional: true },
      { name: "website", type: ColumnType.STRING, isOptional: true },
      { name: "state", type: ColumnType.STRING, isOptional: true },
      { name: "zipcode", type: ColumnType.STRING, isOptional: true },
      { name: "address", type: ColumnType.STRING, isOptional: true },
      { name: "organization_id", type: ColumnType.STRING, isIndexed: true },
    ]),
    generateTableSchema('probe_types', [
      { name: "name", type: ColumnType.STRING },
      { name: "model", type: ColumnType.STRING, isOptional: true },
      { name: "tolerance", type: ColumnType.NUMBER },
      { name: "tolerance_unit", type: ColumnType.STRING },
      { name: "tool_id", type: ColumnType.STRING, isIndexed: true },
      { name: "manufacturer_workgroup_id", type: ColumnType.STRING, isIndexed: true },
    ]),
    generateTableSchema('tools', [
      { name: "name", type: ColumnType.STRING },
      { name: "uncertainty_range_list", type: ColumnType.STRING },
      { name: "tool_type", type: ColumnType.STRING },
      { name: "nickname", type: ColumnType.STRING },
      { name: "description", type: ColumnType.STRING, isOptional: true },
      { name: "tolerance", type: ColumnType.NUMBER ,isOptional: true },
      { name: "tolerance_unit", type: ColumnType.STRING },

    ]),
    generateTableSchema('technician_tools', [
      { name: "serial_number", type: ColumnType.STRING },
      { name: "deactivated_date", type: ColumnType.NUMBER, isOptional: true },
      { name: "notes", type: ColumnType.STRING, isOptional: true },
      { name: "acknowledgement", type: ColumnType.STRING, isOptional: true },
      { name: "calibration_date", type: ColumnType.NUMBER },
      { name: "calibration_due_date", type: ColumnType.NUMBER },
      { name: "technician_user_id", type: ColumnType.STRING, isIndexed: true },
      { name: "tool_id", type: ColumnType.STRING, isIndexed: true },
    ]),
    generateTableSchema('calibrations', [
      { name: "ispassed", type: ColumnType.STRING, isOptional: true},
      { name: "ambient_temperature", type: ColumnType.NUMBER , isOptional: true },
      { name: "ambient_humidity", type: ColumnType.NUMBER, isOptional: true  },
      { name: "ambient_reading_at", type: ColumnType.NUMBER },
      { name: "probe_serial_number", type: ColumnType.STRING },
      { name: "calibrated_at", type: ColumnType.NUMBER },
      { name: "notes", type: ColumnType.STRING },
      { name: "status", type: ColumnType.STRING },
      { name: "job_sensor_id", type: ColumnType.STRING, isIndexed: true },
      { name: "probe_type_id", type: ColumnType.STRING, isIndexed: true },
      { name: "technician_tool_id", type: ColumnType.STRING, isIndexed: true },
      { name: "certificate_id", type: ColumnType.STRING, isIndexed: true },
      { name: "calibration_procedure_id", type: ColumnType.STRING, isIndexed: true },
      { name: "probe_replacement_status", type: ColumnType.STRING },
    ]),
    generateTableSchema('calibration_procedures', [
      { name: "revision_name", type: ColumnType.STRING },
      { name: "description", type: ColumnType.STRING },
      { name: "level", type: ColumnType.STRING },
      { name: "revision_date", type: ColumnType.NUMBER },
      { name: "calibration_standard_text", type: ColumnType.STRING },
      { name: "calibration_procedure_text", type: ColumnType.STRING },
      { name: "tool_id", type: ColumnType.STRING },
      { name: "number_of_tests", type: ColumnType.NUMBER },
      { name: "probe_type_id", type: ColumnType.STRING, isIndexed: true },
      // { name: "customer_workgroup_id", type: ColumnType.STRING, isIndexed: true, isOptional: true },
      { name: "customer_organization_id", type: ColumnType.STRING, isIndexed: true },
    ]),
    generateTableSchema('calibration_readings', [
      { name: "sequence_number", type: ColumnType.NUMBER },
      { name: "remote_temperature", type: ColumnType.NUMBER, isOptional: true },
      { name: "device_temperature", type: ColumnType.NUMBER, isOptional: true },
      { name: "remote_temperature2", type: ColumnType.NUMBER, isOptional: true },
      { name: "device_temperature2", type: ColumnType.NUMBER, isOptional: true },
      { name: "reading2_date", type: ColumnType.STRING, isOptional: true },
      { name: "reading1_date", type: ColumnType.STRING, isOptional: true },
      { name: "remote_temperature3", type: ColumnType.NUMBER, isOptional: true },
      { name: "device_temperature3", type: ColumnType.NUMBER, isOptional: true },
      { name: "reading3_date", type: ColumnType.STRING, isOptional: true },
      { name: "remote_temperature4", type: ColumnType.NUMBER, isOptional: true },
      { name: "device_temperature4", type: ColumnType.NUMBER, isOptional: true },
      { name: "reading4_date", type: ColumnType.STRING, isOptional: true },
      { name: "remote_humidity", type: ColumnType.NUMBER, isOptional: true },
      { name: "device_humidity", type: ColumnType.NUMBER, isOptional: true },
      { name: "calibration_id", type: ColumnType.STRING, isIndexed: true },
    ]),
    generateTableSchema('certificates', [
      { name: "name", type: ColumnType.STRING },
      { name: "file_url", type: ColumnType.STRING },
      { name: "notes", type: ColumnType.STRING },
      { name: "calibration_id", type: ColumnType.STRING, isIndexed: true },
      { name: "user_id", type: ColumnType.STRING, isIndexed: true },
      { name: "status", type: ColumnType.NUMBER },
    ]),
    generateTableSchema('certificate_batches', [
      { name: "calibration_ids", type: ColumnType.STRING },
      { name: "zip_url", type: ColumnType.STRING },
      { name: "status", type: ColumnType.NUMBER },
      { name: "user_id", type: ColumnType.STRING, isIndexed: true },
      { name: "job_id", type: ColumnType.STRING, isIndexed: true },
    ]),
    generateTableSchema('jobs', [
      { name: "name", type: ColumnType.STRING },
      { name: "submitted_at", type: ColumnType.NUMBER },
      { name: "started_at", type: ColumnType.NUMBER, isOptional: true },
      { name: "closed_at", type: ColumnType.NUMBER, isOptional: true },
      { name: "status", type: ColumnType.STRING },
      { name: "tolerance_limit_rule", type: ColumnType.STRING },
      { name: "notes", type: ColumnType.STRING },
      { name: "due_date", type: ColumnType.NUMBER },
      { name: "job_lead_technician_id", type: ColumnType.STRING, isIndexed: true },
      { name: "technician_organization_id", type: ColumnType.STRING, isIndexed: true },
    ]),
    generateTableSchema('job_sensors', [
      { name: "sensor_id", type: ColumnType.STRING },
      { name: "sensor_type", type: ColumnType.STRING },
      { name: "sensor_modal", type: ColumnType.STRING },
      { name: "name", type: ColumnType.STRING },
      { name: "description", type: ColumnType.STRING },
      { name: "sensor_group", type: ColumnType.STRING },
      { name: "sensor_database", type: ColumnType.STRING },
      { name: "probe_number", type: ColumnType.NUMBER },
      { name: "building", type: ColumnType.STRING },
      { name: "floor", type: ColumnType.STRING },
      { name: "room", type: ColumnType.STRING },
      { name: "chamber", type: ColumnType.STRING },
      { name: "added_to_scope_at", type: ColumnType.NUMBER },
      { name: "job_id", type: ColumnType.STRING, isIndexed: true },
      { name: "manufacturer_workgroup_id", type: ColumnType.STRING, isIndexed: true },
      { name: "notes", type: ColumnType.STRING, isOptional: true },
      { name: "is_disabled", type: ColumnType.BOOLEAN, isOptional: true },
      { name: "is_lumity", type: ColumnType.BOOLEAN},
      
     
    ]),
    generateTableSchema('job_technicians', [
      { name: "name", type: ColumnType.STRING },
      { name: "submitted_at", type: ColumnType.NUMBER },
      { name: "started_at", type: ColumnType.NUMBER, isOptional: true },
      { name: "closed_at", type: ColumnType.NUMBER, isOptional: true },
      { name: "deactivated_date", type: ColumnType.NUMBER, isOptional: true },
      { name: "status", type: ColumnType.STRING },
      { name: "notes", type: ColumnType.STRING },
      { name: "job_lead_technician_id", type: ColumnType.STRING, isIndexed: true },
      { name: "job_id", type: ColumnType.STRING, isIndexed: true },
      { name: "technician_id", type: ColumnType.STRING, isIndexed: true },
    ]),
    generateTableSchema('job_lead_technicians', [
      { name: "job_id", type: ColumnType.STRING, isIndexed: true },
      { name: "job_technician_id", type: ColumnType.STRING, isIndexed: true },
    ]),
    generateTableSchema('job_workgroups', [
      { name: "type", type: ColumnType.STRING },
      { name: "job_id", type: ColumnType.STRING, isIndexed: true },
      { name: "workgroup_id", type: ColumnType.STRING, isIndexed: true },
    ]),
    generateTableSchema('job_resources', [
      { name: "job_id", type: ColumnType.STRING, isIndexed: true },
      { name: "user_id", type: ColumnType.STRING, isIndexed: true },
      { name: "url", type: ColumnType.STRING },
      { name: "media_type", type: ColumnType.STRING },
      { name: "filename", type: ColumnType.STRING },
    ]),
    generateTableSchema('metadata', [
      { name: "_created_at", type: ColumnType.NUMBER },
      { name: "email", type: ColumnType.STRING },
      { name: "email_verified", type: ColumnType.STRING },
      { name: "identities", type: ColumnType.STRING },
      { name: "name", type: ColumnType.STRING },
      { name: "nickname", type: ColumnType.STRING },
      { name: "picture", type: ColumnType.STRING },
      { name: "_updated_at", type: ColumnType.NUMBER },
      { name: "user_id", type: ColumnType.STRING },
      { name: "user_metadata", type: ColumnType.STRING },
      { name: "last_login", type: ColumnType.STRING },
      { name: "last_ip", type: ColumnType.STRING },
      { name: "logins_count", type: ColumnType.STRING },
      { name: "app_metadata", type: ColumnType.STRING },
      { name: "user_id", type: ColumnType.STRING },
    ]),
  ],
});
