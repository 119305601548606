import React,{useEffect} from 'react';
import _ from 'lodash';
import { ActiveJobOverview } from './JobOverview/ActiveJobOverview';
import { RecentJobOverview } from './JobOverview/RecentJobOverview';
import { UpcomingJobOverview } from './JobOverview/UpcomingJobOverview';
import { JobType } from './types';
import { JobsDBEntry } from 'src/database/store/StoreTypes';
import useNavStore from '../RootNavBarIcon/NavStore';

export function RoleBasedJobs(props: { onSelect?: Function }) {
	const { isToogle } = useNavStore();
	useEffect(() => {},[isToogle])
	const dispatch = (type: JobType, job: JobsDBEntry) => {
		if (props.onSelect) {
			props.onSelect(type, job);
		}
	}

	return (
		<div className="app-dashboard-data-block data-block-column" style={{marginLeft:isToogle==='YES'?'60px':''}}>

			{/* Active jobs column */}
			<ActiveJobOverview onSelect={(job: JobsDBEntry) => dispatch('active', job)} />

			{/* Recent jobs column */}
			<RecentJobOverview onSelect={(job: JobsDBEntry) => dispatch('recent', job)} />

			{/* Upcoming jobs column */}
			<UpcomingJobOverview onSelect={(job: JobsDBEntry) => dispatch('upcoming', job)} />
		</div>
	);
}
