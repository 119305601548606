import Bugsnag from '@bugsnag/js';import { Collection, Model } from '@nozbe/watermelondb';
import id from 'date-fns/esm/locale/id/index.js';
import _ from 'lodash';
import React, { useState, useEffect, useContext } from 'react';
import { authCtx } from 'src/api/AuthProvider';

import { setStateInitializerFactory } from 'src/utils/general';
import { database } from '..';
import { forceDbReset } from '../utils';
import {
	PopColl,
	RolesDBEntry,
	UsersDBEntry,
	OrganizationsDBEntry,
	WorkgroupsDBEntry,
	ProbeTypesDBEntry,
	ToolsDBEntry,
	TechnicianToolsDBEntry,
	CalibrationsDBEntry,
	CalibrationProceduresDBEntry,
	CertificatesDBEntry,
	JobsDBEntry,
	JobSensorsDBEntry,
	JobTechniciansDBEntry,
	JobLeadTechniciansDBEntry,
	JobWorkgroupsDBEntry,
	MetadataDBEntry,
	Store,
	ModelClass,
	CalibrationReadingsDBEntry,
	JobResourcesDBEntry,
	CertificateBatchesDBEntry,
} from './StoreTypes';

let setStoreIsSynced = setStateInitializerFactory<boolean>();

export type StoreValues = {
	storeIsSynced: boolean;
	store: {
		roles: PopColl<RolesDBEntry>;
		users: PopColl<UsersDBEntry>;
		organizations: PopColl<OrganizationsDBEntry>;
		workgroups: PopColl<WorkgroupsDBEntry>;
		probe_types: PopColl<ProbeTypesDBEntry>;
		tools: PopColl<ToolsDBEntry>;
		technician_tools: PopColl<TechnicianToolsDBEntry>;
		calibrations: PopColl<CalibrationsDBEntry>;
		calibration_procedures: PopColl<CalibrationProceduresDBEntry>;
		calibration_readings: PopColl<CalibrationReadingsDBEntry>;
		certificates: PopColl<CertificatesDBEntry>;
		certificate_batches: PopColl<CertificateBatchesDBEntry>;
		jobs: PopColl<JobsDBEntry>;
		job_sensors: PopColl<JobSensorsDBEntry>;
		job_technicians: PopColl<JobTechniciansDBEntry>;
		job_lead_technicians: PopColl<JobLeadTechniciansDBEntry>;
		job_workgroups: PopColl<JobWorkgroupsDBEntry>;
		job_resources: PopColl<JobResourcesDBEntry>;
		metadata: PopColl<MetadataDBEntry>;
	} & { [key: string]: PopColl };
};

const initialStoreValues: StoreValues = {
	storeIsSynced: true,
	store: {
		roles: {},
		users: {},
		organizations: {},
		workgroups: {},
		probe_types: {},
		tools: {},
		technician_tools: {},
		calibrations: {},
		calibration_procedures: {},
		calibration_readings: {},
		certificates: {},
		certificate_batches: {},
		jobs: {},
		job_sensors: {},
		job_technicians: {},
		job_lead_technicians: {},
		job_workgroups: {},
		job_resources: {},
		metadata: {},
		// asset: {}
	},
};

export const StoreContext =
	React.createContext<StoreValues>(initialStoreValues);

async function updateCollection(
	collection: Model[],
	tableName: string,
	prevStore: Store
) {
	const prevColl: PopColl = prevStore[tableName] || {};

	// map to all records to collection cache
	const collCache: PopColl = {};
	for (let i = 0; i < collection.length; i++) {
		const { id, _raw } = collection[i];
		collCache[id] = _.clone(_raw);
	}

	const clearList = { ...prevColl, ...collCache };

	const modelClass = database.collections.get(tableName)
		.modelClass as ModelClass;
	_.forEach(modelClass.associations, ({ type, key }, fgnTable) => {
		// prepare associative foreign and local fields
		if (type === 'has_many') {
			// copy over previous foreign associations
			_.forEach(collCache, (record, id) => {
				const prevRecord = prevColl[id] || {};
				collCache[id][fgnTable] = prevRecord[fgnTable] || {}; // object literal is not a shared reference.
			});
		} else {
			// type === 'belongs_to': repop
			const prevFgnColl = prevStore[fgnTable];
			const { associations: fgnAss } = database.collections.get(fgnTable)
				.modelClass as ModelClass;
			const hasFgnMany = _.some(
				fgnAss,
				({ foreignKey }, fgnTable) =>
					fgnTable === tableName && foreignKey === key
			);

			if (hasFgnMany) {
				// clear foreign dictionary before repopulating
				_.forEach(prevFgnColl, (fgnRec) => (fgnRec[tableName] = {}));
			}

			_.forEach(collCache, (record, id) => {
				const fgnId = record[key!];
				if (!fgnId) return; // console.warn(`'${key}' does not exist on '${tableName}'`); // this should exist on any non-optional record
				// NB: removed check for existence of ref dict
				// switch id for stored foreign record
				record[`${key!}_ref`] = prevFgnColl[fgnId];
				if (hasFgnMany) {
					// repopulate foreign dict
					if (prevFgnColl[fgnId])
						prevFgnColl[fgnId][tableName][id] = record;
				}
			});
		}
	});
	prevStore[tableName] = collCache;
}

export const StoreProvider: React.FC = ({ children }) => {
	const [store, setStore] = useState<StoreValues['store']>(
		initialStoreValues.store
	);
	const { accessToken, justSync } = useContext(authCtx);
	const [storeIsSynced, _setStoreIsSynced] = useState(true);
	const [isStoredAll, setIsStoredAll] = useState(false);
	setStoreIsSynced = _setStoreIsSynced;

	useEffect(() => {
		(async () => {
			if (!accessToken) return;
			justSync();

			//first run
			let innerStore: Store = {};
			// map raw collections without populating
			const firstRunPromises = _.map(
				database.collections.map,
				async (collection, tableName) => {
					let qRes: Model[] = [];
					try {
						qRes = await (collection as Collection<Model>)
							.query()
							.fetch();
					} catch (err) {
						Bugsnag.notify(err as any);
						forceDbReset({ database });
						throw err;
					}
					const collCache: PopColl = {};
					for (let i = 0; i < qRes.length; i++) {
						const { id, _raw } = qRes[i];
						collCache[id] = _raw;
					}
					innerStore[tableName] = collCache;
				}
			);
			await Promise.all(firstRunPromises);

			const tables = Object.keys(database.collections.map);

			// populate each collection
			// this block must exist because there is currently no way to guarantee that
			// all tables have been populated before the first setStore is called in the subscriptions
			for (let i = 0; i < tables.length; i++) {
				const tableName = tables[i];
				const coll = await database.collections
					.get(tableName)
					.query()
					.fetch();
				await updateCollection(coll, tableName, innerStore);
			}
			// console.log('store----------before----',store);

			// subscribe each collection
			// TODO: you should be able to turn off the initial fetch on subscription
			for (let i = 0; i < tables.length; i++) {
				const tableName = tables[i];
				const columns = Object.keys(
					database.schema.tables[tableName].columns
				);
				database.collections
					.get(tableName)
					.query()
					.observeWithColumns(columns)
					.subscribe(async (coll) => {
						await updateCollection(coll, tableName, innerStore);
						setStore(() => _.clone(innerStore) as StoreValues['store']);
					});
				// console.log('store----------after----',store);
			}

			if (store) {
				// console.log('key size----------------------------');
				// console.log('store----------probe_types----', store.probe_types);
				if (store.probe_types) {
          let size = Object.keys(
            store.probe_types
            ).length;
            // console.log('key size----------------------------',size);
          }
			}
		})();
	}, [accessToken]);
	useEffect(() => {
    // console.log('key size------------------setIsStoredAll----------');
		setIsStoredAll(true);
		
	}, []);

	return (
		<StoreContext.Provider value={{ store, storeIsSynced }}>
			
			{children}
		</StoreContext.Provider>
	);
};
