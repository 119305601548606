import _, { compact } from "lodash";
import { FC, useContext, useMemo } from "react";
import { StoreContext } from "src/database/store/StoreProvider";
import { BaseModelDBEntry, PopColl } from "src/database/store/StoreTypes";

export type Table<TEntry extends BaseModelDBEntry, TColumnKey extends string> = {
  columns: Column<TColumnKey, TEntry, any>[];
  pageSize?: number;
};

export type Column<
  TColumnKey extends string,
  TEntry extends BaseModelDBEntry = BaseModelDBEntry,
  TTableDatum = any
  > = {
    key: TColumnKey;
    title?: string;
    extractFunction?: (entry: TEntry) => TTableDatum;
    customSortFunction?: ((a: TEntry, b: TEntry) => number) | undefined;
    doNotSort?: boolean;
    headerAlign?: 'left' | 'right' | 'center';
    headerComponent?: FC;
  };

export function useTable<TEntry extends BaseModelDBEntry, TColumnKey extends string>(
  table: Table<TEntry, TColumnKey>,
  ambiData: PopColl<TEntry> | TEntry[],
  currentSort: TColumnKey|any,
  isAscending: boolean,
  currentPage: number = 1
) {
  // TOFIX: This hack is used too much. store is only included to fire changes because the data object only mutates and thus doesn't fire a rerender
  const { store } = useContext(StoreContext);

  // lazy, but getting lodash functions to accept objects and arrays without pissing off ts is absolute hell
  // also forcing this hook to only accept objects got pretty annoying
  const data = Array.isArray(ambiData) ? ambiData : _.map(ambiData);

  //INITIALIZING
  const { columns, pageSize = 0 } = table;
  const extractionFunctions = useMemo(() => {
    const extractionFunctions = {} as Record<TColumnKey |any, (
      entry: TEntry) => any>;
    _.forEach(columns, ({ key, extractFunction = () => null }) => extractionFunctions[key] = extractFunction)
    return extractionFunctions;
  }, [columns])

  // START SORTING
  const sortedData = useMemo(() => {
    const sortColumn = _.find(table.columns, ({ key }) => key === currentSort);
    if (sortColumn?.doNotSort) return _.map(data);
    if (sortColumn?.customSortFunction) {
      let sorted = _.map(data).sort(sortColumn.customSortFunction!)
      return isAscending ? sorted : sorted.reverse();
    }
    return _.orderBy(
      data,
      [(entry) => extractionFunctions[currentSort](entry)],
      [isAscending ? 'asc' : 'desc']
    );
  }, [table.columns, data, currentSort, isAscending, extractionFunctions])
  

  const tableData = useMemo(() => {
    // START PAGINATION
    let pagedData = sortedData;
    if (pageSize) {
      const offset = (currentPage - 1) * pageSize
      pagedData = sortedData.slice(offset, offset + pageSize)
    }
    
    // START EXTRACTING
    return compact(pagedData.map((entry) => {
      if (!entry) {
        return;
      }
      const tableEntry: Record<string, any> = {};
      _.forEach(extractionFunctions, (exFn, key) => {
        tableEntry[key] = exFn(entry);
      });
      tableEntry['id'] = entry.id;
      return tableEntry as Record<TColumnKey | 'id', any>;
    }));
  }, [currentPage, extractionFunctions, pageSize, sortedData]);

    return tableData;
}
