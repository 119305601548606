import React, { useContext, useEffect, useState } from 'react';
import { StoreContext } from 'src/database/store/StoreProvider';
import { UsersDBEntry } from 'src/database/store/StoreTypes';
import { extractFirstAndLastName } from '../../utils/general';

export function Headline() {
	const userId = localStorage.getItem('user-id');
	const { store } = useContext(StoreContext);
	let [user, setUser] = useState<UsersDBEntry>();
	let [firstName, setFirstName] = useState<string>('');
	// let userid = localStorage


	useEffect(() => {
		if (userId) {
			setUser(store.users[userId]);
		}
	}, []);

	useEffect(() => {
		let fname: string;

		if (!user) {
			return;
		}

		[fname] = extractFirstAndLastName(user.name);
		setFirstName(fname);
	}, [user]);

	return (
		<div className="dashboard-headline">
			<h3 className='greeting'>Welcome to Function Compliance Services' Operation Center(OPCEN)</h3>
			<h3>Hello {firstName}!</h3>
		</div>
	);
}
