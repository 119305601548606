import React, {
	useEffect,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';
import styles from './navigationMenuIcon.module.css';
import 'font-awesome/css/font-awesome.min.css';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'font-awesome/css/font-awesome.min.css';
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import Profile, { ProfileTab } from '../modals/Profile/Profile';
import { authCtx } from 'src/api/AuthProvider';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { getInitials } from 'src/utils/general';
import useOfflineQueue from 'src/hooks/useOfflineQueue';
import { forceDbReset } from 'src/database/utils';
import { database } from 'src/database';
import Bugsnag from '@bugsnag/js';
import useNavStore from './NavStore';

const NavigationMenuIcon = () => {
	const location = useLocation();
	const { setToken, justSync } = useContext(authCtx);
	const { isOnline } = useOfflineQueue();
	const dbUser = useCurrentUser();
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const [profileTab, setProfileTab] = useState<ProfileTab | null>(null);

	const initials = getInitials(dbUser?.name);
	const openProfile = useCallback((tab: ProfileTab) => {
		setProfileTab(tab);
		setMenuIsOpen(false);
	}, []);

	const handleLogout = useCallback(async () => {
		try {
			justSync();
			forceDbReset({
				database,
				onSuccess: () => setToken!(''),
			});
		} catch (err) {
			Bugsnag.notify(err as any);
			console.error('handleLogout', err);
		}
	}, [setToken, isOnline, justSync, database]);

	const userIsCustomer = useMemo(
		() => dbUser?.role_id_ref.name === 'customer',
		[dbUser]
	);
	const userIsAdmin = useMemo(
		() => dbUser?.role_id_ref.name === 'administrator',
		[dbUser]
	);
	const userIsTech = useMemo(
		() => dbUser?.role_id_ref.name === 'technician',
		[dbUser]
	);
	const { setIsToogle, isSensorCalibrationPage, setIsSensorCalibrationPage } =
		useNavStore();
	const [isNavMenuShow, NavMenuShow] = useState(false);
	function show_hide_nav(t: boolean) {
		NavMenuShow(t);
		setIsToogle(t ? 'YES' : 'NO');
	}

	useEffect(() => {
		let currentURL = location.pathname;
		let isCurrentPageSensorCalibration = currentURL.match('/jobs/');
		let notDocCertStausTab = currentURL.match(
			/documents|certificates|STATUS/g
		);
		if (!notDocCertStausTab && isCurrentPageSensorCalibration) {
			setIsSensorCalibrationPage(true);
		} else {
			setIsSensorCalibrationPage(false);
		}
	}, [location, isSensorCalibrationPage]);
	return (
		<>
			<nav
				className={styles.avigationMenuIcon}
				onClick={() => show_hide_nav(!isNavMenuShow)}
			>
				<div>
					{isNavMenuShow ? (
						<i
							className={`fa fa-duotone fa-window-close  fa-2x ${styles.close}`}
							aria-hidden="true"
						></i>
					) : (
						<i
							className="fa fa-duotone fa-bars  fa-2x"
							aria-hidden="true"
						></i>
					)}
				</div>
			</nav>
			{isNavMenuShow && (
				<nav className={styles.nav}>
					{isNavMenuShow ? (
						<div className={styles.navdivfadeIn}>
							<div className={styles.navbarLink}>
								<NavLinkItem
									to="/"
									exact
									name="Dashboard"
									icon="home"
								/>
								{!userIsCustomer && (
									<NavLinkItem
										to="/customers"
										exact={false}
										name="Accounts"
										icon="hospital-user"
									/>
								)}
								<NavLinkItem
									to="/jobs"
									exact={false}
									name="Jobs"
									icon="toolbox"
								/>
								{userIsAdmin && (
									<>
										<NavLinkItem
											to="/users"
											exact={false}
											name="Users"
											icon="users"
										/>
										<NavLinkItem
											to="/probes"
											exact
											name="Probes"
											icon="thermometer-half"
										/>
										<NavLinkItem
											to="/calibrationTool"
											exact={false}
											name="Calibration Tool"
											icon="wrench"
										/>
									</>
								)}
							</div>
							<div
								className="menuButton hvrPtr"
								onClick={() => setMenuIsOpen(true)}
							>
								{initials}
							</div>
							<Modal
								isOpen={menuIsOpen}
								onRequestClose={() => setMenuIsOpen(false)}
								className={styles.menu}
								overlayClassName="menuOverlay"
							>
								<div className={styles.menuList}>
									{(userIsAdmin || userIsTech) && (
										<p onClick={() => openProfile('tools')}>
											My Tools
										</p>
									)}
									<p onClick={() => openProfile('profile')}>
										Edit Profile
									</p>
									<div
										style={{ height: 1, backgroundColor: '#E0E0E0' }}
									></div>
									<p onClick={handleLogout}>Log Out</p>
								</div>
							</Modal>
							<Modal
								isOpen={!!profileTab}
								onRequestClose={() => setProfileTab(null)}
								className="modal"
								overlayClassName="modalOverlay"
							>
								<Profile
									closeModal={() => setProfileTab(null)}
									currentTab={profileTab!}
									setCurrentTab={setProfileTab}
								/>
							</Modal>
						</div>
					) : (
						<></>
					)}
				</nav>
			)}
		</>
	);
};

type NavLinkProps = {
	to: string;
	exact?: boolean;
	name: string;
	icon: IconName;
};

const NavLinkItem = ({ to, exact, name, icon }: NavLinkProps) => {
	const history = useHistory();
	const location = useLocation();
	const [hover, setHover] = useState(false);

	const onHover = useCallback(() => {
		setHover(true);
	}, []);

	const onLeave = useCallback(() => {
		setHover(false);
	}, []);

	const isCurrent = useMemo(() => {
		if (exact) {
			return location.pathname === to;
		} else {
			return location.pathname.startsWith(to);
		}
	}, [to, location]);

	return (
		<>
			<Link to={to}>
				<div
					className={styles.navLink}
					style={{
						backgroundColor: isCurrent ? '#EBECED' : 'transparent',
					}}
					onMouseEnter={onHover}
					onMouseLeave={onLeave}
				>
					<FontAwesomeIcon
						icon={icon}
						size="1x"
						color={isCurrent ? '#0F7EE3' : '#8c9299'}
					/>
					{hover && <span className={styles.hoverLinkText}>{name}</span>}
				</div>
			</Link>
		</>
	);
};
export default NavigationMenuIcon;
