import _ from 'lodash';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';
import { StoreContext } from 'src/database/store/StoreProvider';
import { JobsDBEntry } from 'src/database/store/StoreTypes';
import Backout from '../Backout/Backout';
import AddGroup from '../modals/AddGroup/AddGroup';
import ClosedJobs from './ClosedJobs';
import OpenJobs from './OpenJobs';
import PendingJobs from './PendingJobs';
import styles from './jobs.module.css'
import useNavStore from '../RootNavBarIcon/NavStore';

type JobsProps = {};

const WorkgroupJobs: FC<JobsProps> = () => {
  const { isToogle } = useNavStore();
	useEffect(() => {}, [isToogle]);
  const { workgroupId } = useParams<{ probeId: string, workgroupId: string }>();
  const [addGroupModalIsOpen, setAddGroupModalIsOpen] = useState(false);
  const { store } = useContext(StoreContext);
  const workgroup = store.workgroups[workgroupId];
  const filteredJobs = useMemo(() => {
    const filteredJobs: Record<string, JobsDBEntry> = {};
    _.forEach(store?.jobs, (job) => {
      _.forEach(job?.job_workgroups, (jwg) => {
        if (jwg.workgroup_id === workgroupId) filteredJobs[workgroupId] = jwg.job_id_ref;
      });
    });
    return filteredJobs;
  }, [store, workgroupId]);

  return (
    <div className={'mainContentContainer'}>
      <div className="header">
        <div>
          <Backout backoutTitle={workgroup?.organization_id_ref?.name} specificAddress={`/customers/${workgroup?.organization_id}`} />
          <div className={styles.titleCluster}>
            <h2 style={{ marginRight: '0.5em' }}>{workgroup?.name}</h2>
            <button className="hollow" onClick={() => setAddGroupModalIsOpen(true)}>edit</button>
          </div>
        </div>
      </div>
      <div style={{ marginLeft: isToogle === 'YES' ? '90px' : '' }}>
      <h3 className="listTitle">Pending</h3>
      <PendingJobs filteredJobs={filteredJobs} />
      <h3 className="listTitle">Open</h3>
      <OpenJobs filteredJobs={filteredJobs} />
      <h3 className="listTitle">Closed</h3>
      <ClosedJobs filteredJobs={filteredJobs} />
</div>
      <Modal isOpen={addGroupModalIsOpen} onRequestClose={() => setAddGroupModalIsOpen(false)} className="modal" overlayClassName="modalOverlay">
        <AddGroup groupId={workgroupId} closeModal={() => setAddGroupModalIsOpen(false)} />
      </Modal>
    </div>
  );
};

export default WorkgroupJobs;
