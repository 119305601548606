import _ from 'lodash';
import React, { FC, useContext, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { StoreContext } from 'src/database/store/StoreProvider';
import { JobsDBEntry, PopColl } from 'src/database/store/StoreTypes';
import { Table, useTable } from 'src/hooks/useTable';
import { getJobProgress, unixToStr } from 'src/utils/general';
import Backout from '../Backout/Backout';
import TableHeader, { SortingPackage } from '../TableHeader/TableHeader';
import EditUser from '../modals/EditUser/EditUser';
import styles from './userDetail.module.css';
import useNavStore from '../RootNavBarIcon/NavStore';

type ColumnKey =
  | 'customer'
  | 'leadTechnician'
  | 'progress'
  | 'sensorCount'
  | 'updated'
  | 'created';

const userTable: Table<JobsDBEntry, ColumnKey> = {
  columns: [
    {
      key: 'customer',
      title: 'Customer',
      extractFunction: (job) =>
        _.chain(job?.job_workgroups)
          .find((jwg) => jwg.type === 'customer')
          .value()?.workgroup_id_ref?.organization_id_ref?.name,
    },
    {
      key: 'leadTechnician',
      title: 'Lead Technician',
      extractFunction: (job) => job?.job_lead_technician_id_ref?.job_technician_id_ref?.technician_id_ref?.email,
    },
    {
      key: 'progress',
      title: 'Progress',
      extractFunction: (job) => getJobProgress(job),
      headerAlign: 'right',

    },
    {
      key: 'sensorCount',
      title: 'Num. Sensors',
      extractFunction: (job) => _.size(job?.job_sensors),
      headerAlign: 'right',
    },
    {
      key: 'updated',
      title: 'Updated',
      extractFunction: (job) => job?.updated_at,
      headerAlign: 'right',
    },
    {
      key: 'created',
      title: 'Created',
      extractFunction: (job) => job?.created_at,
      headerAlign: 'right',
    },
  ],
};

type UsersProps = {};

const UserDetail: FC<UsersProps> = () => {
  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();
  const { store } = useContext(StoreContext);
  const user = store.users[userId];
  const [currentSort, setCurrentSort] = useState<ColumnKey>('created');
  const [isAscending, setIsAscending] = useState(false);
  const [editUserModalIsOpen, setEditUserModalIsOpen] = useState(false);
  const { isToogle } = useNavStore();
  const userJobs = useMemo(() => {
    const userJobs: PopColl<JobsDBEntry> = {};
    _.forEach(user?.job_technicians, ({ job_id, job_id_ref }) => {
      if (job_id_ref) userJobs[job_id] = job_id_ref;
    });
    return userJobs
  }, [user?.job_technicians, user])

  const tableData = useTable(
    userTable,
    userJobs,
    currentSort,
    isAscending
  );

  if (!user) return null;

  const sortingPackage: SortingPackage<ColumnKey> = {
    setCurrentSort,
    currentSort,
    setIsAscending,
    isAscending,
  };
  
  return (
    <div className={'mainContentContainer'}>
      <div className="header">
        <div>
          <Backout backoutTitle="Users" />
          <h2>
            {user.name}
            <button className={`${styles.edit}`} onClick={() => setEditUserModalIsOpen(true)}>Edit</button>
          </h2>

        </div>
      </div>
      <div style={{ marginLeft: isToogle === 'YES' ? '90px' : '' }}>
      <h3 className="listTitle">Jobs</h3>
      <TableHeader
        columns={userTable.columns}
        rowClassName={styles.row}
        sortingPackage={sortingPackage}
      />
      {tableData.map(({ customer, leadTechnician, progress, sensorCount, updated, created, id }) => (
        <div
          key={id}
          className={`${styles.row} hvrPtr row`}
          onClick={() => history.push(`/jobs/${id}`)}
        >
          <p>{customer}</p>
          <p>{leadTechnician}</p>
          <p>{progress}</p>
          <p style={{ textAlign: 'end' }}>{sensorCount}</p>
          <p style={{ textAlign: 'end' }}>{unixToStr(updated)}</p>
          <p style={{ textAlign: 'end' }}>{unixToStr(created)}</p>
        </div>
      ))}
      </div>
   
      <Modal isOpen={editUserModalIsOpen} onRequestClose={() => setEditUserModalIsOpen(false)} className="modal" overlayClassName="modalOverlay">
        <EditUser user={user} closeModal={() => setEditUserModalIsOpen(false)} />
      </Modal>
    </div>
  );
};

export default UserDetail;
