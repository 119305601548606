import {
  addColumns,
  createTable,
  Migration,
  schemaMigrations,
} from "@nozbe/watermelondb/Schema/migrations";
import { ColumnType } from "src/types/watermelon";

/**
 * Update this on schema change to provide compatibility for existing users.
 * ⚠️ Set the new migration's toVersion to a number one larger than the current schema version
 * {
 *  toVersion: 2,
 *  steps: [
 *    addColumns({
 *      table: 'foo',
 *      columns: [
 *        { name: 'bar', type: ColumnType.STRING, isOptional: true },
 *      ],
 *    }),
 *  ],
 * }
 */
const migrations: Migration[] = [
  {
    toVersion: 2,
    steps: [
      createTable({
        name: 'certificate_batches',
        columns: [
          { name: "calibration_ids", type: ColumnType.STRING },
          { name: "zip_url", type: ColumnType.STRING },
          { name: "status", type: ColumnType.NUMBER },
          { name: "user_id", type: ColumnType.STRING, isIndexed: true },
          { name: "job_id", type: ColumnType.STRING, isIndexed: true },
        ],
      }),
    ],
  }
];

export default schemaMigrations({
  migrations,
});
