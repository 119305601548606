import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { authCtx } from "src/api/AuthProvider";
import urls from "src/api/urls";
import { database } from "src/database";
import { StoreContext } from "src/database/store/StoreProvider";
import useCurrentUser from "src/hooks/useCurrentUser";
import TechnicianTool from "src/models/TechnicianTool";
import User from "src/models/User";
import { getActiveToolbelt, parseIsoDate } from "src/utils/general";
import styles from './profile.module.css'
import {
  TechnicianToolsDBEntry
} from 'src/database/store/StoreTypes';
export type ProfileTab = 'profile' | 'tools';

type ProfileProps = {
  closeModal: () => void;
  currentTab: ProfileTab;
  setCurrentTab: React.Dispatch<React.SetStateAction<"profile" | "tools" | null>>;
}

//TODO: this is obviously multiple components
//TODO: rename dissassociate to something more appro

const Profile: FC<ProfileProps> = ({ closeModal, currentTab, setCurrentTab }) => {
  const { store } = useContext(StoreContext);
  
  const user = useCurrentUser();
  const { accessToken, justSync } = useContext(authCtx);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [toolTypeId, setToolTypeId] = useState('');
  const [serialNum, setSerialNum] = useState('');
  const [calibrationDate, setCalibrationDate] = useState(new Date());
  const [calibrationDueDate, setCalibrationDueDate] = useState(new Date());
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const isSubmissionReady = user && (currentTab === 'profile' ? firstName && lastName : toolTypeId && serialNum && calibrationDate && calibrationDueDate);

  // Performance here might be negligble but looks cleaner IMO lol
  const { currentFirstName, currentLastName } = useMemo(() => {
    const nameArr = user?.name.split(' ') || [];
    const currentFirstName = nameArr.slice(0, nameArr.length - 1).join(' ');
    const currentLastName = nameArr[nameArr.length - 1]

    return { currentFirstName, currentLastName }
  }, [user]);
  // Use multiple effects, as first name can stay the same while last name changes (and vice-versa)
  useEffect(() => setFirstName(currentFirstName), [currentFirstName]);
  useEffect(() => setLastName(currentLastName), [currentLastName])

  // React warns about complex attributes (user?.technician_tools) in dependency array
  const userTools = user?.technician_tools;

  /**
    * IMPORTANT: Need to include the `user.technician_tools` in the dependency array, not just
    * `user` alone. The `technician_tools` attribute is a "relation", so React wont necessarily
    * perceive a change in `technician_tools` as a change in the `user`. This means React may
    * re-use the memoized result, even if the `technician_tools` is updated. This seems to be a 
    * recurring theme across the entire project as a result of using WatermelonDB.
    */
  //  const technician_tools  = store.technician_tools
  //  const userIsAdmin = useMemo(
  //    () => user?.role_id_ref.name === 'administrator',
  //    [user]
  //    );
   
    const usersTools = useMemo(() =>getActiveToolbelt(user)
    
    // {
    //   return !userIsAdmin?getActiveToolbelt(user):_.filter(
    //     technician_tools,
    //     ({ deactivated_date }) => !deactivated_date
    //   )
    
    // } 
    , [user, userTools]);

  /**
   * Updates the current user's name using the backend API.
   */
  const handleUpdateProfile = useCallback(async () => {
    if (!isSubmissionReady) return;
    setIsProcessing(true)
    await (async () => {
      const userModel = await database.collections.get<User>('users').find(user!.id);
      await userModel.updateDetails({ firstName, lastName });
      const { base, updateProfile } = urls;
      await fetch(`${base}${updateProfile.url}`, {
        method: updateProfile.method,
        headers: { 'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: `${firstName} ${lastName}`, nickname: firstName })
      })
      justSync();
      setIsProcessing(false);
      closeModal();
    })()
  }, [accessToken, closeModal, user, firstName, lastName, isSubmissionReady]);

  /**
   * Adds a new tool to the user.
   */
  const handleAddTool = useCallback(async () => {
    if (!isSubmissionReady) return;
    setIsProcessing(true);
    const userModel = await database.collections.get<User>('users').find(user!.id);
    await userModel.addTool(toolTypeId, serialNum, calibrationDate, calibrationDueDate);
    setToolTypeId('');
    setSerialNum('');
    setIsInputVisible(false);
    justSync();
    setIsProcessing(false);
  }, [user, isSubmissionReady, toolTypeId, serialNum, calibrationDate, calibrationDueDate]);

  /**
   * Deletes a user's tool.
   */
  const handleDeleteTool = useCallback(async (id: string) => {
    setIsProcessing(true)
    const techTool = await database.collections.get<TechnicianTool>('technician_tools').find(id);
    techTool.disassociateFromTech();
    setIsInputVisible(false);
    justSync();
    setIsProcessing(false)
  }, []);

  return (
    <div className="modalContainer">
      <div className="modalHeader">
        <h2>Profile</h2>
      </div>
      <div className="content">
        <div className="tabs">
          <h3 className={currentTab === 'profile' ? 'selectedTab' : ''} onClick={() => setCurrentTab('profile')}>Details</h3>
          {user?.role_id_ref.name !== 'customer' && <h3 className={currentTab === 'tools' ? 'selectedTab' : ''} onClick={() => setCurrentTab('tools')}>Tools</h3>}
        </div>
        {
          currentTab === 'profile' ?
            <>
              <div className="multiCol" style={{ gridTemplateColumns: '1fr 1fr' }}>
                <div>
                  <label>First Name</label>
                  <input
                    onChange={({ target }) => setFirstName(target.value)}
                    value={firstName}
                  />
                </div>
                <div>
                  <label>Last Name</label>
                  <input
                    onChange={({ target }) => setLastName(target.value)}
                    value={lastName}
                  />
                </div>
              </div>
              <label>Email</label>
              <input
                disabled
                value={user?.email} />
            </> :
            <>
              {
                usersTools.map(({ tool_id_ref, serial_number, id, calibration_date, calibration_due_date }) => (

                  <div key={id} className={`multiCol ${styles.toolsRow}`} style={{ gridTemplateColumns: '3fr 2fr 3fr 3fr 1fr' }}>
                    <div>
                      <label>Type</label>
                      <div className="selectContainer">
                        <select disabled>
                          <option>{tool_id_ref?.name}</option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <label>Serial Number</label>
                      <input disabled value={serial_number} />
                    </div>
                    <div>
                      <label>Calibration Date</label>
                      <input
                        disabled
                        className={styles.inputDate}
                        type="date"
                        value={parseIsoDate(new Date(calibration_date))}
                      />
                    </div>
                    <div>
                      <label>Due Date</label>
                      <input
                        disabled
                        className={styles.inputDate}
                        type="date"
                        value={parseIsoDate(new Date(calibration_due_date))}
                      />
                    </div>
                    {/* <div style={{ marginBottom: 26 }}>
                      <FontAwesomeIcon className="hvrPtr" icon="times-circle" color="#8C9299" onClick={() => handleDeleteTool(id)} />
                    </div> */}
                  </div>
                ))
              }
              {/* {
                isInputVisible ?
                  <div className={`multiCol ${styles.toolsRow}`} style={{ gridTemplateColumns: '2fr 1fr 2fr 2fr 1fr', alignItems: 'flex-start' }}>
                    <div>
                      <label>Type</label>
                      <div className="selectContainer">
                        <select value={toolTypeId} onChange={({ target }) => setToolTypeId(target.value)}>
                          <option value=''>Select Tool Type...</option>
                          {_.map(store.tools, ({ name, id }) => (<option key={id} value={id}>{name}</option>))}
                        </select>
                      </div>
                    </div>
                    <div>
                      <label>Serial Number</label>
                      <input
                        onChange={({ target }) => setSerialNum(target.value)}
                        value={serialNum} />
                    </div>
                    <div>
                      <label>Calibration Date</label>
                      <input
                        className={styles.inputDate}
                        type="date"
                        onChange={({ target }) => { setCalibrationDate(new Date(`${target.value} 00:00:00`)) }}
                        value={parseIsoDate(calibrationDate)}
                      />
                    </div>
                    <div>
                      <label>Due Date</label>
                      <input
                        className={styles.inputDate}
                        type="date"
                        onChange={({ target }) => { setCalibrationDueDate(new Date(`${target.value} 00:00:00`)) }}
                        value={parseIsoDate(calibrationDueDate)}
                      />
                    </div>
                    <button disabled={!serialNum || !toolTypeId || isProcessing} onClick={handleAddTool} style={{ alignSelf: 'flex-end', marginBottom: 16 }}>Save</button>
                  </div>
                  :
                  <p className="hvrPtr" style={{ textAlign: 'center', fontWeight: 'bold', color: '#8C9299', height: '3em' }} onClick={() => setIsInputVisible(true)} >Add Tool</p>
              } */}
            </>
        }
      </div>
      <div className="footer">
        <button disabled={isProcessing} className="cancel" onClick={closeModal}>Cancel</button>
        <button disabled={(currentTab === 'profile' && !isSubmissionReady) || isProcessing} onClick={currentTab === 'profile' ? handleUpdateProfile : closeModal}>
          {currentTab === 'profile' ? 'Submit' : 'Done'}
        </button>
      </div>
    </div>
  );
};

export default Profile;