import React,{useState,useCallback} from 'react';
import './App.css';
import RootRouter from 'src/components/RootRouter/RootRouter';
import { initializeIcons } from 'src/utils/icons';
import Login from "./components/Login";
import EmailLinkRedirect from './components/EmailLinkRedirect/EmailLinkRedirect';
import useCurrentUser from './hooks/useCurrentUser';
// import Loader from './components/modals/Loaded/Loader';
function initializeApp() {
  initializeIcons();
}

initializeApp();
const App = () => {
  const dbUser = useCurrentUser();
    return (
    <>
      {dbUser?
        <RootRouter />
        :
        <>
          <Login />
          <EmailLinkRedirect />
        </>
      }

    </>
  );
};

export default App;
