import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { authCtx } from 'src/api/AuthProvider';
import urls from 'src/api/urls';
import { StoreContext } from 'src/database/store/StoreProvider';
import useOrganizationTypes from 'src/hooks/useOrganizationTypes';
import styles from './addJob.module.css';
import './index.css';

enum View {
	INFO = 0,
	IMPORT = 1,
	// DOCS = 2,
	RESULTS = 2,
}

type AddJobProps = {
	closeModal: () => void;
};

export type FilePkg = {
	file: File;
	reader: FileReader;
};

const AddJob: FC<AddJobProps> = ({ closeModal }) => {
	const { store } = useContext(StoreContext);
	const { justSync } = useContext(authCtx);
	const { accessToken } = useContext(authCtx);
	const [currentView, setCurrentView] = useState(View.INFO);
	const [isProcessing, setIsProcessing] = useState(false);
	const [name, setName] = useState('');
	const [customerId, setCustomerId] = useState('');
	const [techOrgId, setTechOrgId] = useState('');
	const [groupId, setGroupId] = useState('');
	const [primaryContactId, setPrimaryContactId] = useState('');
	const [ITContact, setITContact] = useState('');
	const [csvFile, setCsvFile] = useState<FilePkg>();
	const [docList, setDocList] = useState<FilePkg[]>([]);
	const [sensorCt, setSensorCt] = useState(0);
	const [docCt, setDocCt] = useState(0);
	const [err, setErr] = useState('');
	const [expectedStartDate, setExpectedStartDate] = useState('');
	const [minExpectedStartDate, setMinExpectedStartDate] = useState('');
	const [expectedCompletionDate, setExpectedCompletionDate] = useState('');
	const [tolCalRuleValue, settolCalRuleValue] = useState('');
	const [customerIdentified, setCustomerIdentified] = useState(false);
	const [assetReconciliation, setAssetReconciliation] = useState(false);
	const [sow, setSow] = useState(false);
	const [scheduling, setScheduling] = useState(false);
	const [assignTech, setAssignTech] = useState(false);

	const customers = useOrganizationTypes('customer');
	const manufacturers = useMemo(
		() =>
			_.filter(store.organizations, ({ name }) =>
				['Function Compliance Services', 'Cooper-Atkins/Emerson'].includes(
					name
				)
			),
		[store.organizations]
	);

	const customer = store.organizations[customerId];
	const customerWorkgroups = customer?.workgroups;
	const hasOneWorkgroup = useMemo(
		() => _.size(customerWorkgroups) === 1,
		[customerWorkgroups]
	);

	const isSubmissionReady =
		(currentView === View.INFO &&
			customerId &&
			groupId &&
			primaryContactId &&
			ITContact &&
			techOrgId &&
			name &&
			tolCalRuleValue) ||
		currentView === View.IMPORT;

	const prevView = () => setCurrentView(Math.max(currentView - 1, View.INFO));
	const nextView = () =>
		setCurrentView(Math.min(currentView + 1, View.RESULTS));

	const onCsvDrop = useCallback((acceptedFiles: File[]) => {
		const file = acceptedFiles[0];
		if (!file) {
			console.log('No accepted files found.');
			return;
		}
		const reader = new FileReader();

		reader.onabort = () => console.log('file reading was aborted');
		reader.onerror = () => console.log('file reading has failed');
		reader.onload = () => {
			setCsvFile({ file, reader });
		};
		reader.readAsArrayBuffer(file);
	}, []);

	// React Dropzone does not pick up CSV files on Windows if using MIME type ("text/csv") as acceptance. Need to specify
	// file extension ".csv": https://stackoverflow.com/questions/54047236/dropzone-does-not-pick-up-csv-files-on-windows-10-and-chromeos-with-mime-text-c
	const csvDropzone = useDropzone({
		noClick: true,
		noKeyboard: true,
		onDrop: onCsvDrop,
		accept: '.csv',
	});

	const onDocDrop = useCallback(
		(acceptedFiles: File[]) => {
			if (!acceptedFiles.length) return;
			const docListRef = [...docList];
			acceptedFiles.forEach((file, idx) => {
				const reader = new FileReader();
				reader.onabort = () => console.log('file reading was aborted');
				reader.onerror = () => console.log('file reading has failed');
				reader.onload = () => {
					docListRef.push({ file, reader });
					setDocList([...docListRef]);
				};
				reader.readAsArrayBuffer(file);
			});
		},
		[docList]
	);

	const docDropzone = useDropzone({ onDrop: onDocDrop });

	const removeDoc = (idx: number) => {
		const updatedDocList = [...docList];
		updatedDocList.splice(idx, 1);
		setDocList([...updatedDocList]);
	};
	const changeTolCalRuleValue = (value: any) => {
		settolCalRuleValue(value);
	};
	const handleSubmit = async (isCsvTrue: boolean) => {
		const boolToString = (v: boolean) => (v ? '1' : '0');
		if (!isSubmissionReady) return;
		let startDate = new Date(expectedStartDate);
		let endDate = new Date(expectedCompletionDate);
		setIsProcessing(true);
		if (isCsvTrue) {
			const formData = new FormData();
			formData.append('isCsvTrue', boolToString(isCsvTrue));
			formData.append('name', name);
			formData.append('workgroupId', groupId);
			formData.append('technicianOrganizationId', techOrgId);
			formData.append('toleranceLimitRule', tolCalRuleValue);
			formData.append('expectedStartDate', startDate.getTime().toString());
			formData.append(
				'expectedCompletionDate',
				endDate.getTime().toString()
			);
			formData.append('isAssignTech', boolToString(assignTech));
			formData.append('isSOW', boolToString(sow));
			formData.append(
				'isCustomerIdentified',
				boolToString(customerIdentified)
			);
			formData.append(
				'isAssetReconciled',
				boolToString(assetReconciliation)
			);
			formData.append('isScheduled', boolToString(scheduling));
			// TODO: These two aren't really IDs, They're just email addresses. Should probably sync w/ the backend to get more appropriate names
			formData.append('techContactId', ITContact);
			formData.append('primaryTechnicianId', primaryContactId);
			formData.append(
				'importFile',
				new Blob([csvFile!.reader!.result!], { type: 'text/csv' }),
				csvFile!.file!.name
			);
			docList.forEach((doc) => {
				if (!doc) return;
				formData.append(
					'resourcesFiles[]',
					new Blob([doc.reader!.result!], { type: doc.file.type }),
					doc.file!.name
				);
			});
			const { method, url } = urls.jobImport;
			const jobRes = await fetch(`${urls.base}${url}`, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
				method,
				body: formData,
			});
			if (!jobRes.ok) {
				setErr(await jobRes.text());
			} else {
				const body = await jobRes.json();
				setSensorCt(body.sensors.length);
				setDocCt(body.jobResources.length);
			}
		} else {
			let bodyData = {
				isCsvTrue: boolToString(isCsvTrue),
				name: name,
				workgroupId: groupId,
				technicianOrganizationId: techOrgId,
				toleranceLimitRule: tolCalRuleValue,
				expectedStartDate: startDate.getTime().toString(),
				isAssignTech: boolToString(assignTech),
				isSOW: boolToString(sow),
				isScheduled: boolToString(scheduling),
				techContactId: ITContact,
				primaryTechnicianId: primaryContactId,
        expectedCompletionDate:endDate.getTime().toString(),
        isCustomerIdentified:boolToString(customerIdentified),
        isAssetReconciled:boolToString(assetReconciliation),
        
			};
			const { method, url } = urls.createJobWithoutCSV;
			const jobRes = await fetch(`${urls.base}${url}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
				},
				body: JSON.stringify(bodyData),
			});
		}
		justSync();
		setIsProcessing(false);
		setCurrentView(View.RESULTS);
	};
	/**
	 * If a Customer has only one workgroup, then set it by default.
	 */
	useEffect(() => {
	

		if (hasOneWorkgroup)
			setGroupId(
				_.find(customerWorkgroups, (workgroup) =>
					workgroup ? true : false
				)?.id || ''
			);
	}, [hasOneWorkgroup, customerWorkgroups]);

	useEffect(() => {
		const now = new Date();
		const month = _.padStart(String(now.getMonth() + 1), 2, '0');
		const date = _.padStart(String(now.getDate()), 2, '0');
		setMinExpectedStartDate(`${now.getFullYear()}-${month}-${date}`);
	}, []);

	return (
		<div className="modalContainer">
			<div className="modalHeader">
				<h2>Create a Job</h2>
			</div>
			{currentView === View.INFO && (
				<>
					<div className="content">
						<label>Name</label>
						<input
							onChange={({ target }) => setName(target.value)}
							value={name}
						/>
						<p>
							Please enter the name in{' '}
							<b className="pName">
								"CustomerName_Region_EquipmentName_date"{' '}
							</b>{' '}
							format{' '}
						</p>
						<div
							className="multiCol"
							style={{ gridTemplateColumns: '1fr 1fr' }}
						>
							<div>
								<label>Customer</label>
								<div className="selectContainer">
									<select
										value={customerId}
										onChange={({ target }) =>
											setCustomerId(target.value)
										}
									>
										<option value="">Select a Customer...</option>
										{_.map(customers, (org) => (
											<option key={org?.id} value={org?.id}>
												{org?.name}
											</option>
										))}
									</select>
								</div>
							</div>
							<div>
								<label>Group</label>
								<div className="selectContainer">
									<select
										disabled={!customerId || hasOneWorkgroup}
										value={groupId}
										onChange={({ target }) =>
											setGroupId(target.value)
										}
									>
										<option value="">Select a Group...</option>
										{_.map(customerWorkgroups, ({ id, name }) => (
											<option key={id} value={id}>
												{name}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>
						<div
							className="multiCol"
							style={{ gridTemplateColumns: '1fr 1fr' }}
						>
							<div>
								<label htmlFor="expected-start-date-input">
									Start Date
								</label>
								<div>
									<input
										className="job-date-picker"
										type="date"
										name="expected-start-date"
										id="expected-start-date-input"
										min={minExpectedStartDate}
										onChange={(e) =>
											setExpectedStartDate(e.target.value)
										}
									/>
								</div>
							</div>
							<div>
								<label htmlFor="expected-end-date-input">
									Completion Date
								</label>
								<div>
									<input
										className="job-date-picker"
										type="date"
										name="expected-end-date"
										id="expected-end-date-input"
										min={expectedStartDate}
										onChange={(e) =>
											setExpectedCompletionDate(e.target.value)
										}
									/>
								</div>
							</div>
						</div>

						<div>
							<h3>Tolerance Calculation Rule</h3>

							<div className="radio">
								<label>
									<input
										type="radio"
										id="tol_cal_rule_sar"
										name="tol_cal_rule"
										value="sar"
										onChange={(e) =>
											changeTolCalRuleValue(e.target.value)
										}
										checked={tolCalRuleValue === 'sar'}
									/>
									Simple Acceptance Rule
								</label>
							</div>
							<div className="radio">
								<label>
									<input
										type="radio"
										id="tol_cal_rule_gbr"
										name="tol_cal_rule"
										value="gbr"
										onChange={(e) =>
											changeTolCalRuleValue(e.target.value)
										}
										checked={tolCalRuleValue === 'gbr'}
									/>
									Guard Banding Rule
								</label>
							</div>
						</div>
						<h3>Workflow</h3>
						<div className="workflow-checkbox-group">
							<input
								type="checkbox"
								id="cust-ident-checkbox"
								checked={customerIdentified}
								onChange={(e) =>
									setCustomerIdentified(e.target.checked)
								}
							/>
							<label htmlFor="cust-ident-checkbox">
								Customer Identified
							</label>
						</div>
						<div className="workflow-checkbox-group">
							<input
								type="checkbox"
								id="asset-recon-checkbox"
								checked={assetReconciliation}
								onChange={(e) =>
									setAssetReconciliation(e.target.checked)
								}
							/>
							<label htmlFor="asset-recon-checkbox">
								Asset Reconciliation
							</label>
						</div>
						<div className="workflow-checkbox-group">
							<input
								type="checkbox"
								id="sow-checkbox"
								checked={sow}
								onChange={(e) => setSow(e.target.checked)}
							/>
							<label htmlFor="sow-checkbox">SOW</label>
						</div>
						<div className="workflow-checkbox-group">
							<input
								type="checkbox"
								id="scheduling-checkbox"
								checked={scheduling}
								onChange={(e) => setScheduling(e.target.checked)}
							/>
							<label htmlFor="scheduling-checkbox">Scheduling</label>
						</div>
						<div className="workflow-checkbox-group">
							<input
								type="checkbox"
								id="assign-tech-checkbox"
								checked={assignTech}
								onChange={(e) => setAssignTech(e.target.checked)}
							/>
							<label htmlFor="assign-tech-checkbox">Assign Tech</label>
						</div>

						<h3>Contact</h3>
						<label>Primary Contact</label>
						<input
							onChange={({ target }) =>
								setPrimaryContactId(target.value)
							}
							value={primaryContactId}
						/>
						<label>IT Contact</label>
						<input
							onChange={({ target }) => setITContact(target.value)}
							value={ITContact}
						/>
						<label>Technician Organization</label>
						<div className="selectContainer">
							<select
								disabled={!groupId}
								value={techOrgId}
								onChange={({ target }) => setTechOrgId(target.value)}
							>
								<option value="">
									Select Technician Organization...
								</option>
								{_.map(manufacturers, ({ id, name }) => (
									<option key={id} value={id}>
										{name}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="footer">
						<button className="cancel" onClick={closeModal}>
							Cancel
						</button>
						<button
							disabled={!isSubmissionReady}
							onClick={() => setCurrentView(currentView + 1)}
						>
							Next
						</button>
					</div>
				</>
			)}
			{currentView === View.IMPORT && (
				<>
					<div className="content">
						<h3>Upload Sensor List</h3>
						<div
							{...csvDropzone.getRootProps()}
							className={`dropzone ${styles.dropzone} ${
								csvDropzone.isDragActive && 'dragActive'
							}`}
						>
							<input {...csvDropzone.getInputProps()} />
							{csvFile ? (
								<>
									<div className={styles.success}>
										<FontAwesomeIcon
											icon="check"
											size="3x"
											color="#06C75F"
										/>
									</div>
									<p>{csvFile?.file.name}</p>
								</>
							) : (
								<FontAwesomeIcon
									icon="file-import"
									size="4x"
									color="#0F7EE3"
								/>
							)}
							<h1>
								<span>Drop</span> your import file here, or{' '}
								<span className="hvrPtr" onClick={csvDropzone.open}>
									browse
								</span>
							</h1>
							<p>CSV files only, Max Size 20 MB</p>
						</div>
					</div>
					<div className="footer">
						<button disabled={isProcessing} onClick={prevView}>
							Back
						</button>
						<button
							disabled={isProcessing}
							onClick={() => handleSubmit(false)}
						>
							Submit without CSV
						</button>
						<button
							disabled={isProcessing || !csvFile}
							onClick={() => handleSubmit(true)}
						>
							Submit with CSV
						</button>
					</div>
					{/* <div className="footer">
            <button onClick={prevView}>Back</button>
            <button disabled={!isSubmissionReady} onClick={nextView}>Next</button>
          </div> */}
				</>
			)}
			{/* {currentView === View.DOCS &&
        <>
          <div className="content">
            <h3>Upload Documents</h3>
            <div {...docDropzone.getRootProps()} className={`${styles.fileContainer} ${docDropzone.isDragActive && 'dragActive'}`}>
              {
                docList.map((doc, idx) => (
                  <div className={styles.fileRow} key={idx}>
                    {doc &&
                      <>
                        <p>{doc.file.name}</p>
                        <FontAwesomeIcon className="hvrPtr" icon="times-circle" color="#8C9299" onClick={() => removeDoc(idx)} />
                      </>
                    }
                  </div>
                ))
              }
            </div>
          </div>
          <div className="footer">
            <button disabled={isProcessing} onClick={prevView}>Back</button>
            <button disabled={isProcessing} onClick={handleSubmit}>Submit</button>
          </div>
        </>
      } */}
			{currentView === View.RESULTS && (
				<>
					<div className={`content ${styles.results}`}>
						{err ? (
							<>
								<div className={styles.failure}>
									<FontAwesomeIcon
										icon={'times'}
										size="3x"
										color={'#E8074A'}
									/>
								</div>
								<h2>Import Error</h2>
								<p>
									<strong>Server Message</strong>
								</p>
								<p>{err}</p>
							</>
						) : (
							<>
								<div className={styles.success}>
									<FontAwesomeIcon
										icon="check"
										size="3x"
										color="#06C75F"
									/>
								</div>
								<h2>Job Summary</h2>
								<p>
									<strong>{sensorCt}</strong> Sensors
								</p>
								<p>
									<strong>{docCt}</strong> Documents
								</p>
							</>
						)}
					</div>
					<div className="footer">
						{err ? (
							<button
								onClick={() => {
									setErr('');
									prevView();
								}}
							>
								Back
							</button>
						) : (
							<div></div>
						)}
						<button onClick={closeModal}>Done</button>
					</div>
				</>
			)}
		</div>
	);
};

export default AddJob;
