import { SyncPushArgs } from '@nozbe/watermelondb/sync';
import urls from 'src/api/urls';
import { isTokenExpired } from 'src/utils/general';
import { database } from '..';
import { forceDbReset } from '../utils';
import { ConvertTo, formatChanges } from './maps';
const { sync, base } = urls;

export default async function pushChanges({ changes, lastPulledAt }: SyncPushArgs, bearer: string) {
  const { push } = sync;
  const { url, method } = push;
  const headers = {
    'Content-Type': 'application/json'
  }
  if (bearer) {
    Object.assign(headers, { Authorization: `Bearer ${bearer}` });
  }
  // TODO: extract hardcoded ConvertTo.EXTERNAL_HEROKU into env var
  const formattedChanges = formatChanges(changes, ConvertTo.EXTERNAL_HEROKU);
  const body = JSON.stringify({
    changes: formattedChanges,
    lastPulledAt
  });
  const pushPromise = fetch(`${base}${url}`, {
    headers,
    method,
    body
  });
  return pushPromise.then(({ status, statusText }) => {
    console.log('status----',status);
    
    if (![200, 400, 404].includes(status)) {
      let doReset = false;
      if (status === 401) {
        const tokenIsExpired = isTokenExpired();
        console.warn('tokenIsExpired: ', tokenIsExpired);
        if (!tokenIsExpired) {
          doReset = window.confirm('Unexpected critical error-- user unauthorized. Press "Cancel" to try again. Press "OK" to confirm a database reset. Any unsaved items will be lost.')
        }
      } else {
        console.error(`Push failed: ${statusText}. Resetting...`);
        doReset = window.confirm('Unexpected critical error. Press "Cancel" to try again. Press "OK" to confirm a database reset. Any unsaved items will be lost.')
      }
      if (doReset) {
        forceDbReset({ database })
      };
    }
  })
}
