import _ from 'lodash';import React, { FC, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StoreContext } from 'src/database/store/StoreProvider';
import { JobsDBEntry, PopColl } from 'src/database/store/StoreTypes';
import { Table, useTable } from 'src/hooks/useTable';
import { getJobProgress, unixToStr } from 'src/utils/general';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import TableHeader, { SortingPackage } from '../TableHeader/TableHeader';
import styles from './jobs.module.css';
import { filterJobRecords } from './JobFilter.util';
import { JobFilterField } from './JobFilter';
// const userId = localStorage.getItem('user-id');
type ColumnKey =
	| 'jobName'
	| 'customer'
	| 'progress'
	| 'sensorCount'
	| 'updated'
	| 'created';

const openJobTable: Table<JobsDBEntry, ColumnKey> = {
	columns: [
		{
			key: 'jobName',
			title: 'Job Name',
			extractFunction: (job) => job.name,
		},
		{
			key: 'customer',
			title: 'Customer',
			extractFunction: (job) => {
				const workgroup = _.chain(job.job_workgroups)
					.find((jwg) => jwg.type === 'customer')
					.value()?.workgroup_id_ref;
				if (!workgroup) return '';
				return `${workgroup.organization_id_ref?.name}, ${workgroup.name}`;
			},
		},
		{
			key: 'progress',
			title: 'Progress',
			extractFunction: getJobProgress,
		},
		{
			key: 'sensorCount',
			title: 'Num. Sensors',
			extractFunction: (job) => _.size(job.job_sensors),
			headerAlign: 'right',
		},
		{
			key: 'updated',
			title: 'Updated',
			extractFunction: (job) => job.updated_at,
			headerAlign: 'right',
		},
		{
			key: 'created',
			title: 'Created',
			extractFunction: (job) => job.created_at,
			headerAlign: 'right',
		},
	],
};

type OpenJobsProps = {
	filteredJobs?: PopColl<JobsDBEntry>;
	filterField?: JobFilterField;
	filterValue?: string;
};

const OpenJobs: FC<OpenJobsProps> = ({
	filteredJobs,
	filterField,
	filterValue,
}) => {
	const history = useHistory();
	const { store } = useContext(StoreContext);
	const { jobs } = store;
	const [openJobs, setOpenJobs] = React.useState<JobsDBEntry[]>([]);
	const [currentSort, setCurrentSort] = useState<ColumnKey>('created');
	const [isAscending, setIsAscending] = useState(false);

	React.useEffect(() => {
		setOpenJobs(
			_.orderBy(
				_.filter(filteredJobs || jobs, (job) => {
					// if (job.status === 'open') {
					// 	if (job.job_technicians) {
					// 		return _.values(job.job_technicians).every((tech) => {
					// 			if (tech && userId == tech.technician_id) {
					// 				if (tech.technician_id_ref) {
					// 					return true;
					// 				}
					// 			}
					// 		});
					// 	}
					// }
					if (job.status === 'open') {
						if (job.job_technicians) {
							return _.values(job.job_technicians).every((tech) => {
								if (tech) {

									if (tech.technician_id_ref) {
										return true;
									}
								} else {
									console.log('No Tech Found');

								}
							});
						}
					}
					return false;
				}),
				['updated_at'],
				['desc']
			)
		);
	}, []);

	const tableData = filterJobRecords(
		useTable(openJobTable, openJobs, currentSort, isAscending),
		filterField || '*',
		filterValue || ''
	);

	const sortingPackage: SortingPackage<ColumnKey> = {
		setCurrentSort,
		currentSort,
		setIsAscending,
		isAscending,
	};

	return (
		<div>
			<TableHeader
				columns={openJobTable.columns}
				rowClassName={styles.openRow}
				sortingPackage={sortingPackage}
			/>
			{tableData.map(
				({
					customer,
					jobName,
					progress,
					sensorCount,
					updated,
					created,
					id,
				}) => (
					<div
						key={id}
						className={`${styles.openRow} hvrPtr row`}
						onClick={() => history.push(`/jobs/${id}`)}
					>
						<p>{jobName}</p>
						<p>{customer}</p>
						<ProgressBar prog={progress} />
						<p style={{ textAlign: 'end' }}>{sensorCount}</p>
						<p style={{ textAlign: 'end' }}>{unixToStr(updated)}</p>
						<p style={{ textAlign: 'end' }}>{unixToStr(created)}</p>
					</div>
				)
			)}
		</div>
	);
};

export default OpenJobs;
