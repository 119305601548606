import _, { compact, divide, filter, map, orderBy, reduce } from 'lodash';
import React, {
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
// var JSZip = require("jszip");
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StoreContext } from 'src/database/store/StoreProvider';
import Modal from 'react-modal';
import styles from '../JobDocumentDetail.module.css';
import { Table, useTable } from 'src/hooks/useTable';
import {
	CalibrationsDBEntry,
	JobResourcesDBEntry,
	JobSensorsDBEntry,
} from 'src/database/store/StoreTypes';
import TableHeader, { SortingPackage } from '../../TableHeader/TableHeader';
import AddSensor from '../../modals/AddSensor/AddSensor';
// import CalibrateSensor from '../../modals/CalibrateSensor/CalibrateSensor';
import Job from 'src/models/Job';
import { database } from 'src/database';
import CheckList from '../../CheckList/CheckList';
import Backout from '../../Backout/Backout';
import AddTech from '../../modals/AddTech/AddTech';
import urls from 'src/api/urls';
import { authCtx } from 'src/api/AuthProvider';
import { SearchBar, useSearch } from '../../SearchBar/SearchBar';
import StatHeader from '../StatHeader';
import {
	createCsvDataUri,
	getActiveTechs,
	getInitials,
	getJobProgress,
	getMostRecentCal,
	getMostRecentCert,
	startLocalDownload,
} from 'src/utils/general';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { FilePkg } from '../../modals/AddJob/AddJob';
import { useDropzone } from 'react-dropzone';
import Dropzone from 'react-dropzone';
// import Notes from '../../modals/Notes/Notes';
import JobSensor from 'src/models/JobSensor';
import { CertificateStatus } from 'src/models/Certificate';
import CertificateBatch, {
	CertificateBatchStatus,
} from 'src/models/CertificateBatch';
import { Q } from '@nozbe/watermelondb';
import { format } from 'date-fns';
import Bugsnag from '@bugsnag/js';
import { JobSensorDetail } from '../tabs/JobSensorDetail';
import path from 'path';
import axios, { AxiosResponse } from 'axios';
import useNavStore from 'src/components/RootNavBarIcon/NavStore';

const POLLING_INTERVAL = parseInt(process.env.POLLING_INTERVAL || '5000');

type ColumnKey =
	| 'check'
	| 'sensorId'
	| 'name'
	| 'group'
	| 'probeType'
	| 'building'
	| 'floor'
	| 'room'
	| 'chamber'
	| 'notes'
	| 'cal'
	| 'cert'
	| 'isDisabled';

enum Tabs {
	SENSORS = 0,
	DOCUMENTS = 1,
	CERTIFICATES = 2,
}

const statusOrder = {
	pending: 0,
	failed: 1,
	valid: 2,
};

const sensorsTable: Table<JobSensorsDBEntry, ColumnKey> = {
	columns: [
		{
			key: 'sensorId',
			title: 'Sensor ID',
			extractFunction: (sensor) =>
				`${sensor.sensor_id}${
					sensor.probe_number > 0 ? '/' + sensor.probe_number : ''
				}`,
		},
		{
			key: 'name',
			title: 'Name',
			extractFunction: (sensor) => sensor.name,
		},
		{
			key: 'group',
			title: 'Group',
			extractFunction: (sensor) => sensor.sensor_group,
		},
		{
			key: 'probeType',
			title: 'Probe Type',
			extractFunction: (sensor) =>
				getMostRecentCal(sensor)?.probe_type_id_ref?.name || '',
		}, //Ask Andrew
		{
			key: 'building',
			title: 'Building',
			extractFunction: (sensor) => sensor.building,
		},
		{
			key: 'floor',
			title: 'Floor',
			extractFunction: (sensor) => sensor.floor,
		},
		{
			key: 'room',
			title: 'Room',
			extractFunction: (sensor) => sensor.room,
		},
		{
			key: 'chamber',
			title: 'Chamber',
			extractFunction: (sensor) => sensor.chamber,
		},
		{
			key: 'notes',
			title: 'Notes',
			extractFunction: (sensor) =>
				sensor.notes || getMostRecentCal(sensor)?.notes || '',
		},
		{
			key: 'cal',
			title: 'Cal.',
			extractFunction: (sensor) => getMostRecentCal(sensor),
			headerAlign: 'center',
			// customSortFunction: (a, b) => statusOrder[getMostRecentCal(a)?.status || 'pending'] - statusOrder[getMostRecentCal(b)?.status || 'pending']
		},
		{
			key: 'cert',
			title: 'Cert.',
			extractFunction: (sensor) => {
				let cal = getMostRecentCal(sensor);
				let cert = getMostRecentCert(cal);
				if (cert) {
					return cert;
				} else {
					return null;
				}
			},
			headerAlign: 'center',
		},
		{
			key: 'isDisabled',
			title: 'Disabled',
			extractFunction: (sensor) => sensor.is_disabled,
		},
	],
};

const sensorCertificateTable: Table<JobSensorsDBEntry, ColumnKey> = {
	columns: [
		{
			key: 'sensorId',
			title: 'Sensor ID',
			extractFunction: (sensor) =>
				`${sensor.sensor_id}${
					sensor.probe_number > 0 ? '/' + sensor.probe_number : ''
				}`,
		},
		{
			key: 'name',
			title: 'Name',
			extractFunction: (sensor) => sensor.name,
		},
		{
			key: 'cert',
			title: 'Cert.',
			extractFunction: (sensor) => {
				let cal = getMostRecentCal(sensor);
				let cert = getMostRecentCert(cal);
				if (cert) {
					return cert;
				} else {
					return null;
				}
			},
			headerAlign: 'center',
		},
	],
};

type JobDetailProps = {};

export const JobDocumentDetail: FC<JobDetailProps> = () => {
	const { isToogle } = useNavStore();
	const [selectedFiles, setSelectedFiles] = useState(false);
	const [refreshDocList, setRefreshDocList] = useState(true);
	const [fileList, setFileList] = useState<any[]>([]);
	const [selectedFileList, setSelectedFileList] = useState<any[]>([]);
	const [progress, setProgress] = useState(false);
	const { accessToken, justSync, isSyncing } = useContext(authCtx);
	const { jobId } = useParams<{ jobId: string }>();
	const { store } = useContext(StoreContext);
	const dbUser = useCurrentUser();
	const history = useHistory();
	const [currentTab, setCurrentTab] = useState(Tabs.SENSORS);
	const [searchQuery, setSearchQuery] = useState('');
	const [checkMap, setCheckMap] = useState<Record<string, boolean>>({});
	const [addSensorModalIsOpen, setAddSensorModalIsOpen] = useState(false);
	const [calibrateModalIsOpen, setCalibrateModalIsOpen] = useState(false);
	const [addTechModalIsOpen, setAddTechModalIsOpen] = useState(false);
	const [sensorId, setSensorId] = useState('');
	const [currentSort, setCurrentSort] = useState<ColumnKey>('name');
	const [isAscending, setIsAscending] = useState(false);
	const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
	const [currentNotes, setCurrentNotes] = useState('');
	const docList = useRef<FilePkg[]>([]);
	const userIsAdmin = useMemo(
		() => dbUser?.role_id_ref.name === 'administrator',
		[dbUser]
	);
	const userIsTech = useMemo(
		() => dbUser?.role_id_ref.name === 'technician',
		[dbUser]
	);
	const userIsCustomer = useMemo(
		() => dbUser?.role_id_ref.name === 'customer',
		[dbUser]
	);
	const [certificateBatch, setCertificateBatch] = useState<CertificateBatch>();
	const [showBatchDetails, setShowBatchDetails] = useState(false);
	const [savedBatchForLoading, setSavedBatchForLoading] =
		useState<CertificateBatch>();
	const [manualIsLoading, setManualIsLoading] = useState(false);
	const job = store.jobs[jobId];
	const sensors = job?.job_sensors;
	const jobResources = orderBy(job?.job_resources, ['updated_at'], ['desc']);
	const [certificateFilterQuery, setCertificateFilterQuery] =
		useState<string>('');
	const queryAndSetCertificateBatch = useCallback(async () => {
		const jobUserBatches = await database.collections
			.get<CertificateBatch>('certificate_batches')
			.query(
				Q.where('job_id', jobId),
				Q.where('user_id', dbUser?.id || ''),
				Q.where('status', Q.notEq(CertificateBatchStatus.Unknown))
			)
			.fetch();

		const sortedBatches = orderBy(jobUserBatches, ['updatedAt'], ['desc']);
		const currentBatch = sortedBatches[0];
		const isSameState =
			savedBatchForLoading?.status === currentBatch?.status &&
			savedBatchForLoading?.id === currentBatch?.id;
		setCertificateBatch(currentBatch);
		if (manualIsLoading && !isSameState) {
			setManualIsLoading(false);
		}
	}, [jobId, dbUser?.id, isSyncing, manualIsLoading, savedBatchForLoading]);
	useEffect(() => {
		if (isSyncing === false) {
			queryAndSetCertificateBatch();
		}
	}, [isSyncing]);
	const batchDownloadStatus = manualIsLoading
		? CertificateBatchStatus.Processing
		: certificateBatch?.status;

	// job_lead_technician_id gets lost on sync pull???
	// const leadTechnician = job?.job_lead_technician_id_ref;

	const leadTechnician = useMemo(
		() =>
			_.find(
				store.job_lead_technicians,
				(leadTech) => leadTech.job_id === jobId
			),
		[jobId, store.job_lead_technicians]
	);
	const isCurrentTechLead =
		leadTechnician?.job_technician_id_ref.technician_id === dbUser?.id;
	const filteredSensors = useSearch(
		sensors,
		searchQuery,
		({ sensor_id, name }, test) => test(sensor_id) || test(name)
	);

	const tableData = useTable(
		sensorsTable,
		filteredSensors,
		currentSort,
		isAscending
	);

	const certHasProcessing = Boolean(
		tableData.find((row) => row.cert?.status === CertificateStatus.Processing)
	);
	const batchHasProcessing =
		Boolean(certificateBatch?.status === CertificateBatchStatus.Processing) ||
		manualIsLoading;
	const hasProcessing = certHasProcessing || batchHasProcessing;
	useEffect(() => {
		justSync();
	}, []);

	// useEffect(() => {
	// 	const interval = setInterval(async () => {
	// 		if (hasProcessing) {
	// 			console.log(`Polling sync started on job: ${job.name}`);
	// 			if (!isSyncing) {
	// 				try {
	// 					justSync();
	// 					if (batchHasProcessing) {
	// 						await queryAndSetCertificateBatch();
	// 					}
	// 				} catch (err) {
	// 					Bugsnag.notify(err as any);
	// 					console.error('JobDetail useEffect', err);
	// 				}
	// 			} else {
	// 				console.log('Ongoing sync detected. Skipping sync...');
	// 			}
	// 		}
	// 	}, POLLING_INTERVAL);
	// 	return () => clearInterval(interval);
	// }, [certHasProcessing, batchHasProcessing, hasProcessing, isSyncing]);

	const sortingPackage: SortingPackage<ColumnKey> = {
		setCurrentSort,
		currentSort,
		setIsAscending,
		isAscending,
	};

	const changeJobStatus = useCallback(
		async (value: string) => {
			const job = await database.collections.get<Job>('jobs').find(jobId);
			switch (value) {
				case 'pending':
					await job.resetJobStatus();
					return justSync();
				case 'open':
					await job.openJob();
					return justSync();
				case 'closed':
					await job.closeJob();
					return justSync();
			}
		},
		[jobId]
	);

	/**
	 * Adds a new resource to the job's document list.
	 */

	const addResource = useCallback(
		async (file: File, reader: FileReader) => {
			const formData = new FormData();
			formData.append(
				'resourcesFiles',
				new Blob([reader!.result!], { type: file.type }),
				file!.name
			);
			// await fetch(`${urls.base}/jobs/${jobId}/resources`, {
			//   method: 'POST',
			//   headers: {
			//     'Authorization': `Bearer ${accessToken}`,
			//   },
			//   body: formData,
			// });

			// await fetch(`${urls.base}/jobs/${jobId}/resources`, {
			//   method: 'POST',
			//   headers: {
			//     'Authorization': `Bearer ${accessToken}`,
			//   },
			//   body: formData,
			// });
			return;
			let s3api =
				'https://trivediat.atlassian.net/browse/OP-119?focusedCommentId=21230';
			let S3Ready = await fetch(`${s3api}`, {
				method: 'GET',
			});
			justSync();
		},
		[jobId, accessToken]
	);

	/**
	 * Handles adding files to the document list.
	 */
	let files: File[] = [];
	const onDrop = useCallback((acceptedFiles: File[]) => {
		if (!acceptedFiles.length) return;
		setSelectedFiles(true);
		files = acceptedFiles;
		setSelectedFileList(files);
		console.log('File Loaded...........');
	}, []);
	function removeSelectedFile(fileName: any) {
		let arr;
		arr = selectedFileList.filter((item) => item.name !== fileName);
		onDrop(arr);
		if (arr.length < 1) setSelectedFiles(false);
	}
	const dropzone = useDropzone({ onDrop });

	const uploadToS3 = useCallback(async () => {
		if (!files.length) return;
		setProgress(true);
		let fileNameArr: any[] = [];
		for (let i = 0; i < files.length; i++) {
			fileNameArr.push({ filename: files[i].name });
		}

		let bodyData = {
			files: fileNameArr,
			jobid: jobId,
		};

		try {
			axios({
				// url: urls.uploadFileAwsApi,
				url: `${urls.documentUploadbase}${urls.documents.upload.url}`,
				method: urls.documents.upload.method,
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
				data: JSON.stringify(bodyData),
			})
				.then((res) => {
					let resData = res.data;
					if (res && resData.length > 0) {
						for (let j = 0; j < resData.length; j++) {
							axios({
								url: resData[j].url,
								method: 'PUT',
								headers: {
									'Content-Type': 'application/pdf',
									Accept: '*/*',
								},
								data: files[j],
							});
						}
					}
				})
				.catch((e) => {
					console.log(e);
				})
				.finally(() => {
					setRefreshDocList(true);
				});
		} catch (e) {
			console.log('Error whilw uploading files in S3', e);
		}

		setSelectedFiles(false);
	}, []);
	const S3DocListData = useEffect(() => {
		if (refreshDocList)
			try {
				const S3DocList: any = axios({
					// url: urls.listFileAwsApi + `${jobId}`,
					// method: 'GET',
					url: `${urls.documentUploadbase}${urls.documents.list.url}?jobid=${jobId}`,
					method: urls.documents.list.method,
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				});
				S3DocList.then((result: { data: any[] }) => {
					setFileList(result.data);
				});
			} catch (err) {
				console.error(err);
			}
		setProgress(false);
		setRefreshDocList(false);
	}, [refreshDocList]);

	const removeResource = useCallback(
		async (resource: JobResourcesDBEntry) => {
			await fetch(`${urls.base}/jobs/${jobId}/resources/${resource.id}`, {
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});
			justSync();
		},
		[jobId, accessToken]
	);

	const getResource = useCallback((resource: JobResourcesDBEntry) => {
		window.open(resource.url, '_blank');
	}, []);

	/**
	 * Generates CSV data by calling the backend API.
	 */
	const getCsvData = useCallback(async () => {
		console.log('fetching csv...');
		const { base, jobExport } = urls;
		const csvData = await fetch(base + jobExport.url(jobId), {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
			method: jobExport.method,
		}).then((res) => res.text());

		return csvData;
	}, [jobId, accessToken]);

	/**
	 * Certifies a sensor and creates the PDF certificate.
	 */
	const handleCertifyOne = useCallback(
		async (cal: CalibrationsDBEntry) => {
			let fileUrl = getMostRecentCert(cal)?.file_url;
			if (!fileUrl) {
				try {
					const body = await fetch(
						`${urls.base}/calibrations/${cal.id}/certificates/priority`,
						{
							headers: { Authorization: `Bearer ${accessToken}` },
							method: 'POST',
						}
					).then((res) => res.json());
					fileUrl = body.certificateUrl;
					justSync();
				} catch (e) {
					Bugsnag.notify(e as any);
					return console.log(e);
				}
			} else {
				// Already certified so open the download URL
				window.open(fileUrl, '_blank');
			}
		},
		[accessToken]
	);

	const getSelectedCalibrations = useCallback(
		(
			params: {
				skipDisabled?: boolean;
				validSensorsOnly?: boolean;
			} = {}
		) => {
			const { skipDisabled = true, validSensorsOnly = true } = params;
			const sensors = compact(
				map(checkMap, (selected, id) => {
					if (selected) {
						const cal = getMostRecentCal(store.job_sensors[id]);
						let isValid = true;
						if (validSensorsOnly) {
							if (cal?.status !== 'valid') {
								isValid = false;
							}
						}
						if (!isValid) {
							return;
						}
						const sensor = store.job_sensors[id];
						if (skipDisabled) {
							if (sensor.is_disabled) {
								return;
							}
						}
						return sensor;
					}
				})
			);
			const calibrations = compact(
				sensors.map((sensor) => {
					const cal = getMostRecentCal(sensor);
					if (cal?.status === 'valid') {
						return cal;
					}
				})
			);
			return {
				sensors,
				calibrations,
			};
		},
		[checkMap, store.job_sensors, getMostRecentCal]
	);

	const saveZip = (filename: string | undefined, urls: any[]) => {
		if (!urls) return;
		const zip = new JSZip();
		const folder = zip.folder('SensorDocument');
		urls.forEach((data) => {
			const blobPromise = fetch(data.url).then((r) => {
				if (r.status === 200) return r.blob();
				return Promise.reject(new Error(r.statusText));
			});
			const name = data.filename;
			folder?.file(name, blobPromise);
		});
		zip.generateAsync({ type: 'blob' }).then((blob) =>
			saveAs(blob, filename)
		);
	};
	const downloadAllDoc = async () => {
		saveZip('SensorAllUploadedDoc.zip', fileList);
	};

	const handleCloseJob = useCallback(async () => {
		const csvData = await getCsvData();
		const formData = new FormData();
		formData.append(
			'resourcesFiles',
			new Blob([csvData], { type: 'text/csv' }),
			`${job.name}.csv`
		);
		await fetch(`${urls.base}/jobs/${jobId}/resources`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			body: formData,
		});
		changeJobStatus('closed');
	}, [jobId, accessToken, job?.name, getCsvData, changeJobStatus]);

	/**
	 * Deletes a job and redirects to the jobs page.
	 */
	const handleDeleteJob = useCallback(async () => {
		const { jobDelete } = urls;
		await fetch(`${urls.base}${jobDelete.url}/${jobId}`, {
			method: jobDelete.method,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});
		justSync();
		history.push('/jobs');
	}, [jobId, accessToken, history]);

	const handleCalibrateModalOpen = useCallback(async (id) => {
		setSensorId(id);
		setCalibrateModalIsOpen(true);
	}, []);

	const handleNotesModalOpen = useCallback((id, notes) => {
		setSensorId(id);
		setCurrentNotes(notes);
		setIsNotesModalOpen(true);
	}, []);

	const enabledMap = useMemo(() => {
		const enabledMap: { [id: string]: boolean } = {};
		_.forEach(tableData, ({ id }) => {
			enabledMap[id] = true;
		});
		return enabledMap;
	}, [tableData]);

	const isJobDone = useMemo(() => getJobProgress(job) >= 1, [job]);
	const activeTechs = useMemo(() => getActiveTechs(job), [job]);
	const batchDownloadStyle = useMemo(() => {
		const styles: any[] = [];
		if (batchDownloadStatus === CertificateBatchStatus.Completed) {
			styles.push({ cursor: 'pointer' });
			if (showBatchDetails) {
				styles.push({ borderColor: '#06c75f' });
			}
		}
		return reduce(
			styles,
			(acc, style) => {
				return {
					...acc,
					...style,
				};
			},
			{}
		);
	}, [batchDownloadStatus, showBatchDetails]);

	const certificateBatchDate = certificateBatch?.updatedAt
		? format(new Date(certificateBatch?.updatedAt), 'MMMM dd, yyyy, h:mm a')
		: format(new Date(), 'mm/dd/yy');
	const certificateBatchSensorCount = certificateBatch?.calibrationIds
		? JSON.parse(certificateBatch.calibrationIds).length
		: 0;
	const tooltipString = useMemo(() => {
		if (batchDownloadStatus === CertificateBatchStatus.Processing) {
			return `${certificateBatchSensorCount} sensors (started on: ${certificateBatchDate})`;
		} else if (batchDownloadStatus === CertificateBatchStatus.Failed) {
			return `${certificateBatchSensorCount} sensors (failed on: ${certificateBatchDate}). If the problem persists, please recertify and try again.`;
		} else {
			return `${certificateBatchSensorCount} sensors (${certificateBatchDate})`;
		}
	}, [certificateBatchSensorCount, certificateBatchDate, batchDownloadStatus]);
	const selectedSensorCount = getSelectedCalibrations().calibrations.length;
	const disableActions =
		batchDownloadStatus === CertificateBatchStatus.Processing ||
		certHasProcessing;

	if (!job || !sensors) return null;

	// delete one
	const deleteOne = async (data: any) => {
		console.log('data', data);

		let ids = data.url.split('/');
		if (window.confirm(`Are You Sure To Delete ${data.filename}`)) {
			let bodyData = {
				jobid: jobId,
				filename: data.filename,
				// id: ids[4],
				id: data.id,
			};
			// console.log('bodyData-------------', bodyData);
			// fetch

			try {
				// let res=	await fetch(`${urls.deleteFileAwsApi}`, {
				// 	headers: {
				// 		Authorization: `Bearer ${accessToken}`,
				// 		'Content-Type': 'application/json',
				// 	},
				// 	method:"DELETE",
				// 	body: JSON.stringify(bodyData),
				// })
				// let res=	await fetch(`${urls.documentUploadbase}${urls.documents.delete.url}`, {
				// 	headers: {
				// 		Authorization: `Bearer ${accessToken}`,
				// 		'Content-Type': 'application/json',
				// 	},
				// 	method:urls.documents.delete.method,
				// 	body: JSON.stringify(bodyData),
				// })
				// console.log("res",res);
				// setRefreshDocList(true);
				// justSync();

				const deleteDocumentResponse: any = axios({
					url: `${urls.documentUploadbase}${urls.documents.delete.url}`,
					method: urls.documents.delete.method,
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
					data: JSON.stringify(bodyData),
				});
				deleteDocumentResponse.then((result: { data: any[] }) => {
					setRefreshDocList(true);
					// justSync();
				});
			} catch (e) {
				console.log(
					`Error while Deleting File:${data.filename} from S3`,
					e
				);
			}
			// axios
			// try {
			// 	axios({
			// 		url: urls.deleteFileAwsApi,
			// 		method: 'DELETE',
			// 		headers: {
			// 			Authorization: `Bearer ${accessToken}`,
			// 		},
			// 		data: JSON.stringify(bodyData),
			// 	})
			// 		.then((res) => {
			// 			let resData = res.data;
			// 	console.log('DELETE res  Data',resData);

			// 		})
			// 		.catch((e) => {
			// 			console.log("Error While Deleting File :::::::::::::",e);
			// 		})
			// 		.finally(() => {
			// 			setRefreshDocList(true);
			// 		});
			// } catch (e) {
			// 	console.log("Error While Deleting File :::::::::::::",e);
			// }
		}
	};
	return (
		<div style={{ marginLeft: isToogle === 'YES' ? '90px' : '' }}>
			{!userIsCustomer && (
				<>
					<div>
						<button
							className={styles.uploadFile}
							disabled={!selectedFiles}
							onClick={() => uploadToS3()}
						>
							Upload Files
						</button>
					</div>
					<div>
						{progress ? (
							<div className={styles.loaderContainer}>
								<div className={styles.loader}></div>
							</div>
						) : (
							<Dropzone onDrop={onDrop} multiple={true}>
								{({ getRootProps, getInputProps }) => (
									<section>
										<div
											{...getRootProps({
												className: styles.emptyDrop,
											})}
										>
											Drag and drop file here, or click to select
											file
											{/* <input {...getInputProps()} /> */}
											<FontAwesomeIcon
												className={styles.emptyDropFIleIcon}
												icon="file-import"
												size="4x"
												color="#0F7EE3"
											/>
											<p>PDF Only</p>
										</div>
									</section>
								)}
							</Dropzone>
						)}
					</div>
				</>
			)}
			{selectedFiles && (
				<div>
					<ol>
						<h3 className={styles.tableTitle}>Files Ready To Upload </h3>
						{_.map(selectedFileList, (file) => {
							return (
								<li
									onClick={() => removeSelectedFile(file.name)}
									key={file.name}
									className="FilesReadyToUpload"
								>
									<FontAwesomeIcon
										className={styles.openFileIcon}
										icon="trash"
										size="1x"
										color="red"
									/>
									{file.name}
								</li>
							);
						})}
					</ol>
				</div>
			)}

			<div>
				<div className={styles.TableHeader}>
					<p>Files</p>
					<p>
						<button
							onClick={() => downloadAllDoc()}
							disabled={fileList.length < 1}
						>
							Download All
						</button>
					</p>
				</div>
				{fileList && fileList.length > 0 ? (
					<div>
						{_.map(fileList, (data) => {
							return (
								<table>
									<tbody>
										<tr key={data.url} className={styles.row}>
											<td
												onClick={() =>
													window.open(
														data.url,
														'_blank',
														'noopener,noreferrer'
													)
												}
											>
												{data.filename}
												<FontAwesomeIcon
													className={styles.openFileIcon}
													icon="folder-open"
													size="1x"
													color="#0F7EE3"
												/>
											</td>
											{
									!userIsCustomer && <td>
									<FontAwesomeIcon
										onClick={() => deleteOne(data)}
										className={styles.deteteFile}
										icon="trash"
										size="1x"
										color="#0F7EE3"
									/>
								</td>
								}
											
										</tr>
									</tbody>
								</table>
							);
						})}
					</div>
				) : (
					<h2>No Files Uploaded</h2>
				)}
			</div>
		</div>
	);
};
