import _ from 'lodash';
import React, { FC, useCallback, useContext, useState } from 'react';
import { authCtx } from 'src/api/AuthProvider';
import { StoreContext } from 'src/database/store/StoreProvider';
import urls from 'src/api/urls';
import Tool from 'src/models/Tool';
import { database } from 'src/database';
import { ToolsDBEntry } from 'src/database/store/StoreTypes';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './AddEditTools.module.css';
import { useEffect } from 'react';
type AddEditTools = {
	closeModal: () => void;
	toolId: string | null;
};

const AddEditTools: FC<AddEditTools> = ({ closeModal, toolId }) => {
	const { accessToken, justSync } = useContext(authCtx);
	const { store } = useContext(StoreContext);
	let tool;
	if (toolId) tool = store.tools[toolId];
	const [ID, setID] = useState(tool?.id ?? '');
	const [name, setName] = useState(tool?.name ?? '');
	const [toolType, setToolType] = useState(tool?.tool_type ?? '');
	const [description, setDescription] = useState(tool?.description ?? '');
	// const [tolerance, setTolerance] = useState(tool?.tolerance ?? null);
	const [toleranceUnit, setToleranceUnit] = useState(
		tool?.tolerance_unit ?? ''
	);
	const [isProcessing, setIsProcessing] = useState(false);
	const [reValidateRangeValue, setReValidateRangeValue] = useState(false);
	const [rangeCalFilled, setRangeCalFilled] = useState(true);
	const [uncertaintyRangeList, setUncertaintyRangeList] = useState<any[]>(
		tool?.uncertainty_range_list
			? JSON.parse(tool?.uncertainty_range_list)
			: [
					{
						lowerRange: '',
						upperRange: '',
						uom: toleranceUnit ?? null,
						uncertainty: '',
					},
			  ]
	);
	function update_uom(uom: string) {
		let arr_on_uomChange = uncertaintyRangeList.map((data) => {
			return { ...data, uom: uom };
		});
		setUncertaintyRangeList(arr_on_uomChange);
		let uomNode: any = document.getElementsByClassName(`UOM`);
		for (let i = 0; i < uomNode.length; i++) {
			uomNode[i].value = uom;
		}
	}
	const isSubmissionReady =
		name && toolType && description && toleranceUnit && rangeCalFilled;
	function updateTangeValueOnChange(currentIndex: number, data?: any) {
		const arr = [...uncertaintyRangeList];
		arr[currentIndex] = data;
		setUncertaintyRangeList(arr);
		setReValidateRangeValue(!reValidateRangeValue);
	}

	function addToRangeArr(currentIndex: number, action: any, data?: any) {
		// console.log('data===', data);
		// console.log('currentIndex===', currentIndex);
		if (action === 'pop' && uncertaintyRangeList.length > 1) {
			let f = uncertaintyRangeList.filter((item, i) => i !== currentIndex);
			// console.log('f=', f);
			setUncertaintyRangeList(() => f);
			setReValidateRangeValue(!reValidateRangeValue);
		} else if (action === 'push') {
			data.uom = toleranceUnit;

			if (!data.lowerRange) {
				alert(`Lower Range Can't Be Blank!`);
				return;
			}
			if (!data.upperRange) {
				alert(`Upper Range Can't Be Blank!`);
				return;
			}
			if (!data.uom) {
				alert(`UOM Can't Be Blank!`);
				return;
			}

			if (!data.uncertainty) {
				alert(`Uncertainty Can't Be Blank!`);
				return;
			}
			if (isNaN(data.lowerRange)) {
				alert(`Lower Range Can't Be String!`);
				return;
			}
			if (isNaN(data.upperRange)) {
				alert(`Upper Range Can't Be String!`);
				return;
			}
			if (isNaN(data.uncertainty)) {
				alert(`Uncertainty Can't Be String!`);
				return;
			}
			if (data.lowerRange && data.upperRange) {
				if (Number(data.lowerRange) > Number(data.upperRange)) {
					alert(
						`Upper Range Value is Always Greater Than Lower Range Value!`
					);
					return;
				}
				if (Number(data.lowerRange) === Number(data.upperRange)) {
					alert(`Upper Range  & Lower Range Value Can't Be Same!`);
					return;
				}
			}

			let dummyArrData = {
				lowerRange: '',
				upperRange: '',
				uom: toleranceUnit ?? null,
				uncertainty: '',
			};
			const arr = [...uncertaintyRangeList, dummyArrData];
			arr[currentIndex] = data;
			setUncertaintyRangeList(arr);
		} else {
			return;
		}
		setReValidateRangeValue(!reValidateRangeValue);
	}
	const validateRangeValue = useEffect(() => {
		uncertaintyRangeList.every((data, i) => {
			if (
				!data['lowerRange'] ||
				!data['upperRange'] ||
				!data['uom'] ||
				!data['uncertainty']
			) {
				console.log(`Values Can't Be Blank!`);
				setRangeCalFilled(false);
				return false;
			} else if (
				isNaN(data['uncertainty']) ||
				isNaN(data['lowerRange']) ||
				isNaN(data['upperRange'])
			) {
				console.log(`Can't Be String!`);
				setRangeCalFilled(false);
				return false;
			} else if (Number(data['lowerRange']) > Number(data['upperRange'])) {
				console.log(
					`Upper Range Value is Always Greater Than Lower Range Value!`
				);
				setRangeCalFilled(false);
				return false;
			} else if (Number(data['lowerRange']) === Number(data['upperRange'])) {
				console.log(`Upper Range  & Lower Range Value Can't Be Same!`);
				setRangeCalFilled(false);
				return false;
			} else {
				setRangeCalFilled(true);
				return true;
			}
		});
	}, [uncertaintyRangeList, reValidateRangeValue]);
	const handleAddEdit = useCallback(async () => {
		if (!isSubmissionReady) return;
		// console.log('uncertaintyRangeList.length',uncertaintyRangeList.length);

		for (let i = 0; i < uncertaintyRangeList.length; i++) {
			const element = uncertaintyRangeList[i];
			let uperVal = Number(element['upperRange']);
			let lowerVal = Number(element['lowerRange']);

			if (uncertaintyRangeList.length > 1 && i > 0) {
				for (let j = 0; j < i; j++) {
					const el = uncertaintyRangeList[j];
					if (
						((lowerVal < Number(el['lowerRange']) &&
							lowerVal < Number(el['upperRange'])) ||
							(lowerVal > Number(el['lowerRange']) &&
								lowerVal > Number(el['upperRange']))) &&
						((uperVal < Number(el['lowerRange']) &&
							uperVal < Number(el['upperRange'])) ||
							(uperVal > Number(el['lowerRange']) &&
								uperVal > Number(el['upperRange'])))
					) {
						console.log('el----ok---');
					} else {
						console.log('isuue----', i + 1);

						setReValidateRangeValue(!reValidateRangeValue);
						alert(
							`There is a mismatch in the lower or the upper range set for the tolerance ranges ${
								j + 1
							} and   Please select a values for the range which is not already set`
						);
						return;
					}
				}
			}
		}
		// return;
		setIsProcessing(true);
		let bodyData = {
			name: `${name}`,
			toolType: `${toolType}`,
			description: `${description}`,
			tolerance: `${null}`,
			toleranceUnit: `${toleranceUnit}`,
			uncertaintyRangeList: `${JSON.stringify(uncertaintyRangeList)}`,
		};

		await (async () => {
			if (!ID) {
				try {
					console.log('ADD ONLY');
					const { url, method } = urls.addTools;
					await fetch(`${urls.base}${url}`, {
						method,
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${accessToken}`,
						},
						body: JSON.stringify(bodyData),
					}).then(() => {
						toast.success(`Tool:${name} Added Successfull`, {
							autoClose: 5000,
						});
					});
				} catch (err) {
					console.log('Error While Adding New Tool', err);
					toast.error(`Error While Adding New Tool`, { autoClose: 2000 });
				}
			} else {
				console.log('Update  ONLY');
				await (async () => {
					try {
						const toolModel = await database.collections
							.get<Tool>('tools')
							.find(ID);
						await toolModel
							.updateToolDetails(bodyData as any)
							.then(() => {
								toast.success(`Tool:${name} Updated Successfull`, {
									autoClose: 5000,
								});
							});
					} catch (err) {
						console.log('Error Updating While Tool', err);
						toast.error(`Error Updating While Tool `, {
							autoClose: 2000,
						});
					}
				})();
				// const { url, method } = urls.updateTool;
				// console.log(`Update URL--------${urls.base}${url}${ID}`);
				// await fetch(`${urls.base}${url}${ID}`, {
				// 	method,
				// 	headers: {
				// 		'Content-Type': 'application/json',
				// 		Authorization: `Bearer ${accessToken}`,
				// 	},
				// 	body: JSON.stringify(bodyData),
				// });
			}
			justSync();
			setIsProcessing(false);
			closeModal();
		})();
	}, [
		ID,
		name,
		toolType,
		description,
		// tolerance,
		toleranceUnit,
		isSubmissionReady,
		closeModal,
		reValidateRangeValue,
		uncertaintyRangeList,
	]);
	return (
		<div className="modalContainer" >
			<div className="modalHeader">
				<h2> {ID ? 'Edit' : 'Add'} Tool</h2>
			</div>
			<div className="content">
				<label>Tool Details</label>

				<div
					className="multiCol"
					style={{ gridTemplateColumns: '2fr 2fr' }}
				>
					<div>
						<label>Name</label>
						<input
						
							defaultValue={name}
							onChange={({ target }) => setName(target.value)}
						/>
					</div>
					<div >
						<label>Tool Type</label>
						<div className="selectContainer">
						<select
							defaultValue={toolType}
							onChange={({ target }) => setToolType(target.value)}
						>
							<option value="">Select Tool Type...</option>
							<option value="Temperature">Temperature</option>
							<option value="Humidity">Humidity</option>
							<option value="Simulated Temperature">
								Simulated Temperature
							</option>
						</select>
						</div>
					
					</div>
				</div>

				<div
					className="multiCol"
					style={{ gridTemplateColumns: '2fr  2fr' }}
				>
					<div>
						<label>Description</label>
						<input
						
							defaultValue={description}
							onChange={({ target }) => setDescription(target.value)}
						/>
					</div>
					{/* <div>
						<label>Tolerance</label>
						<input
							placeholder="0.00"
							onBlur={({ target }) =>
								setTolerance(Number(target.value))
							}
							defaultValue={tolerance ? Number(tolerance) : ''}
						/>
					</div> */}
					<div>
						<label>Tolerance Unit</label>
						<div className="selectContainer">
							<select
								defaultValue={toleranceUnit}
								onChange={(e) => {
									setToleranceUnit(e.target.value);
									update_uom(e.target.value);
								}}
							>
								<option value="">Tolerance Unit...</option>
								<option value="C">C</option>
								<option value="F">F</option>
								<option value="%">%</option>
							</select>
						</div>
					</div>
				</div>
				<div>
					{toleranceUnit && (
						<>
							<table className={styles.ruleTable}>
								<thead>
									<tr>
										<th>Sr. No.</th>
										<th>Lower Range</th>
										<th>Upper Range</th>
										<th>UOM</th>
										<th>Uncertainty</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{uncertaintyRangeList.map((data, index) => {
										return (
											<tr key={index}>
												<td>{index + 1}</td>

												<td>
													<input
														type="text"
														defaultValue={data.lowerRange ?? null}
														onChange={(event) => {
															data.lowerRange =
																event.target.value;
															updateTangeValueOnChange(
																index,
																data
															);
															setReValidateRangeValue(
																!reValidateRangeValue
															);
														}}
													/>
												</td>
												<td>
													<input
														type="text"
														defaultValue={data.upperRange ?? null}
														onChange={(event) => {
															data.upperRange =
																event.target.value;
															updateTangeValueOnChange(
																index,
																data
															);
															setReValidateRangeValue(
																!reValidateRangeValue
															);
														}}
													/>
												</td>
												<td>
													<input
														className="UOM"
														defaultValue={
															data?.uom ?? toleranceUnit
														}
														disabled
														type="text"
														onChange={(event) => {
															data.uom = event.target.value;
															updateTangeValueOnChange(
																index,
																data
															);
															setReValidateRangeValue(
																!reValidateRangeValue
															);
														}}
													/>
												</td>
												<td>
													<input
														defaultValue={data.uncertainty}
														onChange={(event) => {
															data.uncertainty = parseFloat(
																event.target.value
															).toFixed(2);
															updateTangeValueOnChange(
																index,
																data
															);
															setReValidateRangeValue(
																!reValidateRangeValue
															);
														}}
														type="text"
													/>
												</td>

												<td>
													<button
														onClick={() =>
															addToRangeArr(index, 'push', data)
														}
													>
														+
													</button>
													<button
														onClick={() =>
															addToRangeArr(index, 'pop')
														}
													>
														<FontAwesomeIcon
															icon="trash"
															color="red"
															cursor="pointer"
														/>
													</button>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</>
					)}
				</div>
			</div>
			<div className="footer">
				<button
					disabled={isProcessing}
					className="cancel"
					onClick={closeModal}
				>
					Cancel
				</button>
				<button
					disabled={!isSubmissionReady || isProcessing}
					onClick={handleAddEdit}
				>
					Submit
				</button>
			</div>
		</div>
	);
};

export default AddEditTools;
