import { HasManyAssociation } from "@nozbe/watermelondb/Model";
import { writer, children, field } from "@nozbe/watermelondb/decorators";
import { Query } from "@nozbe/watermelondb";
import { Association } from "src/types/watermelon";
import BaseModel from "./BaseModel";
import TechnicianTool, * as TechnicianTool_1 from "./TechnicianTool";
import User from "./User";
import ProbeType from "./ProbeType";
type ToleranceUnit = 'C' | 'F' | '%';
export default class Tool extends BaseModel {
  static table = "tools";
  static associations = {
    technician_tools: {
      type: Association.HAS_MANY,
      foreignKey: "tool_id",
    } as HasManyAssociation,
    probe_types: {
      type: Association.HAS_MANY,
      foreignKey: "tool_id",
    } as HasManyAssociation,
  };
  getTableName() {
    return Tool.table;
  }
  @field("name") name!: string;
  @field("uncertainty_range_list") uncertaintyRangeList!: string;
  @field("tool_type") toolType!: string;
  @field("nickname") nickname!: string;
  @field("description") description!: string | null;
  @field("tolerance") tolerance!: number;
  @field("tolerance_unit") toleranceUnit!: ToleranceUnit;
  @children("technician_tools") technicianTools!: Query<TechnicianTool>;
  @children("probe_types") probeTypes!: Query<ProbeType>;
  @writer async checkoutTool(technicianTool: TechnicianTool_1.TechnicianToolUpdateBody, technician: User) {
    await this.collections.get<TechnicianTool>('technician_tools').create(techTool => {
      Object.assign(techTool, technicianTool);
      techTool.tool.set(this);
      techTool.technician.set(technician);
    })
  }

  @writer async updateToolDetails(toolDetails: UpdateDetailsParams) {
    return this.update((tool) => {
      tool.name = toolDetails.name
      tool.uncertaintyRangeList = toolDetails.uncertaintyRangeList
      tool.toolType = toolDetails.toolType
      tool.nickname = (toolDetails.name).replace(/ /g,'')
      tool.description = toolDetails.description
      tool.tolerance = Number(toolDetails.tolerance)
      tool.toleranceUnit = toolDetails.toleranceUnit
    })
  }

}

type UpdateDetailsParams = {
  name: string;
  uncertaintyRangeList: string;
  toolType: string;
  description: string;
  tolerance: number;
  toleranceUnit: ToleranceUnit;
  nickname: null | string;
}
