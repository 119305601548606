import _, { orderBy } from 'lodash';
import React, {
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StoreContext } from 'src/database/store/StoreProvider';
import Modal from 'react-modal';
import styles from './jobDetail.module.css';
import { Table, useTable } from 'src/hooks/useTable';
import {
	CalibrationsDBEntry,
	JobSensorsDBEntry,
} from 'src/database/store/StoreTypes';
import AddSensor from '../modals/AddSensor/AddSensor';
// import CalibrateSensor from '../modals/CalibrateSensor/CalibrateSensor';
import Job from 'src/models/Job';
import { database } from 'src/database';
import Backout from '../Backout/Backout';
import AddTech from '../modals/AddTech/AddTech';
import urls from 'src/api/urls';
import { authCtx } from 'src/api/AuthProvider';
import { SearchBar, useSearch } from '../SearchBar/SearchBar';
import {
	getActiveTechs,
	getInitials,
	getJobProgress,
	getMostRecentCal,
	getMostRecentCert,
	startLocalDownload,
} from 'src/utils/general';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { useDropzone } from 'react-dropzone';
import { CertificateStatus } from 'src/models/Certificate';
import CertificateBatch, {
	CertificateBatchStatus,
} from 'src/models/CertificateBatch';
import { Q } from '@nozbe/watermelondb';
import Bugsnag from '@bugsnag/js';
import { JobDetailTabs, JobDetailTabView } from './tabs/index';
import useNavStore from '../RootNavBarIcon/NavStore';

type ColumnKey =
	| 'check'
	| 'sensorId'
	| 'name'
	| 'group'
	| 'probeType'
	| 'building'
	| 'floor'
	| 'room'
	| 'chamber'
	| 'notes'
	| 'cal'
	| 'cert'
	| 'isDisabled';
const POLLING_INTERVAL = parseInt(process.env.POLLING_INTERVAL || '5000');

const statusOrder = {
	pending: 0,
	failed: 1,
	valid: 2,
};

const sensorsTable: Table<JobSensorsDBEntry, ColumnKey> = {
	columns: [
		{
			key: 'sensorId',
			title: 'Sensor ID',
			extractFunction: (sensor) =>
				`${sensor.sensor_id}${
					sensor.probe_number > 0 ? '/' + sensor.probe_number : ''
				}`,
		},
		{
			key: 'name',
			title: 'Name',
			extractFunction: (sensor) => sensor.name,
		},
		{
			key: 'group',
			title: 'Group',
			extractFunction: (sensor) => sensor.sensor_group,
		},
		{
			key: 'probeType',
			title: 'Probe Type',
			extractFunction: (sensor) =>
				getMostRecentCal(sensor)?.probe_type_id_ref?.name || '',
		}, //Ask Andrew
		{
			key: 'building',
			title: 'Building',
			extractFunction: (sensor) => sensor.building,
		},
		{
			key: 'floor',
			title: 'Floor',
			extractFunction: (sensor) => sensor.floor,
		},
		{
			key: 'room',
			title: 'Room',
			extractFunction: (sensor) => sensor.room,
		},
		{
			key: 'chamber',
			title: 'Chamber',
			extractFunction: (sensor) => sensor.chamber,
		},
		{
			key: 'notes',
			title: 'Notes',
			extractFunction: (sensor) =>
				sensor.notes || getMostRecentCal(sensor)?.notes || '',
		},
		{
			key: 'cal',
			title: 'Cal.',
			extractFunction: (sensor) => getMostRecentCal(sensor),
			// headerAlign: 'center',
			// customSortFunction: (a, b) => statusOrder[getMostRecentCal(a)?.status || 'pending'] - statusOrder[getMostRecentCal(b)?.status || 'pending']
		},
		{
			key: 'cert',
			title: 'Cert.',
			extractFunction: (sensor) =>
				getMostRecentCert(getMostRecentCal(sensor)),
			customSortFunction: (a, b) => {
				let aCal = getMostRecentCal(a);
				let aCert = getMostRecentCert(aCal);
				let aVal = aCal?.status !== 'valid' ? 0 : !aCert ? 1 : 2;
				let bCal = getMostRecentCal(b);
				let bCert = getMostRecentCert(bCal);
				let bVal = bCal?.status !== 'valid' ? 0 : !bCert ? 1 : 2;
				return aVal - bVal;
			},
			headerAlign: 'center',
		},
		{
			key: 'isDisabled',
			title: 'Disabled',
			extractFunction: (sensor) => sensor.is_disabled,
		},
	],
};

type JobDetailProps = {};

const JobDetail: FC<JobDetailProps> = () => {
	const { isToogle, isSensorCalibrationPage, setIsSensorCalibrationPage } =
		useNavStore();
	const { accessToken, justSync, isSyncing } = useContext(authCtx);
	const { jobId, tabId } = useParams<{ jobId: string; tabId: string }>();
	const { store } = useContext(StoreContext);
	const dbUser = useCurrentUser();
	const history = useHistory();
	// const [currentTab, setCurrentTab] = useState<JobDetailTabs>(
	// 	(tabId as JobDetailTabs) || JobDetailTabs.SENSORS
	// );

	const [searchQuery] = useState('');
	const [addSensorModalIsOpen, setAddSensorModalIsOpen] = useState(false);
	const [calibrateModalIsOpen, setCalibrateModalIsOpen] = useState(false);
	const [addTechModalIsOpen, setAddTechModalIsOpen] = useState(false);
	const [sensorId, setSensorId] = useState('');
	const [currentSort, setCurrentSort] = useState<ColumnKey>('name');
	const [isAscending, setIsAscending] = useState(false);
	const userIsAdmin = useMemo(
		() => dbUser?.role_id_ref.name === 'administrator',
		[dbUser]
	);
	const userIsTech = useMemo(
		() => dbUser?.role_id_ref.name === 'technician',
		[dbUser]
	);
	const [certificateBatch, setCertificateBatch] = useState<CertificateBatch>();
	const [savedBatchForLoading] = useState<CertificateBatch>();
	const [manualIsLoading, setManualIsLoading] = useState(false);
	const job = store.jobs[jobId];
	const sensors = job?.job_sensors;
	const [isSensorCountZero] = useState(Object.keys(sensors).length>0);
  const [currentTab, setCurrentTab] = useState<JobDetailTabs>(
    isSensorCountZero?JobDetailTabs.SENSORS:JobDetailTabs.DOCUMENTS
);
const [currentTabNew, setCurrentTabNew] = useState<JobDetailTabs>(
  isSensorCountZero?JobDetailTabs.SENSORS:JobDetailTabs.DOCUMENTS
);
	const queryAndSetCertificateBatch = useCallback(async () => {
		const jobUserBatches = await database.collections
			.get<CertificateBatch>('certificate_batches')
			.query(
				Q.where('job_id', jobId),
				Q.where('user_id', dbUser?.id || ''),
				Q.where('status', Q.notEq(CertificateBatchStatus.Unknown))
			)
			.fetch();
		const sortedBatches = orderBy(jobUserBatches, ['updatedAt'], ['desc']);
		const currentBatch = sortedBatches[0];
		const isSameState =
			savedBatchForLoading?.status === currentBatch?.status &&
			savedBatchForLoading?.id === currentBatch?.id;
		setCertificateBatch(currentBatch);
		if (manualIsLoading && !isSameState) {
			setManualIsLoading(false);
		}
	}, [jobId, dbUser?.id, isSyncing, manualIsLoading, savedBatchForLoading]);
	useEffect(() => {
		if (isSyncing === false) {
			queryAndSetCertificateBatch();
		}
	}, [isSyncing]);

	useEffect(() => {
		if (Object.keys(sensors).length<1) {
      console.log('no job');
      setIsSensorCalibrationPage(false)
		}
	}, [job,sensors]);
	
	useEffect(() => {
		handleUnload();
	}, [currentTab, isSensorCalibrationPage,isSensorCountZero]);
	const handleUnload = () => {
		let message = `Do you want to leave sensor calibration page??`;
		if (currentTabNew == 'sensors') {
			if (currentTab != 'sensors') {
				setIsSensorCalibrationPage(false);
			}
			if ( currentTab != 'sensors' && window.confirm(message)) {
				const switchUrl = `/jobs/${jobId}/${currentTab}`;
				history.push(switchUrl);
				setCurrentTabNew(currentTab);
			} else {
				setCurrentTab(JobDetailTabs.SENSORS);
			}
		} else {
			setCurrentTabNew(currentTab);
			const switchUrl = `/jobs/${jobId}/${currentTab}`;
			history.push(switchUrl);
		}
	};
	const filteredSensors = useSearch(
		sensors,
		searchQuery,
		({ sensor_id, name }, test) => test(sensor_id) || test(name)
	);

	const tableData = useTable(
		sensorsTable,
		filteredSensors,
		currentSort,
		isAscending
	);

	const certHasProcessing = Boolean(
		tableData.find((row) => row.cert?.status === CertificateStatus.Processing)
	);
	const batchHasProcessing =
		Boolean(certificateBatch?.status === CertificateBatchStatus.Processing) ||
		manualIsLoading;
	const hasProcessing = certHasProcessing || batchHasProcessing;
	useEffect(() => {
		justSync();
	}, []);

	// useEffect(() => {
	//   const interval = setInterval(async() => {
	//     if (hasProcessing) {
	//       console.log(`Polling sync started on job: ${job.name}`)
	//       if (!isSyncing) {
	//         try {
	//           justSync();
	//           if (batchHasProcessing) {
	//             await queryAndSetCertificateBatch();
	//           }
	//         } catch (err) {
	//           Bugsnag.notify(err as any);
	//           console.error('JobDetail useEffect', err)
	//         }
	//       } else {
	//         console.log('Ongoing sync detected. Skipping sync...');
	//       }
	//     }
	//   }, POLLING_INTERVAL);
	//   return () => clearInterval(interval);
	// }, [certHasProcessing, batchHasProcessing, hasProcessing, isSyncing]);

	const changeJobStatus = useCallback(
		async (value: string) => {
			const job = await database.collections.get<Job>('jobs').find(jobId);
			switch (value) {
				case 'pending':
					await job.resetJobStatus();
					return justSync();
				case 'open':
					await job.openJob();
					return justSync();
				case 'closed':
					await job.closeJob();
					return justSync();
			}
		},
		[jobId]
	);

	/**
	 * Adds a new resource to the job's document list.
	 */
	const addResource = useCallback(
		async (file: File, reader: FileReader) => {
			const formData = new FormData();
			formData.append(
				'resourcesFiles',
				new Blob([reader!.result!], { type: file.type }),
				file!.name
			);
			await fetch(`${urls.base}/jobs/${jobId}/resources`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
				body: formData,
			});
			justSync();
		},
		[jobId, accessToken]
	);

	/**
	 * Handles adding files to the document list.
	 */
	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			if (!acceptedFiles.length) return;
			acceptedFiles.forEach((file) => {
				const reader = new FileReader();
				reader.onabort = () => console.log('file reading was aborted');
				reader.onerror = () => console.log('file reading has failed');
				reader.onload = () => addResource(file, reader);
				reader.readAsArrayBuffer(file);
			});
		},
		[addResource]
	);
	const dropzone = useDropzone({ onDrop });

	/**
	 * Generates CSV data by calling the backend API.
	 */
	const getCsvData = useCallback(async () => {
		console.log('fetching csv...');
		const { base, jobExport } = urls;
		const csvData = await fetch(base + jobExport.url(jobId), {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
			method: jobExport.method,
		}).then((res) => res.text());

		return csvData;
	}, [jobId, accessToken]);

	/**
	 * Changes the job status to closed and adds the CSV data to the document list.
	 */
	const handleCloseJob = useCallback(async () => {
		const csvData = await getCsvData();
		const formData = new FormData();
		formData.append(
			'resourcesFiles',
			new Blob([csvData], { type: 'text/csv' }),
			`${job.name}.csv`
		);
		await fetch(`${urls.base}/jobs/${jobId}/resources`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			body: formData,
		});
		changeJobStatus('closed');
	}, [jobId, accessToken, job?.name, getCsvData, changeJobStatus]);

	/**
	 * Deletes a job and redirects to the jobs page.
	 */
	const handleDeleteJob = useCallback(async () => {
		const { jobDelete } = urls;
		await fetch(`${urls.base}${jobDelete.url}/${jobId}`, {
			method: jobDelete.method,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});
		justSync();
		history.push('/jobs');
	}, [jobId, accessToken, history]);

	const isJobDone = useMemo(() => getJobProgress(job) >= 1, [job]);
	const activeTechs = useMemo(() => getActiveTechs(job), [job]);

	if (!job || !sensors) return null;

	return (
		<>
			<div className={'mainContentContainer'}>
				<div className="header">
					<div>
						<Backout backoutTitle="Jobs" specificAddress="/jobs" />
						<div style={{ display: 'flex' }}>
							<h2 style={{ whiteSpace: 'nowrap' }}>{job.name}</h2>
							<div className={styles.statusContainer}>
								<div
									className={styles.dot}
									style={{
										backgroundColor:
											job.status === 'closed'
												? '#E8074A'
												: job.status === 'open'
												? '#06C75F'
												: '#EBECED',
									}}
								></div>
								<select
									disabled={!userIsAdmin}
									value={job.status}
									onChange={({ target }) => {
										changeJobStatus(target.value);
										if (target.value === 'closed') handleCloseJob();
										else if (target.value === 'delete')
											if (
												window.confirm(
													'You are attempting to delete this job. Click OK if you wish to continue.'
												)
											)
												handleDeleteJob();
									}}
								>
									<option value="pending">Pending</option>
									<option value="open">In-Progress</option>
									<option disabled={!isJobDone} value="closed">
										Closed
									</option>
									<option disabled={!userIsAdmin} value="delete">
										Delete
									</option>
								</select>
							</div>
						</div>
					</div>
					<div className={styles.rightHeader}>
						<div className={styles.techContainer}>
							<div
								className={'hvrPtr'}
								onClick={() => setAddTechModalIsOpen(true)}
							>
								<FontAwesomeIcon icon={'users'} />
							</div>
							<>
								{_.map(activeTechs, ({ technician_id_ref, id }) => {
									const initials = getInitials(
										technician_id_ref?.name
									);
									return (
										<div
											className="hvrPtr"
											key={id}
											style={{ backgroundColor: 'red' }}
											onClick={() => setAddTechModalIsOpen(true)}
										>
											{initials}
										</div>
									);
								})}
							</>
						</div>
						<button
							disabled={!userIsAdmin && !userIsTech}
							onClick={() => setAddSensorModalIsOpen(true)}
						>
							Add Sensor
						</button>
					</div>
				</div>
				<div
					className="tabs"
					style={{ marginLeft: isToogle === 'YES' ? '90px' : '' }}
				>{
          isSensorCountZero ?
          <>
          <h3
						className={
							currentTab === JobDetailTabs.SENSORS ? 'selectedTab' : ''
						}
						onClick={() => setCurrentTab(JobDetailTabs.SENSORS)}
					>
						Sensors
					</h3>

					<h3
						className={
							currentTab === JobDetailTabs.DOCUMENTS ? 'selectedTab' : ''
						}
						onClick={() => {
							setCurrentTab(JobDetailTabs.DOCUMENTS);
							setIsSensorCalibrationPage(false);
						}}
					>
						Documents
					</h3>

					<h3
						className={
							currentTab === JobDetailTabs.CERTIFICATES
								? 'selectedTab'
								: ''
						}
						onClick={() => {
							setCurrentTab(JobDetailTabs.CERTIFICATES);
							setIsSensorCalibrationPage(false);
						}}
					>
						Certificates
					</h3>
					<h3
						className={
							currentTab === JobDetailTabs.STATUS ? 'selectedTab' : ''
						}
						onClick={() => {
							setCurrentTab(JobDetailTabs.STATUS);
							setIsSensorCalibrationPage(false);
						}}
					>
						Status
					</h3></>:<>
          <h3
						className={
							currentTab === JobDetailTabs.DOCUMENTS ? 'selectedTab' : ''
						}
						onClick={() => {
							setCurrentTab(JobDetailTabs.DOCUMENTS);
							setIsSensorCalibrationPage(false);
						}}
					>
						Documents
					</h3>
          </>
        }
					
				</div>
				<JobDetailTabView name={currentTabNew} />
				{/* <CalibrateSensor calibrateModalIsOpen={calibrateModalIsOpen} setCalibrateModalIsOpen={setCalibrateModalIsOpen} sensorDbId={sensorId} clearSensor={() => setSensorId('')} currentJobId={''} /> */}
				<Modal
					isOpen={addSensorModalIsOpen}
					onRequestClose={() => setAddSensorModalIsOpen(false)}
					className="modal"
					overlayClassName="modalOverlay"
				>
					<AddSensor
						jobId={jobId}
						closeModal={() => setAddSensorModalIsOpen(false)}
					/>
				</Modal>
				<Modal
					isOpen={addTechModalIsOpen}
					onRequestClose={() => setAddTechModalIsOpen(false)}
					className="modal"
					overlayClassName="modalOverlay"
				>
					<AddTech
						jobId={jobId}
						closeModal={() => setAddTechModalIsOpen(false)}
					/>
				</Modal>
			</div>
		</>
	);
};

export default JobDetail;
