import { Database } from "@nozbe/watermelondb";
import LokiJSAdapter, {
  LokiAdapterOptions,
} from "@nozbe/watermelondb/adapters/lokijs";

import schema from "./schema";
import Organization from "src/models/Organization";
import Workgroup from "src/models/Workgroup";
import Role from "src/models/Role";
import User from "src/models/User";
import ProbeType from "src/models/ProbeType";
import Tool from "src/models/Tool";
import TechnicianTool from "src/models/TechnicianTool";
import Job from "src/models/Job";
import JobSensor from "src/models/JobSensor";
import JobTechnician from "src/models/JobTechnician";
import JobLeadTechnician from "src/models/JobLeadTechnician";
import JobWorkgroup from "src/models/JobWorkgroup";
import Calibration from "src/models/Calibration";
import CalibrationProcedure from "src/models/CalibrationProcedure";
import Certificate from "src/models/Certificate";
import Metadata from "src/models/Metadata";
import CalibrationReading from "src/models/CalibrationReading";
import JobResource from "src/models/JobResource";
import CertificateBatch from "src/models/CertificateBatch";
import migrations from './migrations';

const adapter = new LokiJSAdapter({
  schema,
  migrations,
  useWebWorker: false, // recommended for new projects. tends to improve performance and reduce glitches in most cases, but also has downsides - test with and without it
  useIncrementalIndexedDB: true, // recommended for new projects. improves performance (but incompatible with early Watermelon databases)
  // dbName: 'myapp', // optional db name
  // It's recommended you implement this method:
  // onIndexedDBVersionChange: () => {
  //   // database was deleted in another browser tab (user logged out), so we must make sure we delete
  //   // it in this tab as well
  //   if (checkIfUserIsLoggedIn()) {
  //     window.location.reload()
  //   }
  // },
  // Optional:
  // onQuotaExceededError: (error) => { /* do something when user runs out of disk space */ },
} as LokiAdapterOptions);

// Then, make a Watermelon database from it!
export const database = new Database({
  adapter,
  modelClasses: [
    Organization,
    Workgroup,
    Role,
    User,
    ProbeType,
    Tool,
    TechnicianTool,
    Job,
    JobSensor,
    JobTechnician,
    JobLeadTechnician,
    JobWorkgroup,
    JobResource,
    Calibration,
    CalibrationProcedure,
    CalibrationReading,
    Certificate,
    CertificateBatch,
    Metadata
  ],
});
