import React from 'react';
import { MultiProgressBar, MultiProgressBarStat } from '../../MultiProgressBar';
import { JobStat, JobStatRatio, getJobRatioFromStat } from './index';
import './jobProgressBar.css';
export function JobProgressBar(props: { stat: JobStat }) {
   const [ratio] = React.useState<Partial<JobStatRatio>>(getJobRatioFromStat(props.stat));

	return (
		<>
			<label className="job-progress-label">
				<span className="progress-Certified"title={String(props.stat.CertifiedCalCount)}>{ratio.CertifiedCalCount}%</span>
				&nbsp;/&nbsp;
				<span className="progress-Calibrated" 	 title={String(props.stat.CalibratedCalCount)}>{ratio.CalibratedCalCount}%</span>
				&nbsp;/&nbsp;
				<span className="progress-Inprogress" title={String(props.stat.InProgressCalCount)}>{ratio.InProgressCalCount}%</span>
				&nbsp;/&nbsp;
				<span className="progress-Issue" title={String(props.stat.IssueCalCount)}>{ratio.IssueCalCount}%</span>
				&nbsp;/&nbsp;
				<span className="progress-No " title={String(props.stat.NoCalCount)}>{ratio.NoCalCount}%</span>
				&nbsp;/&nbsp;
				<span className="progress-Sensors_to_be_Calibrated" title={String(props.stat.Sensors_to_be_Calibrated)}>{ratio.Sensors_to_be_Calibrated}%</span>
			</label>
			<MultiProgressBar>
				
				<MultiProgressBarStat
				type={'Certified'}
				tooltip={ratio.CertifiedCalCount + '%' +"(Certified)"}
				value={ratio.CertifiedCalCount}
					
				/>
				<MultiProgressBarStat
				type={'Calibrated'}
				tooltip={ratio.CalibratedCalCount +'%'+"(Calibrated)"} 
					value={ratio.CalibratedCalCount}
					
				/>
				<MultiProgressBarStat
				type={'Inprogress'}
					tooltip={ratio.InProgressCalCount + '%'+"(In progress)"}
					value={ratio.InProgressCalCount}
					
				/>
				<MultiProgressBarStat
				type={'Issue'}
					tooltip={ratio.IssueCalCount + '%'+"(Issue)"}
					value={ratio.IssueCalCount}
					
				/>
				<MultiProgressBarStat
				type={'No'}
					tooltip={ratio.NoCalCount + '%'+"(No)"}
					value={ratio.NoCalCount}
					
				/>
				<MultiProgressBarStat
				type={'Sensors_to_be_Calibrated'}
					tooltip={ratio.Sensors_to_be_Calibrated + '%'+"(Sensors to be Calibrated)"}
					value={ratio.Sensors_to_be_Calibrated}
					
				/>
			</MultiProgressBar>
		</>
	);
}
