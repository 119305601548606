import React, { FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { StoreContext } from 'src/database/store/StoreProvider';
import Backout from '../Backout/Backout';
import AddCalProc from '../modals/AddCalProc/AddCalProc';
import styles from './customerDetail.module.css';
import OrgProcedures from './OrgProcedures';
import OrgWorkgroups from './OrgWorkgroups';
import AddGroup from '../modals/AddGroup/AddGroup';
import AddCustomer from '../modals/AddCustomer/AddCustomer';
import useNavStore from '../RootNavBarIcon/NavStore';

type CustomerDetailProps = {};

const CustomerDetail: FC<CustomerDetailProps> = () => {
  const { orgId } = useParams<{ orgId: string }>();
  const { store } = useContext(StoreContext);
  const [AddCalProcModalIsOpen, setAddCalProcModalIsOpen] = useState(false);
  const [addGroupModalIsOpen, setAddGroupModalIsOpen] = useState(false);
  const [curTab, setCurTab] = useState<'groups' | 'procedures'>('groups');
  const [addCustomerModalIsOpen, setAddCustomerModalIsOpen] = useState(false);
  const org = store.organizations[orgId];
  const { isToogle } = useNavStore();
	useEffect(() => {}, [isToogle]);
  if (!org) return null;

  return (
    <div className={'mainContentContainer'}>
      <div className="header">
        <div>
          <Backout backoutTitle="Customers" />
          <div className={styles.titleCluster}>
            <h2>{org?.name}</h2>
            <button className="hollow" onClick={() => setAddCustomerModalIsOpen(true)}>edit</button>
          </div>
        </div>
        {/* {
          curTab === 'groups' &&
            <button onClick={() => setAddGroupModalIsOpen(true)}>New Group</button> 
        } */}
        {
          curTab === 'groups' ?
            <button onClick={() => setAddGroupModalIsOpen(true)}>New Group</button> :
            <button onClick={() => setAddCalProcModalIsOpen(true)}>New Procedure</button>
        }
      </div>
        <div style={{ marginLeft: isToogle === 'YES' ? '90px' : '' }}>
      <div className="tabs">
        <h3 className={curTab === 'groups' ? 'selectedTab' : ''} onClick={() => setCurTab('groups')}>Groups</h3>
        <h3 className={curTab === 'procedures' ? 'selectedTab' : ''} onClick={() => setCurTab('procedures')}>Procedures</h3>
      </div>
      
      {
        curTab === 'groups' ?
          <OrgWorkgroups orgId={orgId} /> :
          <OrgProcedures orgId={orgId} />
      }
      </div>
      <Modal isOpen={addCustomerModalIsOpen} onRequestClose={() => setAddCustomerModalIsOpen(false)} className="modal" overlayClassName="modalOverlay">
        <AddCustomer closeModal={() => setAddCustomerModalIsOpen(false)} orgId={orgId} />
      </Modal>
      <Modal isOpen={AddCalProcModalIsOpen} onRequestClose={() => setAddCalProcModalIsOpen(false)} className="modal" overlayClassName="modalOverlay">
        <AddCalProc orgId={orgId} closeModal={() => setAddCalProcModalIsOpen(false)} />
      </Modal>
      <Modal isOpen={addGroupModalIsOpen} onRequestClose={() => setAddGroupModalIsOpen(false)} className="modal" overlayClassName="modalOverlay">
        <AddGroup orgId={orgId} closeModal={() => setAddGroupModalIsOpen(false)} />
      </Modal>
    </div>
  );
};

export default CustomerDetail;
