import _ from 'lodash';
import React, {
	FC,
	useMemo,
	useContext,
	useState,
	useEffect,
	useCallback,
} from 'react';
import TableHeader, {
	SortingPackage,
} from '../../components/TableHeader/TableHeader';
import { format } from 'date-fns';
import TechnicianTool from "src/models/TechnicianTool";
import { Table, useTable } from 'src/hooks/useTable';
import { PopColl, TechnicianToolsDBEntry } from 'src/database/store/StoreTypes';
import styles from './list.module.css';
import { StoreContext } from 'src/database/store/StoreProvider';
import { SearchBar, useSearch } from '../../components/SearchBar/SearchBar';
import useCurrentUser from 'src/hooks/useCurrentUser';
import Tool from '../../models/Tool';
import useNavStore from 'src/components/RootNavBarIcon/NavStore';
import { extractFirstAndLastName } from 'src/utils/general';
import { useHistory } from 'react-router-dom';
import { database } from 'src/database';
type ColumnKey = 'due_date' | 'Description' | 'Acknowledgement' | 'Action';
const toolsTable: Table<TechnicianToolsDBEntry, ColumnKey> = {
	columns: [
		{
			key: 'due_date',
			title: 'Due Date',
			extractFunction: (technician_tools) =>
				technician_tools.calibration_due_date,
		},
		{
			key: 'Description',
			title: 'Description',
			extractFunction: (technician_tools) => technician_tools,
		},
		{
			key: 'Acknowledgement',
			title: 'Acknowledgement',
			extractFunction: (technician_tools) =>
				technician_tools?.acknowledgement,
		},
		{
			key: 'Action',
			title: 'Action',
			extractFunction: (technician_tools) => {
				// console.log(technician_tools.id);
				// console.log(technician_tools.acknowledgement);
				return technician_tools;
			},
		},
	],
};

// type CompanyAssetsProps = {};
export function Alert() {
	const [searchQuery, setSearchQuery] = useState('');
	const [acknowledgementText, setAcknowledgementText] = useState('');
	const [currentSort, setCurrentSort] = useState<ColumnKey>('Description');
	const [isAscending, setIsAscending] = useState(true);
	const { store } = useContext(StoreContext);
	const { technician_tools } = store;
	let history = useHistory();
	const dbUser = useCurrentUser();
	const userIsAdmin = useMemo(
		() => dbUser?.role_id_ref.name === 'administrator',
		[dbUser]
	);
	// search
	const filteredSensors = useSearch(
		technician_tools || Tool,
		searchQuery,
		({ serial_number }, test) => test(serial_number)
	);
	const tableData = useTable(
		toolsTable,
		filteredSensors,
		currentSort,
		isAscending
	);
	const sortingPackage: SortingPackage<ColumnKey> = {
		setCurrentSort,
		currentSort,
		setIsAscending,
		isAscending,
	};
	const { isToogle } = useNavStore();
	useEffect(() => {
	}, [isToogle]);
	const alertClearFoo = async (toolId:string) => {
		console.log('acknowledgementText:::::::::::::::::::;', acknowledgementText);
		console.log('toolId:::::::::::::::::::;', toolId);
		const TechnicianTool = await database.collections
		.get<TechnicianTool>('technician_tools')
		.find(toolId);

		await TechnicianTool.updateacknowledgementText(acknowledgementText as any);
			};

	return (
		<div className={'mainContentContainer'}>
			<div className="header">
				<div>
					<h2>Company Alert</h2>
				</div>
				<div>
					{/* <button  className='' onClick={() =>history.push(`/CompanyAssets?AssetsID=${}`)}>Alert Assets</button> */}
				</div>
			</div>
			<div style={{ marginLeft: isToogle === 'YES' ? '90px' : '' }}>
				<TableHeader
					columns={toolsTable.columns}
					rowClassName={styles.row}
					sortingPackage={sortingPackage}
				/>

				{tableData.map((data) =>
					// < +new Date() + 1000 * 60 * 60 * 24 * 30

					data?.due_date < +new Date() + 1000 * 60 * 60 * 24 * 30 ? (
						<>
							<div
								// onClick={() =>history.push(`/CompanyAssets?AssetsID=${data.id}`)}
								key={data.id}
								className={`${styles.row} hvrPtr row`}
								style={{
									backgroundColor:
										data?.due_date < +new Date() + 86400000 * 30
											? data?.due_date < +new Date() + 86400000 * 15
												? 'red'
												: 'yellow'
											: 'transparent',
								}}
							>
								<p
									onClick={() =>
										history.push(`/CompanyAssets?AssetsID=${data.id}`)
									}
								>
									{format(
										new Date(data.due_date),
										'MM-dd-yyyy, h:mm a'
									)}
								</p>

								{~~(
									(data.due_date - +new Date()) /
									(1000 * 60 * 60 * 24)
								) < 0 ? (
									<p
										onClick={() =>
											history.push(
												`/CompanyAssets?AssetsID=${data.id}`
											)
										}
									>{`${
										data.Description.technician_user_id_ref?.name
											? extractFirstAndLastName(
													data.Description.technician_user_id_ref
														?.name
											  )[0]
													.trim()
													.slice(-1)
													.toUpperCase() === 'S'
												? extractFirstAndLastName(
														data.Description
															.technician_user_id_ref?.name
												  )[0].trim() + "'"
												: extractFirstAndLastName(
														data.Description
															.technician_user_id_ref?.name
												  )[0].trim() + "'s"
											: ''
									} ${data.Description.tool_id_ref?.name}, SN:${
										data.Description?.serial_number
									}  has  expired ${Math.abs(
										~~(
											(data.due_date - +new Date()) /
											(1000 * 60 * 60 * 24)
										)
									)} days ago.`}</p>
								) : (
									<p
										onClick={() =>
											history.push(
												`/CompanyAssets?AssetsID=${data.id}`
											)
										}
									>{`${
										data.Description.technician_user_id_ref?.name
											? extractFirstAndLastName(
													data.Description.technician_user_id_ref
														?.name
											  )[0]
													.trim()
													.slice(-1)
													.toUpperCase() === 'S'
												? extractFirstAndLastName(
														data.Description
															.technician_user_id_ref?.name
												  )[0].trim() + "'"
												: extractFirstAndLastName(
														data.Description
															.technician_user_id_ref?.name
												  )[0].trim() + "'s"
											: ''
									} ${data.Description.tool_id_ref?.name}, SN:${
										data.Description?.serial_number
									} will expire in ${~~(
										(data.due_date - +new Date()) /
										(1000 * 60 * 60 * 24)
									)} days.`}</p>
								)}
								<p>
									<input
										type="text"
										data-record-field="sensorGroup"
										defaultValue={data.Acknowledgement}
										disabled={data.Acknowledgement}
										onChange={(event: { target: { value: any; }; }) =>setAcknowledgementText(event.target.value)}
									/>
								</p>
								<p>
									<button
										className={styles.btn}
										disabled={!userIsAdmin || data.Acknowledgement}
										onClick={() => alertClearFoo(data.id)}
									>
										Clear
									</button>
								</p>
							</div>
						</>
					) : (
						<></>
					)
				)}
			</div>
		</div>
	);
}

export default Alert;
