import React,{useEffect} from 'react'
import { useHistory } from "react-router-dom";
import useNavStore from '../RootNavBarIcon/NavStore';
import './index.css'

interface TabsProps {
   children: JSX.Element[]
}
interface TabItemProps {
   label?: string,
   disabled?:boolean
}

export function AppTabs(props: TabsProps) {
   const { isToogle } = useNavStore();
   useEffect(() => {},[isToogle])   
   return (
      <div className={'app-tabs'} style={{marginLeft:isToogle==='YES'?'100px':''}}>
      
         { props.children }
      </div>
   )
}

export function AppTabItem(props: TabItemProps) {
   let history = useHistory();
function redirectTo(tabName?: string) {
      if (tabName === 'Calendar') {
         let url='https://fcsteamnet.sharepoint.com/:x:/s/FCSOperations/EeKWmzs0WA1Ht52MFIgXOm0BZwBb0kHm7d1dgN2tFPg7iQ?email=suryasnata%40trivediat.com&e=6H2ps7'
         const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
      }
      if (tabName === 'Jobs') {
         // alert(tabName)
         history.push(`/${tabName}?jobtype=open`);
      }
      if (tabName === 'Survey Tool') {
         history.push('/SurveyTool');
      }
      if (tabName === 'Employees') {
         history.push(`/users?org=FCS`);
      }
      if (tabName === 'Customer List') {
         tabName = 'Employees'
         history.push(`/users?customer=customer`);

      }
      if (tabName === 'Company Assets') {
         tabName = 'CompanyAssets'
         history.push(`/${tabName}`);
         }
      if (tabName === 'Alerts') {
         history.push(`/Alerts`);
      }
   }


   return (
      <button disabled={props.disabled} className='app-tab-item inactive' onClick={() => redirectTo(props.label)}>{props.label}</button>
      // <button disabled={props.disabled} className='app-tab-item inactive' onClick={() => history.push("/home")}>{props.label}</button>



   )
}
