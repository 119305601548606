import { BelongsToAssociation } from "@nozbe/watermelondb/Model";
import { relation } from "@nozbe/watermelondb/decorators";
import { Relation } from "@nozbe/watermelondb";
import { Association } from "src/types/watermelon";
import BaseModel from "./BaseModel";
import Job from "./Job";
import JobTechnician from "./JobTechnician";

export default class JobLeadTechnician extends BaseModel {
  static table = "job_lead_technicians";
  static associations = {
    jobs: {
      type: Association.BELONGS_TO,
      key: "job_id",
    } as BelongsToAssociation,
    job_technicians: {
      type: Association.BELONGS_TO,
      key: "job_technician_id",
    } as BelongsToAssociation,
  };
  getTableName() {
    return JobLeadTechnician.table;
  }
  @relation("jobs", "job_id") job!: Relation<Job>;
  @relation("job_technicians", "job_technician_id") jobTechnician!: Relation<JobTechnician>;
}
