import { Q, Relation } from "@nozbe/watermelondb";
import { BelongsToAssociation, HasManyAssociation } from "@nozbe/watermelondb/Model";
import { field, children, date, writer, lazy, relation } from "@nozbe/watermelondb/decorators";
import { Query } from "@nozbe/watermelondb";
import { Association } from "src/types/watermelon";
import BaseModel from "./BaseModel";
import JobSensor from "./JobSensor";
import JobWorkgroup, * as JobWorkgroup_1 from "./JobWorkgroup";
import Workgroup from "./Workgroup";
import User from "./User";
import JobTechnician from "./JobTechnician";
import JobLeadTechnician from "./JobLeadTechnician";
import Organization from "./Organization";
import JobResource from "./JobResource";

export type JobStatus = 'pending' | 'open' | 'closed';

export default class Job extends BaseModel {
  static table = "jobs";
  static associations = {
    job_sensors: {
      type: Association.HAS_MANY,
      foreignKey: "job_id",
    } as HasManyAssociation,
    job_workgroups: {
      type: Association.HAS_MANY,
      foreignKey: "job_id",
    } as HasManyAssociation,
    organizations: {
      type: Association.BELONGS_TO,
      key: "technician_organization_id",
    } as BelongsToAssociation,
    job_technicians: {
      type: Association.HAS_MANY,
      foreignKey: "job_id",
    } as HasManyAssociation,
    job_lead_technicians: {
      type: Association.BELONGS_TO,
      key: "job_lead_technician_id",
    } as BelongsToAssociation,
    job_resources: {
      type: Association.HAS_MANY,
      foreignKey: "job_id",
    } as HasManyAssociation,
  };
  getTableName() {
    return Job.table;
  }
  @field("name") name!: string;
  @date("submitted_at") submittedDate!: Date;
  @date("started_at") startedDate!: Date;
  @date("closed_at") closedDate!: Date;
  @field("status") status!: JobStatus;
  @field("notes") notes!: string;
  @field("tolerance_limit_rule") toleranceLimitRule!: string;
  @date("due_date") dueDate!: Date;
  @field('expectedCompletionDate') expectedCompletionDate!: string;
  @field('expectedStartDate') expectedStartDate!: string;
  @field('isCustomerIdentified') isCustomerIdentified!: number;
  @field('isAssetReconciled') isAssetReconciled!: number;
  @field('isSOW') isSOW!: number;
  @field('isScheduled') isScheduled!: number;
  @field('isAssignTech') isAssignTech!: number;
  @children("job_sensors") jobSensors!: Query<JobSensor>;
  @children("job_technicians") jobTechnicians!: Query<JobTechnician>;
  @children("job_workgroups") jobWorkgroups!: Query<JobWorkgroup>;
  @children("job_resources") jobResources!: Query<JobResource>;
  @relation("job_lead_technicians", "job_lead_technician_id") jobLeadTechnician!: Relation<JobLeadTechnician>;
  @relation("organizations", "technician_organization_id") technicianOrganizatoin!: Relation<Organization>;
  @lazy customerWorkgroups = this.jobWorkgroups.extend(
    Q.where('type', 'customer'),
  )
  @lazy manufacturerWorkgroups = this.jobWorkgroups.extend(
    Q.where('type', 'manufacturer'),
  )
  @writer async addWorkgroup(type: JobWorkgroup_1.JobWorkgroupType, workgroup: Workgroup) {
    const jobWorkgroupsCollection = this.collections.get<JobWorkgroup>('job_workgroups');
    const jobWorkgroupsOfTypeQuery = this.jobWorkgroups.extend(
      Q.where('type', type),
    )
    const matchingJobWorkgroupsQuery = jobWorkgroupsOfTypeQuery.extend(
      Q.where('workgroup_id', workgroup.id),
    );
    const existingJobWorkgroupsOfType = await jobWorkgroupsOfTypeQuery.fetch();
    const matchingJobWorkgroups = await matchingJobWorkgroupsQuery.fetch();
    const jobWorkgroupAlreadyExists = !!matchingJobWorkgroups[0];
    if (jobWorkgroupAlreadyExists) {
      console.warn(`Workgroup already exists for ${type} type`);
      return false;
    }
    const hasExistingJobWorkgroupOfType = existingJobWorkgroupsOfType.length > 0;
    if (type === 'customer' && hasExistingJobWorkgroupOfType) {
      console.warn(`Only one customer workgroup allowed per job, deleting old JobWorkgroup`);
      // TODO: batch delete
      await this.callWriter(() => existingJobWorkgroupsOfType[0].deleteRecord());
    }
    return jobWorkgroupsCollection.create(jobWorkgroup => {
      jobWorkgroup.type = type;
      jobWorkgroup.workgroup.set(workgroup);
      jobWorkgroup.job.set(this);
    })
  };
  @writer async addTechnician(techId: string) {
    const technician = await this.collections.get<User>('users').find(techId);
    const jobTechnicianCollection = this.collections.get<JobTechnician>('job_technicians');
    const existingTechnicianQuery = this.jobTechnicians.extend(
      Q.where('technician_id', technician.id)
    );
    const matchingTechnician = await existingTechnicianQuery.fetch();
    let createdJobTechnician = matchingTechnician[0];
    if (createdJobTechnician) {
      await this.callWriter(() => createdJobTechnician.reassociate());
    } else {
      createdJobTechnician = await jobTechnicianCollection.create(jobTechnician => {
        jobTechnician.technician.set(technician);
        jobTechnician.job.set(this);
      });
    }
    const existingLeadTech = await this.jobLeadTechnician.fetch();
    const doSetLeadTech = existingLeadTech === null;
    if (doSetLeadTech) {
      await this.callWriter(() => this.setLeadTechnician(createdJobTechnician.id));
    }
  };
  @writer async setLeadTechnician(jobTechnicianId: string) {
    const newLead = await this.collections.get<JobTechnician>('job_technicians').find(jobTechnicianId);
    if (!newLead) return;
    let currentJobLeadTechnician = await this.jobLeadTechnician.fetch();
    if (!currentJobLeadTechnician) {
      // TODO: Why am I setting both ends of the assocation, is the relationship set wrong in wdb?
      currentJobLeadTechnician = await this.collections.get<JobLeadTechnician>('job_lead_technicians').create((createdJobLead) => {
        createdJobLead.job.set(this);
        createdJobLead.jobTechnician.set(newLead);
      });
      // console.log(this.jobLeadTechnician)
      await this.update((job) => job.jobLeadTechnician.set(currentJobLeadTechnician));
    } else {
      // console.log({leadModel: currentJobLeadTechnician.id})
      // console.log({ oldLead: currentJobLeadTechnician.jobTechnician.id })
      // console.log({ newLead: newLead.id })
      await currentJobLeadTechnician?.update((jlt) => jlt.jobTechnician.set(newLead));
      await this.update((job) => job.jobLeadTechnician.set(currentJobLeadTechnician));
      // console.log({ updatedLead: currentJobLeadTechnician.jobTechnician.id })

    }
  }
  @writer async addSensor({ sensorType, name, sensorId, sensorProbeNumber, sensorGroup, building, floor, room, chamber, description, isDisabled,is_lumity }: AddSensorParams) {
    return await this.collections.get<JobSensor>('job_sensors').create(jobSensor => {
      
      jobSensor.sensorType = sensorType ;
      jobSensor.name = name;
      jobSensor.sensorId = sensorId;
      jobSensor.probeNumber = sensorProbeNumber || -1;
      jobSensor.sensorGroup = sensorGroup;
      jobSensor.building = building;
      jobSensor.floor = floor;
      jobSensor.room = room;
      jobSensor.chamber = chamber;
      jobSensor.description = description;
      jobSensor.isDisabled = isDisabled || 0;
      jobSensor.job.set(this);
      jobSensor.is_lumity = is_lumity
      
      
    })
  }
  @writer async openJob() {
    return this.update((job) => {
      this.startedDate = new Date();
      job.status = 'open'
    })
  }
  @writer async closeJob() {
    return this.update((job) => {
      this.closedDate = new Date();
      job.status = 'closed'
    })
  }
  @writer async resetJobStatus() {
    return this.update((job) => {
      job.status = 'pending'
    })
  }
  @writer async updateJob(updateBody: JobUpdateBody) {
    await this.update(job => {
      Object.assign(job, updateBody);
    });
  };
}

export type AddSensorParams = {
  sensorType: string;
  name: string;
  sensorId: string;
  sensorProbeNumber: number;
  sensorGroup: string;
  building: string;
  floor: string;
  room: string;
  chamber: string;
  description: string;
  isDisabled?: 0 | 1;
  is_lumity: number;
  }

export type JobUpdateBody = Pick<
  Job,
  "submittedDate"
  | "startedDate"
  | "closedDate"
  | "status"
  | "notes"
  | "name"
>;