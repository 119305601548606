import _ from 'lodash';
import React, { FC, useCallback, useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { authCtx } from 'src/api/AuthProvider';
import { StoreContext } from 'src/database/store/StoreProvider';
import { UsersDBEntry } from 'src/database/store/StoreTypes';
import useOrganizationTypes from 'src/hooks/useOrganizationTypes';
import { JobWorkgroupType } from 'src/models/JobWorkgroup';
import { database } from 'src/database';
// import styles from './editUser.module.css';
import User from 'src/models/User';
import urls from '../../../api/urls';

type EditUserProps = {
	user: UsersDBEntry;
	closeModal: () => void;
};

const EditUser: FC<EditUserProps> = ({ user, closeModal }) => {
	const { store } = useContext(StoreContext);
	const { accessToken } = useContext(authCtx);
	const { justSync } = useContext(authCtx);
	const history = useHistory();
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [roleId, setRoleId] = useState('');
	const [orgId, setOrgId] = useState('');
	const [groupId, setGroupId] = useState('');
	const [isProcessing, setIsProcessing] = useState(false);
	const { roles } = store;
	const role = roles[roleId];
	const hasOrg = role?.name === 'customer' || role?.name === 'manufacturer';
	const orgType = hasOrg ? (role?.name as JobWorkgroupType) : undefined;
	const orgsOfType = useOrganizationTypes(orgType);

	const [isjobs, setjobs] = React.useState(user.isjobs);
	const [is_calendar, setCalendar] = React.useState(user.is_calendar);
	const [is_survey_tool, setSurveyTool] = React.useState(user.is_survey_tool);
	const [is_employeees, setEmployeees] = React.useState(user.is_employeees);
	const [isverified, setIsverified] = React.useState(user.isverified);
	const [is_company_assets, setCompanyAssets] = React.useState(
		user.is_company_assets
	);
	const [isAlerts, setAlerts] = React.useState(user.is_alerts);
	const org = store.organizations[orgId];

	const logged_user_role_id = localStorage.getItem('user-role-id') || '';
	var isAdmin: boolean = false;
	if (logged_user_role_id === '311b83d9-2cc5-4c17-bc01-e2b9d006f1e2') {
		isAdmin = true;
	}
	const updateTabShow = (assignToRoleId: string) => {
		setRoleId(assignToRoleId);
		assignToRoleId === 'e1c59d16-5bc0-4794-92e7-6bab999c7c63'
			? setCreateEditUserLable(true)
			: setCreateEditUserLable(false);
	};
	const handleTabChange = (tabName: any) => {
		switch (tabName) {
			case 'calender':
				setCalendar(!is_calendar);

				break;
			case 'jobs':
				setjobs(!isjobs);

				break;
			case 'employeees':
				setEmployeees(!is_employeees);

				break;
			case 'companyAssets':
				setCompanyAssets(!is_company_assets);

				break;
			case 'surveyTool':
				setSurveyTool(!is_survey_tool);

				break;
			case 'alerts':
				setAlerts(!isAlerts);

				break;

			default:
				break;
		}
	};
	const [createEditUserLable, setCreateEditUserLable] = useState(false);
	useEffect(() => {
		if (
			roleId === 'e1c59d16-5bc0-4794-92e7-6bab999c7c63' ||
			roleId === '311b83d9-2cc5-4c17-bc01-e2b9d006f1e2'
		) {
			setGroupId('022abfa7-ff39-48a5-b815-2e7af3b3118f');
		}
	}, [orgType, store.workgroups, orgId]);
	const handleUpdateUser = useCallback(async () => {
		setIsProcessing(true);

		try {
			await (async () => {
				let bodyData = {
					email: email,
					updateMetadata: {
						isCalendar: is_calendar,
						isjobs: isjobs,
						isSurveyTool: is_survey_tool,
						isEmployeees: is_employeees,
						isCompanyAssets: is_company_assets,
						isAlerts: isAlerts,
						roleId: roleId,
						name: `${firstName} ${lastName}`,
						workgroupId: groupId,
						isverified:isverified
					},
				};
				const userModel = await database.collections
					.get<User>('users')
					.find(user!.id);

				await userModel.updateDetails({
					firstName,
					lastName,
					email,
					roleId,
					groupId,
					is_calendar,
					isjobs,
					is_survey_tool,
					is_employeees,
					is_company_assets,
					isAlerts,
					isverified
				});
				justSync();
				setIsProcessing(false);
				closeModal();
			})();
		} catch (error) {
			console.log('Error--', error);
			setIsProcessing(false);
			closeModal();
		}
	}, [
		firstName,
		lastName,
		email,
		roleId,
		groupId,
		user,
		accessToken,
		is_calendar,
		isjobs,
		is_survey_tool,
		is_employeees,
		is_company_assets,
		isAlerts,
		closeModal,
	]);

	const handleDelete = useCallback(async () => {
		setIsProcessing(true);
		const { base, deleteUser } = urls;
		await fetch(`${base}${deleteUser.url}/${user.id}`, {
			method: deleteUser.method,
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		});
		justSync();
		setIsProcessing(false);
		closeModal();
		history.push('/users');
	}, [accessToken, user, history, closeModal]);

	useEffect(() => {
		const nameArr = user?.name.split(' ') || [];
		const first = nameArr?.slice(0, nameArr.length - 1).join(' ');
		const last = nameArr[nameArr.length - 1];
		setFirstName(first);
		setLastName(last);
		setEmail(user.email);
		setRoleId(user.role_id);
		if (user?.workgroup_id && user?.workgroup_id_ref?.organization_id) {
			setOrgId(user.workgroup_id_ref.organization_id);
			setGroupId(user.workgroup_id);
		}
		user.role_id === 'e1c59d16-5bc0-4794-92e7-6bab999c7c63'
			? setCreateEditUserLable(true)
			: setCreateEditUserLable(false);
	}, [user]);

	return (
		<div className="modalContainer">
			<div className="modalHeader">
				<h2>Edit User</h2>
			</div>
			<div className="content">
				<h3>Contact Information</h3>
				<div
					className="multiCol"
					style={{ gridTemplateColumns: '1fr 1fr' }}
				>
					<div>
						<label>First Name</label>
						<input
							onChange={({ target }) => setFirstName(target.value)}
							value={firstName}
						/>
					</div>
					<div>
						<label>Last Name</label>
						<input
							onChange={({ target }) => setLastName(target.value)}
							value={lastName}
						/>
					</div>
				</div>
				<label>Email</label>
				<input
					onChange={({ target }) => setEmail(target.value)}
					value={email}
				/>
				<h3>Permissions</h3>
				<label>Role</label>
				<div className="selectContainer">
					<select
						value={roleId}
						onChange={({ target }) => updateTabShow(target.value)}
					>
						<option value="">Select Role...</option>
						{_.map(roles, ({ name, id }) => (
							<option key={id} value={id}>
								{_.capitalize(name)}
							</option>
						))}
					</select>
				</div>
				<div
					className="multiCol"
					style={{ gridTemplateColumns: '1fr 1fr' }}
				>
					{orgType && (
						<>
							<div>
								<label>Organization</label>
								<div className="selectContainer">
									<select
										value={orgId}
										onChange={({ target }) => setOrgId(target.value)}
									>
										<option value="">Select Organization...</option>
										{_.map(orgsOfType, ({ name, id }) => (
											<option key={id} value={id}>
												{name}
											</option>
										))}
									</select>
								</div>
							</div>
							<div>
								<label>Group</label>
								<div className="selectContainer">
									<select
										disabled={!org}
										value={groupId}
										onChange={({ target }) =>
											setGroupId(target.value)
										}
									>
										<option value="">Select Group...</option>
										{_.map(org?.workgroups, ({ name, id }) => (
											<option key={id} value={id}>
												{name}
											</option>
										))}
									</select>
								</div>
							</div>
						</>
					)}
					{/* {
						// Only Customers and Customer users have workgroups
						orgType === 'customer' || orgType === "manufacturer" && (
							
						)
					} */}
				</div>
				<div
					className="multiCol"
					style={{ gridTemplateColumns: '1fr 1fr' }}
				>
					{isAdmin && createEditUserLable && (
						<div>
							<h3>Select Tab List To Show</h3>
							<div className="checkListTab"></div>
							<label>
								<input
									name="calender"
									type="checkbox"
									checked={is_calendar}
									onChange={({ target }) =>
										handleTabChange(target.name)
									}
								/>
								Calendar
							</label>
							<label>
								<input
									name="jobs"
									type="checkbox"
									checked={isjobs}
									onChange={({ target }) =>
										handleTabChange(target.name)
									}
								/>
								Jobs
							</label>
							{/* <label>
								<input
									name="surveyTool"
									type="checkbox"
									checked={is_survey_tool}
									onChange={({ target }) =>
										handleTabChange(target.name)
									}
								/>
								Survey Tool
							</label> */}
							<label>
								<input
									name="employeees"
									type="checkbox"
									checked={is_employeees}
									onChange={({ target }) =>
										handleTabChange(target.name)
									}
								/>
								Employeees
							</label>
							<label>
								<input
									name="companyAssets"
									type="checkbox"
									checked={is_company_assets}
									onChange={({ target }) =>
										handleTabChange(target.name)
									}
								/>
								Company Assets
							</label>
							<label>
								<input
									name="alerts"
									type="checkbox"
									checked={isAlerts}
									onChange={({ target }) =>
										handleTabChange(target.name)
									}
								/>
								Alerts
							</label>
						</div>
					)}
				</div>
			</div>
			<div className="footer">
				{/* <button
					disabled={isProcessing}
					className={`${styles.delete}`}
					onClick={handleDelete}
				>
					Delete User
				</button> */}
				<div>
					<button
						disabled={isProcessing}
						className="cancel"
						onClick={closeModal}
					>
						Cancel
					</button>
					<button disabled={isProcessing} onClick={handleUpdateUser}>
						Save
					</button>
				</div>
			</div>
		</div>
	);
};

export default EditUser;
