import _ from 'lodash';
import React, { FC,useEffect, useContext, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { StoreContext } from 'src/database/store/StoreProvider';
import useCurrentUser from 'src/hooks/useCurrentUser';
import AddJob from '../modals/AddJob/AddJob';
import ClosedJobs from './ClosedJobs';
import OpenJobs from './OpenJobs';
import PendingJobs from './PendingJobs';
import { JobFilter, JobFilterResult } from './JobFilter';
import './jobs.module.css'
import { useLocation } from "react-router-dom";
import useNavStore from '../RootNavBarIcon/NavStore';

type JobsProps = {};

const Jobs: FC<JobsProps> = () => {
  let location = useLocation();

  const queryParams = new URLSearchParams(location.search)
  const job_type = queryParams.get('jobtype')
  let is_open = true
  let is_close = true
  let is_pending = true

  if (job_type) {
    is_close = false
    is_pending = false
  }
  const [addJobModalIsOpen, setAddJobModalIsOpen] = useState(false);
  const { store } = useContext(StoreContext);
  const dbUser = useCurrentUser();
  const [filterState, setFilterState] = useState<JobFilterResult>({ type: '*', value: '' })

  const pageTitle = useMemo(() => {
    if (dbUser?.role_id_ref.name !== 'customer') return 'Jobs';
    const customerName = _.find(store.workgroups, (wg) => !!_.find(wg.users, (user) => user.id === dbUser.id))?.organization_id_ref?.name;
    console.log(customerName);
    return `${customerName} Jobs`
  }, [dbUser, store.workgroups])
  const { isToogle } = useNavStore();
  return (


    <div className={'mainContentContainer'} style={{marginLeft:isToogle==='YES'?'90px':''}}>
      <div className="header" style={{marginLeft:isToogle==='YES'?'0px':''}}>

        <h1>{pageTitle}</h1>
        <JobFilter onChanges={(res: JobFilterResult) => setFilterState(res)} />

        {
          dbUser?.role_id_ref.name === 'administrator' &&
          <button onClick={() => setAddJobModalIsOpen(true)}>New Job</button>
        }
      </div>
      {dbUser?.role_id_ref.name !== 'technician' &&
        <>

        {is_pending ? <>

          <h3 className="listTitle">Pending</h3>
          <PendingJobs filterField={filterState.type} filterValue={filterState.value} />
        </> : <></>}
        </>
      }

      {is_open ? <>
        <h3 className="listTitle">Open</h3>
        <OpenJobs filterField={filterState.type} filterValue={filterState.value} />
      </> : <></>}

      {is_close ? <>
        <h3 className="listTitle">Closed</h3>
      <ClosedJobs filterField={filterState.type} filterValue={filterState.value} />
      </> : <></>}

      <Modal isOpen={addJobModalIsOpen} onRequestClose={() => setAddJobModalIsOpen(false)} className="modal" overlayClassName="modalOverlay">
        <AddJob closeModal={() => setAddJobModalIsOpen(false)} />
      </Modal>
    </div>
  );
};

export default Jobs;
