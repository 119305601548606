import { useContext } from "react";
import { authCtx } from "src/api/AuthProvider";
import { StoreContext } from "src/database/store/StoreProvider";

export default function useCurrentUser() {
    const { user } = useContext(authCtx);
    const { store } = useContext(StoreContext);
    
  if (!user) return null;
  return store.users[user!.id];
}