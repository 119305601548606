import { BelongsToAssociation, HasManyAssociation } from "@nozbe/watermelondb/Model";
import { children, field, relation, writer } from "@nozbe/watermelondb/decorators";
import { Query, Relation } from "@nozbe/watermelondb";
import { Association } from "src/types/watermelon";
import BaseModel from "./BaseModel";
import Organization from "./Organization";
import User from "./User";
import JobWorkgroup from "./JobWorkgroup";
import CalibrationProcedure from "./CalibrationProcedure";
import JobSensor from "./JobSensor";

export default class Workgroup extends BaseModel {
  static table = "workgroups";
  static associations = {
    organizations: {
      type: Association.BELONGS_TO,
      key: "organization_id",
    } as BelongsToAssociation,
    users: {
      type: Association.HAS_MANY,
      foreignKey: "workgroup_id",
    } as HasManyAssociation,
    job_workgroups: {
      type: Association.HAS_MANY,
      foreignKey: "workgroup_id",
    } as HasManyAssociation,
    calibration_procedures: {
      type: Association.HAS_MANY,
      foreignKey: "customer_workgroup_id",
    } as HasManyAssociation,
    job_sensors: {
      type: Association.HAS_MANY,
      foreignKey: "workgroup_id",
    } as HasManyAssociation,
  };
  getTableName() {
    return Workgroup.table;
  }
  @field("name") name!: string;
  @field("is_elevated_workgroup") isElevatedWorkgroup!: boolean;
  @field("state") state!: string;
  @field("city") city!: string;
  @field("phone") phone!: string;
  @field("website") website!: string;
  @field("address") address!: string;
  @field("zipcode") zipcode!: string;
  @relation("organizations", "organization_id") organization!: Relation<Organization>;
  @children("users") users!: Query<User>;
  @children("calibration_procedures") calibrationProcedures!: Query<CalibrationProcedure>;
  @children("job_workgroups") jobWorkgroups!: Query<JobWorkgroup>;
  @children("job_sensors") jobSensors!: Query<JobSensor>;

  @writer async editName(name: string) {
    await this.update((workgroup) => {
      workgroup.name = name;
    })
  }
  @writer async deleteRecord() {
		await this.markAsDeleted();
	}
}
