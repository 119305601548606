import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { FC, useContext, useMemo } from 'react';
import { StoreContext } from 'src/database/store/StoreProvider';
import { PopColl } from 'src/database/store/StoreTypes';
import styles from './searchBar.module.css';

type SearchBarProps = {
  placeholder?: string;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}

export const SearchBar: FC<SearchBarProps> = ({ searchQuery, setSearchQuery, placeholder }) => (
  <div className={styles.searchBar}>
    <FontAwesomeIcon icon="search" />
    <input onChange={({ target }) => setSearchQuery(target.value)} value={searchQuery} type="text" placeholder={placeholder || "Search..."}></input>
  </div>
)

export function useSearch<T>(objColl: PopColl<T>, searchQuery: string, filterFunction: (record: T, test: (string: string) => boolean) => boolean) {
  // lazy again, but store observance is the only way to guarantee proper updates
  const { store } = useContext(StoreContext);
  // lazy, but getting lodash functions to accept objects and arrays without pissing off ts is absolute hell
  const coll = _.map(objColl);

  return useMemo(() => {
    if (!searchQuery) return coll;
    const query = new RegExp(searchQuery, 'i');
    return coll.filter((record) => filterFunction(record, query.test.bind(query)))
  }, [searchQuery, coll, filterFunction])
}