import { BelongsToAssociation, HasManyAssociation } from "@nozbe/watermelondb/Model";
import { writer, children, field, relation } from "@nozbe/watermelondb/decorators";
import { Query, Relation } from "@nozbe/watermelondb";
import { Association } from "src/types/watermelon";
import BaseModel from "./BaseModel";
import Role from "./Role";
import TechnicianTool from "./TechnicianTool";
import Workgroup from "./Workgroup";
import Certificate from "./Certificate";
import Metadata from "./Metadata";
import Tool from "./Tool";
import JobResource from "./JobResource";

export default class User extends BaseModel {
  static table = "users";
  static associations = {
    roles: {
      type: Association.BELONGS_TO,
      key: "role_id",
    } as BelongsToAssociation,
    workgroups: {
      type: Association.BELONGS_TO,
      key: "workgroup_id",
    } as BelongsToAssociation,
    technician_tools: {
      type: Association.HAS_MANY,
      foreignKey: "technician_user_id",
    } as HasManyAssociation,
    certificates: {
      type: Association.HAS_MANY,
      foreignKey: "user_id",
    } as HasManyAssociation,
    metadata: {
      type: Association.BELONGS_TO,
      key: "metadata_id",
    } as BelongsToAssociation,
    job_technicians: {
      type: Association.HAS_MANY,
      foreignKey: "technician_id",
    } as HasManyAssociation,
    job_resources: {
      type: Association.HAS_MANY,
      foreignKey: "user_id",
    } as HasManyAssociation,
  };
  getTableName() {
    return User.table;
  }
  @field("auth_0_id") auth0Id!: string;
  @field("name") name!: string;
  @field("email") email!: string;
  @field("phone") phone!: string;
  @field("isjobs") isjobs!: boolean;
  @field("isverified") isverified!: boolean;
  @field("is_survey_tool") is_survey_tool!: boolean;
  @field("is_employeees") is_employeees!: boolean;
  @field("is_company_assets") isCompanyAssets!: boolean;
  @field("is_alerts") isAlerts!: boolean;
  @field("is_calendar") is_calendar!: boolean;
  @relation("workgroups", "workgroup_id") workgroup!: Relation<Workgroup>;
  @relation("roles", "role_id") role!: Relation<Role>;
  @relation("metadata", "metadata_id") metadata!: Relation<Metadata>;
  @children("technician_tools") technicianTools!: Query<TechnicianTool>;
  @children("certificates") certificates!: Query<Certificate>;
  @children("job_technicians") jobTechnicians!: Query<TechnicianTool>;
  @children("job_resources") jobResources!: Query<JobResource>;
  @writer async addTool(toolId: string, serialNum: string, calibrationDate: Date, calibrationDueDate: Date) {
    const user = this;
    const tool = await this.collections.get<Tool>('tools').find(toolId);
    this.collections.get<TechnicianTool>('technician_tools').create((techTool) => {
      techTool.serialNumber = serialNum;
      techTool.technician.set(user);
      techTool.tool.set(tool);
      techTool.calibrationDate = calibrationDate.getTime();
      techTool.calibrationDueDate = calibrationDueDate.getTime();
    })
  }
  @writer async updateDetails(details: UpdateDetailsParams) {
    const { firstName, lastName, email, roleId, orgId, groupId, is_calendar,
      isjobs,
      is_survey_tool,
      is_employeees,
      is_company_assets,
      isAlerts,isverified } = details;
       return this.update((user) => {
          user.name = `${firstName} ${lastName}`;
      if (email) user.email = email;
      if (roleId) user.role.id = roleId;
      if (groupId) user.workgroup.id = groupId;
      user.is_calendar = is_calendar??false
      user.is_employeees = is_employeees??false
      user.isCompanyAssets = is_company_assets??false
      user.isAlerts = isAlerts??false
      user.isjobs = isjobs??false
      user.is_survey_tool = is_survey_tool??false
      if (isverified) user.isverified = isverified;
 })
  }
  @writer async deletePermanentlyUserRecord(){
  return   this.deletePermanentlyRecord()
  }
}

type UpdateDetailsParams = {
  firstName: string;
  lastName: string;
  email?: string;
  roleId?: string;
  orgId?: string;
  groupId?: string;
  is_calendar?: boolean;
  isjobs?: boolean;
  is_survey_tool?: boolean;
  is_employeees?: boolean;
  is_company_assets?: boolean;
  isAlerts?: boolean;
  isverified?: boolean;
}