import React, { FC, useCallback, useContext, useMemo, useState } from "react";
import { useEffect } from "react";
import { authCtx } from "src/api/AuthProvider";
import { database } from "src/database";
import { StoreContext } from "src/database/store/StoreProvider";
import Organization from "src/models/Organization";
import Workgroup from "src/models/Workgroup";

type AddGroupProps = {
  orgId?: string;
  closeModal: () => void;
  groupId?: string;
}

const AddGroup: FC<AddGroupProps> = ({ orgId = '', closeModal, groupId = '' }) => {
  const { store } = useContext(StoreContext);
  const { justSync } = useContext(authCtx);
  const [groupName, setGroupName] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const workgroup = useMemo(() => groupId ? store.workgroups[groupId] : undefined, [store.workgroups, groupId]);

  /**
   * Handles adding a new group
   */
  const handleSubmit = useCallback(async () => {
    if (!(groupName) || !orgId) return;
    setIsProcessing(true);
    const orgModel = await database.collections.get<Organization>('organizations').find(orgId);
    await orgModel.createWorkgroup(groupName)
    justSync();
    setIsProcessing(false);
    closeModal();
  }, [groupName, closeModal, orgId]);

  /**
   * Handles editing an existing group
   */
  const handleSave = useCallback(async () => {
    if (!groupId || !groupName) return;
    setIsProcessing(true);
    const workgroupModel = await database.collections.get<Workgroup>('workgroups').find(groupId);
    await workgroupModel.editName(groupName);
    justSync();
    setIsProcessing(false);
    closeModal();
  }, [groupId, groupName, closeModal]);

  useEffect(() => {
    if (!workgroup) return;
    setGroupName(workgroup.name);
  }, [workgroup])

  return (
    <div className="modalContainer">
      <div className="modalHeader">
        <h2>{workgroup ? 'Create' : 'Edit'} Group</h2>
      </div>
      <div className="content">
        <label>Group Name</label>
        <input
          onChange={({ target }) => setGroupName(target.value)}
          value={groupName} />
      </div>
      <div className="footer">
        <button disabled={isProcessing} className="cancel" onClick={closeModal}>Cancel</button>
        {workgroup ?
          <button disabled={!groupName || isProcessing} onClick={handleSave}>Save Group</button> :
          <button disabled={!groupName || isProcessing} onClick={handleSubmit}>Create Group</button>
        }
      </div>
    </div>
  );
};

export default AddGroup;