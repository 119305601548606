import React, { useCallback, useEffect, useState } from 'react';
import NavigationPrompt from "react-router-navigation-prompt";
import {
	BrowserRouter,
	Switch,
	Route,
} from 'react-router-dom';
import { Prompt } from "react-router";
import CustomerDetail from '../CustomerDetail/CustomerDetail';
import Customers from '../Customers/Customers';
import JobDetail from '../JobDetail/JobDetail';
import Jobs from '../Jobs/Jobs';
import WorkgroupJobs from '../Jobs/WorkgroupJobs';
import ProbeDetail from '../ProbeDetail/ProbeDetail';
import Probes from '../Probes/Probes';
import UserDetail from '../UserDetail/UserDetail';
import Users from '../Users/Users';
import { RedirectRoute } from '../EmailLinkRedirect/EmailLinkRedirect';
import styles from './router.module.css';
import Login from '../Login';
import { Dashboard } from '../Dashboard';
import CompanyAssets from '../../page/CompanyAssets/ListAsset/list';
import Alert from '../../page/Alert/listAlert';
import { Calender } from '../../page/Calender/calender';
import NavigationMenuIcon from '../RootNavBarIcon/NavigationMenuIcon';
import SurveyTool from 'src/page/SurveyTool/SurveyTool/SurveyTool';
import Tools from 'src/page/Tools/Tools/Tools';
import useNavStore from '../RootNavBarIcon/NavStore';
import Tooldetails from 'src/page/Tools/ToolDetails/Tooldetails';
const Router = () => {
	const { isSensorCalibrationPage } = useNavStore();
	useEffect(()=>{
	},[isSensorCalibrationPage
	])
	return (
		<BrowserRouter>
			<div className={styles.container}>
				<NavigationMenuIcon />
				<div className={styles.content}>
					<Switch>
						<Route path="/login">
							<Login />
						</Route>
						<Route exact path="/jobs" >
							<Jobs />
						</Route>
						<Route
							exact
							path={'/jobs/:jobId/:tabId?'}
							component={JobDetail}
						></Route>
						<Route exact path="/">
							<Dashboard />
						</Route>
						<Route exact path="/customers">
							<Customers />
						</Route>
						<Route exact path="/customers/:orgId">
							<CustomerDetail />
						</Route>
						<Route path="/customers/:orgId/workgroup/:workgroupId">
							<WorkgroupJobs />
						</Route>

						<Route exact path="/customers">
							<Customers />
						</Route>
						<Route exact path="/customers/:orgId">
							<CustomerDetail />
						</Route>
						<Route path="/customers/:orgId/workgroup/:workgroupId">
							<WorkgroupJobs />
						</Route>
						<Route exact path="/users">
							<Users />
						</Route>
						<Route path="/users/:userId">
							<UserDetail />
						</Route>
						<Route exact path="/probes">
							<Probes />
						</Route>
						<Route path="/probes/:probeId">
							<ProbeDetail />
						</Route>
						<Route path="/CompanyAssets">
							<CompanyAssets />
						</Route>
						<Route path="/Calendar">
							<Calender />
						</Route>
						<Route path="/Alerts">
							<Alert />
						</Route>
						<Route path="/SurveyTool">
							<SurveyTool />
						</Route>
						<Route path="/calibrationTool">
							<Tools />
						</Route>
						<Route path="/calibrationToolDetails/:id">
							<Tooldetails />
						</Route>
						<RedirectRoute />
					</Switch>
					{
						isSensorCalibrationPage &&
						<Prompt message="Do you want to leave sensor calibration page?" />
					}
					
        
				</div>
			</div>
		</BrowserRouter>
	);
};

export default Router;
