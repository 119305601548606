import _ from 'lodash';
import Bugsnag from '@bugsnag/browser';
import React, { FC, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import { authCtx } from 'src/api/AuthProvider';
import { StoreContext } from 'src/database/store/StoreProvider';
import { UsersDBEntry } from 'src/database/store/StoreTypes';
import { Table, useTable } from 'src/hooks/useTable';
import TableHeader, { SortingPackage } from '../TableHeader/TableHeader';
import styles from './users.module.css';
import AddUser from '../modals/AddUser/AddUser';
import useNavStore from '../RootNavBarIcon/NavStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCurrentUser from 'src/hooks/useCurrentUser';
import urls from 'src/api/urls';
import { toast } from 'react-toastify';
import { database } from 'src/database';
import User from 'src/models/User';

// import { getLastName } from '../../utils/general';

type ColumnKey =
	| 'name'
	| 'email'
	| 'organization'
	| 'group'
	| 'role'
	| 'isverified'
	| 'action'
	| 'lastName';
const userTable: Table<UsersDBEntry, ColumnKey> = {
	columns: [
		{
			key: 'name',
			title: 'Name',
			extractFunction: (user) => user?.name,
			// customSortFunction: (userA, userB) => {
			// 	const userALast = getLastName(userA.name);
			// 	const userBLast = getLastName(userB.name);
			// 	if (userALast > userBLast) return 1;
			// 	else if (userALast < userBLast) return -1;
			// 	else return 0;
			// },
		},
		{
			key: 'email',
			title: 'Email',
			extractFunction: (user) => user?.email,
		},
		{
			key: 'organization',
			title: 'Organization',
			extractFunction: (user) =>
				user.workgroup_id_ref?.organization_id_ref?.name,
		},
		{
			key: 'group',
			title: 'Group',
			extractFunction: (user) => user.workgroup_id_ref?.name,
		},
		{
			key: 'role',
			title: 'Role',
			extractFunction: (user) => _.capitalize(user?.role_id_ref?.name),
		},
		{
			key: 'isverified',
			title: 'Status',
			extractFunction: (user) => (user?.isverified ? '1' : '0'),
			headerAlign: 'center',
		},
		{
			key: 'action',
			title: 'Action',
			headerAlign: 'center',
		},
	],
};

type UsersProps = {};

const Users: FC<UsersProps> = () => {
	const { accessToken, justSync } = useContext(authCtx);
	const { isToogle } = useNavStore();
	useEffect(() => {}, [isToogle]);
	const history = useHistory();
	const { store } = useContext(StoreContext);
	const { users } = store;
	const [addUserModalIsOpen, setAddUserModalIsOpen] = useState(false);
	const [currentSort, setCurrentSort] = useState<ColumnKey>('name');
	const [isAscending, setIsAscending] = useState(true);
	const [isFcsEmployee, setIsFcsEmployee] = useState(false);
	const [isCustomerOnly, setIsCustomerOnly] = useState(false);

	const roleId = localStorage.getItem('user-role-id') || '';

	let location = useLocation();

	const queryParams = new URLSearchParams(location.search);
	const org_type = queryParams.get('org');
	const user_type = queryParams.get('customer');
	const dbUser = useCurrentUser();
	const dbUserId = useMemo(() => dbUser?.id, [dbUser]);
	if (user_type === 'customer' && !isCustomerOnly) {
		setIsCustomerOnly(true);
	}

	if (org_type == 'FCS' && !isFcsEmployee) {
		setIsFcsEmployee(true);
	}
	if (!org_type && isFcsEmployee) {
		setIsFcsEmployee(false);
	}

	const tableData = useTable(userTable, users, currentSort, isAscending);

	const sortingPackage: SortingPackage<ColumnKey> = {
		setCurrentSort,
		currentSort,
		setIsAscending,
		isAscending,
	};
	const deleteOneUser = async (id: string, email: string) => {
		let canDeleteUser=true
		if(dbUserId==id){
			alert('You are not allow to delete logged in user')
			 canDeleteUser=false
			return;
		}
		if (canDeleteUser && id && window.confirm(`Are You Sure To Delete ${email}`)) {
			const { base, deleteUser } = urls;

			try {
				const userModel = await database.collections
					.get<User>('users')
					.find(id);
					console.log('user deleted from WatermelonDB');
					
				await userModel.deletePermanentlyRecord()
				await fetch(`${base}${deleteUser.url}/${id}`, {
					method: deleteUser.method,
					headers: {
						Authorization: `Bearer ${accessToken}`,
						'Content-Type': 'application/json',
					},
				}).then((res) => {
					if (res?.status == 204) {
						justSync();
						toast.error(`User:${email} Deleted Successfully`, {
							autoClose: 5000,
						});
					}
				});
			} catch (error) {
				console.log('Error while deleting User', error);

				toast.error(`Error while deleting User:${email}`, {
					autoClose: 5000,
				});
			}
		}
	};
	return (
		<div className={'mainContentContainer'}>
			<div className="header">
				<h1>Users</h1>
				<button onClick={() => setAddUserModalIsOpen(true)}>
					Add User
				</button>
			</div>
			<div style={{ marginLeft: isToogle === 'YES' ? '90px' : '' }}>
				<TableHeader
					columns={userTable.columns}
					rowClassName={styles.row}
					sortingPackage={sortingPackage}
				/>
				{isFcsEmployee &&
					tableData
						.filter(
							(data) =>
								data.organization == 'Function Compliance Services'
						)
						.map(
							({
								name,
								email,
								organization,
								group,
								role,
								id,
								isverified,
							}) => (
								<div
									key={id}
									className={`${styles.row} hvrPtr row`}
									onClick={() => history.push(`/users/${id}`)}
								>
									<p>{name}</p>
									<p>{email}</p>
									<p>{organization}</p>
									<p>{group}</p>
									<p>{role}</p>
									{isverified == '1' ? (
										<p className={styles.Active}></p>
									) : (
										<p className={styles.inActive}></p>
									)}
									<p style={{ textAlign: 'center' }}>
										<FontAwesomeIcon
											className={styles.actionEdit}
											onClick={() => history.push(`/users/${id}`)}
											icon="pen-square"
											size="1x"
											cursor="pointer"
										/>
										{/* <FontAwesomeIcon
											className={styles.actionDel}
											onClick={() => deleteOneUser(id, email)}
											icon="trash"
											size="1x"
											cursor="pointer"
										/> */}
									</p>
								</div>
							)
						)}
				{isCustomerOnly &&
					tableData
						.filter((data) => data.role == 'Customer')
						.map(
							({
								name,
								email,
								organization,
								group,
								role,
								id,
								isverified,
							}) => (
								<div
									key={id}
									className={`${styles.row} hvrPtr row`}
								
								>
									<p>{name}</p>
									<p>{email}</p>
									<p>{organization}</p>
									<p>{group}</p>
									<p>{role}</p>
									{isverified == '1' ? (
										<p className={styles.Active}></p>
									) : (
										<p className={styles.inActive}></p>
									)}
									<p style={{ textAlign: 'center' }}>
										<FontAwesomeIcon
											className={styles.actionEdit}
											onClick={() => history.push(`/users/${id}`)}
											icon="pen-square"
											size="1x"
											cursor="pointer"
										/>
										{/* <FontAwesomeIcon
											className={styles.actionDel}
										
											onClick={() => deleteOneUser(id, email)}
											icon="trash"
											size="1x"
											cursor="pointer"
										/> */}
									</p>
								</div>
							)
						)}

				{!isFcsEmployee &&
					!isCustomerOnly &&
					tableData.map(
						({
							name,
							email,
							organization,
							group,
							role,
							id,
							isverified,
						}) => (
							<div key={id} className={`${styles.row} hvrPtr row`}>
								<p>{name}</p>
								<p>{email}</p>
								<p>{organization}</p>
								<p>{group}</p>
								<p>{role}</p>
								{isverified == '1' ? (
									<p className={styles.Active}></p>
								) : (
									<p className={styles.inActive}></p>
								)}

								<p style={{ textAlign: 'center' }}>
									<FontAwesomeIcon
										className={styles.actionEdit}
										onClick={() => history.push(`/users/${id}`)}
										icon="pen-square"
										size="1x"
										cursor="pointer"
									/>
									<FontAwesomeIcon
										className={styles.actionDel}
										onClick={() => deleteOneUser(id, email)}
										icon="trash"
										size="1x"
										cursor="pointer"
									/>
								</p>
							</div>
						)
					)}
			</div>
			<Modal
				isOpen={addUserModalIsOpen}
				onRequestClose={() => setAddUserModalIsOpen(false)}
				className="modal"
				overlayClassName="modalOverlay"
			>
				<AddUser closeModal={() => setAddUserModalIsOpen(false)} />
			</Modal>
		</div>
	);
};

export default Users;
