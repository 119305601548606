import React, { FC, useCallback, useContext, useState } from 'react';
import _, { compact, map, orderBy, reduce } from 'lodash';
import { authCtx } from 'src/api/AuthProvider';
import { database } from 'src/database';
import Job from 'src/models/Job';
import { StoreContext } from 'src/database/store/StoreProvider';
type AddSensorProps = {
	jobId: string;
	closeModal: () => void;
};

const AddSensor: FC<AddSensorProps> = ({ jobId, closeModal }) => {
	const { justSync } = useContext(authCtx);
	const [name, setName] = useState('');
	const [sensorId, setSensorId] = useState('');
	const [sensorType, setSensorType] = useState('');
	const [probeType, setProbeType] = useState('');
	const [is_lumity, setIsLumity] = useState(0);
	
	const [sensorProbeNumber, setSensorProbeNumber] = useState('');
	const [sensorGroup, setSensorGroup] = useState('');
	const [building, setBuilding] = useState('');
	const [floor, setFloor] = useState('');
	const [room, setRoom] = useState('');
	const [chamber, setChamber] = useState('');
	const [description, setDescription] = useState('');
	const [isProcessing, setIsProcessing] = useState(false);
	const { store } = useContext(StoreContext);
	const isSubmissionReady =
		name && sensorType&&
		sensorId &&
		sensorGroup &&
		building &&
		floor &&
		room &&
		chamber;

	const handleSubmit = useCallback(async () => {
		if (!isSubmissionReady) return;
		setIsProcessing(true);
		const job = await database.collections.get<Job>('jobs').find(jobId);
		await job.addSensor({
			sensorType,
      is_lumity,
	  
			name,
			sensorId,
			sensorProbeNumber: parseInt(sensorProbeNumber),
			sensorGroup,
			building,
			floor,
			room,
			chamber,
			description,
		});
		justSync();
		closeModal();
		setIsProcessing(false);
	}, [
		isSubmissionReady,
		sensorType,
		name,
    is_lumity,
	
		sensorId,
		sensorProbeNumber,
		sensorGroup,
		building,
		floor,
		room,
		chamber,
		description,
		jobId,
		closeModal,
	]);

	//sensor Type------------------------
	const handleSensorType = () => {
		let lumity, probeType, sensorId, sensor_type;
		const sensorIdElement: any = document.querySelector(
			`input[name='SensorId']`
		);
		const lumityElement: any = document.querySelector(
			`select[name='lumity']`
		);
		const probeTypeElement: any = document.querySelector(
			`select[name='probeType']`
		);

		lumity = lumityElement.value;
		sensorId = sensorIdElement.value;
		probeType = probeTypeElement.value;

		if (sensorId) {
			if (lumity == 1) {
				if (
					probeType == '5fd7573c-e015-4d1e-8fd6-55c8ebef427d' ||
					probeType == '6c6ae773-5d70-46c7-a414-f9286b61b5c3'
				) {
					sensor_type = 'FX200 (LUMITY-TH)';
				} else {
					sensor_type = 'FX100 (LUMITY-T)';
				}
			} else {
				if (/[a-zA-Z]/.test(sensorId)){
          if (
            probeType == '5fd7573c-e015-4d1e-8fd6-55c8ebef427d' ||
            probeType == '6c6ae773-5d70-46c7-a414-f9286b61b5c3'
          ) {
            sensor_type = '11079(WIFI-TH)';
          } else {
            sensor_type = '11078(WIFI-T)';
          }
				} else {
					if (
						probeType == '5fd7573c-e015-4d1e-8fd6-55c8ebef427d' ||
						probeType == '6c6ae773-5d70-46c7-a414-f9286b61b5c3'
					) {
						sensor_type = '10071 (900-TH)';
					} else {
						sensor_type = '10080-DT (900-T)';
					}
				}
			}
		} else {
			sensor_type = '';
		}
		const sensorTypeElement: any = document.querySelector(
			`input[name='sensorType']`
		);
		sensorTypeElement.value = sensor_type;
		setSensorType(sensor_type)
	};

	/**
	 * Another hacky workaround for the sensor ID and probe number issue
	 */
	const handleSensorId = (idStr: string) => {
		if (idStr.includes('/')) {
			const [sensorRealId, probeNumber] = idStr.split('/');
			if (probeNumber) {
				setSensorId(sensorRealId);
				setSensorProbeNumber(probeNumber);
			} else {
				setSensorId(idStr);
				setSensorProbeNumber('');
			}
		} else {
			setSensorId(idStr);
			setSensorProbeNumber('');
		}
	};

	// TODO: this render ccode is duplicated in calibrateSensor - refactor
	return (
		<div className="modalContainer">
			<div className="modalHeader">
				<h2>Add Sensor</h2>
			</div>

			<div className="content">
				<div
					className="multiCol"
					style={{ gridTemplateColumns: '1fr 1fr 1fr' }}
				>
					<div>
						<label>Sensor Name</label>
						<input
							onChange={({ target }) => setName(target.value)}
							value={name}
						/>
					</div>
					<div>
						<label>Sensor ID</label>
						<input
							name="SensorId"
              onBlur={()=>handleSensorType()}
							onChange={(event) => handleSensorId(event.target.value)}
							value={
								sensorProbeNumber
									? `${sensorId}/${sensorProbeNumber}`
									: sensorId
							}
						/>
					</div>
					<div>
						<label>Lumity</label>
						<div className="selectContainer">
							<select
								name="lumity"
								onChange={(event) => {handleSensorType();setIsLumity(Number(event.target.value))}}
							>
								<option key={0} value={0}>NO</option>
								<option key={1} value={1}>YES</option>
							</select>
						</div>
					</div>
					<div>
						<label>Probe Type</label>
						<div className="selectContainer">
							<select
								name="probeType"
								onChange={() => handleSensorType()}
							>
								<option value="">Select Probe...</option>

								{_.map(store.probe_types, ({ id, name }) => (
									<option key={id} value={id}>
										{name}
									</option>
								))}
							</select>
						</div>
					</div>

					<div>
						<label>Sensor Type</label>
						<input name="sensorType" disabled placeholder="Sensor Type" />
					</div>
					<div>
						<label>Group</label>
						<input
							onChange={({ target }) => setSensorGroup(target.value)}
							value={sensorGroup}
						/>
					</div>
				</div>

				<h3>Location</h3>
				<label>Building</label>
				<input
					onChange={({ target }) => setBuilding(target.value)}
					value={building}
				/>
				<div
					className="multiCol"
					style={{ gridTemplateColumns: '1fr 1fr' }}
				>
					<div>
						<label>Floor</label>
						<input
							onChange={({ target }) => setFloor(target.value)}
							value={floor}
						/>
					</div>
					<div>
						<label>Room</label>
						<input
							onChange={({ target }) => setRoom(target.value)}
							value={room}
						/>
					</div>
				</div>
				<label>Chamber</label>
				<input
					onChange={({ target }) => setChamber(target.value)}
					value={chamber}
				/>
				<label>Description</label>
				<textarea
					rows={5}
					onChange={({ target }) => setDescription(target.value)}
					value={description}
				/>
			</div>
			<div className="footer">
				<button
					disabled={isProcessing}
					className="cancel"
					onClick={closeModal}
				>
					Cancel
				</button>
				<button
					disabled={!isSubmissionReady || isProcessing}
					onClick={handleSubmit}
				>
					Submit
				</button>
			</div>
		</div>
	);
};

export default AddSensor;
