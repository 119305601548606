import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export function HighchartComp(props: any) {
	return (
    <div>
      <HighchartsReact
    highcharts={Highcharts}
      options={props.options}
  />
    </div>
  )
}
