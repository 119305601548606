import { Query, Relation } from "@nozbe/watermelondb";
import { BelongsToAssociation, HasManyAssociation } from "@nozbe/watermelondb/Model";
import { field, date, relation, children, writer } from "@nozbe/watermelondb/decorators";
import { Association } from "src/types/watermelon";
import BaseModel from "./BaseModel";
import JobSensor from "./JobSensor";
import ProbeType from "./ProbeType";
import TechnicianTool from "./TechnicianTool";
import Certificate from "./Certificate";
import CalibrationProcedure from "./CalibrationProcedure";
import CalibrationReading from "./CalibrationReading";
import { database } from "src/database";
// import { Partial } from "lodash";

export type CalibrationStatus = 'failed' | 'valid' |'Certified'|'Calibrated'|'InProgress'|'Issue'|'No';

export default class Calibration extends BaseModel {
  inlineUpdateCal(arg0: any) {
	  throw new Error('Method not implemented.');
  }

  static table = "calibrations";
  static associations = {
    job_sensors: {
      type: Association.BELONGS_TO,
      key: "job_sensor_id",
    } as BelongsToAssociation,
    probe_types: {
      type: Association.BELONGS_TO,
      key: "probe_type_id",
    } as BelongsToAssociation,
    technician_tools: {
      type: Association.BELONGS_TO,
      key: "technician_tool_id",
    } as BelongsToAssociation,
    certificates: {
      type: Association.HAS_MANY,
      foreignKey: "calibration_id",
    } as HasManyAssociation,
    calibration_procedures: {
      type: Association.BELONGS_TO,
      key: "calibration_procedure_id",
    } as BelongsToAssociation,
    calibration_readings: {
      type: Association.HAS_MANY,
      foreignKey: "calibration_id",
    } as HasManyAssociation

  };
  getTableName() {
    return Calibration.table;
  }
  
  @field("ambient_temperature") ambientTemperature!: number;
  @field("ambient_humidity") ambientHumidity!: number;
  @date("ambient_reading_at") ambientReadingDate!: Date;
  @field("remote_temperature") remoteTemperature!: number;
  @field("device_temperature") deviceTemperature!: number;
  @field("probe_serial_number") probeSerialNumber!: string;
  @date("calibrated_at") calibratedDate!: Date;
  @field("notes") notes!: string;
  @field("status") status!: string;
  @field("probe_replacement_status") probeReplacementStatus!: string;
  @field("ispassed") ispassed!:string| null;
  @relation("job_sensors", "job_sensor_id") jobSensor!: Relation<JobSensor>;
  @relation("probe_types", "probe_type_id") probeType!: Relation<ProbeType>;
  @relation("technician_tools", "technician_tool_id") technicianTool!: Relation<TechnicianTool>;
  @children("certificates") certificates!: Query<Certificate>;
  @children("calibration_readings") calibrationReadings!: Query<CalibrationReading>;
  @relation("calibration_procedures", "calibration_procedure_id") calibrationProcedure!: Relation<CalibrationProcedure>;

  @writer addReading(params: addReadingParams) {
    return database.collections.get<CalibrationReading>('calibration_readings').create((calReading) => {
      Object.assign(calReading, params);
      calReading.calibration.set(this);
    })
  }
}

type ReadingKeys = 'remoteTemperature' | 'deviceTemperature' | 'remoteHumidity' | 'deviceHumidity'

export type addReadingParams  = Partial<{
  SequenceNumber: number
  device_temperature:number
  remote_temperature:number
  readingTime:string

  device_temperature2:number
  remote_temperature2:number
  reading2Time:Date

  remote_temperature3:number
  device_temperature3:number
  reading3Time:Date

  device_temperature4:number
  deviceTemperature4:number
  reading4Time:Date
  
}> | { [k in ReadingKeys]:number };

export type CalibrationUpdateBody = Pick<
  Calibration,
  "ambientTemperature"
  | "ambientHumidity"
  | "ambientReadingDate"
  | "remoteTemperature"
  | "deviceTemperature"
  | "probeSerialNumber"
  | "calibratedDate"
  | "notes"
  | "status"
  | "ispassed"
>;