import { Relation } from "@nozbe/watermelondb";
import { BelongsToAssociation } from "@nozbe/watermelondb/Model";
import { field, relation } from "@nozbe/watermelondb/decorators";
import { Association } from "src/types/watermelon";
import BaseModel from "./BaseModel";
import User from "./User";
import Job from "./Job";

export default class JobResource extends BaseModel {
  static table = "job_resources";
  static associations = {
    users: {
      type: Association.BELONGS_TO,
      key: "user_id",
    } as BelongsToAssociation,
    jobs: {
      type: Association.BELONGS_TO,
      key: "job_id",
    } as BelongsToAssociation,
  };
  getTableName() {
    return JobResource.table;
  }
  @field("filename") filename!: string;
  @field("media_type") mediaType!: string;
  @field("url") url!: string;
  @relation("users", "user_id") certifiedBy!: Relation<User>;
  @relation("jobs", "calibration_id") calibration!: Relation<Job>;
}
