import _, { compact, map, orderBy, reduce } from 'lodash';
import React, {
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StoreContext } from 'src/database/store/StoreProvider';
import Modal from 'react-modal';
import styles from '../jobDetail.module.css';
import moment from 'moment';
import { Table, useTable } from 'src/hooks/useTable';
import {
	CalibrationProceduresDBEntry,
	CalibrationsDBEntry,
	JobResourcesDBEntry,
	JobSensorsDBEntry,
	PopColl,
} from 'src/database/store/StoreTypes';
// import TableHeader, { SortingPackage } from '../../TableHeader/TableHeader';
import AddSensor from '../../modals/AddSensor/AddSensor';
// import CalibrateSensor from '../../modals/CalibrateSensor/CalibrateSensor';
import Job from 'src/models/Job';
import { database } from 'src/database';
import AddTech from '../../modals/AddTech/AddTech';
import urls from 'src/api/urls';
import { authCtx } from 'src/api/AuthProvider';
import { SearchBar, useSearch } from '../../SearchBar/SearchBar';
// import CheckList from '../../CheckList/CheckList';÷
// import StatHeader from '../StatHeader';
import {
	createCsvDataUri,
	getActiveTechs,
	getJobProgress,
	getMostRecentCal,
	getMostRecentCalReadings,
	getMostRecentCert,
	startLocalDownload,
} from 'src/utils/general';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { FilePkg } from '../../modals/AddJob/AddJob';
import { useDropzone } from 'react-dropzone';
import JobSensor, { SensorParams } from 'src/models/JobSensor';
import { CertificateStatus } from 'src/models/Certificate';
import CertificateBatch, {
	CertificateBatchStatus,
} from 'src/models/CertificateBatch';
import { Q } from '@nozbe/watermelondb';
import { eachMonthOfInterval, format } from 'date-fns';
import Bugsnag from '@bugsnag/js';
import '../index.css';
import Calibration from 'src/models/Calibration';
import useNavStore from 'src/components/RootNavBarIcon/NavStore';
import jwtDecode from 'jwt-decode';
// import Notes from '../../modals/Notes/Notes';
// import Calibration,{ CalibrationParams } from "src/models//Calibration";
// import id from 'date-fns/esm/locale/id/index.js';
// import Filter from 'src/components/utility/Filter/FilterComp';
// import { group } from 'console';

// export type ReadingInput<T = string> = {
// 	[k in ReadingKey]: T;
//   } & {
// 	updatedAt: number;
//   }
// import { input } from 'aws-amplify';
let updatesensorIdList: any[] = [];
let updatesensorIdData: any[] = [];
// let calStatusOpt: any[] = [];
let sensorTypeOpt: any[] = [];
let sensorGroupOpt: any[] = [];
// let countCalCertify: any[] = [];
let filterKey: any[] = [];

const POLLING_INTERVAL = parseInt(process.env.POLLING_INTERVAL || '5000');

type ColumnKey =
	| 'calibrations'
	| 'isToCalibration'
	| 'status'
	// | 'check'
	| 'sensorId'
	| 'sensorId_SequenceNumber'
	| 'name'
	| 'group'
	| 'probeType'
	// | 'building'
	// | 'floor'
	// | 'room'
	// | 'chamber'
	| 'notes'
	// | 'probeTypeId'
	| 'technicianToolId'
	| 'cal'
	| 'cert'
	| 'isDisabled'
	// | 'SequenceNumber'
	| 'sensor_type'
	| 'sensor_modal'
	| 'probeSerialNumber'
	// | 'serialNumber'
	| 'ambientTemperature'
	| 'ambientHumidity'
	// | 'sensorReading'
	// | 'calibrationReading'
	| 'description'
	| 'sensor_database'
	| 'tech'
	// | 'calibrationProcedureId'
	| 'updatedAt'
	| 'status'
	| 'lumity'
	| 'timestamp_1'
	| 'tt_reading'
	| 'device_reading'
	| 'tt_reading_2'
	| 'timestamp_2'
	| 'device_reading_2'
	| 'tt_reading_3'
	| 'timestamp_3'
	| 'device_reading_3'
	| 'tt_reading_4'
	| 'timestamp_4'
	| 'device_reading_4'
	| 'result'
	| 'probe_replaced'
	| 'calReading';

enum Tabs {
	SENSORS = 0,
	DOCUMENTS = 1,
	CERTIFICATES = 2,
}

// const statusOrder = {
// 	pending: 0,
// 	failed: 1,
// 	valid: 2,
// };
const sensorsTable: Table<JobSensorsDBEntry, ColumnKey> = {
	columns: [
		{
			key: 'name',
			title: 'Sensor Name',
			extractFunction: (sensor) => sensor.name,
		},
		{
			key: 'description',
			title: 'Description',
			extractFunction: (sensor) => sensor.description,
		},
		{
			key: 'group',
			title: 'Sensor Group',
			extractFunction: (sensor) => {
				let sensor_group = sensor.sensor_group;
				if (sensor_group && !sensorGroupOpt.includes(sensor_group)) {
					sensorGroupOpt.push(sensor_group);
				}
				return sensor_group;
			},
		},

		{
			key: 'sensor_type',
			title: 'Sensor Type',
			extractFunction: (sensor) => {
				let sensor_type = sensor.sensor_type;
				if (sensor_type && !sensorTypeOpt.includes(sensor_type)) {
					sensorTypeOpt.push(sensor_type);
				}
				return sensor_type;
			},
		},
		{
			key: 'sensor_modal',
			title: 'Sensor Modal',
			extractFunction: (sensor) => sensor?.sensor_modal,
			// sensorModal
		},
		{
			key: 'sensorId_SequenceNumber',
			title: 'Sensor ID',
			extractFunction: (sensor) => {
				let sensorId = sensor.sensor_id ? sensor.sensor_id : '';
				let calReadings = getMostRecentCal(sensor);
				let SequenceNumber;
				if (calReadings) {
					let calReading = getMostRecentCalReadings(calReadings);
					SequenceNumber = calReading?.sequence_number ?? '1';
				}
				return sensorId + '/' + SequenceNumber;
			},
		},
		// {
		// 	key: 'SequenceNumber',
		// 	title: 'Seq No.',
		// 	extractFunction: (sensor) => {
		// 		let calReadings = getMostRecentCal(sensor);
		// 		if (calReadings) {
		// 			let calReading = getMostRecentCalReadings(calReadings);
		// 			return calReading?.sequence_number;
		// 		} else return null;
		// 	},
		// },
		{
			key: 'status',
			title: 'Status',
			extractFunction: (sensor) => {
				let status = getMostRecentCal(sensor)?.status;
				// if (status && !calStatusOpt.includes(status)) {
				// 	calStatusOpt.push(status);
				// }
				return status;
			},
		},

		{
			key: 'probeType',
			title: 'Probe Type**',
			extractFunction: (sensor) =>
				getMostRecentCal(sensor)?.probe_type_id || '',
		},
		// {
		// 	key: 'calibrationProcedureId',
		// 	title: 'Procedures**',
		// 	extractFunction: (sensor) =>
		// 		getMostRecentCal(sensor)?.calibration_procedure_id,
		// },

		{
			key: 'ambientTemperature',
			title: 'Amb/T',
			extractFunction: (sensor) => {
				let at = getMostRecentCal(sensor)?.ambient_temperature;
				// console.log('at,',at);
				return at;
			},
		},
		{
			key: 'ambientHumidity',
			title: 'Amb/H',
			extractFunction: (sensor) =>
				getMostRecentCal(sensor)?.ambient_humidity,
		},

		{
			key: 'timestamp_1',
			title: 'Time',

			extractFunction: (sensor) => {
				let calReadings = getMostRecentCal(sensor);
				if (calReadings) {
					return getMostRecentCalReadings(calReadings)?.reading1_date;
				}
			},
		},

		{
			key: 'tt_reading',
			title: 'UUT-R',

			extractFunction: (sensor) => {
				let calReadings = getMostRecentCal(sensor);
				if (calReadings) {
					return getMostRecentCalReadings(calReadings)?.device_temperature;
				} else return null;
			},
		},

		{
			key: 'device_reading',
			title: 'STD-R',
			extractFunction: (sensor) => {
				let calReadings = getMostRecentCal(sensor);
				if (calReadings) {
					return getMostRecentCalReadings(calReadings)?.remote_temperature;
				} else return null;
			},
		},

		{
			key: 'timestamp_2',
			title: 'Time 2',
			extractFunction: (sensor) => {
				let calReadings = getMostRecentCal(sensor);
				if (calReadings) {
					return getMostRecentCalReadings(calReadings)?.reading2_date;
				}
			},
		},
		{
			key: 'tt_reading_2',
			title: 'UUT-R',
			extractFunction: (sensor) => {
				let calReadings = getMostRecentCal(sensor);
				if (calReadings) {
					return getMostRecentCalReadings(calReadings)
						?.device_temperature2;
				} else return null;
			},
		},
		{
			key: 'device_reading_2',
			title: 'STD-R',
			extractFunction: (sensor) => {
				let calReadings = getMostRecentCal(sensor);
				if (calReadings) {
					return getMostRecentCalReadings(calReadings)
						?.remote_temperature2;
				} else return null;
			},
		},

		{
			key: 'timestamp_3',
			title: 'Time 3',
			extractFunction: (sensor) => {
				let calReadings = getMostRecentCal(sensor);
				if (calReadings) {
					return getMostRecentCalReadings(calReadings)?.reading3_date;
				}
			},
		},
		{
			key: 'tt_reading_3',
			title: 'UUT-R',
			extractFunction: (sensor) => {
				let calReadings = getMostRecentCal(sensor);
				if (calReadings) {
					return getMostRecentCalReadings(calReadings)
						?.device_temperature3;
				} else return null;
			},
		},
		{
			key: 'device_reading_3',
			title: 'STD-R',
			extractFunction: (sensor) => {
				let calReadings = getMostRecentCal(sensor);
				if (calReadings) {
					return getMostRecentCalReadings(calReadings)
						?.remote_temperature3;
				} else return null;
			},
		},

		{
			key: 'timestamp_4',
			title: 'Time 4',
			extractFunction: (sensor) => {
				let calReadings = getMostRecentCal(sensor);

				if (calReadings) {
					// let reading4Time =
					return getMostRecentCalReadings(calReadings)?.reading4_date;
					// return reading4Time != null
					// 	? reading4Time
					// 	: new Date().setHours(0, 0, 0);
				}
			},
		},
		{
			key: 'tt_reading_4',
			title: 'UUT-R',
			extractFunction: (sensor) => {
				let calReadings = getMostRecentCal(sensor);
				if (calReadings) {
					return getMostRecentCalReadings(calReadings)
						?.device_temperature4;
				} else return null;
			},
		},
		{
			key: 'device_reading_4',
			title: 'STD-R',
			extractFunction: (sensor) => {
				let calReadings = getMostRecentCal(sensor);
				if (calReadings) {
					return getMostRecentCalReadings(calReadings)
						?.remote_temperature4;
				} else return null;
			},
		},
		{
			key: 'probeSerialNumber',
			title: 'Probe Sr No',
			extractFunction: (sensor) => {
				let probeSr = getMostRecentCal(sensor)?.probe_serial_number;
				if (!probeSr) {
					return 'noProbeSr';
				} else {
					return probeSr;
				}
			},
		},

		{
			key: 'notes',
			title: 'Notes',
			extractFunction: (sensor) => getMostRecentCal(sensor)?.notes,
		},
		// {
		// 	key: 'probeTypeId',
		// 	title: 'Prop Type Id',
		// 	extractFunction: (sensor) => getMostRecentCal(sensor)?.probe_type_id,
		// },

		{
			key: 'result',
			title: 'Result',
			extractFunction: (sensor) => {
				let is_passed = getMostRecentCal(sensor)?.ispassed;
				let r =
					is_passed == '1' ? 'PASS' : is_passed == '0' ? 'FAIL' : null;
				return r;
			},
		},
		{
			key: 'probe_replaced',
			title: 'Probe Replaced',
			extractFunction: (sensor) =>
				getMostRecentCal(sensor)?.probe_replacement_status,
			// extractFunction: (sensor) => sensor.calibrations.probeReplacementStatus,
		},
		{
			key: 'lumity',
			title: 'Lumity Y/N',
			extractFunction: (sensor) => (sensor.is_lumity == true ? 1 : 0),
		},
		{
			key: 'sensor_database',
			title: 'Database',
			extractFunction: (sensor) => sensor.sensor_database,
		},

		{
			key: 'technicianToolId',
			title: 'Tech**',
			extractFunction: (sensor) =>
				getMostRecentCal(sensor)?.technician_tool_id,
		},
		{
			key: 'updatedAt',
			title: 'Date',

			extractFunction: (sensor) => {
				let calReadings = getMostRecentCal(sensor);
				if (calReadings) {
					return getMostRecentCalReadings(calReadings)?.updated_at;
				} else return null;
			},
		},

		// {
		// 	key: 'building',
		// 	title: 'Building',
		// 	extractFunction: (sensor) => sensor.building,
		// },
		// {
		// 	key: 'floor',
		// 	title: 'Floor',
		// 	extractFunction: (sensor) => sensor.floor,
		// },
		// {
		// 	key: 'room',
		// 	title: 'Room',
		// 	extractFunction: (sensor) => sensor.room,
		// },
		// {
		// 	key: 'chamber',
		// 	title: 'Chamber',
		// 	extractFunction: (sensor) => sensor.chamber,
		// },
		// {
		// 	key: 'serialNumber',
		// 	title: 'Serial Number**',
		// 	extractFunction: (sensor) =>
		// 		getMostRecentCal(sensor)?.technician_tool_id_ref?.serial_number,
		// },
		// {
		// 	key: 'sensorReading',
		// 	title: 'Sensor Reading',
		// 	extractFunction: (sensor) => {
		// 		return '';
		// 	},
		// },
		// {
		// 	key: 'calibrationReading',
		// 	title: 'Calibration Reading',
		// 	extractFunction: (sensor) => {
		// 		let readings = _.values(
		// 			getMostRecentCal(sensor)?.calibration_readings
		// 		);
		// 		if (readings.length > 0) {
		// 			return readings[0].device_temperature;
		// 		}

		// 		return ' ';
		// 	},
		// },
		{
			key: 'cert',
			title: 'Action',
			extractFunction: (sensor) => {
				let cal = getMostRecentCal(sensor);
				let cert = getMostRecentCert(cal);
				if (cert) {
					return cert;
				} else {
					return null;
				}
			},
			// 	getMostRecentCert(getMostRecentCal(sensor)),
			// customSortFunction: (a, b) => {
			// 	let aCal = getMostRecentCal(a);
			// 	let aCert = getMostRecentCert(aCal);
			// 	let aVal = aCal?.status !== 'valid' ? 0 : !aCert ? 1 : 2;
			// 	let bCal = getMostRecentCal(b);
			// 	let bCert = getMostRecentCert(bCal);
			// 	let bVal = bCal?.status !== 'valid' ? 0 : !bCert ? 1 : 2;
			// 	return aVal - bVal;
			// },
			// headerAlign: 'center',
		},

		// 	extractFunction: (sensor) => getMostRecentCal(sensor),
		// 	headerAlign: 'center',
		// 	customSortFunction: (a, b) =>
		// 		statusOrder[getMostRecentCal(a)?.status || 'pending'] -
		// 		statusOrder[getMostRecentCal(b)?.status || 'pending'],
		// },

		{
			key: 'isDisabled',
			title: 'Disabled',
			extractFunction: (sensor) => sensor.is_disabled,
		},
		{
			key: 'cal',
			title: 'Cal.',

			extractFunction: (sensor) => {
				let cal = getMostRecentCal(sensor);
				return cal;
			},
		},
		{
			key: 'calReading',
			title: 'calReading',
			extractFunction: (sensor) => {
				let calReadings = getMostRecentCal(sensor);
				if (calReadings) {
					let calReading = getMostRecentCalReadings(calReadings);
					return calReading;
				}
			},
		},
	],
};

export function JobSensorDetail() {
	const { isToogle, isCallibrationAutoSave, setIsCallibrationAutoSave } =
		useNavStore();
	const { accessToken, justSync, isSyncing } = useContext(authCtx);
	const { jobId } = useParams<{ jobId: string }>();
	const { store } = useContext(StoreContext);

	const dbUser = useCurrentUser();
	const history = useHistory();
	const [currentTab, setCurrentTab] = useState(Tabs.SENSORS);
	const [searchQuery, setSearchQuery] = useState('');
	const [checkMap, setCheckMap] = useState<Record<string, boolean>>({});
	const [addSensorModalIsOpen, setAddSensorModalIsOpen] = useState(false);
	const [calibrateModalIsOpen, setCalibrateModalIsOpen] = useState(false);
	const [addTechModalIsOpen, setAddTechModalIsOpen] = useState(false);
	// const [sensorId, setSensorId] = useState('');
	// const [currentJobId, setCurrentJobId] = useState('');
	// const [currentNotes, setCurrentNotes] = useState('');
	// const docList = useRef<FilePkg[]>([]);
	// const [isFilter, setIsFilter] = useState(false);
	const [currentSort, setCurrentSort] = useState<ColumnKey>('name');
	const [userIs, setUserIs] = useState(dbUser?.role_id_ref?.name);

	// const [isUserTech, setIsUserTech] = useState(false);
	const [isAscending, setIsAscending] = useState(true);
	// const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
	const [isAdvanceSortModalOpen, setIsAdvanceSortModalOpen] = useState(false);
	const [isCustamizeTableOpen, setisCustamizeTableOpen] = useState(false);
	const [filterList, setFilterList] = useState<any[]>([
		{
			field: '',
			operation: '',
			feildValue: '',
		},
	]);

	const calibration_procedures = store.calibration_procedures;
	const probe_types = store.probe_types;

	const job = store.jobs[jobId];
	const [sensors, setSensors] = useState(job?.job_sensors);

	useMemo(() => setUserIs(dbUser?.role_id_ref.name), [dbUser]);
	// console.log('all sensors', sensors);

	const userId = useMemo(() => dbUser?.id, [dbUser]);
	const isCertifyAll = useMemo(() => {
		let Sensors_to_be_Calibrated: number = 0;
		let SensorsIsPass: number = 0;

		_.forEach(job.job_sensors, (sensor) => {
			const ispassed = getMostRecentCal(sensor)?.ispassed;
			if (ispassed == '1') {
				SensorsIsPass++;
				let cal = getMostRecentCal(sensor);
				let cert = getMostRecentCert(cal);
				cert?.file_url && Sensors_to_be_Calibrated++;
			}
		});
		return {
			SensorsCalibrated: Sensors_to_be_Calibrated,
			SensorsIsPass: SensorsIsPass,
		};
	}, [job, sensors]);

	const techToolId = useMemo(() => {
		if (dbUser) {
			let techData = _.filter(
				dbUser?.technician_tools,
				(data) => data.calibration_due_date > +new Date()
			);
			return techData[0]?.id ?? null;
			// return Object.keys(dbUser?.technician_tools)[0];
		}
	}, [dbUser]);

	const calProcedureId = useMemo(() => {
		if (dbUser) {
			return Object.keys(dbUser?.technician_tools)[0];
		}
	}, [dbUser]);

	const calDueDate = useMemo(() => {
		return _.filter(
			store.technician_tools,
			(data) => data.technician_user_id == userId
		)[0]?.calibration_due_date;
	}, [dbUser, sensors]);

	const [certificateBatch, setCertificateBatch] = useState<CertificateBatch>();
	const [showBatchDetails, setShowBatchDetails] = useState(false);
	const [savedBatchForLoading, setSavedBatchForLoading] =
		useState<CertificateBatch>();
	const [manualIsLoading, setManualIsLoading] = useState(false);
	const [activeTag, setActiveTag] = useState('');

	// Table input

	const [certificateFilterQuery, setCertificateFilterQuery] =
		useState<string>('');

	// const queryAndSetCertificateBatch = useCallback(async () => {
	// 	const jobUserBatches = await database.collections
	// 		.get<CertificateBatch>('certificate_batches')
	// 		.query(
	// 			Q.where('job_id', jobId),
	// 			Q.where('user_id', dbUser?.id || ''),
	// 			Q.where('status', Q.notEq(CertificateBatchStatus.Unknown))
	// 		)
	// 		.fetch();
	// 	const sortedBatches = orderBy(jobUserBatches, ['updatedAt'], ['desc']);
	// 	const currentBatch = sortedBatches[0];
	// 	const isSameState =
	// 		savedBatchForLoading?.status === currentBatch?.status &&
	// 		savedBatchForLoading?.id === currentBatch?.id;
	// 	setCertificateBatch(currentBatch);
	// 	if (manualIsLoading && !isSameState) {
	// 		setManualIsLoading(false);
	// 	}
	// }, [jobId, dbUser?.id, isSyncing, manualIsLoading, savedBatchForLoading]);
	// useEffect(() => {
	// 	if (isSyncing === false) {
	// 		queryAndSetCertificateBatch();
	// 	}
	// }, [isSyncing]);
	const batchDownloadStatus = manualIsLoading
		? CertificateBatchStatus.Processing
		: certificateBatch?.status;

	const leadTechnician = useMemo(
		() =>
			_.find(
				store.job_lead_technicians,
				(leadTech) => leadTech.job_id === jobId
			),
		[jobId, store.job_lead_technicians]
	);
	const isCurrentTechLead =
		leadTechnician?.job_technician_id_ref.technician_id === dbUser?.id;
	// search
	const filteredSensors = useSearch(
		sensors || Calibration,
		searchQuery,
		(
			{
				sensor_id,
				name,
				description,
				sensor_group,
				sensor_database,
				sensor_type,
			},
			test
		) =>
			test(sensor_id) ||
			test(name) ||
			test(description) ||
			test(sensor_group) ||
			test(sensor_database) ||
			test(sensor_type)
	);

	const tableData = useTable(
		sensorsTable,
		filteredSensors,
		currentSort,
		isAscending
	);

	const certHasProcessing = Boolean(
		tableData.find((row) => row.cert?.status === CertificateStatus.Processing)
	);
	const batchHasProcessing =
		Boolean(certificateBatch?.status === CertificateBatchStatus.Processing) ||
		manualIsLoading;
	const hasProcessing = certHasProcessing || batchHasProcessing;
	useEffect(() => {
		// console.log('dbUser-----------=', dbUser);
		justSync();
	}, []);
	useEffect(() => {}, [activeTag, filterList]);

	// useEffect(() => {
	// 	const interval = setInterval(async () => {
	// 		if (hasProcessing) {
	// 			if (!isSyncing) {
	// 				try {
	// 					justSync();
	// 					if (batchHasProcessing) {
	// 						await queryAndSetCertificateBatch();
	// 					}
	// 				} catch (err) {
	// 					Bugsnag.notify(err as any);
	// 					console.error('JobDetail useEffect', err);
	// 				}
	// 			} else {
	// 				// console.log('Ongoing sync detected. Skipping sync...');
	// 			}
	// 		}
	// 	}, POLLING_INTERVAL);
	// 	return () => clearInterval(interval);
	// }, [certHasProcessing, batchHasProcessing, hasProcessing, isSyncing]);

	// const sortingPackage: SortingPackage<ColumnKey> = {
	// 	setCurrentSort,
	// 	currentSort,
	// 	setIsAscending,
	// 	isAscending,
	// };

	// sorting
	const handleSort = useCallback(
		(colKey) => {
			if (currentSort === colKey) {
				setIsAscending(!isAscending);
			} else {
				setIsAscending(false);
				setCurrentSort(colKey);
			}
		},
		[currentSort, isAscending, setCurrentSort, setIsAscending]
	);

	const resizeTableCall = (k: string, action: string) => {
		const tableHeaderElement: any = document.getElementById(`${k}`);
		let x =
			action === 'sub'
				? tableHeaderElement.offsetWidth - 50
				: tableHeaderElement.offsetWidth + 50;
		if (x < 60) return;
		tableHeaderElement.setAttribute('style', `width: ${x}px`);
		const tableBodyElement: any = document.getElementsByClassName(`${k}`);
		for (let e = 0; e < tableBodyElement.length; e++) {
			tableBodyElement[e].setAttribute('style', `width: ${x}px; `);
		}
	};

	function FilterData() {
		const FilterStaus: any | null = document.getElementById('toFilterStaus');
		const FilterGroup: any | null = document.getElementById('toFilterGroup');
		const FilterSensorType: any | null =
			document.getElementById('toFilterSensorType');
		let Status: any = FilterStaus?.value;
		let Group: string = FilterGroup?.value;
		let Type: string = FilterSensorType?.value;

		if (Status && Type && Group) {
			let filtered_array = _.filter(job?.job_sensors, (data) => {
				let mapData = _.map(data.calibrations, (d) => d.status);
				return (
					(data.sensor_type == Type &&
						mapData.includes(Status) &&
						data.sensor_group == Group) ??
					false
				);
			});
			setSensors(filtered_array as any);
		} else if (Status && Type && !Group) {
			let filtered_array = _.filter(job?.job_sensors, (data) => {
				let mapData = _.map(data.calibrations, (d) => d.status);
				return (
					(data.sensor_type == Type && mapData.includes(Status)) ?? false
				);
			});
			setSensors(filtered_array as any);
		} else if (Status && !Type && Group) {
			let filtered_array = _.filter(job?.job_sensors, (data) => {
				let mapData = _.map(data.calibrations, (d) => d.status);
				return (
					(mapData.includes(Status) && data.sensor_group == Group) ?? false
				);
			});
			setSensors(filtered_array as any);
		} else if (!Status && Type && Group) {
			let filtered_array = _.filter(job?.job_sensors, (data) => {
				return (
					(data.sensor_type == Type && data.sensor_group == Group) ?? false
				);
			});
			setSensors(filtered_array as any);
		} else if (Status && !Type && !Group) {
			let filtered_array = _.filter(job?.job_sensors, (data) => {
				let mapData = _.map(data.calibrations, (d) => d.status);
				return mapData.includes(Status) ?? false;
			});
			setSensors(filtered_array as any);
		} else if (!Status && !Type && Group) {
			let filtered_array = _.filter(
				job?.job_sensors,
				(data) => data.sensor_group == Group ?? false
			);
			setSensors(filtered_array as any);
		} else if (!Status && Type && !Group) {
			let filtered_array = _.filter(
				job?.job_sensors,
				(data) => data.sensor_type == Type
			);
			setSensors(filtered_array as any);
		} else {
			setSensors(job?.job_sensors);
		}

		// let StatusKey = e.target.value;
		// let filtered_array = _.filter(job?.job_sensors, (data) => {
		// 	let mapData = _.map(data.calibrations, (d) => d.status);
		// 	return mapData.includes(StatusKey) ?? false;
		// });
		// setSensors(filtered_array as any);
		// )
		// let isMatch = false;
		// for (let i = 0; i < filterKey.length; i++) {
		// 	mapData.includes(filterKey[i])
		// 		? (isMatch = true)
		// 		: (isMatch = isMatch);
		// });

		// let k = e.target.name;
		// if (!filterKey.includes(k)) {
		// 	filterKey.push(k);
		// } else {
		// 	const index = filterKey.indexOf(k);
		// 	if (index > -1) {
		// 		filterKey.splice(index, 1);
		// 	}
		// }

		// if (filterKey.length > 0) {
		// 	let filtered_array = _.filter(job?.job_sensors, (data) => {
		// 		let mapData = _.map(data.calibrations, (d) => d.status);
		// 		let isMatch = false;
		// 		for (let i = 0; i < filterKey.length; i++) {
		// 			mapData.includes(filterKey[i])
		// 				? (isMatch = true)
		// 				: (isMatch = isMatch);
		// 		}
		// 		return isMatch;
		// 	});

		// } else {
		// 	setSensors(job?.job_sensors);
		// }
		setIsAdvanceSortModalOpen(false);
	}

	function addToFilterArr(currentIndex: number, action: any, data?: any) {
		if (action === 'pop' && filterList.length > 1) {
			let f = filterList.filter((item, i) => i !== currentIndex);
			console.log('f=', f);

			setFilterList(() => f);
		} else if (action === 'push') {
			let dummyArrData = {
				field: '',
				operation: '',
				feildValue: '',
			};
			const arr = [...filterList, dummyArrData];
			arr[currentIndex] = data;
			setFilterList(arr);
		} else {
			return;
		}
	}
	function filterTableData(confirmFilter: boolean) {
		if (!confirmFilter) return setisCustamizeTableOpen(false);
		// data.description=='33'
		if (filterList.length > 0) {
			let filtered_array = _.filter(job?.job_sensors, (data) => {
				let isMatch = false;
				for (let i = 0; i < filterList.length; i++) {
					let element = filterList[i];
					if (element.field == 'name')
						isMatch = data.name == element.feildValue;
					if (element.field == 'description')
						isMatch = data.description == element.feildValue;
					if (element.field == 'group')
						isMatch = data.sensor_group == element.feildValue;
					if (element.field == 'sensorId_SequenceNumber')
						isMatch = data.sensor_id == element.feildValue;
					if (element.field == 'sensor_type')
						isMatch = data.sensor_type == element.feildValue;
				}
				return isMatch;
			});
			// {

			// let mapData = _.map(data.calibrations, (d) => d.status);
			// let isMatch = false;
			// for (let i = 0; i < filterKey.length; i++) {
			// 	mapData.includes(filterKey[i])
			// 		? (isMatch = true)
			// 		: (isMatch = isMatch);
			// }
			// return isMatch;
			// });

			setSensors(filtered_array as any);
		} else {
			setSensors(job?.job_sensors);
		}
	}

	const changeJobStatus = useCallback(
		async (value: string) => {
			const job = await database.collections.get<Job>('jobs').find(jobId);
			switch (value) {
				case 'pending':
					await job.resetJobStatus();
					return justSync();
				case 'open':
					await job.openJob();
					return justSync();
				case 'closed':
					await job.closeJob();
					return justSync();
			}
		},
		[jobId]
	);
	/**
	 * Adds a new resource to the job's document list.
	 */
	const addResource = useCallback(
		async (file: File, reader: FileReader) => {
			const formData = new FormData();
			formData.append(
				'resourcesFiles',
				new Blob([reader!.result!], { type: file.type }),
				file!.name
			);
			await fetch(`${urls.base}/jobs/${jobId}/resources`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
				body: formData,
			});
			justSync();
		},
		[jobId, accessToken]
	);

	/**
	 * Removes a resource from the document list.
	 */
	const removeResource = useCallback(
		async (resource: JobResourcesDBEntry) => {
			await fetch(`${urls.base}/jobs/${jobId}/resources/${resource.id}`, {
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});
			justSync();
		},
		[jobId, accessToken]
	);

	const getResource = useCallback((resource: JobResourcesDBEntry) => {
		window.open(resource.url, '_blank');
	}, []);

	/**
	 * Generates CSV data by calling the backend API.
	 */
	const getCsvData = useCallback(async () => {
		console.log('fetching csv...');
		const { base, jobExport } = urls;
		const csvData = await fetch(base + jobExport.url(jobId), {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
			method: jobExport.method,
		}).then((res) => res.text());

		return csvData;
	}, [jobId, accessToken]);

	/**
	 * Certifies a sensor and creates the PDF certificate.
	 */
	const handleCertifyOne = useCallback(
		async (cal: CalibrationsDBEntry) => {
			console.log('Cal id---', cal.id);

			let fileUrl = getMostRecentCert(cal)?.file_url;
			try {
				const body = await fetch(
					`${urls.base}/calibrations/${cal.id}/certificates/priority`,
					{
						headers: { Authorization: `Bearer ${accessToken}` },
						method: 'POST',
					}
				).then((res) => res.json());
				fileUrl = body.certificateUrl;
				if (fileUrl) window.open(fileUrl, '_blank');
				justSync();
			} catch (e) {
				Bugsnag.notify(e as any);
				return console.log(e);
			}
		},
		[accessToken]
	);

	const getSelectedCalibrations = useCallback(
		(
			params: {
				skipDisabled?: boolean;
				validSensorsOnly?: boolean;
			} = {}
		) => {
			const { skipDisabled = true, validSensorsOnly = true } = params;
			const sensors = compact(
				map(checkMap, (selected, id) => {
					if (selected) {
						const cal = getMostRecentCal(store.job_sensors[id]);
						let isValid = true;
						if (validSensorsOnly) {
							if (cal?.status !== 'valid') {
								isValid = false;
							}
						}
						if (!isValid) {
							return;
						}
						const sensor = store.job_sensors[id];
						if (skipDisabled) {
							if (sensor.is_disabled) {
								return;
							}
						}
						return sensor;
					}
				})
			);
			const calibrations = compact(
				sensors.map((sensor) => {
					const cal = getMostRecentCal(sensor);
					if (cal?.status === 'valid') {
						return cal;
					}
					// return cal;
				})
			);
			return {
				sensors,
				calibrations,
			};
		},
		[checkMap, store.job_sensors, getMostRecentCal]
	);

	/**
	 * Exports the CSV data and downloads to a file.
	 */
	const handleCsvDownload = useCallback(async () => {
		const csvData = await getCsvData();
		const dataUri = createCsvDataUri(csvData);
		startLocalDownload(dataUri, `${job.name}.csv`);
	}, [job?.name, getCsvData]);

	/**
	 * Disables the selected sensors.
	 */
	const handleDisableSensors = async () => {
		const ids = getSelectedCalibrations({
			skipDisabled: false,
			validSensorsOnly: false,
		}).sensors.map((sensor) => sensor.id);
		const jobSensors = await database.collections
			.get<JobSensor>('job_sensors')
			.query(Q.where('id', Q.oneOf(ids)))
			.fetch();
		const batchRecords = jobSensors.map((jobSensor) =>
			jobSensor.prepareUpdate(() => {
				jobSensor.isDisabled = 1;
			})
		);
		await database.write(async () => {
			await database.batch(...batchRecords);
		});
		justSync();
	};

	/**
	 * Enables the selected sensors.
	 */
	const handleEnableSensors = async () => {
		const ids = getSelectedCalibrations({
			skipDisabled: false,
			validSensorsOnly: false,
		}).sensors.map((sensor) => sensor.id);
		const jobSensors = await database.collections
			.get<JobSensor>('job_sensors')
			.query(Q.where('id', Q.oneOf(ids)))
			.fetch();
		const batchRecords = jobSensors.map((jobSensor) =>
			jobSensor.prepareUpdate(() => {
				jobSensor.isDisabled = 0;
			})
		);
		await database.write(async () => {
			await database.batch(...batchRecords);
		});
		justSync();
	};

	/**
	 * Changes the job status to closed and adds the CSV data to the document list.
	 */
	const handleCloseJob = useCallback(async () => {
		const csvData = await getCsvData();
		const formData = new FormData();
		formData.append(
			'resourcesFiles',
			new Blob([csvData], { type: 'text/csv' }),
			`${job.name}.csv`
		);
		await fetch(`${urls.base}/jobs/${jobId}/resources`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			body: formData,
		});
		changeJobStatus('closed');
	}, [jobId, accessToken, job?.name, getCsvData, changeJobStatus]);

	/**
	 * Deletes a job and redirects to the jobs page.
	 */
	const handleDeleteJob = useCallback(async () => {
		const { jobDelete } = urls;
		await fetch(`${urls.base}${jobDelete.url}/${jobId}`, {
			method: jobDelete.method,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});
		justSync();
		history.push('/jobs');
	}, [jobId, accessToken, history]);

	// const handleCalibrateModalOpen = useCallback(
	// 	async (id, jobId, SequenceNumber, event) => {
	// 		if (event.innerText == 'Test' && SequenceNumber) {
	// 			if (
	// 				window.confirm(
	// 					`Is the probe serial number - ${SequenceNumber} rightly entered?`
	// 				)
	// 			) {
	// 				setCurrentJobId(jobId);
	// 				setSensorId(id);
	// 				setCalibrateModalIsOpen(true);
	// 			}
	// 		} else {
	// 			setCurrentJobId(jobId);
	// 			setSensorId(id);
	// 			setCalibrateModalIsOpen(true);
	// 		}
	// 	},
	// 	[]
	// );

	// const handleNotesModalOpen = useCallback((id, notes) => {
	// 	setSensorId(id);
	// 	setCurrentNotes(notes);
	// 	setIsNotesModalOpen(true);
	// }, []);

	const enabledMap = useMemo(() => {
		const enabledMap: { [id: string]: boolean } = {};
		_.forEach(tableData, ({ id }) => {
			enabledMap[id] = true;
		});
		return enabledMap;
	}, [tableData]);

	const isJobDone = useMemo(() => getJobProgress(job) >= 1, [job]);
	const activeTechs = useMemo(() => getActiveTechs(job), [job]);
	const batchDownloadStyle = useMemo(() => {
		const styles: any[] = [];
		if (batchDownloadStatus === CertificateBatchStatus.Completed) {
			styles.push({ cursor: 'pointer' });
			if (showBatchDetails) {
				styles.push({ borderColor: '#06c75f' });
			}
		}
		return reduce(
			styles,
			(acc, style) => {
				return {
					...acc,
					...style,
				};
			},
			{}
		);
	}, [batchDownloadStatus, showBatchDetails]);

	const certificateBatchDate = certificateBatch?.updatedAt
		? format(new Date(certificateBatch?.updatedAt), 'MMMM dd, yyyy, h:mm a')
		: format(new Date(), 'mm/dd/yy');
	const certificateBatchSensorCount = certificateBatch?.calibrationIds
		? JSON.parse(certificateBatch.calibrationIds).length
		: 0;
	const tooltipString = useMemo(() => {
		if (batchDownloadStatus === CertificateBatchStatus.Processing) {
			return `${certificateBatchSensorCount} sensors (started on: ${certificateBatchDate})`;
		} else if (batchDownloadStatus === CertificateBatchStatus.Failed) {
			return `${certificateBatchSensorCount} sensors (failed on: ${certificateBatchDate}). If the problem persists, please recertify and try again.`;
		} else {
			return `${certificateBatchSensorCount} sensors (${certificateBatchDate})`;
		}
	}, [certificateBatchSensorCount, certificateBatchDate, batchDownloadStatus]);
	const selectedSensorCount = getSelectedCalibrations().calibrations.length;
	const disableActions =
		batchDownloadStatus === CertificateBatchStatus.Processing ||
		certHasProcessing;

	if (!job || !sensors) return null;
	// save
	const saveTableData = async () => {
		let isAllRequiedFeildsMissing: boolean = false;
		if (updatesensorIdList.length > 0) {
			for (let i = 0; i < updatesensorIdList.length; i++) {
				let obj1: any;
				const feilds: any | null = document.getElementsByClassName(
					`${updatesensorIdList[i]}`
				);
				// let calProcedureId = null;
				// let setTechToolId = null;
				for (let j = 0; j < feilds.length; j++) {
					let tagname = feilds[j].dataset.recordField;
					let val = feilds[j].value;
					if (
						!isCallibrationAutoSave &&
						tagname == 'probeTypeId' &&
						(!val || val == '')
					) {
						let text = `Probe Type is missing for Sensor '${feilds[0].value}'. Press Cancel to enter Probe Type or OK to save calibration details for other sensors.`;
						if (window.confirm(text) == false) {
							console.log('breaked loop');
							isAllRequiedFeildsMissing = true;
							break;
						}
					}
					if (
						!isCallibrationAutoSave &&
						tagname == 'technicianToolId' &&
						(!val || val == '')
					) {
						let text = `Technician name is missing for Sensor '${feilds[0].value}'. Press Cancel to enter Probe Type or OK to save calibration details for other sensors.`;
						if (window.confirm(text) == false) {
							console.log('breaked loop');
							isAllRequiedFeildsMissing = true;
							break;
						}
					}
					// if (tagname == 'probeTypeId') {
					// 	let calProcedure = _.filter(
					// 		calibration_procedures,
					// 		(data) => data.probe_type_id === val
					// 	);
					// 	if (!isCallibrationAutoSave && calProcedure.length < 1) {
					// 		let text = `No Procedures found for selected Probe Type in '${feilds[0].value}'. Press Cancel to change Probe Type or OK to save calibration details for other sensors.`;
					// 		if (window.confirm(text) == false) {
					// 			console.log('breaked loop');
					// 			isAllRequiedFeildsMissing = true;
					// 			break;
					// 		}
					// 	}
					// 	const selectedCalProc = _.reduce(
					// 		calProcedure,
					// 		(acc, cur) =>
					// 			cur.created_at >= acc.created_at ? cur : acc,
					// 		{ created_at: 0 } as CalibrationProceduresDBEntry
					// 	);

					// 	calProcedureId = selectedCalProc.id;
					// }
					// if (tagname == 'technicianToolId' && val) setTechToolId = val;

					obj1 = {
						...obj1,
						[tagname]: val,
						id: `${updatesensorIdList[i]}`,
						// technicianToolId: techToolId ?? setTechToolId,
						calibrationId: feilds[i].dataset.calibrationsId,
						calReadingId: feilds[i].dataset.readingId,
						isCallibrationAutoSave:isCallibrationAutoSave
						// calibrationProcedureId: calProcedureId,
					};
				}

				updatesensorIdData.push(obj1);
				obj1 = {};
			}
			updatesensorIdList = [];
		}
		if (!isAllRequiedFeildsMissing) {
			for (let i = 0; i < updatesensorIdData.length; i++) {
				const jobSensor = await database.collections
					.get<JobSensor>('job_sensors')
					.find(updatesensorIdData[i].id);

				await jobSensor.inlineUpdateJob(updatesensorIdData[i]);
			}
			updatesensorIdData = [];
			justSync();
		}
	};
	// auto save data in every 5 min
	setInterval(() => {
		if (updatesensorIdList.length > 0 && job.status !== 'closed') {
			setIsCallibrationAutoSave('YES');
			saveTableData();
		}
	}, 300000);

	// Download All Cert
	const downloadCloseJob = async () => {
		let csvTitleRow: any[] = [];
		let cols: any;
		sensorsTable.columns
			.slice(0, sensorsTable.columns.length - 4)
			.map(({ title }) => csvTitleRow.push(title));

		cols = document.querySelectorAll('#sensorTable .sensor-table-body td');
		let k = 30;
		let csv_row: any[] = [];
		let csv_data: any[] = [];
		csv_data.push(csvTitleRow);
		for (let j = 0; j < cols.length; j++) {
			if (j > 0 && j === k) {
				k += 32;
				if (csv_row.length > 30) {
					csv_row.splice(0, 2);
					csv_data.push(csv_row);
				} else {
					csv_data.push(csv_row);
				}
				csv_row = [];
			} else if (j === k + 1 || j === k + 2) {
			} else {
				let cellVal: any = '';
				cellVal = cols[j]['firstElementChild']?.value;
				if (cols[j]['firstElementChild']?.options != undefined) {
					cellVal =
						cols[j]['firstElementChild']?.options[
							cols[j]['firstElementChild']['selectedIndex']
						]?.text;
				}

				if (cellVal === undefined || !cellVal) {
					cellVal = '';
				}
				csv_row.push(cellVal.replaceAll(',', ''));
			}
		}
		// csv_data.push(csv_row);
		let finalDataArr = [];
		finalDataArr.push(csv_data.join(`\n`));
		downloadCSVFile(finalDataArr);
	};
	function downloadCSVFile(csv_data: any) {
		let CSVFile = new Blob([csv_data], {
			type: 'text/csv;charset=utf-8',
		});
		var temp_link = document.createElement('a');
		temp_link.download = 'Close Job.csv';
		var url = window.URL.createObjectURL(CSVFile);
		temp_link.href = url;
		temp_link.style.display = 'none';
		document.body.appendChild(temp_link);
		temp_link.click();
		document.body.removeChild(temp_link);
	}
	// pass only download
	const downloadAllCert = async () => {
		toast.success(`Certificates will be downloaded shortly.`, {
			autoClose: 10000,
		});
		let ids: any[] = [];
		let calibrationsList = _.map(sensors, (data) => data.calibrations);
		_.values(calibrationsList).forEach((el) => {
			_.values(el).forEach((cal) => {
				if (cal.ispassed == '1') {
					ids.push(cal.id);
				}
			});
		});

		const { base, batchCertificates } = urls;
		await fetch(`${base}${batchCertificates.url}`, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
			method: batchCertificates.method,
			body: JSON.stringify({ calibrationIds: ids, jobId }),
		}).then((res) => {
			justSync();
			const responsePromise = res.json();
			responsePromise.then(async (response) => {
				const { id } = response;
				if (id) {
					var refreshIntervalId = setInterval(async () => {
						justSync();
						const batchCertData = await database.collections
							.get<CertificateBatch>('certificate_batches')
							.query(Q.where('id', id))
							.fetch()
							.then((data) => {
								if (data[0]?.zipUrl) {
									window.open(data[0]?.zipUrl, '_blank');
									clearInterval(refreshIntervalId);
								}
							});
					}, 10000);
				}
			});
		});
	};

	// Certify All
	const batchCertyfy = async () => {
		console.log('Certify All...');
		const { base, batchCertify } = urls;
		let ids: any[] = [];
		try {
			let calibrationsList = _.map(sensors, (data) => data.calibrations);
			_.values(calibrationsList).forEach((el) => {
				_.values(el).forEach((cal) => {
					if (cal.ispassed == '1') {
						ids.push(cal.id);
					}
				});
			});
			let result: any = null;
			if (ids.length) {
				result = await fetch(`${base}${batchCertify.url}`, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
						'Content-Type': 'application/json',
					},
					method: batchCertify.method,
					body: JSON.stringify({ calibrationIds: ids }),
				})
					.then(async (res) => res.json())
					.catch((err) => {
						justSync();
						console.log('Err While Certify All::::::::::::::', err);
					});
			}
			// console.log('result----------',result);
			console.log('ids---------------', ids);
		} catch (error) {
			console.log('Error While Certify All::::::::::::::', error);
		}
	};
	// advanceSort
	const advanceSort = async () => {
		console.log('advanceSort...');
		setIsAdvanceSortModalOpen(true);
		return null;
	};
	// Custamize Table
	const custamizeTable = async () => {
		console.log('custamizeTable...');
		setisCustamizeTableOpen(true);
	};

	const AdvanceSortFoo = async (d: any) => {
		let feildName = d.name;

		let sortedData = _.orderBy(sensors, [d.name], ['asc']);
		let extractionFoo = _.forEach(sensors, (data) => data);
		const grouped = _.groupBy(sensors, (data) => data.name);
		const grouped_extractionFoo = _.groupBy(
			extractionFoo,
			(data) => data.calibrations[feildName]
		);
		return null;
	};

	return (
		<div style={{ marginLeft: isToogle === 'YES' ? '90px' : '' }}>
			<div className={'mainContentContainer'}>
				{/* <StatHeader job={job} /> */}

				{currentTab === Tabs.SENSORS && (
					<>
						{/* <StatHeader job={job} /> */}
						<div className={styles.sensorsHeader}>
							<h3 className="listTitle">Sensors</h3>
							<div style={{ display: 'flex' }}>
								<SearchBar
									searchQuery={searchQuery}
									setSearchQuery={setSearchQuery}
									placeholder="Search sensor ID"
								/>
								<button
									disabled={job.status === 'closed'}
									className={styles.headerButton}
									onClick={() => saveTableData()}
								>
									Save
								</button>
								<button
									onClick={() => advanceSort()}
									className={styles.headerButton}
								>
									Filter
								</button>
								<button
									disabled={isCertifyAll.SensorsIsPass < 1}
									onClick={() => batchCertyfy()}
									className={styles.headerButton}
								>
									Certify All
								</button>
								<button
									disabled={
										isCertifyAll.SensorsIsPass !==
										isCertifyAll.SensorsCalibrated || (isCertifyAll.SensorsIsPass<1 ||isCertifyAll.SensorsCalibrated<1)
									}
									onClick={() => downloadAllCert()}
									className={styles.headerButton}
								>
									Download All
								</button>
								{job.status === 'closed' && (
									<button
										className={styles.headerButton}
										onClick={() => downloadCloseJob()}
									>
										<FontAwesomeIcon icon="download" />
									</button>
								)}

								{/* <button
									onClick={() => custamizeTable()}
									style={{ backgroundColor: 'green' }}
								>
									Filter
								</button> */}
							</div>
						</div>

						<div className="sensor-datatable">
							{/* <CheckList checkMap={checkMap} enabledMap={enabledMap} setCheckMap={setCheckMap} tableData={tableData} /> */}
							<table
								className="sensor-datatable-content"
								id="sensorTable"
							>
								{/* <TableHeader  columns={sensorsTable.columns.slice(0, sensorsTable.columns.length - 3)} rowClassName={styles.row} sortingPackage={sortingPackage} /> */}
								<thead className="sensor-table-header">
									<tr>
										{/* className={`headerRow`} */}
										{sensorsTable.columns
											.slice(0, sensorsTable.columns.length - 3)
											.map(
												({
													title,
													key,
													doNotSort,
													headerComponent,
													headerAlign = 'left',
												}) =>
													headerComponent ? (
														headerComponent({})
													) : (
														<th
															key={key}
															// className={`header-row-item`}
															data-record-field={key}
															id={key}
														>
															<div
																className={` ${
																	!doNotSort &&
																	styles.clickableArea
																} ${styles.col} ${
																	styles[headerAlign]
																} ${styles.tableHeader}`}
															>
																<p>
																	<span
																		className={
																			styles.tableHeaderTitleSortAndIcon
																		}
																	>
																		{title}
																		{!doNotSort && (
																			<FontAwesomeIcon
																				onClick={() =>
																					!doNotSort &&
																					handleSort(key)
																				}
																				style={{
																					marginLeft:
																						'5px',
																				}}
																				icon={
																					currentSort !==
																					key
																						? 'sort'
																						: isAscending
																						? 'sort-up'
																						: 'sort-down'
																				}
																			/>
																		)}
																	</span>

																	<span
																		className={
																			styles.resizeCalIcon
																		}
																	>
																		<button
																			className={`${styles.resizeCalBtn} ${styles.resizeCalBtn1}`}
																			onClick={() =>
																				resizeTableCall(
																					key,
																					'add'
																				)
																			}
																		>
																			<FontAwesomeIcon
																				className={
																					styles.resizeCalBtnIcon
																				}
																				icon="arrow-right"
																			/>
																		</button>
																		<button
																			className={`${styles.resizeCalBtn} ${styles.resizeCalBtn2}`}
																			onClick={() =>
																				resizeTableCall(
																					key,
																					'sub'
																				)
																			}
																		>
																			<FontAwesomeIcon
																				className={
																					styles.resizeCalBtnIcon
																				}
																				icon="arrow-left"
																			/>
																		</button>
																	</span>
																</p>
															</div>
														</th>
													)
											)}
									</tr>
								</thead>
								<tbody className="sensor-table-body">
									{tableData.map(
										({
											calibrations,
											sensorId: sensorBizId,
											name,
											sensor_database,
											sensorId_SequenceNumber,
											description,
											group,
											lumity,
											status,
											updatedAt,
											// tech,
											// calibrationProcedureId,
											probeType,
											// building,
											// floor,
											// room,
											// chamber,
											cal,
											timestamp_1,
											tt_reading,
											device_reading,
											timestamp_2,
											tt_reading_2,
											device_reading_2,
											timestamp_3,
											tt_reading_3,
											device_reading_3,
											timestamp_4,
											tt_reading_4,
											device_reading_4,
											probe_replaced,
											result,
											cert,
											id,
											notes,
											isDisabled,
											// SequenceNumber,
											sensor_type,
											sensor_modal,
											probeSerialNumber,
											// serialNumber,
											// probeTypeId,
											technicianToolId,
											ambientTemperature,
											ambientHumidity,
											// sensorReading,
											// calibrationReading,
											calReading,
										}) => {
											const isCalibrating =
												id === sensorId_SequenceNumber &&
												calibrateModalIsOpen;
											const calButtonStyle = isCalibrating
												? styles.processing
												: !result
												? styles.pending
												: result === 'PASS' || result === 'FAIL'
												? styles.valid
												: styles.failed;
											let rowColors = ['transparent', 'inherit'];

											const certButtonStyle =
												result && cert?.file_url
													? styles.valid
													: styles.pending;
											if (cal?.status) {
												if (cal.status === 'Certified') {
													rowColors = ['#8DB367', '#000000'];
												} else if (cal.status === 'Calibrated') {
													rowColors = ['#487EC1', '#FFFFFF'];
												} else if (cal.status === 'InProgress') {
													rowColors = ['#CCC0DA', '#FFFFFF'];
												} else if (cal.status === 'Issue') {
													rowColors = ['yellow', '#000000'];
												} else if (cal.status === 'No') {
													rowColors = ['red', '#000000'];
												} else {
													rowColors = ['#c4bd97', '#000000'];
												}
											} else {
												rowColors = ['#c4bd97', '#000000'];
											}

											// Result Calculat

											// if (
											// 	tt_reading ||
											// 	device_reading ||
											// 	tt_reading_2 ||
											// 	device_reading_2 ||
											// 	tt_reading_3 ||
											// 	device_reading_3 ||
											// 	tt_reading_4 ||
											// 	device_reading_4
											// ) {
											// 	if (tt_reading_4 && device_reading_4) {
											// 		result =
											// 			Math.abs(
											// 				tt_reading_4 - device_reading_4
											// 			) == 0
											// 				? 'PASS'
											// 				: 'FAIL';
											// 	} else if (
											// 		tt_reading_3 &&
											// 		device_reading_3
											// 	) {
											// 		result =
											// 			Math.abs(
											// 				tt_reading_3 - device_reading_3
											// 			) == 0
											// 				? 'PASS'
											// 				: 'FAIL';
											// 	} else if (
											// 		tt_reading_2 &&
											// 		device_reading_2
											// 	) {
											// 		result =
											// 			Math.abs(
											// 				tt_reading_2 - device_reading_2
											// 			) == 0
											// 				? 'PASS'
											// 				: 'FAIL';
											// 	} else if (tt_reading && device_reading) {
											// 		result =
											// 			Math.abs(
											// 				tt_reading - device_reading
											// 			) == 0
											// 				? 'PASS'
											// 				: 'FAIL';
											// 	} else {
											// 		result = null;
											// 	}
											// }
											// sensorRealId
											let sensorId = '';
											if (sensorId_SequenceNumber.includes('/')) {
												const [sensorRealId, probeNumber] =
													sensorId_SequenceNumber.split('/');
												sensorId = sensorRealId;

												// probeSerialNumber
												if (probeSerialNumber == 'noProbeSr') {
													let filterJob = _.filter(
														sensors,
														(data) =>
															data.sensor_id == sensorRealId
													)[0].calibrations;
													let probe = _.map(
														filterJob,
														(data) => data.probe_serial_number
													);
													probeSerialNumber = probe[0];
												}
											} else {
												sensorId = sensorId_SequenceNumber;
											}
											if (probeSerialNumber == 'noProbeSr') {
												let filterJob = _.filter(
													sensors,
													(data) =>
														data.sensor_id ==
														sensorId_SequenceNumber
												)[0].calibrations;
												let probe = _.map(
													filterJob,
													(data) => data.probe_serial_number
												);
												probeSerialNumber = probe[0];
											}

											//
											// noProbeSr
											// sensorId
											// if (sensorId) {
											// 	sensorId = sensorId.replace(/[-""]/g, '');
											// 	let sensorIdLength = sensorId.length;
											// 	let newSensorIdLength = 11;
											// 	if (sensor_type == '11078 (WIFI-T)') {
											// 		//reverification of sensor_type requider with DB and Surya
											// 		newSensorIdLength = 12;
											// 	}
											// 	for (
											// 		let i = sensorIdLength;
											// 		i < newSensorIdLength;
											// 		i++
											// 	) {
											// 		sensorId = '0' + sensorId;
											// 	}
											// 	let charAt2ndLast = sensorId.charAt(
											// 		sensorId.length - 2
											// 	);
											// 	if (charAt2ndLast !== '/') {
											// 		sensorId = sensorId.replace(
											// 			charAt2ndLast,
											// 			'/'
											// 		);
											// 	}
											// }

											// //sensor Type
											// if (
											// 	sensorId &&
											// 	probeType &&
											// 	lumity &&
											// 	sensorId != '' &&
											// 	sensorId != null &&
											// 	sensorId != undefined
											// ) {
											// 	if (lumity) {
											// 		if (
											// 			probeType ==
											// 				'Internal Hum (ISO/NIST)' ||
											// 			probeType ==
											// 				'Internal Temp (ISO/NIST)'
											// 		) {
											// 			sensor_type = 'FX200 (LUMITY-TH)';
											// 		} else {
											// 			sensor_type = 'FX100 (LUMITY-T)';
											// 		}
											// 	} else {
											// 		if (sensorId.match(/^[a-zA-Z]/)) {
											// 			if (
											// 				probeType ==
											// 					'Internal Hum (ISO/NIST)' ||
											// 				probeType ==
											// 					' Internal Temp (ISO/NIST)'
											// 			) {
											// 				sensor_type = '11079(WIFI-TH)';
											// 			} else {
											// 				sensor_type = '10080-DT (900-T)';
											// 			}
											// 		} else {
											// 			sensor_type = '10071 (900-TH)';
											// 		}
											// 	}
											// } else {
											// 	sensor_type = '-';
											// }

											// input Handle-----------------------------------------------------

											const focusRecordCell = (event: any) => {
												setActiveTag('');
												setActiveTag(event.target.value);
											};

											// Result Updated Here --------------------------------------
											const updateTimestamp = (event: any) => {
												if (
													event.key === 'Enter' ||
													event.key === 'Tab'
												) {
													const target = event.target;
													const data = target.dataset;
													const value: any = target.value;
													const ID = data.recordId;

													const dateElement: any =
														document.querySelector(
															`input[data-record-id='${ID}'][data-record-field='updatedAt']`
														);
													dateElement.value = moment(
														new Date()
													).format('yyyy-MM-DD');

													// ts1
													if (
														data.recordField === 'tt_reading' ||
														data.recordField === 'device_reading'
													) {
														const timestampElement: any =
															document.querySelector(
																`input[data-record-id='${data.recordId}'][data-record-field='timestamp_1']`
															);

														const device_readingElement: any =
															document.querySelector(
																`input[data-record-id='${data.recordId}'][data-record-field='device_reading']`
															);
														if (
															device_readingElement.value &&
															data.recordField ===
																'device_reading'
														) {
															timestampElement.value = moment(
																new Date()
															).format('HH:mm:ss');
														}
													}
													// ts2
													if (
														data.recordField === 'tt_reading_2' ||
														data.recordField ===
															'device_reading_2'
													) {
														const timestamp2Element: any =
															document.querySelector(
																`input[data-record-id='${data.recordId}'][data-record-field='timestamp_2']`
															);

														const device_reading2Element: any =
															document.querySelector(
																`input[data-record-id='${data.recordId}'][data-record-field='device_reading_2']`
															);
														if (
															device_reading2Element.value &&
															data.recordField ===
																'device_reading_2'
														) {
															timestamp2Element.value = moment(
																new Date()
															).format('HH:mm:ss');
														}
													}
													// ts3
													if (
														data.recordField === 'tt_reading_3' ||
														data.recordField ===
															'device_reading_3'
													) {
														const timestamp3Element: any =
															document.querySelector(
																`input[data-record-id='${data.recordId}'][data-record-field='timestamp_3']`
															);

														const device_reading3Element: any =
															document.querySelector(
																`input[data-record-id='${data.recordId}'][data-record-field='device_reading_3']`
															);

														if (
															device_reading3Element.value &&
															data.recordField ===
																'device_reading_3'
														) {
															timestamp3Element.value = moment(
																new Date()
															).format('HH:mm:ss');
														}
													}
													// ts4
													if (
														data.recordField === 'tt_reading_4' ||
														data.recordField ===
															'device_reading_4'
													) {
														const timestamp4Element: any =
															document.querySelector(
																`input[data-record-id='${data.recordId}'][data-record-field='timestamp_4']`
															);

														const device_reading4Element: any =
															document.querySelector(
																`input[data-record-id='${data.recordId}'][data-record-field='device_reading_4']`
															);

														if (
															device_reading4Element.value &&
															data.recordField ===
																'device_reading_4'
														) {
															timestamp4Element.value = moment(
																new Date()
															).format('HH:mm:ss');
														}
													}
												}
											};
											// ---------------------handleSensorRecordCellChanges------------------------------------
											const handleSensorRecordCellChanges = (
												event: any
											) => {
												const target = event.target;
												const data = target.dataset;
												const value: any = target.value;
												const ID = data.recordId;
												if (!updatesensorIdList.includes(ID)) {
													updatesensorIdList.push(ID);
												}
												// Result Updated Here
												// ts1
												if (
													data.recordField === 'tt_reading' ||
													data.recordField === 'device_reading'
												) {
													const tt_readingElement: any =
														document.querySelector(
															`input[data-record-id='${data.recordId}'][data-record-field='tt_reading']`
														);
													const device_readingElement: any =
														document.querySelector(
															`input[data-record-id='${data.recordId}'][data-record-field='device_reading']`
														);
													const result: any =
														document.querySelector(
															`input[data-record-id='${data.recordId}'][data-record-field='ispassed']`
														);
													result.value =
														Math.abs(
															tt_readingElement.value -
																device_readingElement.value
														) == 0
															? 'PASS'
															: 'FAIL';
												}
												// ts2
												if (
													data.recordField === 'tt_reading_2' ||
													data.recordField === 'device_reading_2'
												) {
													const tt_reading2Element: any =
														document.querySelector(
															`input[data-record-id='${data.recordId}'][data-record-field='tt_reading_2']`
														);
													const device_reading2Element: any =
														document.querySelector(
															`input[data-record-id='${data.recordId}'][data-record-field='device_reading_2']`
														);
													const result: any =
														document.querySelector(
															`input[data-record-id='${data.recordId}'][data-record-field='ispassed']`
														);
													result.value =
														Math.abs(
															tt_reading2Element.value -
																device_reading2Element.value
														) == 0
															? 'PASS'
															: 'FAIL';
												}
												// ts3
												if (
													data.recordField === 'tt_reading_3' ||
													data.recordField === 'device_reading_3'
												) {
													const tt_reading3Element: any =
														document.querySelector(
															`input[data-record-id='${data.recordId}'][data-record-field='tt_reading_3']`
														);
													const device_reading3Element: any =
														document.querySelector(
															`input[data-record-id='${data.recordId}'][data-record-field='device_reading_3']`
														);
													const result: any =
														document.querySelector(
															`input[data-record-id='${data.recordId}'][data-record-field='ispassed']`
														);
													result.value =
														Math.abs(
															tt_reading3Element.value -
																device_reading3Element.value
														) == 0
															? 'PASS'
															: 'FAIL';
												}
												// ts4
												if (
													data.recordField === 'tt_reading_4' ||
													data.recordField === 'device_reading_4'
												) {
													const tt_reading4Element: any =
														document.querySelector(
															`input[data-record-id='${data.recordId}'][data-record-field='tt_reading_4']`
														);
													const device_reading4Element: any =
														document.querySelector(
															`input[data-record-id='${data.recordId}'][data-record-field='device_reading_4']`
														);
													const result: any =
														document.querySelector(
															`input[data-record-id='${data.recordId}'][data-record-field='ispassed']`
														);
													result.value =
														Math.abs(
															tt_reading4Element.value -
																device_reading4Element.value
														) == 0
															? 'PASS'
															: 'FAIL';
												}
												// onchange Color----------------------

												if (data.recordField === 'status') {
													const status: any =
														document.querySelector(
															`tr[data-record-id='${data.recordId}'][data-record-field='id']`
														);

													let rowColor;
													let backgroundColor;
													if (value === 'Certified') {
														backgroundColor = '#8db367';
														rowColor = '#000000';
													}
													if (value === 'Calibrated') {
														backgroundColor = '#487EC1';
														rowColor = '#FFFFFF';
													}
													if (value === 'InProgress') {
														backgroundColor = '#CCC0DA';

														rowColor = '#FFFFFF';
													}
													if (value === 'Issue') {
														backgroundColor = 'yellow';

														rowColor = '#000000';
													}
													if (value === 'No') {
														backgroundColor = 'red';

														rowColor = '#000000';
													}
													if (
														!value ||
														value == null ||
														value == ''
													) {
														backgroundColor = '#c4bd97';
														rowColor = '#000000';
													}

													status.style.color = rowColor;
													status.style.backgroundColor =
														backgroundColor;

													backgroundColor = '';
													rowColor = '';
												}

												//sensor Modal------------------------

												if (
													data.recordField === 'probeTypeId' ||
													data.recordField === 'is_lumity' ||
													data.recordField ===
														'sensorId_SequenceNumber'
												) {
													const lumityElement: any =
														document.querySelector(
															`select[data-record-id='${data.recordId}'][data-record-field='is_lumity']`
														);
													const probeTypeElement: any =
														document.querySelector(
															`select[data-record-id='${data.recordId}'][data-record-field='probeTypeId']`
														);
													const sensorIdElement: any =
														document.querySelector(
															`input[data-record-id='${data.recordId}'][data-record-field='sensorId_SequenceNumber']`
														);
													const sensorModalElement: any =
														document.querySelector(
															`input[data-record-id='${data.recordId}'][data-record-field='sensor_modal']`
														);

													let sensor_id,
														is_lumity,
														probe_type,
														sensor_modal;
													if (
														sensorIdElement?.value.includes('/')
													) {
														const [sensorRealId, probeNumber] =
															sensorId_SequenceNumber.split('/');

														sensor_id = sensorRealId;
													}
													sensor_type = sensorModalElement?.value;
													is_lumity = lumityElement?.value;
													probe_type = probeTypeElement?.value;

													// sensor_id = sensorIdElement?.value;
													if (sensor_id) {
														if (is_lumity == 1) {
															if (
																probe_type ==
																	'5fd7573c-e015-4d1e-8fd6-55c8ebef427d' ||
																probe_type ==
																	'6c6ae773-5d70-46c7-a414-f9286b61b5c3'
															) {
																sensor_modal =
																	'FX200 (LUMITY-TH)';
															} else {
																sensor_modal =
																	'FX100 (LUMITY-T)';
															}
														} else {
															if (/[a-zA-Z]/.test(sensor_id)) {
																if (
																	probe_type ==
																		'5fd7573c-e015-4d1e-8fd6-55c8ebef427d' ||
																	probe_type ==
																		'6c6ae773-5d70-46c7-a414-f9286b61b5c3'
																) {
																	sensor_modal =
																		'11079(WIFI-TH)';
																} else {
																	sensor_modal =
																		'11078(WIFI-T)';
																}
															} else {
																if (
																	probe_type ==
																		'5fd7573c-e015-4d1e-8fd6-55c8ebef427d' ||
																	probe_type ==
																		'6c6ae773-5d70-46c7-a414-f9286b61b5c3'
																) {
																	sensor_modal =
																		'10071 (900-TH)';
																} else {
																	sensor_modal =
																		'10080-DT (900-T)';
																}
															}
														}

														sensorModalElement.value =
															sensor_modal;
													}
												}
											};
											// };

											// status-----------------

											if (status == 'valid') {
												status = 'Certified';
											} else if (status == 'failed') {
												status = 'Issue';
											} else if (
												status == undefined ||
												status == null ||
												!status
											) {
												status = 'In progress';
											}

											return (
												<tr
													key={id}
													data-record-field="id"
													defaultValue={id}
													data-record-id={id}
													data-calibration-status={calButtonStyle}
													style={{
														backgroundColor: rowColors[0],
														color: rowColors[1],
													}}
												>
													<td>
														<input
															className={`${id} name`}
															type="text"
															data-record-field="name"
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={name}
															onBlur={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
															onKeyDown={(event) =>
																updateTimestamp(event)
															}
														/>
													</td>

													<td>
														<input
															type="text"
															data-record-field="description"
															className={`${id} description`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={description}
															onBlur={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
															onKeyDown={(event) =>
																updateTimestamp(event)
															}
														/>
													</td>
													<td>
														<input
															className={`${id} group`}
															type="text"
															data-record-field="sensorGroup"
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={group}
															onBlur={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
															onKeyDown={(event) =>
																updateTimestamp(event)
															}
														/>
													</td>

													<td>
														<input
															disabled
															type="text"
															data-record-field="sensor_type"
															className={`${id} sensor_type`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={sensor_type}
															onBlur={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
														/>
													</td>
													<td>
														<input
															disabled
															type="text"
															data-record-field="sensor_modal"
															className={`${id} sensor_modal`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={sensor_modal}
															onBlur={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
														/>
													</td>
													<td>
														<input
															// style={{ width: '100px' }}
															placeholder="Sensor Id"
															type="text"
															data-record-field="sensorId_SequenceNumber"
															className={`${id} sensorId_SequenceNumber`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={
																sensorId_SequenceNumber
															}
															onBlur={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
															onKeyDown={(event) =>
																updateTimestamp(event)
															}
														/>
													</td>
													{/* <td>
														<input
															// style={{ width: '70px' }}
															type="text"
															data-record-field="SequenceNumber"
															className={`${id} description`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={SequenceNumber}
															onBlur={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
														/>
													</td> */}
													<td>
														<select
															data-record-field="status"
															className={`${id} status`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={status}
															onChange={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
														>
															<option value="">
																Select Status...
															</option>
															<option
																key="Certified"
																value="Certified"
															>
																Certified
															</option>
															<option
																key="Calibrated"
																value="Calibrated"
															>
																Calibrated
															</option>
															<option
																key="InProgress"
																value="InProgress"
															>
																In progress
															</option>
															<option key="Issue" value="Issue">
																Issue
															</option>
															<option key="No" value="No">
																No
															</option>
														</select>
													</td>
													<td>
														<select
															data-record-field="probeTypeId"
															className={`${id} probeType`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={probeType}
															onChange={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
														>
															<option value="">
																Select Probe...
															</option>

															{_.map(
																probe_types,
																({ id, name }) => (
																	<option key={id} value={id}>
																		{name}
																	</option>
																)
															)}
														</select>
													</td>
													{/* <td>
														<select
															data-record-field="calibrationProcedureId"
															className={`${id} description`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={
																calibrationProcedureId
															}
															onChange={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
														>
															<option value="">
																Select Procedures...
															</option>

															{_.map(
																store.calibration_procedures,
																({ id, description }) => (
																	<option key={id} value={id}>
																		{description}
																	</option>
																)
															)}
														</select>
													</td> */}

													<td>
														<input
															// style={{ width: '90px' }}
															type="text"
															placeholder="Amb/T"
															data-record-field="ambientTemperature"
															className={`${id} ambientTemperature`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={ambientTemperature}
															onBlur={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
															onKeyDown={(event) =>
																updateTimestamp(event)
															}
														/>
													</td>
													<td>
														<input
															// style={{ width: '90px' }}
															type="text"
															placeholder="Amb/H"
															data-record-field="ambientHumidity"
															className={`${id} ambientHumidity`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={ambientHumidity}
															onBlur={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
															onKeyDown={(event) =>
																updateTimestamp(event)
															}
														/>
													</td>

													<td>
														<input
															type="time"
															step="1"
															data-record-field="timestamp_1"
															className={`${id} timestamp_1`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															disabled
															onMouseOver={(e) =>
																e.currentTarget.toggleAttribute(
																	'disabled',
																	false
																)
															}
															onMouseOut={(e) =>
																e.currentTarget.toggleAttribute(
																	'disabled',
																	true
																)
															}
															defaultValue={
																timestamp_1
																	? moment(
																			new Date(timestamp_1)
																	  ).format('HH:mm:ss')
																	: ''
															}
															onChange={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
														/>
													</td>

													<td>
														<input
															// style={{ width: '100px' }}
															type="text"
															data-record-field="tt_reading"
															className={`${id} tt_reading`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={
																tt_reading != null
																	? tt_reading
																	: ''
															}
															placeholder="UUT-R"
															onBlur={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
															onKeyDown={(event) =>
																updateTimestamp(event)
															}
														/>
													</td>
													<td>
														<input
															// style={{ width: '100px' }}
															placeholder="STD-R"
															type="text"
															data-record-field="device_reading"
															className={`${id} device_reading`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={
																device_reading != null
																	? device_reading
																	: ''
															}
															onBlur={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
															onKeyDown={(event) =>
																updateTimestamp(event)
															}
														/>
													</td>

													<td>
														<input
															type="time"
															step="1"
															data-record-field="timestamp_2"
															className={`${id} timestamp_2`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															disabled
															onMouseOver={(e) =>
																e.currentTarget.toggleAttribute(
																	'disabled',
																	false
																)
															}
															onMouseOut={(e) =>
																e.currentTarget.toggleAttribute(
																	'disabled',
																	true
																)
															}
															defaultValue={
																timestamp_2
																	? moment(
																			new Date(timestamp_2)
																	  ).format('HH:mm:ss')
																	: ''
															}
															onChange={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
														/>
													</td>

													<td>
														<input
															type="text"
															data-record-field="tt_reading_2"
															className={`${id} tt_reading_2`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={
																tt_reading_2 != null
																	? tt_reading_2
																	: ''
															}
															placeholder="UUT-R 2"
															onBlur={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
															onKeyDown={(event) =>
																updateTimestamp(event)
															}
														/>
													</td>
													<td>
														<input
															// style={{ width: '100px' }}
															placeholder="STD-R 2"
															type="text"
															data-record-field="device_reading_2"
															className={`${id} device_reading_2`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={
																device_reading_2 != null
																	? device_reading_2
																	: ''
															}
															onBlur={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
															onKeyDown={(event) =>
																updateTimestamp(event)
															}
														/>
													</td>
													<td>
														<input
															type="time"
															step="1"
															data-record-field="timestamp_3"
															className={`${id} timestamp_3`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															disabled
															onMouseOver={(e) =>
																e.currentTarget.toggleAttribute(
																	'disabled',
																	false
																)
															}
															onMouseOut={(e) =>
																e.currentTarget.toggleAttribute(
																	'disabled',
																	true
																)
															}
															defaultValue={
																timestamp_3
																	? moment(
																			new Date(timestamp_3)
																	  ).format('HH:mm:ss')
																	: ''
															}
															onChange={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
														/>
													</td>
													<td>
														<input
															// style={{ width: '100px' }}
															type="text"
															data-record-field="tt_reading_3"
															className={`${id} tt_reading_3`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={
																tt_reading_3 != null
																	? tt_reading_3
																	: ''
															}
															placeholder="UUT-R 3"
															onBlur={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
															onKeyDown={(event) =>
																updateTimestamp(event)
															}
														/>
													</td>
													<td>
														<input
															// style={{ width: '100px' }}
															placeholder="STD-R 3"
															type="text"
															data-record-field="device_reading_3"
															className={`${id} 															device_reading_3
															`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={
																device_reading_3 != null
																	? device_reading_3
																	: ''
															}
															onBlur={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
															onKeyDown={(event) =>
																updateTimestamp(event)
															}
														/>
													</td>
													<td>
														<input
															type="time"
															step="1"
															data-record-field="timestamp_4"
															className={`${id} timestamp_4`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															disabled
															onMouseOver={(e) =>
																e.currentTarget.toggleAttribute(
																	'disabled',
																	false
																)
															}
															onMouseOut={(e) =>
																e.currentTarget.toggleAttribute(
																	'disabled',
																	true
																)
															}
															defaultValue={
																timestamp_4
																	? moment(
																			new Date(timestamp_4)
																	  ).format('HH:mm:ss')
																	: ''
															}
															onChange={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
														/>
													</td>

													<td>
														<input
															// style={{ width: '100px' }}
															type="text"
															data-record-field="tt_reading_4"
															className={`${id} tt_reading_4`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={
																tt_reading_4 != null
																	? tt_reading_4
																	: ''
															}
															placeholder="UUT-R 4"
															onBlur={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
															onKeyDown={(event) =>
																updateTimestamp(event)
															}
														/>
													</td>
													<td>
														<input
															// style={{ width: '100px' }}
															placeholder="STD-R 4"
															type="text"
															data-record-field="device_reading_4"
															className={`${id} device_reading_4`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={
																device_reading_4 != null
																	? device_reading_4
																	: ''
															}
															onBlur={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
															onKeyDown={(event) =>
																updateTimestamp(event)
															}
														/>
													</td>
													<td>
														<input
															// style={{ width: '100px' }}
															placeholder="probe Serial Number"
															type="text"
															data-record-field="probeSerialNumber"
															className={`${id} probeSerialNumber`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={probeSerialNumber}
															onBlur={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
															onKeyDown={(event) =>
																updateTimestamp(event)
															}
														/>
													</td>
													<td>
														<input
															// style={{ width: '100px' }}
															type="text"
															data-record-field="notes"
															className={`${id} notes`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															placeholder="Notes"
															defaultValue={notes ? notes : ''}
															onBlur={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
															onKeyDown={(event) =>
																updateTimestamp(event)
															}
														/>
													</td>

													{/* 
													<td
														className={
															styles.statusButtonContainer
														}
													>
														<button
															className={styles.row_action}
															onClick={() => {
																handleNotesModalOpen(id, notes);
															}}
														>
															<FontAwesomeIcon
																icon={'file-alt'}
																size="lg"
																color={'#0F7EE3'}
															/>
														</button>
													</td> */}
													<td>
														<input
															style={{
																width: '60px',
																margin: '0',
																paddingTop: '1px',
																paddingRight: '2px',
																paddingBottom: '1px',
																paddingLeft: '2px',
															}}
															data-record-field="ispassed"
															className={`${id} result`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={result}
															disabled
														/>
													</td>

													<td>
														<select
															data-record-field="probe_replaced"
															className={`${id} probe_replaced`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={probe_replaced}
															onChange={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
														>
															<option key={'No'} value="No">
																NO
															</option>
															<option
																key={'YesUpgraded'}
																value="YesUpgraded"
															>
																YES UPGRADED
															</option>
															<option
																key={'YesFailed'}
																value="YesFailed"
															>
																YES FAILED
															</option>
														</select>
													</td>
													<td>
														<select
															data-record-field="is_lumity"
															className={`${id} lumity`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={lumity}
															onChange={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
														>
															<option value="">
																Select Lumity
															</option>
															<option value={0}>NO</option>
															<option value={1}>YES</option>
														</select>
													</td>
													<td>
														<input
															type="text"
															data-record-field="sensor_database"
															className={`${id} sensor_database`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={sensor_database}
															onBlur={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
															onKeyDown={(event) =>
																updateTimestamp(event)
															}
														/>
													</td>
													<td>
														{userIs === 'technician' && (
															<select
																data-record-field="technicianToolId"
																className={`${id} technicianToolId`}
																data-record-id={id}
																data-sensor-id={sensorId}
																data-calibrations-id={cal?.id}
																data-reading-id={calReading?.id}
																defaultValue={
																	technicianToolId ??
																	techToolId
																}
																onChange={(event) =>
																	handleSensorRecordCellChanges(
																		event
																	)
																}
																onFocus={(event) =>
																	focusRecordCell(event)
																}
															>
																{_.map(
																	store.technician_tools,
																	({
																		id,
																		technician_user_id_ref,
																		tool_id,
																	}) =>
																		tool_id &&
																		technician_user_id_ref?.name && (
																			<option
																				key={id}
																				value={id}
																			>
																				{
																					technician_user_id_ref?.name
																				}
																			</option>
																		)
																)}
															</select>
														)}

														{userIs === 'administrator' &&
															!techToolId && (
																<select
																	data-record-field="technicianToolId"
																	className={`${id} technicianToolId`}
																	data-record-id={id}
																	data-sensor-id={sensorId}
																	data-calibrations-id={
																		cal?.id
																	}
																	data-reading-id={
																		calReading?.id
																	}
																	defaultValue={
																		technicianToolId
																	}
																	onChange={(event) =>
																		handleSensorRecordCellChanges(
																			event
																		)
																	}
																	onFocus={(event) =>
																		focusRecordCell(event)
																	}
																>
																	<option value="">
																		Select Tech...
																	</option>
																	{_.map(
																		store.technician_tools,
																		({
																			id,
																			technician_user_id_ref,
																			tool_id,
																		}) =>
																			tool_id &&
																			technician_user_id_ref?.name && (
																				<option
																					key={id}
																					value={id}
																				>
																					{
																						technician_user_id_ref?.name
																					}
																				</option>
																			)
																	)}
																</select>
															)}
														{userIs === 'administrator' &&
															techToolId && (
																<select
																	data-record-field="technicianToolId"
																	className={`${id} technicianToolId`}
																	data-record-id={id}
																	data-sensor-id={sensorId}
																	data-calibrations-id={
																		cal?.id
																	}
																	data-reading-id={
																		calReading?.id
																	}
																	defaultValue={techToolId}
																	onChange={(event) =>
																		handleSensorRecordCellChanges(
																			event
																		)
																	}
																	onFocus={(event) =>
																		focusRecordCell(event)
																	}
																>
																	<option value="">
																		Select Tech...
																	</option>
																	{_.map(
																		store.technician_tools,
																		({
																			id,
																			technician_user_id_ref,
																			tool_id,
																		}) =>
																			tool_id &&
																			technician_user_id_ref?.name && (
																				<option
																					key={id}
																					value={id}
																				>
																					{
																						technician_user_id_ref?.name
																					}
																				</option>
																			)
																	)}
																</select>
															)}
													</td>
													<td>
														<input
															type="date"
															data-record-field="updatedAt"
															className={`${id} updatedAt`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={moment(
																new Date(updatedAt)
															).format('yyyy-MM-DD')}
															onChange={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
														/>
													</td>
													{/* <td>
														<p>
															<input
																placeholder="building"
																type="text"
																data-record-field="building"
																className={`${id} description`}
																data-record-id={id}
																data-sensor-id={sensorId}
																data-calibrations-id={cal?.id}
																data-reading-id={calReading?.id}
																defaultValue={building}
																onBlur={(event) =>
																	handleSensorRecordCellChanges(
																		event
																	)
																}
															/>
														</p>
													</td>
													<td>
														<p>
															<input
																placeholder="floor"
																type="text"
																data-record-field="floor"
																className={`${id} description`}
																data-record-id={id}
																data-sensor-id={sensorId}
																data-calibrations-id={cal?.id}
																data-reading-id={calReading?.id}
																defaultValue={floor}
																onBlur={(event) =>
																	handleSensorRecordCellChanges(
																		event
																	)
																}
															/>
														</p>
													</td>
'sensorDatabase':'xyzwifsakfgS'
													<td>
														<p>
															<input
																placeholder="room"
																type="text"
																data-record-field="room"
																className={`${id} description`}
																data-record-id={id}
																data-sensor-id={sensorId}
																data-calibrations-id={cal?.id}
																data-reading-id={calReading?.id}
																defaultValue={room}
																onBlur={(event) =>
																	handleSensorRecordCellChanges(
																		event
																	)
																}
															/>
														</p>
													</td>
													<td>
														<p>
															<input
																placeholder="chamber"
																type="text"
																data-record-field="chamber"
																className={`${id} description`}
																data-record-id={id}
																data-sensor-id={sensorId}
																data-calibrations-id={cal?.id}
																data-reading-id={calReading?.id}
																defaultValue={chamber}
																onBlur={(event) =>
																	handleSensorRecordCellChanges(
																		event
																	)
																}
															/>
														</p>
													</td>
															*/}
													{/* <td>
														<p>
														<select
															
															data-record-field="serialNumber"
															className={`${id} description`}
															data-record-id={id}
															data-sensor-id={sensorId}
															data-calibrations-id={cal?.id}
															data-reading-id={calReading?.id}
															defaultValue={serialNumber}
															onChange={(event) =>
																handleSensorRecordCellChanges(
																	event
																)
															}
															onFocus={(event) =>
																focusRecordCell(event)
															}
														>
														    <option value="">Select Tool...</option>
                  {
                    _.map(store.technician_tools, ({ id, serial_number }) => (
                      <option key={id} value={serial_number}>
                        {serial_number}
                      </option>
                    ))}
														</select>
														
														</p>
													</td> */}
													{/*
													<td>
														<p>
															<input
																placeholder="sensorReading"
																type="text"
																data-record-field="sensorReading"
																className={`${id} description`}
																data-record-id={id}
																data-sensor-id={sensorId}
																data-calibrations-id={cal?.id}
																data-reading-id={calReading?.id}
																defaultValue={sensorReading}
																onBlur={(event) =>
																	handleSensorRecordCellChanges(
																		event
																	)
																}
															/>
														</p>
													</td>

													<td>
														<p>
															<input
																placeholder="calibration Reading"
																type="text"
																data-record-field="calibrationReading"
																className={`${id} description`}
																data-record-id={id}
																data-sensor-id={sensorId}
																data-calibrations-id={cal?.id}
																data-reading-id={calReading?.id}
																defaultValue={
																	calibrationReading
																}
																onBlur={(event) =>
																	handleSensorRecordCellChanges(
																		event
																	)
																}
															/>
														</p>
													</td> */}
													<td
														className={
															styles.statusButtonContainer
														}
													>
														<button
															disabled={
																!result ||
																(userIs != 'administrator' &&
																	calDueDate < +new Date())
															}
															// disabled={
															// 	result !== 'PASS' ||
															// 	(!isCurrentTechLead && !cert) ||

															className={`${certButtonStyle} ${styles.row_action}`}
															style={{
																color: rowColors[1],
																borderColor: rowColors[1],
															}}
															onClick={() =>
																handleCertifyOne(cal)
															}
														>
															Certify
														</button>
													</td>
													<td
														style={{ display: 'none' }}
														className={
															styles.statusButtonContainer
														}
													>
														<button
															disabled={
																job.status !== 'open' ||
																isDisabled ||
																!(
																	userIs === 'administrator' ||
																	userIs === 'technician'
																)
															}
															className={`${calButtonStyle} ${styles.row_action}`}
															style={{
																pointerEvents: 'auto',
																color: rowColors[1],
																borderColor: rowColors[1],
															}}
															// onClick={(event) =>
															// 	handleCalibrateModalOpen(
															// 		id,
															// 		jobId,
															// 		SequenceNumber,
															// 		event.target
															// 	)
															// }
														>
															{isCalibrating ? (
																<div className="spinner"></div>
															) : !cal?.status ? (
																'Test'
															) : cal?.status === 'valid' ? (
																'Pass'
															) : (
																'Fail'
															)}
														</button>
													</td>

													<td>{isDisabled}</td>
												</tr>
											);
										}
									)}
								</tbody>
							</table>
						</div>
					</>
				)}
				{/* <CalibrateSensor
					calibrateModalIsOpen={calibrateModalIsOpen}
					setCalibrateModalIsOpen={setCalibrateModalIsOpen}
					sensorDbId={sensorId}
					currentJobId={currentJobId}
					clearSensor={() => {
						setSensorId('');
					}}
				/> */}
				<Modal
					isOpen={addSensorModalIsOpen}
					onRequestClose={() => setAddSensorModalIsOpen(false)}
					className="modal"
					overlayClassName="modalOverlay"
				>
					<AddSensor
						jobId={jobId}
						closeModal={() => setAddSensorModalIsOpen(false)}
					/>
				</Modal>
				<Modal
					isOpen={addTechModalIsOpen}
					onRequestClose={() => setAddTechModalIsOpen(false)}
					className="modal"
					overlayClassName="modalOverlay"
				>
					<AddTech
						jobId={jobId}
						closeModal={() => setAddTechModalIsOpen(false)}
					/>
				</Modal>
			</div>
			{/* {currentTab === Tabs.SENSORS && (
				<div className={styles.actionContainer}>
					<div className={`selectContainer ${styles.actionSelect}`}>
						<select
							value=""
							disabled={
								!_.filter(checkMap, (check) => check).length ||
								disableActions
							}
							onChange={({ target }) => {
								if (target.value === 'certify') {
									handleCertify();
								} else if (target.value === 'download') {
									handleBatchDownload();
								} else if (target.value === 'queueDownload') {
									downloadCertificates();
								} else if (target.value === 'disable') {
									handleDisableSensors();
								} else if (target.value === 'enable') {
									handleEnableSensors();
								} else if (target.value === 'recertify') {
									handleCertify(true);
								}
							}}
						>
							<option value="">
								{disableActions
									? 'Processing certs...'
									: 'Select an action...'}
							</option>
							<option value="certify">Certify</option>
							<option
								value="queueDownload"
								disabled={selectedSensorCount === 0}
							>{`Queue New Download (${selectedSensorCount} certs)`}</option>
							<option
								value="download"
								disabled={
									batchDownloadStatus !==
									CertificateBatchStatus.Completed
								}
							>
								Download .zip ({certificateBatchSensorCount} certs)
							</option>
							<option value="disable">Disable</option>
							<option value="enable">Enable</option>
							<option value="recertify">Re-certify</option>
						</select>
					</div>
					<div>
						<button
							disabled={!isJobDone || job.status === 'closed'}
							onClick={() => handleCloseJob()}
						>
							Close Job
						</button>
					</div>
				</div>
			)} */}

			{/* <Modal
				isOpen={isNotesModalOpen}
				onRequestClose={() => setIsNotesModalOpen(false)}
				className="modal"
				overlayClassName="modalOverlay"
			>
				<Notes
					sensorId={sensorId}
					notes={currentNotes}
					closeModal={() => {
						setIsNotesModalOpen(false);
					}}
				/>
			</Modal> */}
			{isAdvanceSortModalOpen && (
				<div className={styles.filterModalHead}>
					<div className={styles.filterModal}>
						<div>
							<div>Status</div>
							<select id="toFilterStaus" name="FilterStaus">
								<option value="">Select Status...</option>
								<option key="Certified" value="Certified">
									Certified
								</option>
								<option key="Calibrated" value="Calibrated">
									Calibrated
								</option>
								<option key="InProgress" value="InProgress">
									In progress
								</option>
								<option key="Issue" value="Issue">
									Issue
								</option>
								<option key="No" value="No">
									No
								</option>
							</select>
						</div>
						<div>
							<div>Sensor Group</div>
							<select id="toFilterGroup" name="FilterGroup">
								<option value="">Select Group...</option>
								{_.map(sensorGroupOpt, (data) => (
									<option key={data} value={data}>
										{data}
									</option>
								))}
							</select>
						</div>
						<div>
							<div>Sensor Type</div>
							<select
								id="toFilterSensorType"
								name="FilterSensorType"
								onChange={(e) => e.target.value}
							>
								<option value="">Sensor Type...</option>
								{_.map(sensorTypeOpt, (data) => (
									<option key={data} value={data}>
										{data}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="footer">
						<button
							className="cancel"
							onClick={() => {
								setIsAdvanceSortModalOpen(false);
								setSensors(job?.job_sensors);
							}}
						>
							Cancel
						</button>
						<button className="cancel" onClick={() => FilterData()}>
							Filter
						</button>
					</div>
				</div>
			)}
			{isCustamizeTableOpen && (
				<div className={styles.CustamizeTableModal}>
					<table className={styles.custamizetable}>
						<thead>
							<tr>
								<th>Sr. No.</th>
								<th>Field</th>
								<th>Operation</th>
								<th>Value</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{filterList.map((data, index) => {
								return (
									<tr key={index}>
										<td>{index + 1}</td>
										<td>
											<select
												// defaultValue={data.field??''}
												onChange={(event) =>
													(data.field = event.target.value)
												}
											>
												<option value="">Select Field...</option>

												{_.map(
													sensorsTable.columns.slice(
														0,
														sensorsTable.columns.length - 4
													),
													(data) => (
														<option
															key={data.key}
															value={data.key}
														>
															{data.title}
														</option>
													)
												)}
											</select>
										</td>
										<td>
											<select
												onChange={(event) =>
													(data.operation = event.target.value)
												}
											>
												<option value="">
													Select Operation...
												</option>
												<option
													key="GreaterThan"
													value="GreaterThan"
												>
													Greater Than
												</option>
												<option key="LessThan" value="LessThan">
													Less Than
												</option>
												<option key="EqualTo" value="EqualTo">
													Equal To
												</option>

												<option key="contains" value="contains">
													Contains
												</option>
											</select>
										</td>
										<td>
											<input
												onBlur={(event) =>
													(data.feildValue = event.target.value)
												}
												type="text"
											/>
										</td>
										<td>
											<button
												onClick={() =>
													addToFilterArr(index, 'push', data)
												}
											>
												+
											</button>
											<button
												onClick={() => addToFilterArr(index, 'pop')}
											>
												<FontAwesomeIcon
													icon="trash"
													color="red"
													cursor="pointer"
												/>
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					<div className="footer">
						<button
							className="cancel"
							onClick={() => filterTableData(false)}
						>
							Cancel
						</button>
						<button onClick={() => filterTableData(true)}>Done</button>
					</div>
				</div>
			)}
		</div>
	);
}
