import _ from 'lodash';import React, {
	FC,
	useContext,
	useState,
	useMemo,
	useCallback,
	useEffect,
} from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Modal from 'react-modal';
import { authCtx } from 'src/api/AuthProvider';
import { StoreContext } from 'src/database/store/StoreProvider';
import { TechnicianToolsDBEntry } from 'src/database/store/StoreTypes';
import { Table, useTable } from 'src/hooks/useTable';
import { format } from 'date-fns';
import TableHeader, {
	SortingPackage,
} from '../../../components/TableHeader/TableHeader';
import urls from '../../../api/urls';
import styles from './list.module.css';
import { SearchBar, useSearch } from '../../../components/SearchBar/SearchBar';
import AddAsset from '../../../components/modals/AddAsset/addAsset';
import EditAsset from '../../../components/modals/EditAsset/EditAsset';
// import CSVReader from '../../../components/CSV/CsvUpload';
import Tool from '../../../models/Tool';
import useCurrentUser from 'src/hooks/useCurrentUser';
import useNavStore from 'src/components/RootNavBarIcon/NavStore';
import { useLocation } from 'react-router-dom';
// const roleId = localStorage.getItem('user-role-id') || '';

type ColumnKey =
	| 'serial_number'
	| 'assetType'
	| 'technicians'
	| 'calibration_due_date'
	| 'action';
const toolsTable: Table<TechnicianToolsDBEntry, ColumnKey> = {
	columns: [
		{
			key: 'serial_number',
			title: 'Asset ID',
			extractFunction: (technician_tools) => technician_tools.serial_number,
		},
		{
			key: 'assetType',
			title: 'Asset Type',
			extractFunction: (technician_tools) =>
				technician_tools.tool_id_ref?.name,
		},
		{
			key: 'technicians',
			title: 'Technicians',
			extractFunction: (technician_tools) =>
				technician_tools.technician_user_id_ref?.name,
		},
		{
			key: 'calibration_due_date',
			title: 'Due Date',
			extractFunction: (technician_tools) =>
				technician_tools.calibration_due_date,
		},
		{
			key: 'action',
			title: 'Action',
			extractFunction: (technician_tools) => '',
		},
	],
};

type CompanyAssetsProps = {};
toast.configure();
const CompanyAssets: FC<CompanyAssetsProps> = () => {
	const { isToogle } = useNavStore();
	useEffect(() => {}, [isToogle]);
	const { justSync } = useContext(authCtx);
	const { accessToken } = useContext(authCtx);
	const [searchQuery, setSearchQuery] = useState('');
	const { store } = useContext(StoreContext);
	// var { technician_tools } = store;
	const [addUserModalIsOpen, setAddUserModalIsOpen] = useState(false);
	const [technician_tools, setSechnician_tools] = useState(
		store.technician_tools
	);
	const [SelectedAlertAssetOnly, setSelectedAlertAssetOnly] = useState(false);
	const [editUserModalIsOpen, setEditUserModalIsOpen] = useState(false);
	const [currentSort, setCurrentSort] = useState<ColumnKey>('serial_number');
	const [isAscending, setIsAscending] = useState(true);
	const [assetToUpdate, setAssetToUpdate] = useState<TechnicianToolsDBEntry>();
	const dbUser = useCurrentUser();
	// User Type
	const userIsAdmin = useMemo(
		() => dbUser?.role_id_ref.name === 'administrator',
		[dbUser]
	);
	const userIsTech = useMemo(
		() => dbUser?.role_id_ref.name === 'technician',
		[dbUser]
	);
	// search
	const filteredSensors = useSearch(
		technician_tools || Tool,
		searchQuery,
		(
			{
				serial_number,
				tool_id_ref,
				technician_user_id_ref,
				calibration_due_date,
			},
			test
		) =>
			test(serial_number) ||
			test(tool_id_ref.name) ||
			test(technician_user_id_ref?.name) ||
			test(calibration_due_date.toString())
	);
	const tableData = useTable(
		toolsTable,
		filteredSensors,
		currentSort,
		isAscending
	);
	const sortingPackage: SortingPackage<ColumnKey> = {
		setCurrentSort,
		currentSort,
		setIsAscending,
		isAscending,
	};
	function EditAssetFun(id: any) {
		setAssetToUpdate(store.technician_tools[id]);
		setEditUserModalIsOpen(true);
	}
	function deleteAssetFun(id: any, tech: any, rsNo: any) {
		if (
			window.confirm(
				`Do you want to delete the Asset - ${rsNo} assigned to ${tech}?`
			)
		) {
			handleDelete(id);
		}
	}

	// localStorage.setItem('just-user-login', 'true');
	const justUserLogin = localStorage.getItem('just-user-login');
	useEffect(() => {
		if (technician_tools && userIsTech && justUserLogin === 'true') {
			setTimeout(() => {
				let newData = _.filter(
					technician_tools,
					(data) => data.calibration_due_date < +new Date()
				);
				_.map(newData, (data) => {
					let days = ~~(
						(data.calibration_due_date - +new Date()) /
						(1000 * 60 * 60 * 24)
					);
					if (days <= 30) {
						0 > days
							? toast.error(
									`${data.tool_id_ref?.name}-${
										data.serial_number
									} is  expired  ${Math.abs(days)} days ago.`,
									{ autoClose: 10000 }
							  )
							: toast.error(
									`${data.tool_id_ref?.name}-${
										data.serial_number
									} is due expiry in ${Math.abs(days)} days.`,
									{ autoClose: 10000 }
							  );
					}
				});
			}, 1000);
		}
		localStorage.removeItem('just-user-login');
	}, []);
	let location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const param = queryParams.get('AssetsID');
	useEffect(() => {
		if (param) {
			setSelectedAlertAssetOnly(true);
		}
	}, []);
	useEffect(() => {
		if (SelectedAlertAssetOnly && param) {
			setSechnician_tools(
				_.filter(technician_tools, (data) => data.id === param) as any
			);
			// setSechnician_tools(_.filter(technician_tools,(data)=>data.calibration_due_date<+new Date() + 86400000 * 30) as any)
			// console.log("technician_tools.length===SelectedAlertAssetOnly",Object.keys(technician_tools).length);
		}
	}, [SelectedAlertAssetOnly, param]);

	function tableToCSV() {
		let csv_data: any[] = [];
		let csv_row: any[] = [];
		let rows = document.querySelectorAll('.TableHeader p');
		let cols = document.querySelectorAll('#assetTable p');
		let SysCal='SysCal Reference Standard Device'

		for (let i = 0; i < rows.length - 1; i++) {
			
			csv_row.push(rows[i].innerHTML);
		}
		csv_data.push(csv_row);
		csv_row = [];
		let k = 4;
		for (let j = 0; j < cols.length; j++) {
			if (j > 0 && j === k) {
				k += 5;
				csv_data.push(csv_row);
				csv_row = [];
			} else {
				let tableCellData=cols[j].innerHTML.replace(',', '')
				csv_row.push(tableCellData==='SysCal™ Reference Standard Device'?SysCal:tableCellData);
					
			}
		}
		csv_data.push(csv_row);
		let finalDataArr = [];
		finalDataArr.push(csv_data.join(`\n`));
		downloadCSVFile(finalDataArr);
	}
	function downloadCSVFile(csv_data: any) {
		let CSVFile = new Blob([csv_data], {
			type: 'text/csv;charset=utf-8' 
			
		});
		var temp_link = document.createElement('a');
		temp_link.download = 'Company Assets.csv';
		var url = window.URL.createObjectURL(CSVFile);
		temp_link.href = url;
		temp_link.style.display = 'none';
		document.body.appendChild(temp_link);
		temp_link.click();
		document.body.removeChild(temp_link);
	}
	const handleDelete = useCallback(async (id) => {
		const { base, deteteAsset } = urls;
		try {
			let del = await fetch(`${base}${deteteAsset.url}/${id}`, {
				method: deteteAsset.method,
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'Content-Type': 'application/json',
				},
			});
			if (del.status == 204) {
				toast.success(`Asset Deleted Successfull`, { autoClose: 1000 });
			} else {
				toast.error(`Error While Deleting Asset `, { autoClose: 2000 });
			}

			justSync();
		} catch (error) {
			console.log('Error While Deleting Asset');
			toast.error(`Error While Deleting Asset `, { autoClose: 2000 });
		}
	}, []);

	return (
		<div className={'mainContentContainer'}>
			<div className={styles.header}>
				<h2>Company Assets</h2>
				{userIsAdmin && (
					<button onClick={() => setAddUserModalIsOpen(true)}>
						Add Company Asset
					</button>
				)}
				{/* {userIsAdmin && <CSVReader />} */}
				<button type="button" onClick={tableToCSV}>
					Download CSV
				</button>
				<SearchBar
					searchQuery={searchQuery}
					setSearchQuery={setSearchQuery}
					placeholder="Search"
				/>
			</div>
			<div style={{ marginLeft: isToogle === 'YES' ? '90px' : '' }}>
				<TableHeader
					columns={toolsTable.columns}
					// rowClassName={styles.row}
					rowClassName={`${styles.row} TableHeader`}
					sortingPackage={sortingPackage}
				/>
				{tableData.map(
					({
						id,
						serial_number,
						assetType,
						technicians,
						calibration_due_date,
					}) => (
						// {SelectedAlertAssetOnly?}
						<div
							id="assetTable"
							style={{
								backgroundColor:
									userIsTech &&
									calibration_due_date < +new Date() + 86400000 * 30
										? calibration_due_date <
										  +new Date() + 86400000 * 15
											? 'red'
											: 'yellow'
										: 'transparent',
							}}
							key={id}
							className={`${styles.row} hvrPtr row`}
							// onClick={() => EditAssetFun(id)}
						>
							<p>{serial_number}</p>
							<p>{assetType}</p>
							<p>{technicians}</p>
							<p>
								{format(
									new Date(calibration_due_date),
									'MM-dd-yyyy, h:mm a'
								)}
							</p>
							<p>
								<button
									disabled={!userIsAdmin}
									className={`${styles.btn} ${styles.btnEdit}`}
									onClick={() => EditAssetFun(id)}
								>
									Edit
								</button>
								<button
									disabled={!userIsAdmin}
									className={`${styles.btn} ${styles.btnDel}`}
									onClick={() =>
										deleteAssetFun(id, technicians, serial_number)
									}
								>
									Delete
								</button>
							</p>
						</div>
					)
				)}
			</div>
			<Modal
				isOpen={addUserModalIsOpen}
				onRequestClose={() => setAddUserModalIsOpen(false)}
				className="modal"
				overlayClassName="modalOverlay"
			>
				<AddAsset closeModal={() => setAddUserModalIsOpen(false)} />
			</Modal>
			<Modal
				isOpen={editUserModalIsOpen}
				onRequestClose={() => setEditUserModalIsOpen(false)}
				className="modal"
				overlayClassName="modalOverlay"
			>
				{assetToUpdate ? (
					<EditAsset
						assetToUpdate={assetToUpdate}
						closeModal={() => setEditUserModalIsOpen(false)}
					/>
				) : (
					<></>
				)}
			</Modal>
		</div>
	);
};

export default CompanyAssets;
