import _ from 'lodash';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { authCtx } from 'src/api/AuthProvider';
import { StoreContext } from 'src/database/store/StoreProvider';
import { upsertCalProc } from 'src/models/CalibrationProcedure';
import { latestDefaultCalProc, parseIsoDate, getToleranceFScore } from 'src/utils/general';
import styles from "./addCalProc.module.css";

type AddCalProcProps = {
  closeModal: () => void;
  orgId?: string;
  seed?: {
    id?: string;
    probeTypeId?: string;
    name?: string;
    level?: string;
    seqCount?: string;
    calStd?: string;
    procDesc?: string;
    description?: string;
    revDate?: Date;
  }
};

const AddCalProc: FC<AddCalProcProps> = ({ orgId, closeModal, seed = {} }) => {
  const { store } = useContext(StoreContext);
  const { justSync } = useContext(authCtx);
  const [probeTypeId, setProbeTypeId] = useState(seed.probeTypeId || '')
  const [name, setName] = useState(seed.name || '');
  const [level, setLevel] = useState(seed.level || '');
  const [seqCount, setSeqCount] = useState(seed.seqCount || '');
  const [calStd, setCalStd] = useState(seed.calStd || '');
  const [procDesc, setProcDesc] = useState(seed.procDesc || '');
  const [description, setDescription] = useState(seed.description || '');
  const [revDate, setRevDate] = useState(seed.revDate||new Date());
  const [isProcessing, setIsProcessing] = useState(false);

  const { probe_types } = store;
  const probeType = probe_types[probeTypeId!];

  const isSubmissionReady = name && level && seqCount && calStd && procDesc && probeTypeId && description;

  const handleSubmit = useCallback(async () => {
    if (!isSubmissionReady) return;
    setIsProcessing(true);
    // await upsertCalProc({ id: seed?.id, name, level,
    //   //  seqCount, 
    //   //  calStd, 
    //   //  procDesc,
    //    probeTypeId, revDate, orgId ,description});
    justSync();
    setIsProcessing(false);
    closeModal();
  }, [isSubmissionReady, seed, name, level, seqCount, calStd, procDesc, probeTypeId, revDate, orgId,description, closeModal]);

  useEffect(() => {
    if (_.size(seed)) return;
    const defaultCalProc = latestDefaultCalProc(probeType);
    console.log('probeType',probeType);
    console.log('defaultCalProc',defaultCalProc);
    if (!defaultCalProc) return;
    const { level, number_of_tests, calibration_standard_text, calibration_procedure_text } = defaultCalProc;
    setLevel(level);
    setSeqCount(number_of_tests.toString(10));
    setCalStd(calibration_standard_text);
    setProcDesc(calibration_procedure_text);
  }, [seed, probeType])

  return (
    <div className="modalContainer">
      <div className="modalHeader">
        <h2>Calibration Procedure</h2>
      </div>
      <div className="content">
        <div className="multiCol" style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
        <div>
        <label>Probe Type</label>
        <div className="selectContainer">
            <select
              disabled={!!seed.probeTypeId}
              value={probeTypeId}
              onChange={({ target }) => setProbeTypeId(target.value)}
            >
              <option value="">{seed.probeTypeId ? probeType?.name : 'Select Probe Type...'}</option>
              {_.map(probe_types, ({ name, id }) => (<option key={id} value={id}>{name}</option>))}
            </select>
          </div>
        </div>
        <div>
            <label>Procedure Name</label>
            <input
              onChange={({ target }) => setName(target.value)}
              value={name}
            />
          </div>
          <div>
            <label>Level</label>
            <input
              onChange={({ target }) => setLevel(target.value)}
              value={level}
            />
          </div>
          <div>
            <label>Revision Date</label>
            <input
              className={styles.inputDate}
              type="date"
              onChange={({ target }) => { setRevDate(new Date(`${target.value} 00:00:00`)) }}
              value={parseIsoDate(revDate)}
            />
          </div>
        </div>
    
        <h3>Calibration Requirements</h3>
        <div className="multiCol" style={{ gridTemplateColumns: '2fr 1fr 1fr' }}>

          <div>
            <label>Calibration Tool</label>
            {/* <div className="selectContainer">
              <select
                value={toolId}
                onChange={({ target }) => setToolId(target.value)}
              >
                <option value="">Select Tool...</option>
                {
                  _.map(tools, ({ name, id }) => (<option key={id} value={id}>{_.capitalize(name)}</option>))
                }
              </select>
            </div> */}
            <input
              disabled
              value={probeType?.tool_id_ref?.name} />
          </div>
          <div>
            <label>Tolerance</label>
            <input
              disabled
              value={`+/- ${probeType?.tolerance || ''} \u00b0${probeType?.tolerance_unit || ''} ${probeType?.tolerance_unit === 'C' ? `(${getToleranceFScore(probeType?.tolerance)}\u00b0F)` : ''}`}
            />
          </div>
          <div>
            <label>Num. of Sequences</label>
            <input
              onChange={({ target }) => setSeqCount(target.value)}
              value={seqCount}
            />
          </div>
        </div>
        <h3>Calibration Text</h3>
        <label>Calibration Standard</label>
        <textarea
          rows={6}
          onChange={({ target }) => setCalStd(target.value)}
          value={calStd}
        />
        <label>Calibration Procedure</label>
        <textarea
          rows={8}
          onChange={({ target }) => setProcDesc(target.value)}
          value={procDesc}
        />
        <label>Calibration Description</label>
        <textarea
          rows={8}
          onChange={({ target }) => setDescription(target.value)}
          value={description}
        />
      </div>
      <div className="footer">
        <button disabled={isProcessing} className="cancel" onClick={closeModal}>Cancel</button>
        <button disabled={!isSubmissionReady || isProcessing} onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

export default AddCalProc;
