import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { BaseModelDBEntry } from "src/database/store/StoreTypes";
import { Column } from "src/hooks/useTable";
import styles from './tableHeader.module.css';

export type SortingPackage<TColumns extends string> = {
  setCurrentSort: React.Dispatch<React.SetStateAction<TColumns>>;
  currentSort: TColumns;
  setIsAscending: React.Dispatch<React.SetStateAction<boolean>>;
  isAscending: boolean;
};

type TableHeaderProps<TColumns extends string, TEntry extends BaseModelDBEntry> = {
  columns: Column<TColumns, TEntry>[];
  rowClassName: string;
  sortingPackage: SortingPackage<TColumns>;
};

function TableHeader<TColumns extends string, TEntry extends BaseModelDBEntry>({
  columns,
  rowClassName,
  sortingPackage,
}: TableHeaderProps<TColumns, TEntry>) {
  const {
    setCurrentSort,
    currentSort,
    setIsAscending,
    isAscending,
  } = sortingPackage;

  const handleSort = useCallback(
    (colKey: TColumns) => {
      if (currentSort === colKey) {
        setIsAscending(!isAscending);
      } else {
        setIsAscending(false);
        setCurrentSort(colKey);
      }
    },
    [currentSort, isAscending, setCurrentSort, setIsAscending]
  );

  return (
    <div className={`${rowClassName} headerRow`}>
      {columns.map(({ title, key, doNotSort, headerComponent, headerAlign = 'left' }) => (
        headerComponent ? headerComponent({}) :
          <div key={key}>
            <div className={`${!doNotSort && styles.clickableArea} ${styles.col} ${styles[headerAlign]} tableHeader`} onClick={() => !doNotSort && handleSort(key)}>
              <p>{title}</p>
              {!doNotSort && <FontAwesomeIcon icon={currentSort !== key ? 'sort' : isAscending ? 'sort-up' : 'sort-down'} />}
            </div>
          </div>
      ))}
    </div>
  );
};

export default TableHeader;
