import Bugsnag from '@bugsnag/browser';
import auth0 from 'auth0-js';
import React, {
	createContext,
	FC,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import useNavStore from 'src/components/RootNavBarIcon/NavStore';
import Loader from 'src/components/modals/Loaded/Loader';
import { database } from 'src/database';
import { syncDatabase } from 'src/database/sync';
import useOfflineQueue from 'src/hooks/useOfflineQueue';
import User from 'src/models/User';
import { isTokenExpired } from 'src/utils/general';
import urls from './urls';

export const staticToken = { token: '' };

export const webAuth = new auth0.WebAuth({
	domain: process.env.REACT_APP_AUTH_DOMAIN!,
	clientID: process.env.REACT_APP_CLIENT_ID!,
	responseType: 'token',
	redirectUri: process.env.REACT_APP_PUBLIC_URL,
	audience: process.env.REACT_APP_AUDIENCE || 'ah-backend-starter-01',
});

type AuthValues = {
	isLoadingUser?: boolean;
	accessToken: string;
	setToken?: (token: string) => void;
	user?: User;
	justSync: () => void;
	tokenIsExpired: boolean;
	isSyncing: boolean;
};

export const authCtx = createContext<AuthValues>({
	accessToken: '',
	justSync: () => {},
	tokenIsExpired: false,
	isSyncing: false,
});

export const AuthProvider: FC = ({ children }) => {
	const history = useHistory();
	const { isOnline, setDoSyncOnceOnline, doSyncOnceOnline } =
		useOfflineQueue();
	const [accessToken, _setToken] = useState('');
	const [user, setUser] = useState<User>();
	const [isSyncing, setIsSyncing] = useState(false);
	const [isLoadingUser, setIsLoadingUser] = useState(false);
	const [syncAgain, setSyncAgain] = useState(false);
	const storedToken = localStorage.getItem('token');
	const { isCallibrationAutoSave ,setIsCallibrationAutoSave} = useNavStore();
	const setToken = useCallback(
		(token: string = '') => {
			staticToken.token = token;
			localStorage.setItem('token', token);
			_setToken(token);
		},
		[_setToken]
	);

	useEffect(() => {
		if (storedToken) {
			const isExpired = isTokenExpired(storedToken);
			if (isExpired) {
				history?.push('/login');
			} else {
				setToken(storedToken!);
			}
		}
	}, [setToken, history, storedToken]);

	const justSync = () => {
		setDoSyncOnceOnline(true);
		if (isSyncing) {
			setSyncAgain(true);
		}
		
	};
	useEffect(() => {
		const actuallySync = async () => {
			try {
				if (!isSyncing) {
					setIsSyncing(true);
					await syncDatabase(database, staticToken.token);
					if (syncAgain) {
						await syncDatabase(database, staticToken.token);
					}
					setIsSyncing(false);
					setDoSyncOnceOnline(false);
					setSyncAgain(false);
				}
			} catch (err) {
				Bugsnag.notify(err as any);
				setIsSyncing(false);
				setDoSyncOnceOnline(true);
			}
		};
		if (isOnline && doSyncOnceOnline) {
			actuallySync();
		}
		setIsCallibrationAutoSave('NO')
	}, [
		isOnline,
		doSyncOnceOnline,
		isSyncing,
		database,
		staticToken.token,
		syncAgain,
	]);

	const handleSetUser = useCallback((user?: User) => {
		setUser(user);
		if (user) {
			localStorage.setItem('user', JSON.stringify(user));
			
		} else {
			localStorage.removeItem('user');
			localStorage.removeItem('user-id');
			localStorage.removeItem('user-role-id');
		}
	}, []);

	const fetchUser = useCallback(
		async (token?: string) => {
			let anyToken = token || accessToken || storedToken;
			if (!anyToken) {
				return;
			}
			const headers = new Headers();
			headers.append('Authorization', `Bearer ${anyToken}`);

			const requestOptions = {
				method: 'GET',
				headers,
				redirect: 'follow' as any,
			};

			setIsLoadingUser(true);
			let response, user;
			try {
				const url = `${urls.base}${urls.usersMe.url}`;
				response = await fetch(url, requestOptions);
				user = await response.json();
			} finally {
				setIsLoadingUser(false);
			}

			if (response.status !== 200) {
				handleSetUser(undefined);
				return;
			}

			localStorage.setItem('token', anyToken);
			localStorage.setItem('user-role-id', user.roleId);
			localStorage.setItem('user-id', user.id);
			localStorage.setItem('just-user-login', 'true');
			handleSetUser(user);
			return user;
		},
		[accessToken, storedToken]
	);

	useEffect(() => {
		if (!accessToken && !storedToken) {
			handleSetUser(undefined);
			return;
		}
		fetchUser();
	}, [accessToken, fetchUser, storedToken]);

	return (
		<>
			{isSyncing && isCallibrationAutoSave==='NO' && <Loader closeModal={() => setIsSyncing(false)} />}

			<authCtx.Provider
				value={{
					accessToken,
					setToken,
					user,
					isLoadingUser,
					justSync,
					tokenIsExpired: isTokenExpired(accessToken),
					isSyncing,
				}}
			>
				{children}
			</authCtx.Provider>
		</>
	);
};
