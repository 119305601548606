import React, { FC, useCallback, useContext, useState } from 'react';import styles from './Loader.module.css';
type loaderProps = {
	closeModal: () => void;
};

const Loader: FC<loaderProps> = ({ closeModal }) => {
	return (
			<div className={styles.loader}></div>
	);
};
export default Loader;
