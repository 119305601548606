import { Relation } from "@nozbe/watermelondb";
import { BelongsToAssociation } from "@nozbe/watermelondb/Model";
import { writer, field, relation } from "@nozbe/watermelondb/decorators";
import { Association, FieldData } from "src/types/watermelon";
import BaseModel from "./BaseModel";
import User from "./User";

export default class Certificate extends BaseModel {
  static table = "certificates";
  static associations = {
    users: {
      type: Association.BELONGS_TO,
      key: "user_id",
    } as BelongsToAssociation,
    calibrations: {
      type: Association.BELONGS_TO,
      key: "calibration_id",
    } as BelongsToAssociation,
  };
  getTableName() {
    return Certificate.table;
  }
  @field("name") name!: string;
  @field("file_url") fileUrl!: string;
  @field("notes") notes!: string;
  @field("status") status!: CertificateStatus;
  @relation("users", "user_id") certifiedBy!: Relation<User>;
  @relation("calibrations", "calibration_id") calibration!: Relation<Certificate>;
  @writer async certifySensor(certificate: CertificateUpdateBody, certifiedBy: User) {
    const createdCertificate = await this.collections.get<Certificate>('certificates').create(newCertificate => {
      Object.assign(newCertificate, certificate);
      newCertificate.calibration.set(this);
      newCertificate.certifiedBy.set(certifiedBy);
    });
    return createdCertificate;
  }
}

export enum CertificateStatus {
  Unknown = 0,
  Processing = "PASS",
  Completed = 2,
  Failed = 3,
}

export type CertificateUpdateBody = Pick<
  Certificate,
  "name"
  | "fileUrl"
  | "notes"
  | "status"
>;

export const certificateFieldData: FieldData<CertificateUpdateBody> = {
  name: "Certificate Name",
  fileUrl: "File URL",
  notes: "Notes",
  status: "Certificate Status"
}
