import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _, { map } from 'lodash';
import React, {
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import Modal from 'react-modal';
import { useHistory, useParams } from 'react-router-dom';
import urls from 'src/api/urls';
import { toast } from 'react-toastify';
import { database } from 'src/database';
import Backout from 'src/components/Backout/Backout';
import useNavStore from 'src/components/RootNavBarIcon/NavStore';
import TableHeader, {
	SortingPackage,
} from 'src/components/TableHeader/TableHeader';
import { StoreContext } from 'src/database/store/StoreProvider';
import {
	CalibrationProceduresDBEntry,
	PopColl,
} from 'src/database/store/StoreTypes';
import { Table, useTable } from 'src/hooks/useTable';
import { unixToStr } from 'src/utils/general';
import AddCalProcTool from './AddCalProcTool/AddCalProcTool';
import styles from './tooldetails.module.css';
import { authCtx } from 'src/api/AuthProvider';
import CalibrationProcedure from 'src/models/CalibrationProcedure';
// import { useParams } from 'react-router-dom';
type ColumnKey = 'name' | 'level' | 'revisionDate' | 'description' | 'action';

const calProcTable: Table<CalibrationProceduresDBEntry, ColumnKey> = {
	columns: [
		{
			key: 'name',
			title: 'Name',
			extractFunction: (calProc) => calProc?.revision_name,
		},
		{
			key: 'level',
			title: 'Level',
			extractFunction: (calProc) => calProc?.level,
		},
		{
			key: 'revisionDate',
			title: 'Revision Date',
			extractFunction: (calProc) => calProc?.revision_date,
		},
		{
			key: 'description',
			title: 'Description',
			extractFunction: (calProc) => calProc?.description,
			// headerAlign: 'right',
		},
		{
			key: 'action',
			title: 'action',
			extractFunction: (calProc) => calProc,
			headerAlign: 'center',
		},
	],
};
type ToolProps = {};

const Tooldetails: FC<ToolProps> = () => {
	const { id } = useParams<{ id: string }>();
	const { accessToken, justSync } = useContext(authCtx);
	const history = useHistory();
	const [AddCalProcModalIsOpen, setAddCalProcModalIsOpen] = useState(false);
	const [selectedCalProcId, setSelectedCalProcId] = useState('');
	const [currentSort, setCurrentSort] = useState<ColumnKey>('revisionDate');
	const [isAscending, setIsAscending] = useState(false);
	const [selsectedToolId, setSelsectedToolId] = useState(id);
	const { isToogle } = useNavStore();
	const { store } = useContext(StoreContext);
	const selsectedTool = store.tools[id];
	const selectedCalProc = store.calibration_procedures[selectedCalProcId];

	const handleOpenModal = useCallback((id: string) => {
		if (probeforOnlySelectedTool.length > 0 && id==='') {
			toast.error(`You already created one procedure for selected tool`, {
				autoClose: 10000,
			});
		}else{
			setSelectedCalProcId(id ?? '');
			setAddCalProcModalIsOpen(true);
		}
		
	}, []);
	const handleCloseModal = useCallback(() => {
		setSelectedCalProcId('');
		setAddCalProcModalIsOpen(false);
	}, []);
	const probe = store.calibration_procedures;
	const selectedProbeToUpdate =
		store.calibration_procedures[selectedCalProcId];
	const probeforOnlySelectedTool: any = useMemo(() => {
		return _.filter(
			store.calibration_procedures,
			(data) => data.tool_id == selsectedToolId
		);
	}, [selsectedToolId, store.calibration_procedures]);
	// console.log('selectedCalProc--------', selectedCalProc);
	// console.log('probeforOnlySelectedTool d=====', probeforOnlySelectedTool);
	// console.log('probe d=====', store.calibration_procedures);
	const tableData = useTable(
		calProcTable,
		probeforOnlySelectedTool,
		currentSort,
		isAscending
	);
	const sortingPackage: SortingPackage<ColumnKey> = {
		setCurrentSort,
		currentSort,
		setIsAscending,
		isAscending,
	};

	const deleteOne = async (id: string, name: string) => {
		if (id && window.confirm(`Are You Sure To Delete ${name}`)) {
			// const { base, deleteUser } = urls;

			try {
			
				const { base, calProc } = urls;
				await fetch(`${base}${calProc.delete.url}${id}`, {
					method: calProc.delete.method,
					headers: {
						Authorization: `Bearer ${accessToken}`,
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ id: id }),
				}).then(async (res) => {
					if (res?.status == 204) {
						justSync();
						toast.error(`Calibration procedures:${name} Deleted Successfully`, {
							autoClose: 5000,
						});
					}
					const calibrationModel = await database.collections
					.get<CalibrationProcedure>('calibration_procedures')
					.find(id);
				console.log('calibration_procedures deleted from WatermelonDB');
				await calibrationModel.deletePermanentlyRecord()
				});
			} catch (error) {
				console.log('Error while deleting calibration_procedures', error);

				toast.error(`Error while deleting calibration_procedures:${name}`, {
					autoClose: 5000,
				});
			}
		}
	};


	return (
		<div className={'mainContentContainer'}>
			<div className="header">
				<div>
					<Backout backoutTitle="Tool" />
					<h2>{selsectedTool?.name}</h2>
				</div>
		
				<button
					onClick={() => handleOpenModal('')}
				>
					New Procedure
				</button>
				
				
			</div>
			<div style={{ marginLeft: isToogle === 'YES' ? '90px' : '' }}>
				<h3 className="listTitle">Calibration Procedures</h3>
				<TableHeader
					columns={calProcTable.columns}
					rowClassName={styles.row}
					sortingPackage={sortingPackage}
				/>
				{tableData.map(({ name, level, revisionDate, id, description }) => (
					<div key={id} className={`${styles.row} hvrPtr row`}>
						<p onClick={() => handleOpenModal(id)}>{name}</p>
						<p>{level}</p>
						<p>{unixToStr(revisionDate)}</p>
						<p>{description}</p>
						<p style={{ textAlign: 'center' }}>
							<FontAwesomeIcon
								className={styles.actionEdit}
								onClick={() => handleOpenModal(id)}
								icon="pen-square"
								size="1x"
								cursor="pointer"
							/>
							<FontAwesomeIcon
								className={styles.actionDel}
								onClick={() => deleteOne(id, name)}
								icon="trash"
								size="1x"
								cursor="pointer"
							/>
						</p>
					</div>
				))}
			</div>

			<Modal
				isOpen={AddCalProcModalIsOpen}
				onRequestClose={handleCloseModal}
				className="modal"
				overlayClassName="modalOverlay"
			>
				<AddCalProcTool
					closeModal={handleCloseModal}
					seed={{
						selsectedToolId: selsectedToolId,
						id: selectedProbeToUpdate?.id,
						// probeTypeId: selectedProbeToUpdate?.probe_type_id,
						level: selectedProbeToUpdate?.level,
						name: selectedProbeToUpdate?.revision_name,
						description: selectedProbeToUpdate?.description,
						revDate: new Date(selectedProbeToUpdate?.revision_date),
					}}
				/>
			</Modal>
		</div>
	);
};

export default Tooldetails;
