import _ from 'lodash';
import React, { FC, useContext, useMemo, useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { StoreContext } from 'src/database/store/StoreProvider';
import { CalibrationProceduresDBEntry, PopColl } from 'src/database/store/StoreTypes';
import { Table, useTable } from 'src/hooks/useTable';
import AddCalProc from '../modals/AddCalProc/AddCalProc';
import TableHeader, { SortingPackage } from '../TableHeader/TableHeader';
import styles from './probeDetail.module.css';
import Backout from '../Backout/Backout';
import { unixToStr } from 'src/utils/general';
import useNavStore from '../RootNavBarIcon/NavStore';

type ColumnKey = 'name' | 'level' | 'tool' | 'revisionDate';

const calProcTable: Table<CalibrationProceduresDBEntry, ColumnKey> = {
  columns: [
    {
      key: 'name',
      title: 'Revision Number',
      extractFunction: (calProc) => calProc.revision_name,
    },
    {
      key: 'level',
      title: 'Level',
      extractFunction: (calProc) => calProc.level,
    },
    {
      key: 'tool',
      title: 'Tool',
      extractFunction: (calProc) => calProc.probe_type_id_ref?.tool_id_ref?.name,
    },
    {
      key: 'revisionDate',
      title: 'Revision Date',
      extractFunction: (calProc) => calProc.revision_date,
      headerAlign: 'right',
    }
  ],
};

type UsersProps = {};

const UserDetail: FC<UsersProps> = () => {
  const { probeId } = useParams<{ probeId: string }>();
  const { store } = useContext(StoreContext);
  const [AddCalProcModalIsOpen, setAddCalProcModalIsOpen] = useState(false);
  const [selectedCalProcId, setSelectedCalProcId] = useState('');
  const [currentSort, setCurrentSort] = useState<ColumnKey>('revisionDate');
  const [isAscending, setIsAscending] = useState(false);

  const probe = store.probe_types[probeId];
  const calProc = store.calibration_procedures[selectedCalProcId];

  
  const { isToogle } = useNavStore();
	
  /**
   * Customers can create their own calibration procedures. For the general probe
   * detail view, we just want to show the default calibration procedures. This is why
   * we ignore the ones with a customer_organization_id
   */
  const defaultCalProcs = useMemo(() => {
    const defaultCalProcs: PopColl<CalibrationProceduresDBEntry> = {};
    _.forEach(probe?.calibration_procedures, (calProc) => {
      if (!calProc.customer_organization_id) defaultCalProcs[calProc.id] = calProc;
    })
    return defaultCalProcs
  }, [probe, probe?.calibration_procedures])

  const handleOpenModal = useCallback((id: string) => {
    setSelectedCalProcId(id);
    setAddCalProcModalIsOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setSelectedCalProcId('');
    setAddCalProcModalIsOpen(false);
  }, []);

  const tableData = useTable(
    calProcTable,
    defaultCalProcs,
    currentSort,
    isAscending
  );

  if (!probe) return null;

  const sortingPackage: SortingPackage<ColumnKey> = {
    setCurrentSort,
    currentSort,
    setIsAscending,
    isAscending,
  };
  return (
    <div className={'mainContentContainer'}>
      <div className="header">
        <div>
          <Backout backoutTitle="Probes" />
          <h2>{probe.name}</h2>
        </div>
        <button onClick={() => handleOpenModal('')}>New Procedure</button>
      </div>
      
      <div style={{ marginLeft: isToogle === 'YES' ? '90px' : '' }}>
        <div  className={styles.makeModel}>
          <div>
            <h3>Model</h3>
            <p className={styles.probeInfo}>{probe.model}</p>
          </div>
          <div>
            <h3>Manufacturer</h3>
            <p className={styles.probeInfo}>{probe.manufacturer_workgroup_id_ref?.organization_id_ref?.name}</p>
          </div>
        </div>
        <h3 className="listTitle">Calibration Procedures</h3>
        <TableHeader
          columns={calProcTable.columns}
          rowClassName={styles.row}
          sortingPackage={sortingPackage}
        />
        {tableData.map(({ name, level, tool, revisionDate, id }) =>
        (<div
          key={id}
          className={`${styles.row} hvrPtr row`}
          onClick={() => handleOpenModal(id)}
        >
          <p>{name}</p>
          <p>{level}</p>
          <p>{tool}</p>
          <p style={{ textAlign: 'end' }} >{unixToStr(revisionDate)}</p>
        </div>
        )
        )}
      </div>
      <Modal isOpen={AddCalProcModalIsOpen} onRequestClose={handleCloseModal} className="modal" overlayClassName="modalOverlay">
        <AddCalProc
          seed={{
            id: calProc?.id,
            probeTypeId: probeId,
            name: calProc?.revision_name,
            level: calProc?.level,
            seqCount: calProc?.number_of_tests.toString(10),
            calStd: calProc?.calibration_standard_text,
            procDesc: calProc?.calibration_procedure_text,
            revDate: new Date(calProc?.revision_date),
          }}
          closeModal={handleCloseModal} />
      </Modal>
    </div>
  );
};

export default UserDetail;
