import { format } from 'date-fns';
import _, { chain, filter, orderBy } from 'lodash';
import {
	CalibrationProceduresDBEntry,
	CalibrationReadingsDBEntry,
	CalibrationsDBEntry,
	CertificatesDBEntry,
	JobsDBEntry,
	JobSensorsDBEntry,
	JobTechniciansDBEntry,
	ProbeTypesDBEntry,
	TechnicianToolsDBEntry,
	UsersDBEntry,
} from 'src/database/store/StoreTypes';
// import { ReadingInput } from '../components/modals/CalibrateSensor/CalibrateSensor';
import jwtDecode, { JwtPayload } from 'jwt-decode';
/**
 * Gets the completion percentage of a given Job.
 * @param job the JobsDBEntry of the Job
 * @returns a number representing the percentage of sensors completed
 */
export const getJobProgress = (job?: JobsDBEntry): number => {
	if (!job) return 0;
	const completedCount = _.filter(job.job_sensors, (sensor) => {
		if (sensor.is_disabled) {
			return true;
		}
		const mostRecentCal = getMostRecentCal(sensor);
		if (!mostRecentCal) return false;
		return mostRecentCal.status ||
			mostRecentCal.notes.includes('NO CALIBRATION')
			? true
			: false;
	}).length;

	const totalSensors = _.size(job.job_sensors);
	return completedCount / (totalSensors || 1);
};

/**
 * Takes a Unix date and converts it to a readable date string.
 * @param unix a number representing a Unix date
 * @returns a readable date string
 */
export const unixToStr = (unix: number): string =>
	unix ? format(new Date(unix), 'MM-dd-yyyy') : '';

/**
 * Gets the most recent Calibration for a given JobSensor.
 * @param sensor the JobSensorDBEntry of the JobSensor
 * @returns the latest CalibrationsDBEntry or null if none exist
 */
export function getMostRecentCal(
	sensor: JobSensorsDBEntry
): CalibrationsDBEntry | null {
	if (!sensor) return null;
	let mostRecentCalibration: CalibrationsDBEntry = _.reduce(
		sensor.calibrations,
		(prevCal, curCal) =>
			curCal.created_at > prevCal.created_at ? curCal : prevCal,
		{ created_at: 0 } as CalibrationsDBEntry
	);
	return mostRecentCalibration.id ? mostRecentCalibration : null;
}

/**
 * Gets the most recent Calibration for a given JobSensor.
 * @param calibrations the JobSensorDBEntry of the JobSensor
 * @returns the latest CalibrationsDBEntry or null if none exist
 */
export function getMostRecentCalReadings(
	calibrations: CalibrationsDBEntry |null
): CalibrationReadingsDBEntry | null {
	if (!calibrations) return null;
	let mostRecentCalReadings: CalibrationReadingsDBEntry = _.reduce(
		calibrations.calibration_readings,
		(prevCal, curCal) =>
			curCal.created_at > prevCal.created_at ? curCal : prevCal,
		{ created_at: 0 } as CalibrationReadingsDBEntry
	);
	return mostRecentCalReadings.id ? mostRecentCalReadings : null;
}

/**
 * Gets the most recent Certificate for a given Calibration.
 * @param cal the CalibrationDBEntry of the Calibration
 * @returns the latest CertificatesDBEntry or null if none exist
 */
export function getMostRecentCert(
	cal: CalibrationsDBEntry | null
): CertificatesDBEntry | null {
	if (!cal) return null;
	const sortedCerts = chain(cal?.certificates)
		.filter((cert) => Boolean(cert.id))
		.orderBy(['updated_at'], ['desc'])
		.value();
	return sortedCerts[0];
}

/**
 * Gets the tools for a given Technician.
 * @param tech the UsersDBEntry of the Technician
 * @returns a list of TechnicianToolsDBEntry
 */
export function getActiveToolbelt(
	tech: UsersDBEntry | null
): TechnicianToolsDBEntry[] {
	if (!tech) return [];
	return _.filter(
		tech.technician_tools,
		({ deactivated_date }) => !deactivated_date
	);
}

/**
 * Gets the active Technicians for a given Job.
 * @param job the JobsDBEntry of the Job
 * @returns a list of JobTechniciansDBEntry
 */
export function getActiveTechs(
	job: JobsDBEntry | null
): JobTechniciansDBEntry[] {
	if (!job) return [];
	return _.filter(
		job.job_technicians,
		({ deactivated_date }) => !deactivated_date
	);
}

/**
 * Gets the initials of a name.
 * @param name the full name
 * @returns the initials
 */
export const getInitials = (name: string = ''): string =>
	name
		.split(' ')
		.reduce((acc, name) => `${acc}${(name[0] || '').toUpperCase()}`, '');

/**
 * Finds the latest CalibrationProcedure for a given probe.
 * @param probe the ProbeTypesDBEntry of the Probe
 * @returns the CalibrationProceduresDBEntry of the latest calibration procedure
 */
export function latestDefaultCalProc(
	probe: ProbeTypesDBEntry
): CalibrationProceduresDBEntry | undefined {
	if (!probe) return undefined;
	const latestPotential = _.reduce(
		probe.calibration_procedures,
		(latest, current) =>
			!current.customer_organization_id &&
				current.created_at >= latest.created_at
				? current
				: latest,
		{ created_at: 0 } as CalibrationProceduresDBEntry
	);
	return latestPotential.id ? latestPotential : undefined;
}

// This pattern may seem hacky, but it's less obnoxious than passing all handlers through the context.
export function setStateInitializerFactory<T = any>(): (val: T) => any {
	return (value: T) => console.error('Context not initialized');
}

/**
 * Gets the last name (word) from a given string.
 * @param name the full name
 * @returns the last name
 */
export function getLastName(name: string): string {
	return name.split(' ').pop() || '';
}

/**
 * Converts from Celsius to Fahrenheit (rounding to one decimal place).
 * @param celsius degrees in Celsius
 * @returns degrees in Fahrenheit
 */
export function celsiusToFahrenheit(celsius: number): number {
	return parseFloat(
		(Math.round(((celsius * (9 / 5 ))+ 32) * 10) / 10).toFixed(1)
	);
}

/**
 * Converts from Fahrenheit to Celsius.
 * @param fahrenheit degrees in Fahrenheit
 * @returns degrees in Celsius
 */
export function fahrenheitToCelsius(fahrenheit: number): number {
	// return ((fahrenheit - 32) * (5/9));
	return Number(((fahrenheit - 32) * (5/9)).toFixed(2))
}

/**
 * Takes a Date object and returns a date string in ISO format.
 * @param date the Date object
 * @returns an ISO format string
 */
export function parseIsoDate(date: Date): string {
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();

	return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day
		}`;
}

/**
 * Takes a Date object and returns a date string in readable format. This is
 * particularly useful for passing values to <input type="date"> (see the AddCalProc modal).
 * @param date the Date object
 * @returns an input acceptable string
 */
export function parseReadableDate(date: Date): string {
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();

	return `${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day
		}-${year}`;
}

/**
 * Creates a data URI to allow users to download local CSV data.
 * @param csvData the string of CSV data
 * @returns a data URI
 */
export function createCsvDataUri(csvData: string) {
	const encodedUri = encodeURI(
		'data:text/csv;charset=utf-8,' + _.join(csvData.match(/[^\r\n]+/g), '\n')
	);

	// encodeURI does not escape '#' (this character also declares the end of file)
	// https://stackoverflow.com/questions/63924635/js-built-csv-breaking-after-in-excel
	return encodedUri.replaceAll('#', '%23');
}

/**
 * Downloads local data. A specific name can be specified.
 * @param dataUri the URI containing the local data
 * @param name an optional name for the download
 */
export function startLocalDownload(dataUri: string, name?: string) {
	if (name) {
		const link = document.createElement('a'); // Creating an <a> tag allows a specified name
		link.setAttribute('href', dataUri);
		link.setAttribute('download', name);
		document.body.appendChild(link); // Required for FF
		link.click();
	} else window.open(dataUri);
}

/**
 * Converts the CalibrationReading model to a list of ReadingInputs
 */
// export function calibrationReadingToReadingInput(
// 	calibrationReading: CalibrationReadingsDBEntry
// ): ReadingInput {
// 	const deviceReading =
// 		calibrationReading.device_humidity ||
// 		calibrationReading.device_temperature;
// 	const remoteReading =
// 		calibrationReading.remote_humidity ||
// 		calibrationReading.remote_temperature;
// 	const deviceReadingString = deviceReading ? String(deviceReading) : '';
// 	const remoteReadingString = remoteReading ? String(remoteReading) : '';
// 	return {
// 		sensor: deviceReadingString,
// 		calibration: remoteReadingString,
// 		updatedAt: calibrationReading.updated_at,
// 	};
// }

// I'm not proud of this but in my defense this was never clarified until the week of production
// This is for the celsius "conversions" for probe tolerance
export const getToleranceFScore = (tolerance: number) => {
	switch (tolerance) {
		case 1.12:
			return 2;
		case 0.56:
			return 1;
		case 5:
			return 9;
		default:
			return 0;
	}
};

/**
 * This function should only be used to check if a token is EXPIRED.
 * This should only be used to test is a user should try to go through the re-auth flow--
 * if their token is invalid or they don't have one, we want them to skip this and login from scratch.
 */
export function isTokenExpired(token?: string) {
	const jwt = token || localStorage.getItem('token');
	if (jwt) {
		const decoded = jwtDecode<JwtPayload>(jwt);
		const { exp } = decoded;
		if (!exp) {
			console.warn('No token exp found');
			return false;
		}
		const expMs = exp * 1000;
		const now = new Date().valueOf();
		if (now > expMs) {
			return true;
		}
	}
	return false;
}

/**
 * Returns first and last name from given `fullname`
 * @param fullname - Full name
 * @returns [firstname, lastname]
 */
export function extractFirstAndLastName(fullname: string): any[] {
	const parts = fullname.split(' ') || [];
	const first = parts?.slice(0, parts.length - 1).join(' ');
	const last = parts[parts.length - 1];
	return [first, last];
}

export function getRoleNameByRoleId(roleId: string) {
	switch (roleId) {
		case '311b83d9-2cc5-4c17-bc01-e2b9d006f1e2':
			return 'Admin';

		default:
		case 'e1c59d16-5bc0-4794-92e7-6bab999c7c63':
			return 'Customer';

		case 'd63cbb89-28ac-4742-bf95-54c7735ed798':
			return 'Technician';

		// TODO: Find out Manufacturer's role id
		case '':
			return 'Manufacturer';
	}
}
