import React from 'react';
import {
    BrowserRouter,
    Switch,
    Route,
} from "react-router-dom";

export const REDIRECT_PATH = '/redirect/:url'
export interface RouteProps {
    match: {
        params: {
            url: string;
        }
    }
}

/**
 * Provites a route on local domain that redirects to external domain. This allows
 * us to display the app domain in the registration email (as opposed to a non-user friendly
 * auth0 domain).
 */
const EmailLinkRedirect = () => {
    return (
        <BrowserRouter>
            <Switch>
                <RedirectRoute />
            </Switch>
        </BrowserRouter>
    );
}

export const RedirectRoute = () => {
    return (
        <Route path={REDIRECT_PATH} component={(props: RouteProps) => {
            window.location.replace(decodeURIComponent(props.match.params.url)); // .replace for better back button support
            return null;
        }} />
    )
}

export default EmailLinkRedirect;