import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';import _ from 'lodash';
import React from 'react';
// import { HighchartComp } from 'src/components/Highcharts/HighchartComp';
import { StoreContext } from 'src/database/store/StoreProvider';
import { JobsDBEntry } from 'src/database/store/StoreTypes';
import { JobStat, getJobStat } from './index';
import { JobProgressBar } from './JobProgressBar';

export type StatusOrder = 'completed' | 'failed' | 'pending';

interface JobOverviewRecord extends Partial<JobsDBEntry> {
	total_sensors: number;
}

export function ActiveJobDataBlock(props: {
	job: JobsDBEntry;
	onClick?: Function;
}) {
	const [jobStat] = React.useState<JobStat>(getJobStat(props.job));

	return (
		<div
			className="data-block-card"
			onClick={(e) => props.onClick && props.onClick.call(e) && true}
		>
			<div className="data-block-card-detail">
				<h5 className="data-block-job-name">{jobStat.name}</h5>
				<p className="data-block-job-role">{jobStat.role}</p>
				<p className="data-block-job-role">
					Total sensors: {jobStat.total}
				</p>
							</div>
			<div className="data-block-card-gview">
								<JobProgressBar stat={jobStat} />
			</div>
		</div>
	);
}

export function ActiveJobOverview(props: { onSelect: Function }) {
	const { store } = React.useContext(StoreContext);
	const [activeJobs, setActiveJobs] = React.useState<JobsDBEntry[]>([]);
	const [showAll, setLimitedJobs] = React.useState<boolean>(false);
	const [qRoleId, setQRoleId] = React.useState<string>('*');
	const [sortBy, setSortBy] = React.useState<StatusOrder>('completed');
	const [sortingOrder, setSortingOrder] = React.useState<'asc' | 'desc'>(
		'asc'
	);
	const jobs = _.values(store.jobs);
	let limit = showAll ? jobs.length : 2;

	function handleSortOrderChange() {
		if (sortingOrder === 'asc') {
			setSortingOrder('desc');
		} else {
			setSortingOrder('asc');
		}
	}

	React.useEffect(() => {
		setActiveJobs(
			_.slice(
				_.orderBy(
					_.filter(jobs, (job) => job.status === 'open'),

					['updated_at'],
					[sortingOrder]
				),
				0,
				limit
			)
		);
	}, [showAll]);

	React.useEffect(() => {
		setActiveJobs(
			_.slice(
				_.orderBy(
					_.filter(jobs as any, (job: JobOverviewRecord) => {
						if (job.status === 'open') {
							job['total_sensors'] = _.values(job.job_sensors).length;
							if (qRoleId === '*') {
								return true;
							}

							if (job.job_technicians) {
								return _.values(job.job_technicians).every((tech) => {
									if (tech) {
										if (
											tech.technician_id_ref &&
											tech.technician_id_ref.role_id === qRoleId
										) {
											return true;
										}
									}
								});
							}
						}
						return false;
					}),
					[sortBy],
					[sortingOrder]
				),
				0,
				limit
			)
		);
	}, [qRoleId, sortBy, sortingOrder]);

	return (
		<div className="data-block-row">
			<div className="data-block-header">
				<div className="data-block-label">Active Jobs</div>
				<div className="x-stack job-filter-bar">
					<div className="status-job-filter">
						<FontAwesomeIcon
							icon={
								sortingOrder === 'asc'
									? 'sort-numeric-down'
									: 'sort-numeric-up'
							}
							onClick={handleSortOrderChange}
						/>
						&nbsp;&nbsp;
						<select
							value={sortBy}
							onChange={(e) => setSortBy(e.target.value as StatusOrder)}
						>
							<option value="updated_at">Completion</option>
							<option value="name">Name</option>
							<option value="total_sensors">Total Sensors</option>
						</select>
					</div>
					<div className="role-based-job-filter">
						<select
							value={qRoleId}
							onChange={(e) => setQRoleId(e.target.value)}
						>
							<option value="*">All</option>
							<option value="311b83d9-2cc5-4c17-bc01-e2b9d006f1e2">
								Admin
							</option>
							<option value="e1c59d16-5bc0-4794-92e7-6bab999c7c63">
								Customer
							</option>
							<option value="d63cbb89-28ac-4742-bf95-54c7735ed798">
								Technician
							</option>
							<option value="manufacturer">Manufacturer</option>
						</select>
					</div>
				</div>
			</div>

			{activeJobs.map((activeJob) => (
				<ActiveJobDataBlock
					key={activeJob.name}
					job={activeJob}
					onClick={() => props.onSelect(activeJob)}
				/>
			))}

			{activeJobs.length > 0 ? (
				<button onClick={() => setLimitedJobs(!showAll)}>
					{showAll ? 'Show less' : 'Show more'}
				</button>
			) : (
				<p className="text-center">No jobs</p>
			)}
		</div>
	);
}
