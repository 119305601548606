import _ from 'lodash';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { authCtx } from 'src/api/AuthProvider';
import { StoreContext } from 'src/database/store/StoreProvider';
import { upsertCalProc } from 'src/models/CalibrationProcedure';
import { latestDefaultCalProc, parseIsoDate, getToleranceFScore } from 'src/utils/general';
import styles from "./addCalProc.module.css";

type AddCalProcProps = {
  closeModal: () => void;
  seed?: {
    selsectedToolId?:string;
    id?: string;
    probeTypeId?: string;
    name?: string;
    level?: string;
    seqCount?: string;
    calStd?: string;
    procDesc?: string;
    description?: string;
    revDate?: Date;
  }
};

const AddCalProcTool: FC<AddCalProcProps> = ({closeModal, seed = {} }) => {
  const { store } = useContext(StoreContext);

  
  const { justSync } = useContext(authCtx);
  const [toolId, setToolId] = useState(seed.selsectedToolId || '')
  const [id, setId] = useState(seed.id || null)
  const [probeTypeId, setProbeTypeId] = useState(seed.probeTypeId || '')
  const [name, setName] = useState(seed.name || '');
  const [level, setLevel] = useState(seed.level || '');
  const [description, setDescription] = useState(seed.description || '');
  const [revDate, setRevDate] = useState(seed.revDate||new Date());
  const [isProcessing, setIsProcessing] = useState(false);
  // console.log('store-------',store);
// console.log('toolId-------',toolId);
// console.log('seed-------',seed);

  const { probe_types } = store;
  // const probeType = probe_types[probeTypeId!];

  const isSubmissionReady = name && level   && description;
// Add Update ----------------------------------
  const handleSubmit = useCallback(async () => {
    if (!isSubmissionReady) return;
    setIsProcessing(true);
    await upsertCalProc({ id: seed?.id,probeTypeId, name, level, revDate ,toolId,description});
    justSync();
    setIsProcessing(false);
    closeModal();
  }, [isSubmissionReady, seed, name, level, revDate,description, closeModal]);

  return (
    <div className="modalContainer">
      <div className="modalHeader">
        <h2>Calibration Procedure</h2>
      </div>
      <div className="content">
        <div className="multiCol" style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
        <div>
            <label>Procedure Name</label>
            <input
              onChange={({ target }) => setName(target.value)}
              value={name}
              placeholder="Procedure Name"
            />
          </div>
          <div>
            <label>Revision Level</label>
            <input
              onChange={({ target }) => setLevel(target.value)}
              value={level}
              placeholder="Procedure Level"
            />
          </div>
          <div>
            <label>Revision Date</label>
            <input
              className={styles.inputDate}
              type="date"
              onChange={({ target }) => { setRevDate(new Date(`${target.value} 00:00:00`)) }}
              value={parseIsoDate(revDate)}
            />
          </div>
        </div>
    
        <label>Calibration Description</label>
        <textarea
          rows={8}
          onChange={({ target }) => setDescription(target.value)}
          value={description}
        />
      </div>
      <div className="footer">
        <button disabled={isProcessing} className="cancel" onClick={closeModal}>Cancel</button>
        <button disabled={!isSubmissionReady || isProcessing} onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

export default AddCalProcTool;
