import { Amplify } from 'aws-amplify';

export function configureAmplify() {
	Amplify.configure({
		Auth: {
			identityPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
			region: process.env.REACT_APP_COGNITO_REGION,
			userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
			userPoolWebClientId:
				process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
			oauth: {
				redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN,
				redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT,
			},
		},
	});
}
