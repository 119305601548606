import { Relation } from "@nozbe/watermelondb";
import { BelongsToAssociation } from "@nozbe/watermelondb/Model";
import { field, relation } from "@nozbe/watermelondb/decorators";
import { Association } from "src/types/watermelon";
import Calibration from "./Calibration";
import BaseModel from "./BaseModel";

export enum CalibrationReadingType {
  HUMIDITY="humidity",
  TEMP="temperature"
}

export default class CalibrationReading extends BaseModel {
  static table = "calibration_readings";
  static associations = {
    calibrations: {
      type: Association.BELONGS_TO,
      key: "calibration_id",
    } as BelongsToAssociation,
  };
  getTableName() {
    return CalibrationReading.table;
  }
  @field("reading2_date") reading2Date!: string;
  @field("reading1_date") reading1Date!: string;
  @field("reading3_date") reading3Date!: string;
  @field("reading4_date") reading4Date!: string;
  
  @field("sequence_number") SequenceNumber!: number;
  @field("remote_temperature") remoteTemperature!: number;
  @field("remote_temperature2") remoteTemperature2!: number;
  @field("remote_temperature3") remoteTemperature3!: number;
  @field("remote_temperature4") remoteTemperature4!: number;
  @field("device_temperature") deviceTemperature!: number;
  @field("device_temperature2") deviceTemperature2!: number;
  @field("device_temperature3") deviceTemperature3!: number;
  @field("device_temperature4") deviceTemperature4!: number;
  @field("remote_humidity") remoteHumidity!: number;
  @field("device_humidity") deviceHumidity!: number;
  @relation("calibrations", "calibration_id") calibration!: Relation<Calibration>;
}

export type CalibrationReadingUpdateBody = Pick<
  CalibrationReading,
  "SequenceNumber"
|"remoteTemperature"
|"deviceTemperature"
|"remoteTemperature2"
|"deviceTemperature2"
|"reading2Date"
|"reading1Date"
|"remoteTemperature3"
|"deviceTemperature3"
|"reading3Date"
|"remoteTemperature4"
|"deviceTemperature4"
|"reading4Date"
|"remoteHumidity"
|"deviceHumidity"
  
>;