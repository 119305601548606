import _ from 'lodash';
import React, { FC, useCallback, useContext, useState, useEffect } from 'react';
import { authCtx } from 'src/api/AuthProvider';
import { TechnicianToolsDBEntry } from 'src/database/store/StoreTypes';
import urls from 'src/api/urls';
import { StoreContext } from 'src/database/store/StoreProvider';
import { format } from 'date-fns';
import moment from 'moment';
import { toast } from 'react-toastify';
type AddProps = {
	assetToUpdate: TechnicianToolsDBEntry;
	closeModal: () => void;
};

const EditAsset: FC<AddProps> = ({ assetToUpdate, closeModal }) => {
	const { accessToken, justSync } = useContext(authCtx);
	const { store } = useContext(StoreContext);
	const assetType = store.tools;
	const assignedTechnicians = store.users;
	// let todayDate = moment(new Date()).format('yyyy-MM-DDThh:mm:ss')
	// let todayDate = new Date()
	const [excludeUsers, setExcludeUsers] = useState<any[]>();
	const { technician_tools } = store;
	const [assetId, setAssetId] = useState('');
	const [ID, setID] = useState('');
	const [assetTypeId, setAssetTypeId] = useState('');
	const [techniciansId, setTechniciansId] = useState('');
	let todayDate = moment(+new Date()).format('yyyy-MM-DD');
	const [dueDate, setDueDate] = useState('');
	const [isProcessing, setIsProcessing] = useState(false);
	const isSubmissionReady = assetId && assetTypeId && dueDate;
	const checkIsAssettypeAlreadyAssing = (
		assetType_Id: React.SetStateAction<string>
	) => {
		setAssetTypeId(assetType_Id);
		let abc = _.filter(
			technician_tools,
			(data) =>
				data.tool_id == assetType_Id &&
				data.calibration_due_date > +new Date()
		);
		setExcludeUsers(abc);
	};
	const techIsAvilbe = (id: any) => {
		let a = _.filter(excludeUsers, (data) => data?.technician_user_id == id);

		return a[0] ? false : true;
	};
	const handleAddEdit = useCallback(async () => {
		if (!isSubmissionReady) return;
		setIsProcessing(true);
		await (async () => {
			const { url, method } = urls.updateAsset;
			let bodyData = {};
			if (techniciansId) {
				bodyData = {
					toolId: `${assetTypeId}`,
					serialNumber: `${assetId}`,
					calibrationDueDate: `${dueDate}`,
					technicianUserId: `${techniciansId}`,
				};
			} else {
				bodyData = {
					toolId: `${assetTypeId}`,
					serialNumber: `${assetId}`,
					calibrationDueDate: `${dueDate}`,
					technicianUserId: null,
				};
			}
console.log('bodyData-----',bodyData);
console.log('technicianUserId-----',techniciansId);

			await fetch(`${urls.base}${url}${ID}`, {
				method,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
				},
				body: JSON.stringify(bodyData),
			}).then((res) => {
				res.status == 200
					? toast.success(`Company Assets Update Successfull`, {
							autoClose: 1000,
					  })
					: toast.error(`Error While Updating Company Assets `, {
							autoClose: 2000,
					  });
			});
			justSync();
			setIsProcessing(false);
			closeModal();
		})();
	}, [
		ID,
		assetId,
		assetTypeId,
		techniciansId,
		dueDate,
		isSubmissionReady,
		closeModal,
	]);

	useEffect(() => {
		setAssetId(assetToUpdate.serial_number);
		setAssetTypeId(assetToUpdate.tool_id);
		setTechniciansId(assetToUpdate.technician_user_id);
		setID(assetToUpdate.id);
		setDueDate(
			moment(+new Date(assetToUpdate.calibration_due_date)).format(
				'yyyy-MM-DD'
			)
		);
	}, [assetToUpdate]);

	return (
		<div className="modalContainer">
			<div className="modalHeader">
				<h2>Edit Company Assets</h2>
			</div>

			<div className="content">
				<h3>Asset Information</h3>
				<div
					className="multiCol"
					style={{ gridTemplateColumns: '1fr 1fr' }}
				>
					<div>
						<label>Asset ID</label>
						<input
							placeholder="Asset ID"
							onChange={({ target }) => setAssetId(target.value)}
							value={assetId}
						/>
					</div>

					<div>
						<label> Asset Type</label>
						<div className="selectContainer">
							<select
								value={assetTypeId}
								onChange={({ target }) =>
									checkIsAssettypeAlreadyAssing(target.value)
								}
							>
								<option value="">Select Group...</option>
								{_.map(assetType, ({ name, id }) => (
									<option key={id} value={id}>
										{name}
									</option>
								))}
							</select>
						</div>
					</div>
					<div>
						<label>Assigned Technicians</label>
						<div className="selectContainer">
							<select
								value={techniciansId}
								onChange={({ target }) =>
									setTechniciansId(target.value)
								}
							>
								<option value="">Select Group...</option>

								{_.map(assignedTechnicians, ({ role_id, name, id }) =>
									(role_id == 'e1c59d16-5bc0-4794-92e7-6bab999c7c63' ||
										role_id ==
											'311b83d9-2cc5-4c17-bc01-e2b9d006f1e2') &&
									techIsAvilbe(id) ? (
										<option key={id} value={id}>
											{name}
										</option>
									) : (
										<></>
									)
								)}
							</select>
						</div>
					</div>
					<div>
						<label>Due Date</label>
						<div className="selectContainer">
							<input
								type="date"
								value={dueDate}
								onChange={({ target }) => setDueDate(target.value)}
								min={todayDate}
							/>
						</div>
					</div>
				</div>
				<div className="footer">
					<button
						disabled={isProcessing}
						className="cancel"
						onClick={closeModal}
					>
						Cancel
					</button>
					<button
						disabled={!isSubmissionReady || isProcessing}
						onClick={handleAddEdit}
					>
						Submit
					</button>
				</div>
			</div>
		</div>
	);
};

export default EditAsset;
