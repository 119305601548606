import _ from 'lodash';
import { JobsDBEntry } from 'src/database/store/StoreTypes';
import { getMostRecentCal, getRoleNameByRoleId } from 'src/utils/general';

export interface JobStat {
	name: string;
	role: string;
	passed: number;
	failed: number;
	closed: number;
	total: number;
	createdAt: number;
	CertifiedCalCount :number;
	CalibratedCalCount :number;
	InProgressCalCount :number;
	IssueCalCount :number;
	NoCalCount :number;
	Sensors_to_be_Calibrated :number;
}

export interface JobStatRatio {
//    success: number;
//    warn: number;
//    error: number;
   CertifiedCalCount:number;
   CalibratedCalCount:number;
   InProgressCalCount:number;
   IssueCalCount:number;
   NoCalCount:number;
   Sensors_to_be_Calibrated:number;
}

export function getJobRatioFromStat(stat: JobStat): JobStatRatio {
	// let total = stat.passed + stat.failed + stat.closed;
	// const success = Number(Math.abs((stat.passed * 100) / total).toFixed(2)) || 0; // Finished
	// const warn = Number(Math.abs((stat.failed * 100) / total).toFixed(2)) || 0; // Issues
	// const error = Number(Math.abs(((total - (stat.passed +stat.failed)) * 100) / total).toFixed(2)) || 0; // Closed
	let total = stat.CertifiedCalCount + stat.CalibratedCalCount + stat.InProgressCalCount+ stat.IssueCalCount + stat.NoCalCount+ stat.Sensors_to_be_Calibrated;
	let CertifiedCalCount=Number(Math.abs((stat.CertifiedCalCount * 100) / total).toFixed(2)) || 0;
	let CalibratedCalCount=Number(Math.abs((stat.CalibratedCalCount * 100) / total).toFixed(2)) || 0
	let InProgressCalCount=Number(Math.abs((stat.InProgressCalCount * 100) / total).toFixed(2)) || 0
	let IssueCalCount=Number(Math.abs((stat.IssueCalCount * 100) / total).toFixed(2)) || 0
	let NoCalCount=Number(Math.abs((stat.NoCalCount * 100) / total).toFixed(2)) || 0
	let Sensors_to_be_Calibrated=Number(Math.abs((stat.Sensors_to_be_Calibrated * 100) / total).toFixed(2)) || 0




   return {
    //   success,
    //   warn,
    //   error,
	 CertifiedCalCount,
	CalibratedCalCount,
	InProgressCalCount,
	IssueCalCount,
	NoCalCount,
	Sensors_to_be_Calibrated,
   }
}

export function getJobStat(job: JobsDBEntry): JobStat {
	let name: string,
		role: string = '';
	let passed: number,
		failed: number,
		closed: number,
		total: number,
		createdAt: number;
		let CertifiedCalCount = 0;
		let CalibratedCalCount = 0;
		let InProgressCalCount = 0;
		let IssueCalCount = 0;
		let NoCalCount = 0;
		let Sensors_to_be_Calibrated = 0;

	name = job.name;

	if (job.job_technicians) {
		_.forEach(job.job_technicians, (tech) => {
			if (tech) {
				if (tech.technician_id_ref && tech.technician_id_ref.role_id) {
					role = getRoleNameByRoleId(tech.technician_id_ref.role_id);
				}
			}
		});
	}

	CertifiedCalCount = _.reduce(
		job.job_sensors,
		(validSensorCount, sensor) => {
			let mostRecentCalibration = getMostRecentCal(sensor);
			return mostRecentCalibration?.status === 'Certified'
				? validSensorCount + 1
				: validSensorCount;
		},
		0
	);
	CalibratedCalCount = _.reduce(
		job.job_sensors,
		(validSensorCount, sensor) => {
			let mostRecentCalibration = getMostRecentCal(sensor);
			return mostRecentCalibration?.status === 'Calibrated'
				? validSensorCount + 1
				: validSensorCount;
		},
		0
	);
	InProgressCalCount = _.reduce(
		job.job_sensors,
		(validSensorCount, sensor) => {
			let mostRecentCalibration = getMostRecentCal(sensor);
			return mostRecentCalibration?.status === 'InProgress'
				? validSensorCount + 1
				: validSensorCount;
		},
		0
	);
	CalibratedCalCount = _.reduce(
		job.job_sensors,
		(validSensorCount, sensor) => {
			let mostRecentCalibration = getMostRecentCal(sensor);
			return mostRecentCalibration?.status === 'Calibrated'
				? validSensorCount + 1
				: validSensorCount;
		},
		0
	);
	IssueCalCount = _.reduce(
		job.job_sensors,
		(validSensorCount, sensor) => {
			let mostRecentCalibration = getMostRecentCal(sensor);
			return mostRecentCalibration?.status === 'Issue'
				? validSensorCount + 1
				: validSensorCount;
		},
		0
	);
	NoCalCount = _.reduce(
		job.job_sensors,
		(validSensorCount, sensor) => {
			let mostRecentCalibration = getMostRecentCal(sensor);
			return mostRecentCalibration?.status === 'No'
				? validSensorCount + 1
				: validSensorCount;
		},
		0
	);
	Sensors_to_be_Calibrated = _.reduce(
		job.job_sensors,
		(validSensorCount, sensor) => {
			let mostRecentCalibration = getMostRecentCal(sensor);
			return !mostRecentCalibration?.status
				? validSensorCount + 1
				: validSensorCount;
		},
		0
	);
	passed = _.reduce(
		job.job_sensors,
		(validSensorCount, sensor) => {
			let mostRecentCalibration = getMostRecentCal(sensor);	
			return mostRecentCalibration?.status === 'Certified'
				? validSensorCount + 1
				: validSensorCount;
		},
		0
	);

	failed = _.reduce(
		job.job_sensors,
		(failedSensorCount, sensor) => {
			let mostRecentCalibration = getMostRecentCal(sensor);
			return mostRecentCalibration?.status === 'Calibrated'
				? failedSensorCount + 1
				: failedSensorCount;
		},
		0
	);

	closed = job.closed_at || 0;
	total = _.size(job.job_sensors);
	createdAt = job.created_at;

	return {
		name,
		role,
		passed,
		failed,
		closed,
		total,
		createdAt,
		CertifiedCalCount,
		CalibratedCalCount,
		InProgressCalCount,
		IssueCalCount,
		NoCalCount,
		Sensors_to_be_Calibrated
	};
}
