import { BelongsToAssociation } from "@nozbe/watermelondb/Model";
import { writer, field, relation } from "@nozbe/watermelondb/decorators";
import { Relation } from "@nozbe/watermelondb";
import { Association } from "src/types/watermelon";
import BaseModel from "./BaseModel";
import Job from "./Job";
import User from "./User";
import JobLeadTechnician from "./JobLeadTechnician";

export default class JobTechnician extends BaseModel {
  static table = "job_technicians";
  static associations = {
    jobs: {
      type: Association.BELONGS_TO,
      key: "job_id",
    } as BelongsToAssociation,
    users: {
      type: Association.BELONGS_TO,
      key: "technician_id",
    } as BelongsToAssociation,
    job_lead_technicians: {
      type: Association.BELONGS_TO,
      key: "job_lead_technician_id",
    } as BelongsToAssociation,
  };
  getTableName() {
    return JobTechnician.table;
  }
  @relation("jobs", "job_id") job!: Relation<Job>;
  @relation("users", "technician_id") technician!: Relation<User>;
  @field("deactivated_date") deactivatedDate?: number;
  @relation("job_lead_technicians", "job_lead_technician_id") jobLeadTechnician!: Relation<JobLeadTechnician>;
  @writer async disassociate() {
    await this.update(jobTech => {
      jobTech.deactivatedDate = Date.now();
    });
  };
  @writer async reassociate() {
    await this.update(jobTech => {
      jobTech.deactivatedDate = undefined;
    });
  };
}
