import _ from 'lodash';
import React, {
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { authCtx } from 'src/api/AuthProvider';
import { StoreContext } from 'src/database/store/StoreProvider';
import styles from './AddProbes.module.css';
import urls from 'src/api/urls';
import useOrganizationTypes from 'src/hooks/useOrganizationTypes';
import { JobWorkgroupType } from 'src/models/JobWorkgroup';
type AddProbesProps = {
	closeModal: () => void;
	probeId: string | null;
};

const AddEditProbes: FC<AddProbesProps> = ({ closeModal, probeId }) => {
	const { accessToken, justSync } = useContext(authCtx);
	const { store } = useContext(StoreContext);

	const orgsOfType = useOrganizationTypes('manufacturer');
	const workgroups = store.workgroups;

	let probe;
	if (probeId) probe = store.probe_types[probeId];
	const assetType = store.tools;
	const [ID, setID] = useState(probe?.id ?? '');
	const [name, setName] = useState(probe?.name ?? '');
	const [model, setModel] = useState(probe?.model ?? '');
	const [tolerance, setTolerance] = useState(probe?.tolerance ?? '');
	const [toleranceUnit, setToleranceUnit] = useState(
		probe?.tolerance_unit ?? ''
	);
	const [toolId, setToolId] = useState(probe?.tool_id ?? '');
	const [workrgoupId, SetWorkgroupId] = useState(
		probe?.manufacturer_workgroup_id ?? ''
	);
	if (probe?.manufacturer_workgroup_id) {
		let org = workgroups[probe?.manufacturer_workgroup_id].organization_id;
	}
	const [orgId, setOrgId] = useState(
		probe?.manufacturer_workgroup_id
			? workgroups[probe?.manufacturer_workgroup_id]?.organization_id
			: ''
	);
	const orgType = 'manufacturer';
	const org = store.organizations[orgId];

	const [isProcessing, setIsProcessing] = useState(false);
	const isSubmissionReady =
		name && model && tolerance && toleranceUnit && toolId && workrgoupId;

	const handleAddEdit = useCallback(async () => {
		if (!isSubmissionReady) return;
		setIsProcessing(true);

		let bodyData = {
			name: `${name}`,
			model: `${model}`,
			tolerance: `${tolerance}`,
			toleranceUnit: `${toleranceUnit}`,
			toolId: `${toolId}`,
			manufacturerWorkgroupId: `${workrgoupId}`,
		};
		await (async () => {
			if (!ID) {
				console.log('ADD ONLY');
				const { url, method } = urls.addProbeType;
				await fetch(`${urls.base}${url}`, {
					method,
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`,
					},
					body: JSON.stringify(bodyData),
				});
			} else {
				const { url, method } = urls.updateProbeType;
				console.log('Update  ONLY');
				await fetch(`${urls.base}${url}${ID}`, {
					method,
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`,
					},
					body: JSON.stringify(bodyData),
				});
			}
			justSync();
			setIsProcessing(false);
			closeModal();
		})();
	}, [
		ID,
		name,
		model,
		tolerance,
		toleranceUnit,
		toolId,
		workrgoupId,
		isSubmissionReady,
		closeModal,
	]);
	return (
		<div className="modalContainer">
			<div className="modalHeader">
				<h2> {probeId ? 'Edit' : 'Add'} Probe</h2>
			</div>
			<div className="content">
				<label>Probe Details</label>

				<div
					className="multiCol"
					style={{ gridTemplateColumns: '2fr 1fr' }}
				>
					<div>
						<label>Name</label>
						<input
							onChange={({ target }) => setName(target.value)}
							defaultValue={name}
						/>
					</div>
					<div>
						<label>Model</label>
						<input
							onChange={({ target }) => setModel(target.value)}
							defaultValue={model}
						/>
					</div>
				</div>

				<div
					className="multiCol"
					style={{ gridTemplateColumns: '2fr  2fr' }}
				>
					<div>
						<label>Tolerance</label>
						<input
							onChange={({ target }) =>
								setTolerance(Number(target.value))
							}
							defaultValue={tolerance}
						/>
					</div>
					<div>
						<label>Tolerance Unit</label>
						<div className="selectContainer">
							<select
								defaultValue={toleranceUnit}
								onChange={({ target }) =>
									setToleranceUnit(target.value)
								}
							>
								<option value="">Tolerance Unit...</option>
								<option value="C">C</option>
								<option value="F">F</option>
								<option value="%">%</option>
							</select>
						</div>
					</div>
				</div>
				<div
					className="multiCol"
					style={{ gridTemplateColumns: '3fr  3fr 3fr' }}
				>
					<div>
						<label>Tool</label>
						<div className="selectContainer">
							<select
								defaultValue={toolId}
								onChange={({ target }) => setToolId(target.value)}
							>
								<option value="">Select Tool...</option>
								{_.map(assetType, ({ name, id }) => (
									<option key={id} value={id}>
										{_.capitalize(name)}
									</option>
								))}
							</select>
						</div>
					</div>
					<div>
						<label>Manufacturer</label>
						<div className="selectContainer">
							<select
								value={orgId}
								onChange={({ target }) => setOrgId(target.value)}
							>
								<option value="">Select Manufacturer</option>
								{_.map(orgsOfType, ({ name, id }) => (
									<option key={id} value={id}>
										{name}
									</option>
								))}
							</select>
						</div>
					</div>
					<div>
						<label>Group</label>
						<div className="selectContainer">
							<select
								disabled={!org}
								value={workrgoupId}
								onChange={({ target }) => SetWorkgroupId(target.value)}
							>
								<option value="">Select Group...</option>
								{_.map(org?.workgroups, ({ name, id }) => (
									<option key={id} value={id}>
										{name}
									</option>
								))}
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="footer">
				<button
					disabled={isProcessing}
					className="cancel"
					onClick={closeModal}
				>
					Cancel
				</button>
				<button
					disabled={!isSubmissionReady || isProcessing}
					onClick={handleAddEdit}
				>
					Submit
				</button>
			</div>
		</div>
	);
};

export default AddEditProbes;
