import React, { FC, useCallback, useState } from "react";
import styles from "./styles.module.css";
import logo from "../../../assets/img/fcs-logo.png"
import PasswordResetSuccessModal from "./PasswordResetSuccessModal";
import Bugsnag from "@bugsnag/js";


type ResetPasswordProps = {
    goBack: () => void;
}

const ResetPassword: FC<ResetPasswordProps> = ({ goBack }) => {
    const [email, setEmail] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleForgotPassword = useCallback(async () => {
        setIsProcessing(true);
        try {
            const url = `https://${process.env.REACT_APP_AUTH_DOMAIN!}/dbconnections/change_password`;
            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({
                    client_id: process.env.REACT_APP_CLIENT_ID!,
                    connection: 'Username-Password-Authentication',
                    email
                })
            };
            const res = await fetch(url, options);
            if (res.status !== 200) throw res.statusText
        } catch (err) {
            Bugsnag.notify(err as any);
            console.error(err);
            return;
        }
        setIsProcessing(false);
        setIsModalVisible(true);
    }, [email])

    return (
        <div className={styles.leftSide}>
            <img src={logo} className={styles.companyLogo} alt="logo" />
            <p className={styles.companyName}>OpCen</p>
            <p className={styles.pageTitle}>Reset Password</p>
            <p className={styles.pageDescription}>Enter the email address you use to sign in, and a link to reset your password will be emailed to you.</p>
            <div className={styles.emailContainer}>
                <label className={styles.emailHint}>Email Address</label>
                <input
                    onChange={({ target }) => setEmail(target.value)}
                    value={email}
                />
            </div>
            <button disabled={isProcessing} className={styles.cancelButton} onClick={() => goBack()}>Cancel</button>
            <button disabled={isProcessing} className={styles.requestLinkButton} onClick={handleForgotPassword} >Request Link</button>
            {isModalVisible && <PasswordResetSuccessModal goBack={goBack} />}
        </div>
    );
};

export default ResetPassword;
