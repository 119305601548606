import _ from 'lodash';
import React, { FC, useCallback, useContext, useState,useEffect } from 'react';
import { authCtx } from 'src/api/AuthProvider';
import urls from 'src/api/urls';
import { StoreContext } from 'src/database/store/StoreProvider';
import moment from 'moment';
import { toast } from 'react-toastify';

type AddProps = {
  closeModal: () => void;
};

const AddAsset: FC<AddProps> = ({ closeModal }) => {
  const { accessToken, justSync } = useContext(authCtx);
  const { store } = useContext(StoreContext);
  const [assetId, setAssetId] = useState('');
  const assetType = store.tools
  const { technician_tools } = store;
  const assignedTechnicians = store.users
  let todayDate = (moment(+ new Date()).format('yyyy-MM-DD'));
  const [assetTypeId, setAssetTypeId] = useState('');
  const [techniciansId, setTechniciansId] = useState('');
  const [dueDate, setDueDate] = useState(todayDate)
  const [excludeUsers, setExcludeUsers] = useState <any[]>()
  const [isProcessing, setIsProcessing] = useState(false);
  const isSubmissionReady = assetId && assetTypeId  && dueDate;
  
  const handleAddEdit = useCallback(async () => {
    setExcludeUsers([])
    if (!isSubmissionReady) return;
    setIsProcessing(true);
    await (async () => {
      const { url, method } = urls.addAsset;
      let dt = new Date(dueDate)
      let ts = dt.getTime()
      
      let bodyData = {
        "toolId": `${assetTypeId}`,
        "serialNumber": `${assetId}`,
        "calibrationDueDate": `${ts}`,
        "technicianUserId": `${techniciansId}`,
      }
      
      await fetch(`${urls.base}${url}`, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(bodyData)
      }).then((res) => {
        console.log('Add Company Asset res',res);
        
				res.status == 200
					? toast.success(`Company Assets Added Successfull`, { autoClose: 1000 })
					: toast.error(`Error While Adding Company Assets `, {
							autoClose: 2000,
					  });
			});
      
      justSync()
      setIsProcessing(false);
      closeModal();
      
    })()
  }, [assetId, assetTypeId, techniciansId, dueDate, isSubmissionReady, closeModal]);

  
  const checkIsAssettypeAlreadyAssing=(assetType_Id: React.SetStateAction<string>)=>{
    setAssetTypeId(assetType_Id)
    let abc =_.filter(technician_tools, (data)=>data.tool_id==assetType_Id && data.calibration_due_date > +new Date())
     setExcludeUsers(abc)
     }
const techIsAvilbe=(id:any)=>{
let a=_.filter(excludeUsers ,(data)=>data?.technician_user_id==id)

return a[0]?false:true
}

  return (
    <div className="modalContainer">

      <div className="modalHeader" >
        <h2>Add Company Asset</h2>
      </div>

      <div className='content'>
        <h3>Assets Information</h3>
        <div className="multiCol" style={{ gridTemplateColumns: '1fr 1fr' }}>
          <div>
            <label>Asset ID</label>
            <input placeholder='Asset ID'
              onChange={({ target }) => setAssetId(target.value)}
              value={assetId}
            />
          </div>

          <div>

            <label> Asset Type</label>
            <div className="selectContainer">
              <select
                value={assetTypeId}
                onChange={({ target }) => checkIsAssettypeAlreadyAssing(target.value)}
              >
                <option value="">Select Asset Type...</option>
                {
                  _.map(assetType, ({ name, id }) => (<option key={id} value={id}>{name}</option>))
                }
              </select>
            </div>
          </div>
          <div>
            <label>Assigned Technicians</label>
            <div className="selectContainer">
              <select
                value={techniciansId}
                onChange={({ target }) => setTechniciansId(target.value)}
                disabled={!assetTypeId}
              >
                <option value="">Select Technicians...</option>

                {
                  
                  _.map(assignedTechnicians, ({ role_id, name, id }) => (
                    (role_id == 'e1c59d16-5bc0-4794-92e7-6bab999c7c63' || role_id == '311b83d9-2cc5-4c17-bc01-e2b9d006f1e2') && techIsAvilbe(id)  ? <option key={id} value={id}>{name}</option> : <></>
                    ))
                  }
                  
              </select>
            </div>
          </div>
          <div>
            <label>Due Date</label>
            <div className="selectContainer">
              <input type="date"
                value={dueDate}
                onChange={({ target }) => setDueDate(target.value)}

                min={todayDate}
              />

            </div>
          </div>

        </div>
        <div className="footer">
          <button disabled={isProcessing} className="cancel" onClick={closeModal}>Cancel</button>
          <button disabled={!isSubmissionReady || isProcessing} onClick={handleAddEdit}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default AddAsset;
